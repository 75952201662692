
// services
import getFeedbackRoleOfUser from "./getFeedbackRoleOfUser";

// constants
import { ROLE_HIERARCHY } from "./constants";

const sortFeedbackList = (feedbackList) => {
    if (!feedbackList) {
        return [];
    }
    return feedbackList?.sort((a, b) => {
        return ROLE_HIERARCHY[b.getCurrentValue("model")] - ROLE_HIERARCHY[a.getCurrentValue("model")];
    });
}

export const placeUserFeedbackLast = (feedbackList) => {
    // Assuming feedbackList is an array of feedback objects
    const model = getFeedbackRoleOfUser(); // Get the model value

    // Filter the feedback items that match the model (the current user)
    const userFeedback = feedbackList.filter(feedback => feedback.getCurrentValue("model") === model);

    // Filter the feedback items that do not match the model (the current user)
    const nonMatchingFeedback = feedbackList.filter(feedback => feedback.getCurrentValue("model") !== model);

    // Combine non-matching feedback first, then matching feedback at the end
    return [...nonMatchingFeedback, ...userFeedback];
}

export default sortFeedbackList;
