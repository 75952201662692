
// routing
import { useHistory } from "react-router-dom";

// styling
import "./TopBar.scss";

// components
import FetchAssignment from "../../../../../../../../../../components/ServiceComponents/FetchAssignment/FetchAssignment";
import Loader from "../../../../../../../../../../components/Loader";

// constants
import { OVERVIEW_EXPANDED } from "../../../../../../services/constants";

const TopBar = (props) => {

    // routing
    let history = useHistory();

    const toAssignment = () => {
        history.push(`/opdrachten/${props.assignmentUuid}`);
    }

    return (
        <div className="gradingExamsOverviewTopBarView" >
            
            {
                props.overviewWidth !== OVERVIEW_EXPANDED ? null :
                !props.assignment && props.loading ? <Loader /> :
                <div 
                    className="gradingExamsOverviewTopBarFoldingTitle"
                    onClick={() => toAssignment()}
                >
                    <div className="gradingExamsOverviewTopBarFoldingIntroduction" >Opdracht:</div>
                    {
                        props.assignment?.name
                    }
                    {props.loading ? <div className="gradingExamsOverviewTopBarFoldingLoader" ><Loader /></div> : null}
                </div>
            }

            <div
                className="gradingExamsOverviewTopBarFoldingButton" 
                onClick={() => props.changeOverviewWidth()}
            >{
                props.overviewWidth === OVERVIEW_EXPANDED ? "Nakijken" : "Overzicht"
            }</div>

            <FetchAssignment
                assignmentUuid={props.assignmentUuid}
                updateAssignment={props.updateAssignment}

                toggleLoading={props.toggleLoading}
            />
        </div>
    )
}

export default TopBar;
