
// standard objects
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { NO_CONTENT, UNAUTHORIZED} from "../../../../../../../services/errors/constants";

const passwordErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: "Verkeerd wachtwoord",
                description: "Verkeerd wachtwoord.",
                advice: null,
                moreInfo: null
            });

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Geen wachtwoord ingevult",
                description: "Je moet wel een wachtwoord invullen.",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default passwordErrors;
