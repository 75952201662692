
// style
import "./invalidExam.scss";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

const InvalidExam = (props) => {

    const ignoreWarning = () => {
        props.ignoreWarning({examState: props.lastSelectedState});
    }

    const close = () => {

        props.toggleValidate(false);
        props.toggleShowInvalid(false)
    }

    return (
        <div
            className="InvalidExam"
        >

            <div className="InvalidExamTitle" >
                Niet alles is ingevuld
            </div>

            {
                !props.errorClass ? null : props.errorClass.errorCode === SUCCESS ? null :
                <div className="InvalidExamError" >{
                    props.errorClass.description
                }</div>
            }

            {
                !props.warningClass ? null : props.warningClass.errorCode === SUCCESS ? null :
                <div className="InvalidExamWarning" >{
                    props.warningClass.description
                }</div>
            }

            <div
                className="InvalidExamOptions"
            >

                

                {
                    !props.errorClass ? 
                    <div
                        className="InvalidExamProceed"
                        onClick={() => ignoreWarning()}
                    >{

                        props.proceedText ? props.proceedText : "Toch Opslaan"

                    }</div>
                    : props.errorClass.errorCode !== SUCCESS ? null : 
                    <div
                        className="InvalidExamProceed"
                        onClick={() => ignoreWarning()}
                    >{

                        props.proceedText ? props.proceedText : "Toch Opslaan"

                    }</div>
                }

                    <div
                        className="InvalidExamCancle"
                        onClick={() => close()}
                    >{

                        props.cancleText ? props.cancleText : "Annuleren"

                    }</div>

            </div>

        </div>
    )

}

export default InvalidExam;
