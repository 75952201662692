
class ExamVersions {
    constructor() {
        this.publishedExam = null;
        this.draftExam = null;
    }

    setPublished(exam) {
        this.publishedExam = exam;
    }

    setDraft(exam) {
        this.draftExam = exam;
    }
}

export default ExamVersions;
