
// style
import "./AboutUs.scss";

// local components
import EtAlia from "./components/EtAlia"
import EntityView from "./components/EntityView";

// components
import PageNav from "../../components/PageNav";

// classes
import tab from "../../components/PageNav/services/classes";

const AboutUs = (props) => {
    
    return (
        <div className="aboutUsView" >

            <PageNav
                tabs={[
                    new tab({
                        id: 1,
                        title: "EtAlia Introduceren",
                        component: <EtAlia />
                    }),
                    new tab({
                        id: 2,
                        title: "Onze Geniale Content Creators",
                        component: <EntityView type="creators" />
                    }),
                    new tab({
                        id: 3,
                        title: "Onze vaste adviseurs",
                        component: <EntityView type="advisors" />
                    }),
                    new tab({
                        id: 4,
                        title: "Partners",
                        component: <EntityView type="partners" />
                    })
                ]}
            />

        </div>
    )
}

export default AboutUs;
