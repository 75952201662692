
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const valitateLevelInital = (schoolLevel) => {

    switch (schoolLevel) {
        case "VMBO":
            return BAD_REQUEST;

        case "praktijkonderwijs":
            return BAD_REQUEST;

        default:
            return SUCCESS;
    }
}

export default valitateLevelInital;
