
import parse from "html-react-parser";

const iframeLink = (url) => {
    if (!url) {
        return null;
    }

    const useableUrl = parse(url);
    
    if (useableUrl.includes("youtube")) {
        return useableUrl;
    } else {
        return `https://www.youtube.com/embed/${useableUrl}`
    }

}

export default iframeLink;
