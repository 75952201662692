
import React, { useState, useEffect } from 'react';

// style
import "./TopBar.scss";

// components
import LocalModal from "../../../../../../components/LocalModal/LocalModal";

// local components
import InfoPointer from "./components/Pointer";
import DelQ from "./components/DelQ";
import Points from "./components/Points";
import DelQConfermation from "./components/DelQConfermation";

// constants
import { EDIT } from "../../../../services/tabConstants";

const TopBar = (props) => {

    const [initDel, toggleInitDel] = useState(false);
    const [showDeleteWarning, toggleShowDeleteWarning] = useState(false);

    const removeQuestion = () => {

        props.questionHub.removeQuestion();
        props.updateQuestionDeleted(true);

    }

    const deleteQuestion = () => {

        // throw up warning
        toggleShowDeleteWarning(true);

    }

    return (

        <div
            className={`questionHeaderInfo ${initDel ? "showDelOptions" : ""}`}
        >{
            initDel === false ?
                <>
                
                    <InfoPointer
                        number={props.questionHub.number+1}

                        showInfo={props.showInfo}
                        toggleShowInfo={props.toggleShowInfo}

                        showQuestionInfo={props.showQuestionInfo}
                        toggleShowQuestionInfo={props.toggleShowQuestionInfo}
                    />

                    {
                        props.examView !== EDIT ? null :
                        <DelQ
                            title="Vraag verwijderen"
                            questionHub={props.questionHub}

                            whenClicked={() => toggleInitDel(value => !value)}
                            toggleInitDel={() => toggleInitDel(value => !value)}
                        />
                    }

                    <Points
                        examView={props.examView}

                        points={props.points}
                        updatePoints={props.updatePoints}

                        questionHub={props.questionHub}
                        answerhubs={props.answerhubs}

                        // exam edit
                        questionType={props.questionType}

                        // exam check
                        updateUserPoints={props.updateUserPoints}
                        scoredPoints={props.scoredPoints}

                        triggerReRender={props.triggerReRender}

                        // validation
                        pointsWarning={props.pointsWarning}
                        updatePointsWarning={props.updatePointsWarning}
                    />

                </>

                :

                <div>
                    <DelQ
                        title="Permanent verwijderen"
                        questionHub={props.questionHub}

                        whenClicked={deleteQuestion}
                        toggleInitDel={() => toggleInitDel(value => !value)}
                    />

                    <DelQ
                        title="Vraag uit toets verwijderen"
                        questionHub={props.questionHub}

                        whenClicked={removeQuestion}
                        toggleInitDel={() => toggleInitDel(value => !value)}
                    />

                    <DelQ
                        title="Annuleren"
                        questionHub={props.questionHub}

                        whenClicked={() => toggleInitDel(value => !value)}
                        toggleInitDel={() => toggleInitDel(value => !value)}
                    />
                </div>

        }
        
        <LocalModal
            
            show={showDeleteWarning}
            toggleShow={toggleShowDeleteWarning}
            component={
                
                <DelQConfermation
                
                    updateQuestionDeleted={props.updateQuestionDeleted}

                    questionHub={props.questionHub}
                    updateQuestionHubs={props.updateQuestionHubs}
                
                    toggleShowDeleteWarning={toggleShowDeleteWarning}
                
                />
            }
        
        />

        </div>

    )
}

export default TopBar;
