import React from 'react';
import { Container, Row } from 'react-bootstrap';
import NavBar from "../../newSRC/Views/NavBar";
import './Layout.scss';

const layout = (props) => {
    return (
        <Container>
            <NavBar />
            {/* <EtAliaNavbar /> */}
            <main>
                {props.children}
            </main>
        </Container>
    )
}
export default layout;