
// standard objects
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { SUCCESS, UNAUTHORIZED, CONFLICT } from "../../../../../../../services/errors/constants";

const authRegisterErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Je bent ingelogd",
                description: "Je bent ingelogd",
                advice: null,
                moreInfo: null,
            });

        // add incorrect password
        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: "Verkeerd wachtwoord",
                description: "Verkeerd wachtwoord",
                advice: null,
                moreInfo: null
            });

        case CONFLICT:

            return new errorClass({
                errorCode: CONFLICT,
                priority: 1,
                title: "Dit mail adress bestaat all",
                description: "Dit mail adress bestaat all in ons systeem. Login of herstel je wachtwoord door op 'INLOGGEN' te klikken, rechtsboven in je screm.",
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default authRegisterErrors;
