
// standard objects
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { SUCCESS, UNAUTHORIZED, NOT_FOUND } from "../../../../../../../services/errors/constants";

const authUserErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Je bent ingelogd",
                description: "Je bent ingelogd",
                advice: null,
                moreInfo: null,
            });

        // add incorrect password
        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: "Verkeerd wachtwoord",
                description: "Verkeerd wachtwoord",
                advice: null,
                moreInfo: null
            });

        case NOT_FOUND:

            return new errorClass({
                errorCode: NOT_FOUND,
                priority: 1,
                title: "Dit mail adres bestaat niet",
                description: "Dit mail adres bestaat niet in ons systeem. Weet je zeker dat je een account hebt?",
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default authUserErrors;
