
// constants
import { EDITOR_ACCESS, USE_ACCESS } from "../../../../../../services/access/contentAccess/contentAccessConstants";
import { CHECK, EDIT, MAKE, VIEW } from "../../../../services/tabConstants";

// does the user have access to view the answer
const answerAccess = ({
    examView,
    userAccess
} = {}) => {

    if (examView === CHECK) {
        return true;
    } else if (examView === MAKE) {
        return false;
    } else if (examView === VIEW) {
        if (userAccess === USE_ACCESS || userAccess === EDITOR_ACCESS) {
            return true;
        }
        return false;
    } else if (examView === EDIT) {
        return true;
    }

    // questions should not be shown on other pages
    return false;

}

export default answerAccess;
