
// classes
import CustomErrorsManager from "../classes/CustomErrorsManager";

// constants
import { BAD_REQUEST, NO_CONTENT } from "../constants";

export const defaultDateInputErrors = new CustomErrorsManager({
    [NO_CONTENT]: {
        title: "Je hebt geen datum en/of tijd ingevuld. Beide moeten ingevuld zijn."
    },
    [BAD_REQUEST]: {
        title: "De datum/tijd is niet in het correcte format. Er kan een systeemfout hebben plaatsgevonden."
    }
});

export const defaultDateInputNotRequiredErrors = new CustomErrorsManager({
    [NO_CONTENT]: {
        title: "Als je datum of tijd invult, moet je beide de datum en tijd invullenn."
    },
    [BAD_REQUEST]: {
        title: "De datum en/of tijd is niet in het correcte format. Er kan een systeemfout hebben plaatsgevonden."
    }
});
