import { useState } from "react";

// style
import "./AutogradingPermissions.scss";

// classes
import errorClass from "../../../../services/errors/classes/errorClass";

// services
import handleEnvironment from "../../../../services/handleEnvironment/handleEnvironment";

// components
import Title from "../../../../components/Alert/components/Title";
import LocalModal from "../../../../components/LocalModal";
import Button from "../../../../components/Alert/components/Button";
import Boolean from "../../../../components/input/Boolean/Boolean";

// constants
import { BAD_REQUEST, INTERNAL_ERROR, SUCCESS } from "../../../../services/errors/constants";
import { ETALIA_ENV } from "../../../../services/handleEnvironment/constants";
import { ADD_LOCAL_STORAGE, REMOVE_LOCAL_STORAGE } from "../../../../services/localStorage/constants";
import { LAST_ERROR_GENERATED_FEEDBACK } from "../../services/constants/feedbackGeneration";
import handleLocalStorage from "../../../../services/localStorage/localStorage";
import examEvaluation from "../../../../services/Networking/examEvaluation/examEvaluation";
import Redux from "../../../../components/Redux/Redux";
import { REDUX_ERRORS } from "../../../../components/Redux/services/constants";
import Loader from "../../../../components/Loader";

const AutogradingPermissions = (props) => {

    // data
    const [generateFeedbackAllowed, toggleGenerateFeedbackAllowed] = useState(null);
    const [trainingAllowed, toggleTrainingAllowed] = useState(null);

    // states
    const [loadingGeneratingFeedback, toggleLoadingGeneratingFeedback] = useState(false);

    // errors
    const [generateFeedbackInputError, updateGenerateFeedbackInputError] = useState(null);
    const [trainingAllowedInputError, updateTrainingAllowedInputError] = useState(null);
    const [generateFeedbackError, updateGenerateFeedbackError] = useState(null);

    const generateFeedback = () => {
        let noErrors = true;
        if (generateFeedbackAllowed === null) {
            updateGenerateFeedbackInputError(new errorClass({
                errorCode: BAD_REQUEST,
                description: "Deze moet je nog invullen"
            }));
            noErrors = false;
        }
        if (trainingAllowed === null) {
            updateTrainingAllowedInputError(new errorClass({
                errorCode: BAD_REQUEST,
                description: "Deze moet je nog invullen"
            }));
            noErrors = false;
        }
        if (generateFeedbackAllowed === false) {
            updateGenerateFeedbackInputError(new errorClass({
                errorCode: BAD_REQUEST,
                description: "Als je gegenereerde feedback op je antwoorden wilt hebben, dan moet je hiermee akkoord gaan."
            }));
            noErrors = false;
        }
        if (noErrors) {
            updateGenerateFeedbackInputError(null);
            updateTrainingAllowedInputError(null);
            updateGenerateFeedbackInputError(null);
            feedbackGeneren();
        }
    }

    const feedbackGeneren = async(order) => {
        toggleLoadingGeneratingFeedback(true);
        handleLocalStorage({
            action: REMOVE_LOCAL_STORAGE,
            key: LAST_ERROR_GENERATED_FEEDBACK,
        });
        if (handleEnvironment() === ETALIA_ENV) {
            return;
        }
        if (!props.waitForGeneratedFeedback) {
            examEvaluation.postExamEvaluation(props.examID, props.examOrder);
            if (props.runNext) {
                props.runNext();
            }
            props.toggleShowAutogradingPermissions();
            return;
        }

        const feedback = await examEvaluation.postExamEvaluation(props.examID, props.examOrder);
        if (!feedback || feedback.status !== SUCCESS) {
            handleLocalStorage({
                action: ADD_LOCAL_STORAGE,
                key: LAST_ERROR_GENERATED_FEEDBACK,
                data: new Date()
            });
            updateGenerateFeedbackError(new errorClass({
                errorCode: INTERNAL_ERROR,
                description: "Feedback genereren niet gelukt. Het kan zijn dat er te veel studenten tegelijkertijd feedback aan het genereren zijn. Probeer het zo opnieuw."
            }));
        }
        if (props.runNext) {
            props.runNext();
        }
        toggleLoadingGeneratingFeedback(false);
        props.toggleShowAutogradingPermissions();
        return feedback;
    }

    return (
        <>
        <LocalModal
            show={props.showAutogradingPermissions}
            toggleShow={() => loadingGeneratingFeedback ? null : props.toggleShowAutogradingPermissions(false)}
            component={
            <div className="AutogradingPermissionsView" >
                <Title
                    title={loadingGeneratingFeedback && props.waitForGeneratedFeedback ? "Feedback wordt gegenereerd!" : "Voorwaarden voor het gebruik van Large Language Models (LLM's) voor automatisch nakijken"}
                />
                {
                    loadingGeneratingFeedback && props.waitForGeneratedFeedback ? 
                    <div className="AutogradingPermissionsBodyRowDirection">
                        Het genereren van feedback kan even duren. <Loader />
                    </div> :
                    <>
                        <div className="AutogradingPermissionSubTitle">
                            Limitaties
                        </div>
                        <div className="AutogradingPermissionsBody">
                            Wij gebruiken een Large Language Model (LLM) om feedback en punten te genereren voor elke vraag op basis van jouw antwoorden op het oefententamen. 
                            Dit systeem, gebaseerd op kunstmatige intelligentie, produceert automatisch feedback zonder menselijke tussenkomst. Het model kan onjuiste feedback of punten opgeven. 
                            Zorg er dus voor dat je de gegenereerde feedback en punten niet blindelings vertrouwt.
                        </div>

                        <div className="AutogradingPermissionSubTitle">
                            Data
                        </div>
                        <div className="AutogradingPermissionsBody">
                            Jouw antwoorden worden verwerkt op servers van Microsoft, in overeenstemming met de contractuele afspraken van de UvA. Jouw gegevens worden niet opgeslagen door externe partijen en niet zonder toestemming gebruikt voor verdere training van het model.
                        </div>

                        <div className="AutogradingPermissionSubTitle">
                            Wachttijd
                        </div>
                        <div className="AutogradingPermissionsBody">
                            Het genereren van punten en feedback kan tot ongeveer 50 seconden duren.
                        </div>

                        <div className="AutogradingPermissionSubTitle">
                            Contact
                        </div>
                        <div className="AutogradingPermissionsBody">
                            Heb je vragen? Stuur dan een e-mail naar olaf@etalia.nl of een WhatsApp-bericht naar 0623991946.
                        </div>

                        <div className="AutogradingPermissionSubTitle">
                            Jouw input:
                        </div>
                        <div className="AutogradingPermissionsBody">
                            <div className="AutogradingPermissionsBody">
                                
                            </div>
                            <Boolean
                                title="Er mag feedback worden gegenereerd op mijn antwoorden"
                                valueKey="value"
                                data={{value: generateFeedbackAllowed}}
                                customUpdateFunc={((_, newValue) => toggleGenerateFeedbackAllowed(newValue))}
                                error={generateFeedbackInputError}
                                updateError={updateGenerateFeedbackInputError}
                            />
                        </div>
                        <div className="AutogradingPermissionsBody">
                            <Boolean
                                title="Mijn antwoorden, inclusief de feedback en punten die ik opgeef tijdens het nakijken van mijn eigen werk, mogen gebruikt worden voor het trainen van modellen (niet nodig voor het ontvangen van gegenereerde feedback)"
                                valueKey="value"
                                data={{value: trainingAllowed}}
                                customUpdateFunc={((_, newValue) => toggleTrainingAllowed(newValue))}

                                error={trainingAllowedInputError}
                                updateError={updateTrainingAllowedInputError}
                            />
                        </div>

                        <div className="AutogradingPermissionsButtons" >
                            <Button
                                data={{
                                    title: props.dontGenerateFeedbackButtonTitle ?? "Sluiten",
                                    buttonFunc: props.onClickDontGenerateFeedbackButton ? () => props.onClickDontGenerateFeedbackButton() : () => props.toggleShowAutogradingPermissions(false)
                                }}
                            />
                            <Button
                                data={{
                                    title: props.generateFeedbackButtonTitle ?? "Feedback Genereren",
                                    buttonFunc: () => generateFeedback()
                                }}
                            />
                        </div>
                    </>
                }
            </div>
            }
        />
        <Redux
            showSuccess={false}
            varId={REDUX_ERRORS}

            reduxVar={generateFeedbackError}
        />
        </>
    )
}

export default AutogradingPermissions;
