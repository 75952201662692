
// classes
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { NO_CONTENT, BAD_REQUEST, NOT_FOUND } from "../../../../../../../services/errors/constants";

const repeatPasswordErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Geen wachtwoord ingevult",
                description: "Dit vlak moet ingevult zijn.",
                advice: null,
                moreInfo: null,
            });

        case NOT_FOUND:

            return new errorClass({
                errorCode: errorCode,
                title: "Wachtwoorden komen niet overeen",
                description: "Wachtwoorden komen niet overeen.",
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default repeatPasswordErrors;
