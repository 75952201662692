
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import textExplenationApi from "../../../../services/Networking/textExplenation";

// services
import convertError from "../../../../services/errors/convertError";
import access from "../../../../services/access/accessCheck";

// style
import "./TextExplenation.scss";

// classes
import tab from "../../../../components/PageNav/services/classes";
import errorClass from "../../../../services/errors/classes";
import newTextClass from "../../../../services/Networking/textExplenation/classes";

// local components
import Text from "./components/Text";
import AddNewText from "./components/AddNewText";

// components
import Add from "../../../../elements/Add";
import PageNav from "../../../../components/PageNav";
import Loader from '../../../../components/Loader';

// constants
import { EDITOR } from "../../../../services/access/constants";
import { SUCCESS, NO_RESPONSE } from "../../../../services/errors/constants";

const TextExplenation = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [addText, toggleAddText] = useState(false);
    const [newText, updateNewText] = useState(new newTextClass({
        textExplanation: ""
    }));

    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(true);

    const [texts, updateTexts] = useState([]);
    const [tabs, updateTabs] = useState([]);
    const [initalTab, updateInitalTab] = useState(1);

    const [newTextError, updateNewTextError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const makeTabs = () => {
        const tabs = texts.map((text, i) => {
            return new tab({
                id: i+1,
                title: `${i+1} tekst`,
                component: <Text
                    textClass={text}
                    triggerReRender={triggerReRender}
                />
            })
        });

        updateTabs(tabs);

    }
    useEffect(() => {

        makeTabs();

    }, [texts]);

    const addNewText = () => {

        toggleAddText(true);
        
        updateTabs((values) => {
            return [new tab({
                id: 0,
                title: `Nieuwe text`,
                component: <AddNewText
                    initialNewText={newText}
                    deleteNewText={deleteNewText}

                    triggerReRender={triggerReRender}
                />
            }), ...values]
        });

        updateInitalTab(0);
    }

    const deleteNewText = (currentText) => {

        updateNewText(currentText);
        
        updateTabs((values) => {
            return values.filter((value) => value.id !== 0);
        });

        toggleAddText(false);
        updateInitalTab(1);

        triggerReRender((value) => !value);
    }

    const fetchTexts = async() => {

        const returned = await textExplenationApi.getTextExplBySubTopicId(
            subTopicId
        );

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            updateTexts(returned.payload);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);

    }

    useEffect(() => {

        toggleLoading(true);

        fetchTexts();
    
    }, [rerender]);

    return (
        <div className="TextExplenation" >
            <div className="titleTextExplenation" >
                Samenvatting
                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: EDITOR,
                        userRoles: user.user.roles
                    }) ?
                    <div className="addText" >{
                        addText ?
                            null
                        :
                            <Add
                                onClick={() => addNewText()}
                            />
                    }</div> : null
                }
            </div>

            <PageNav
                tabs={tabs}
                initalTab={initalTab}
            />

            {
                loading ? 
                <div className="textExplenationHolder" ><Loader /></div> : null
            }

        </div>
    )
}

export default TextExplenation;
