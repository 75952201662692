
import parse from "html-react-parser";

// services
import cleanText from "../../../../../../../../services/cleanText";

// style
import "./CardFront.scss";

const CardFront = (props) => {
    return (
        <div className="cardFrontView">

            <div className="cardFront">{

                parse(
                    cleanText({
                        textToClean: `${props.frontText}`,
                        h1: true,
                        h2: true,
                        h3: true,
                        h4: true,
                        p: true,
                        bold: true
                    })
                )

            }</div>

        </div>
    )
}

export default CardFront;
