
// react
import { useState } from "react";

// routing
import { useHistory } from 'react-router-dom';

// styles
import "./AddAssignment.scss";

// classes
import errorClass from "../../../../services/errors/classes/errorClass";
import CustomErrorsManager from "../../../../services/errors/classes/CustomErrorsManager";

// services
import assignmentApi from "../../../../services/Networking/assignment/assignment";
import networking from "../../../../services/handleNetworking/networking";
import validateTextInput from "../../../../services/validate/textInput/textInput";
import convertError from "../../../../services/errors/convertError";
import defaultTextInputErrors from "../../../../services/errors/defaultTextInputErrors";
import convertDateToString from "../../../../services/dateTime/convertDateToString/convertDateToString";
import { bothDateAndTimeFilled } from "../../../../components/input/DateTime/services/validate";

// components
import TextInput from "../../../../components/input/Text/Text";
import CkTextInput from "../../../../components/input/CkText/CkText";
import Submit from "../../../../components/input/elements/Submit";
import Loader from "../../../../components/Loader";
import DateTime from "../../../../components/input/DateTime/DateTime";
import Boolean from "../../../../components/input/Boolean/Boolean";
import Redux from "../../../../components/Redux/Redux";

// elements
import Title from "../../../../elements/ModalTitle/Title";

// constants
import { FORBIDDEN, SUCCESS, UNAUTHORIZED } from "../../../../services/errors/constants";
import { REDUX_ERRORS } from "../../../../components/Redux/services/constants";
import { defaultDateInputErrors, defaultDateInputNotRequiredErrors } from "../../../../services/errors/defaultDateInputErrors/defaultDateInputErrors";

const AddAssignment = (props) => {

    // routing
    const history = useHistory();

    // state
    const [loading, toggleLoading] = useState(false);
    const [gradingDeadlineChanged, toggleGradingDeadlineChanged] = useState(false);

    // data
    const [assignment, updateAssignment] = useState({
        "name": "",
        "submittingDeadline": null,
        "gradingDeadline": null,
        "canAIGrade": true,
        "canViewFeedback": false,
        "canStudentGrade": true,
        "canSubmittAfterDeadline": false,
        "annonymousGrading": false,
        "info": ""
    });

    // errors
    const [sendError, updateSendError] = useState(null);
    const [titleError, updateTitleError] = useState(new errorClass({errorCode: SUCCESS}));
    const [infoError, updateInfoError] = useState(new errorClass({errorCode: SUCCESS}));
    const [deadlineError, updateDeadlineError] = useState(new errorClass({errorCode: SUCCESS}));
    const [gradingDeadlineError, updateGradingDeadlineError] = useState(new errorClass({ errorCode: SUCCESS }));
    const [canAIGradeError, updateCanAIGradeError] = useState(new errorClass({ errorCode: SUCCESS }));
    const [canViewFeedbackError, updateCanViewFeedbackError] = useState(new errorClass({ errorCode: SUCCESS }));
    const [canStudentGradeError, updateCanStudentGradeError] = useState(new errorClass({ errorCode: SUCCESS }));
    const [canSubmitAfterDeadlineError, updateCanSubmitAfterDeadlineError] = useState(new errorClass({ errorCode: SUCCESS }));
    const [annonymousGradingError, updateAnnonymousGradingError] = useState(new errorClass({ errorCode: SUCCESS }));

    const validateFields = () => {

        let error = SUCCESS;

        // validate name
        const valid = validateTextInput(assignment["name"]);
        // add title error
        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateTitleError,
            customErrors: defaultTextInputErrors
        });
        if (!errorTemp || errorTemp.errorCode !== SUCCESS) {
            error = valid
        }

        // validate dateTime
        const submissionDate = bothDateAndTimeFilled(assignment["submittingDeadline"]);
        updateDeadlineError(defaultDateInputErrors.getError(submissionDate));
        if (submissionDate !== SUCCESS) {
            error = error !== SUCCESS ?? submissionDate;
        }

        // validate gradingDeadline
        if (gradingDeadlineChanged) {
            const gradingDeadline = bothDateAndTimeFilled(assignment["gradingDeadline"]);
            updateGradingDeadlineError(defaultDateInputNotRequiredErrors.getError(gradingDeadline))
            if (gradingDeadline !== SUCCESS) {
                error = error !== SUCCESS ?? gradingDeadline;
            }
        }

        return error === SUCCESS;
    }

    const save = async() => {
        const inputValid = validateFields();

        if (inputValid) {

            // Convert gradingDeadline and submittingDeadline to ISO strings if they exist and are Date objects
            if (assignment?.gradingDeadline instanceof Date) {
                assignment.gradingDeadline = convertDateToString(assignment.gradingDeadline);
            }
            if (assignment?.submittingDeadline instanceof Date) {
                assignment.submittingDeadline = convertDateToString(assignment.submittingDeadline);
            }

            const returned = await networking({
                toggleLoading: toggleLoading,
                errorOnSuccess: false,
    
                api: assignmentApi.postAssignments,
                apiParams: assignment,
    
                updateError: updateSendError,
                customErrors: (errorCode) => new CustomErrorsManager(
                    {
                        [FORBIDDEN]: {
                            title: "Je kan geen opdrachten aanmaken met jou account."
                        },
                        [UNAUTHORIZED]: {
                            title: "Je moet ingelogd zijn om een opdrachten aan te maken."
                        }
                    }
                ).getError(errorCode)
            });

            if (returned && returned?.status === SUCCESS) {
                history.push(`/opdrachten/${returned.payload}`);
            } else {
                if (assignment.gradingDeadline) {
                    assignment.gradingDeadline = new Date(assignment.gradingDeadline);
                }
                if (assignment.submittingDeadline) {
                    assignment.submittingDeadline = new Date(assignment.submittingDeadline);
                }
            }
        }
    }

    return (
        <div className="AddAssignment" >
            <Title title="Opdracht Toevoegen" />

            <div className="AddAssignmentExtraInfo">
                Alleen de title moet nu ingevult te worden.
            </div>

            <TextInput
                title="Title"

                valueKey="name"
                dataClass={assignment}

                errorClass={titleError}
                updateError={updateTitleError}
            />

            <CkTextInput
                title="Beschrijving"

                valueKey={"info"}
                dataClass={assignment}

                errorClass={infoError}
                updateError={updateInfoError}
            />

            <DateTime
                title="Deadline voor het inleveren"

                valueKey="submittingDeadline"
                data={assignment}

                errorClass={deadlineError}
                updateError={updateDeadlineError}
            />

            <DateTime
                title="Deadline voor het nakijken"

                valueKey="gradingDeadline"
                data={assignment}

                notRequired={true}
                atLeatPartlyFilled={gradingDeadlineChanged}
                toggleAtLeatPartlyFilled={toggleGradingDeadlineChanged}

                errorClass={gradingDeadlineError}
                updateError={updateGradingDeadlineError}
            />

            <Boolean
                title="AI kan nakijken"

                valueKey="canAIGrade"
                data={assignment}

                errorClass={canAIGradeError}
                updateError={updateCanAIGradeError}
            />

            <Boolean
                title="Studenten kunnen feedback aan zichzelf geven"

                valueKey="canStudentGrade"
                data={assignment}

                errorClass={canStudentGradeError}
                updateError={updateCanStudentGradeError}
            />

            <Boolean
                title="Studenten kunnen gelijk feedback inzien"

                valueKey="canViewFeedback"
                data={assignment}

                errorClass={canViewFeedbackError}
                updateError={updateCanViewFeedbackError}
            />

            <Boolean
                title="Studenten kunnen na de deadline inleveren"

                valueKey="canSubmittAfterDeadline"
                data={assignment}

                errorClass={canSubmitAfterDeadlineError}
                updateError={updateCanSubmitAfterDeadlineError}
            />

            <Boolean
                title="Identificeerbaar nakijken"

                valueKey="annonymousGrading"
                data={assignment}

                errorClass={annonymousGradingError}
                updateError={updateAnnonymousGradingError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={sendError}
            />

        </div>
    )
}

export default AddAssignment;
