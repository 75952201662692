
// Helper function to convert Date to string with timezone offset
const convertDateToString = (date) => {
    if (!(date instanceof Date)) return date;
    const pad = (num) => (num < 10 ? '0' + num : num);

    const yyyy = date?.getFullYear();
    const MM = pad(date?.getMonth() + 1);
    const dd = pad(date?.getDate());
    const HH = pad(date?.getHours());
    const mm = pad(date?.getMinutes());
    const ss = pad(date?.getSeconds());

    return `${yyyy}-${MM}-${dd}T${HH}:${mm}:${ss}`;
};

export default convertDateToString;
