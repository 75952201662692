
// standard objects
import errorClass from "../../../../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../../../../services/errors/constants";

const sentErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        // add incorrect password
        case SUCCESS:

            return new errorClass({
                errorCode: SUCCESS,
                priority: 1,
                title: `Je feedback is verstuurd, bedankt!`,
                description: `Je feedback is verstuurd, bedankt!`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default sentErrors;
