
// constants
import { PRACTICE_EXAM } from "../../../../../services/constants/examCategoryConstants";
import { DRAFT } from "../../../../../services/constants/stateConstants";

class ExamClass {

    constructor({
        title = "",
        category = PRACTICE_EXAM,
    } = {}) {
        this.title = title;
        this.category = category;
    }
}

export default ExamClass;
