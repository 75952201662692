
import { useState } from 'react';

// redux
import { useSelector } from 'react-redux';

// routing
import { useHistory } from 'react-router-dom';

// styles
import "./Assignment.scss";

// services
import access from '../../../../services/access/accessCheck/access';
import deleteNetworking from '../../../../services/handleNetworking/deleteNetworking';
import assignmentApi from '../../../../services/Networking/assignment/assignment';

// components
import Loader from '../../../../components/Loader';

// constants
import { SUCCESS } from '../../../../services/errors/constants';
import { ADMIN, EDITOR, TEACHER } from '../../../../services/access/constants';

const Assignment = (props) => {

    // routing
    const history = useHistory();

    // redux
    const user = useSelector(state => state.userReducer);

    // states
    const [laoding, toggleLoading] = useState(false);
    const [initDeleting, toggleInitDeleting] = useState(false);

    // errors
    const [deleteAssignmentsError, updateDeleteAssignmentsError] = useState(null);

    const toAssignment = (id) => {
        history.push(`/opdrachten/${id}`);
    }

    const toggleDel = (e) => {
        // make action above is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        toggleInitDeleting((value) => !value);
    }

    const deleteAssignment = async(e) => {
        // make action above is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        toggleLoading(true);

        const response = await deleteNetworking({
            api: assignmentApi.deleteAssignment,
            apiParams: props.data?.id,

            updateError: updateDeleteAssignmentsError,
        });

        if (response && response.status === SUCCESS) {
            props.getAssignments();
        } else {
            toggleLoading(false);
        }
    }

    return (
        <div
            key={props.data?.id}
            onClick={() => toAssignment(props.data?.id)}
            className="AssignmentsAssignmentView"
        >
            {
                props.data?.name
            }

            {
                !user ? null : !user.user ? null : access({
                    accessLevel: [ADMIN, EDITOR, TEACHER],
                    userRoles: user.user.roles
                }) ? 
                laoding ? <Loader /> :
                initDeleting ?
                <div className="AssignmentsAssignmentDeleteCancleView">
                    <div className="AssignmentsAssignmentDelete" onClick={(e) => deleteAssignment(e)} >Verwijderen</div>
                    <div className="AssignmentsAssignmentCancleDelete" onClick={(e) => toggleDel(e)} >Annuleren</div>
                </div>
                :
                <div className="AssignmentsAssignmentDelete" onClick={(e) => toggleDel(e)} >Verwijderen</div>
                : null
            }
        </div>
    )
}

export default Assignment;
