
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

// constants
import { SUCCESS } from '../../errors/constants';

const subTopicApi = {
    createSubTopic,
    getSubTopicByTopicID,
    getSubTopicBySubTopicID,
    putSubTopicBySubTopicId,
    deleteSubTopicBySubTopicID
};

async function createSubTopic(topicID, topicObject ) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/topics/${topicID}/subTopics`, 
            topicObject, 
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;

}

async function getSubTopicByTopicID({topicID, type} = {}) {

    const responseObj = await handleResponse(
        axios.get(
            `/topics/${topicID}/subTopics?type=${type}`)
        );

    return responseObj;

}

async function getSubTopicBySubTopicID(subTopicID) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(`/subtopics/${subTopicID}`, 
        {
            headers: auth.payload
        }
    ));

    return responseObj;

}

async function putSubTopicBySubTopicId(subTopicId, topicObject) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/subtopics/${subTopicId}`,
            topicObject, 
            {
                headers: auth.payload
            }
        ));

    return responseObj;

}

async function deleteSubTopicBySubTopicID(subTopicID) {
    const auth = await authHeader();

    const responseObj = handleResponse(
        axios.delete(
            `/subtopics/${subTopicID}`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}


export default subTopicApi;
