
// classes
import infoObj from "../../classes/infoObj/infoObj";

// services
import convertError from "../../errors/convertError/convertError";
import sessionEndedErrors from "./services/errors/errors";

// constants
import { MAIN_OPERATION_SUCCESS, NOT_FOUND, NO_CONTENT, SUCCESS, UNAUTHORIZED } from "../../errors/constants";

const networking = async({

    updateContent = null,

    toggleLoading = null,

    accessableToAllUsers = false, // if it is true and unauthorized is returned, it means the user needs to re-login
    logOut = null,

    errorOnSuccess = true,
    
    api,
    apiParams,
    apiParamsOnFailure = null,

    updateError,
    customErrors=null,

    history=null // to rerount to page not found if NOT_FOUND is returned

} = {}) => {

    if (toggleLoading)  {
        toggleLoading(true);
    }

    const returned = await api(apiParams);

    if (!returned) {

        convertError({
            errorCode: NO_CONTENT,
            updateLocally: updateError,
            customErrors: customErrors // no response has nothing to do with the action, so standard errors will be shown
        });

        // something weard happend
        if (toggleLoading) {
            toggleLoading(false);
        }

        return new infoObj({
            status: NO_CONTENT
        });

    }

    if (returned.status !== SUCCESS && apiParamsOnFailure) {
        return networking({
            updateContent,

            toggleLoading,
        
            accessableToAllUsers, // if it is true and unauthorized is returned, it means the user needs to re-login
            logOut,
        
            errorOnSuccess,
            
            api,
            apiParams: apiParamsOnFailure,
        
            updateError,
            customErrors,
        
            history // to rerount to page not found if NOT_FOUND is returned
        });
    }

    if (returned.status === SUCCESS || returned.status === MAIN_OPERATION_SUCCESS) {

        // update content if place to update content at is given
        if (updateContent) {
            updateContent(returned.payload);
        }

        if (toggleLoading) {
            toggleLoading(false);
        }

        // update error
        if (errorOnSuccess === true) {
            convertError({
                errorCode: returned.status,
                updateLocally: updateError,
                customErrors: customErrors
            });
        }

        return new infoObj({
            payload: returned.payload,
            status: returned.status
        });

    } else if (returned.status === UNAUTHORIZED) {
        
        // log user out if content accessible to all users, it means the users token has expired and they need to re-login
        if (accessableToAllUsers && logOut) {
            logOut()
        }

        // update error
        convertError({
            errorCode: returned.status,
            updateLocally: updateError,
            customErrors: customErrors ? customErrors : accessableToAllUsers ? sessionEndedErrors : null
        });

        if (toggleLoading) {
            toggleLoading(false);
        }
        return new infoObj({
            status: returned.status
        });

    } else if (returned.status === NOT_FOUND) {

        // page not found
        convertError({
            errorCode: returned.status,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (history) {
            // push to page not found page
            history.push("/niet-gevonden");
        }

        if (toggleLoading) {
            toggleLoading(false);
        }

        return new infoObj({
            status: returned.status
        });

    } else {
        
        convertError({
            errorCode: returned.status,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (toggleLoading) {
            toggleLoading(false);
        }

        return new infoObj({
            status: returned.status
        });

    }

}

export default networking;
