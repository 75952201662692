import { useState } from "react";

// styling
import "./CopyToClipboard.scss";

// constants
import { COPY_NOT_SUCCESS, COPY_SUCCESS, NOT_TRIED_TO_COPY } from "./services/constants";

const CopyToClipboard = (props) => {

    const [copySuccessStatus, updateCopySuccessStatus] = useState(NOT_TRIED_TO_COPY);

    const copyLink = () => {
        // add status update!
        navigator.clipboard.writeText(props.value)
            .then(() => {
                updateCopySuccessStatus(COPY_SUCCESS);
            })
            .catch(() => {
                updateCopySuccessStatus(COPY_NOT_SUCCESS);
            });
        
        // Reset after 2 seconds
        setTimeout(() => {
            updateCopySuccessStatus(NOT_TRIED_TO_COPY);
        }, 1500);
    }

    return (
        <div
            className={copySuccessStatus === NOT_TRIED_TO_COPY ? "copyToClipboardView" : `copyToClipboardViewNoHover ${copySuccessStatus === COPY_SUCCESS ? "copyToClipboardTextGreen" : "copyToClipboardTextRed"}`}
            onClick={() => copySuccessStatus === NOT_TRIED_TO_COPY ? copyLink() : null}
        >{
            copySuccessStatus === NOT_TRIED_TO_COPY ?
            props.title :
            copySuccessStatus === COPY_SUCCESS ? "Kopieren gelukt" : "Kopieren niet gelukt"
        }</div>
    )
}

export default CopyToClipboard;
