
// classes
import Exam from "../classes/Exam/Exam";

// question classes
import questionPostClass from "./classes/question/questionPost";
import questionPutClass from "./classes/question/questionPut";
// answer classes
import answerPostClass from "./classes/answer/answerPost";
import answerPutClass from "./classes/answer/answerPut";

// services
import validateEditQuestion from "../validateEditQuestion/validateEditQuestion";

// constants
import { NO_CONTENT, SUCCESS } from "../../../../services/errors/constants";
import { DRAFT } from "../../../../services/constants/stateConstants";

const getEditedHubs = (examState, exam, examId, questionHubs) => {

    const hubsObjToSend = {
        changedDetected: false,
        warning: SUCCESS,
        error: SUCCESS,

        postExam: new Exam({
            uuid: examId,
            title: exam.title,
            category: exam.category,
            state: examState,
            questions: [],
            questionsToRemove: []
        }),
        
        // questionsPut: [],
        // answersPut: [],

        // questionsRemoved: [],
        // answersRemoved: [],

        // answersDeleted: [],
    }

    if (!exam.title || !exam.category) {
        hubsObjToSend.error = NO_CONTENT;
    }

    questionHubs.map((questionHub) => {

        const questionValid = validateEditQuestion({
            questionHub: questionHub
        });

        if (questionValid.warning !== SUCCESS) {
            hubsObjToSend.warning = questionValid.warning;
        }
        if (questionValid.error !== SUCCESS) {
            hubsObjToSend.error = questionValid.error;
        }

        if (questionHub.questionPost || questionHub.questionPut || questionHub.questionRemove || questionHub.questionDelete) {
            hubsObjToSend.changedDetected = true;
        }

        let questionAnswers = [];

        let removedAnswers = [];
        
        let answerChanged = false;
        // loop through all answers
        questionHub.getAnswers().map((answerHub) => {

            if (answerHub.answerPut || answerHub.answerPost || answerHub.answerDelete) {
                hubsObjToSend.changedDetected = true;
            }

            if (answerHub.answerDelete !== null) {
                
                // check if this answer was sent or if it is newly created in this session
                if (answerHub.sentAnswer !== null) {
                    
                    removedAnswers = [...removedAnswers, answerHub.answerId]

                    // const delAnswer = {
                    //     questionId: questionHub.questionId,
                    //     answerId: answerHub.answerId
                    // };
    
                    // hubsObjToSend.answersDeleted = [...hubsObjToSend.answersDeleted, delAnswer];

                }

            } else {

                // if answerId & answer edited
                if (answerHub.answerPut) {
                    removedAnswers = [...removedAnswers, answerHub.answerId]
                    answerChanged = true;
                }

                questionAnswers = [
                    ...questionAnswers,
                    {
                        answerId: answerHub.answerId ? answerHub.answerPut ? 0 : answerHub.answerId : 0,

                        title: answerHub.getCurrentValue("title"),
                        correct: answerHub.getCurrentValue("correct"),
                        points: answerHub.getCurrentValue("points"),
                        state: examState
                    }
                ]
            }
        });

        // remove question
        if (questionHub.questionRemove !== null) {

            // check if this question was sent or if it is newly created in this session
            if (questionHub.sentQuestion !== null) {

                hubsObjToSend.postExam.questionsToRemove = [...hubsObjToSend.postExam.questionsToRemove, questionHub.questionId]

                // const removeQ = {
                //     examId: examId,
                //     questionId: questionHub.questionId
                // };

                // hubsObjToSend.questionsRemoved = [...hubsObjToSend.questionsRemoved, removeQ];
            }

        } else if (questionHub.questionDelete !== null) {
            // already deleted and removed from exam
        } else {
            let examQuestion;
            // check if question has id & edited question
            if ((questionHub.questionId && questionHub.questionPut) || answerChanged) {

                // questionId -> 0
                // parentId = questionId
                examQuestion = {
                    questionId: 0,
                    parentId: questionHub.questionId,

                    title: questionHub.getCurrentValue("title"),
                    type: questionHub.getCurrentValue("type"),
                    image: questionHub.getImageForSave(),
                    category: questionHub.getCurrentValue("category"),
                    state: examState,
        
                    answers: questionAnswers,
                    answersToRemove: [...removedAnswers]
                }

                // add questionId to removed questions list.
                hubsObjToSend.postExam.questionsToRemove = [...hubsObjToSend.postExam.questionsToRemove, questionHub.questionId];

            } else {
                // fill question
                // no id -> make new questions with questionId 0
                examQuestion = {
                    questionId: questionHub.questionId ? questionHub.questionId : 0,
                    
                    title: questionHub.getCurrentValue("title"),
                    type: questionHub.getCurrentValue("type"),
                    image: questionHub.getImageForSave(),
                    category: questionHub.getCurrentValue("category"),
                    state: questionHub.questionId && questionHub.getCurrentValue("state") !== DRAFT ? questionHub.getCurrentValue("state") : examState,
        
                    answers: questionAnswers,
                    answersToRemove: [...removedAnswers]
                }
            }

            hubsObjToSend.postExam.questions = [
                ...hubsObjToSend.postExam.questions,
                examQuestion
            ];
        }
    });

    return hubsObjToSend;

}

export default getEditedHubs;
