
// style
import "./NavButton.scss";

const NavButton = (props) => {

    return ( 
        <div className="NavButton" onClick={props.onClick} >
            {props.title}
        </div>
    )
}

export default NavButton;
