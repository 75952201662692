
// components
import PlainTextPage from "../../components/PlainTextPage/PlainTextPage"

const NotFound = (props) => {

    return (
        <PlainTextPage 
        
            Title="Pagina niet gevonden 😕"
            Text={
                <>
                    Het zou kunnen dat u een oude URL gebruikt.
                    <br/>
                    <br/>
                    Wat opties om de goede pagina te vinden:
                    <br/>
                    <ul>
                        <li><a href='/geschiedenis' >Home pagina</a>: hier vind je de hostorische contexten en tijdvakken.</li>
                        <li><a href='/examen-voorbereidingen' >Examen pagina</a>: hier vind je een overzicht van alle oud eindexamens die bij online beschikbaar zijn.</li>
                    </ul>
                    Sorry voor het ongemak.
                </>
            }
        />
    )

}

export default NotFound;
