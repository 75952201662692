
import React, { useEffect, useState } from 'react';

// routing
import { useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';

// image
import logo from "../../assets/images/logo.png";

// styles
import "./NavBar.scss";

// services
import handleEnvironment from '../../services/handleEnvironment/handleEnvironment';
import adminView from '../../services/uva/adminView';

// components local
import NavButton from "./components/NavButton";
import User from "./components/User";
import Login from "./components/Login";
import Register from "./components/Register";

// components
import LocalModal from "../../components/LocalModal";
import GlobalErrors from "../../components/GlobalErros";
import Feedback from '../../components/Feedback/Feedback';

// constants
import { UVA_ENV } from '../../services/handleEnvironment/constants';

const NavBar = (props) => {

    // submit
    const [loading, toggleLoading] = useState(false);

    // redux
    const user = useSelector(state => state.userReducer);
    const dispatch = useDispatch();
    
    const [showLogin, toggleShowLogin] = useState(!user?.user && handleEnvironment() === UVA_ENV ? true : false);
    useEffect(() => {
        if (!user?.user && handleEnvironment() === UVA_ENV) {
            toggleShowLogin(true);
        } else if (user?.user && handleEnvironment() === UVA_ENV) {
            toggleShowLogin(false);
        }
    }, [user]);
    
    const updateShowLogin = () => {
        if (!user?.user && handleEnvironment() === UVA_ENV && !adminView()) {
            // login required
        } else {
            toggleShowLogin((value) => !value);
        }
        
    }
    const [showReg, toggleShowReg] = useState(false);

    // routing
    let history = useHistory();

    const home = () => {
        if (handleEnvironment() === UVA_ENV) {
            history.push("/opdrachten");
        } else {
            history.push("/geschiedenis");
        }
    }

    const aboutUs = () => {
        history.push("/over-ons");
    }

    const assignments = () => {
        history.push("/opdrachten");
    }

    const examPrep = () => {
        if (handleEnvironment() === UVA_ENV) {
            history.push("/tentamen-voorbereidingen");
        } else {
            history.push("/examen-voorbereidingen");
        }
    }

    return (
        <div className="NavBar" >

            <img 
                onClick={home}
                className="logo"
                src={logo} 
                alt="Italian Trulli" 
            />

            <div className="NavButtons">
                
                <div className="NavButtonsSect" >
                    {
                        handleEnvironment() === UVA_ENV ? "" :
                        <NavButton
                            onClick={home}
                            title="HOME"
                        />
                    }

                    {
                        handleEnvironment() === UVA_ENV ? "" :
                        <NavButton
                            onClick={aboutUs}
                            title="OVER ONS"
                        />
                    }

                    {
                        handleEnvironment() === UVA_ENV ? 
                        <NavButton
                            onClick={assignments}
                            title="OPDRACHTEN"
                        />
                        :
                        ""
                    }

                    {
                        handleEnvironment() === UVA_ENV && !adminView() ? "" :
                        <NavButton
                            onClick={examPrep}
                            title={handleEnvironment() === UVA_ENV ? "TENTAMENS OEFENEN" : "EXAMENS"}
                        />
                    }
                </div>

                <div className="NavButtonsSect" >
                    {
                        !user ? null : user.user ? null :
                        <NavButton
                            onClick={() => toggleShowLogin(true)}
                            title="INLOGGEN"
                        />
                    }
                    {
                        handleEnvironment() === UVA_ENV && !user?.user ? null :
                            <User
                                user={user?.user || null}
                                toggleShowReg={toggleShowReg}
                                title={user?.user ? user.user.firstName : "GRATIS REGISTREREN"}
                            />
                    }

                    {
                        // handleEnvironment() === UVA_ENV && !adminView() && !user?.user ? null :
                        // <User
                        //     user={!user ? null : user.user}
                        //     toggleShowReg={toggleShowReg}
                        //     title={!user ? "GRATIS REGISTREREN" : user.user ? user.user.fullName : "GRATIS REGISTREREN"}
                        // />
                    }
                </div>

                <LocalModal
                    show={showLogin}
                    runOnToggle={updateShowLogin}
                    component={<Login toggleShow={toggleShowLogin} />}
                />

                <LocalModal
                    show={showReg}
                    toggleShow={toggleShowReg}
                    component={<Register toggleShow={toggleShowReg} />}
                />

                <GlobalErrors />

                {
                    ((handleEnvironment() !== UVA_ENV || (handleEnvironment() === UVA_ENV && adminView()))) &&
                    <Feedback />
                }

            </div>
            
        </div>
    )

}

export default NavBar;
