
// standard objects
import errorClass from "../../errors/classes";

// constants
import { SUCCESS, UNAUTHORIZED } from "../../errors/constants";

const loginAccessErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        // add incorrect password
        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: `Je moet eerst even inloggen of registreren`,
                description: `Je moet eerst even inloggen of registreren`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default loginAccessErrors;
