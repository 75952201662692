
const handleLocalStorage = ({
    action,
    key,
    data
}) => {

    switch (action) {
        case "add":
            localStorage.setItem(key, JSON.stringify(data));
            break;
        
        case "fetch":
            if (localStorage.getItem(key)) {
                return JSON.parse(localStorage.getItem(key));
            } else {
                return null
            }

        case "remove":
            localStorage.removeItem(key);
            break
        default:
            // something went wrong
            break;
    }

}

export default handleLocalStorage;
