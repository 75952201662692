
// standard objects
import errorClass from "../../../../../../services/errors/classes";

// constants
import { NO_CONTENT } from "../../../../../../services/errors/constants";

const feedbackErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        // add incorrect password
        case NO_CONTENT:

            return new errorClass({
                errorCode: NO_CONTENT,
                priority: 1,
                title: `Het feedback feld is not leeg`,
                description: `Het feedback feld is not leeg`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default feedbackErrors;

