
// routing
import { useHistory, useLocation } from 'react-router-dom';

// style
import "./Tab.scss";

const Tab = (props) => {

    // routing
    let history = useHistory();
    let location = useLocation();

    const tabClicked = () => {

        // add tab to url
        if (props.updateURL) {

            // update URL
            location.hash = `#${props.tabData.id}`;
            history.push({
                hash: `#${props.tabData.id}`
            })
        }

        props.updateSelected(props.tabData.id);

        if (props.updatedCustomSelectedTab) {
            props.updatedCustomSelectedTab(props.tabData.id);
        }
    }

    return (
        <div
            className={props.selected ? "selectedTab" : "Tab"}
            onClick={() => tabClicked()}
        >
            {props.tabData.title}
        </div>
    )
}

export default Tab;
