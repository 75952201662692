
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// style
import "./PracticeCards.scss";

// services
import practiceCardApi from "../../../../services/Networking/practiceCards";
import convertError from "../../../../services/errors/convertError";
import { renderFilter } from './services/renderItems';
import networking from '../../../../services/handleNetworking/networking/networking';

// components
import Loader from '../../../../components/Loader';
import Redux from '../../../../components/Redux/Redux';
import AddPracticeCard from "../AddPracticeCard";
import LocalModal from "../../../../components/LocalModal";

// local components
import Overview from "./components/Overview";
import Practicing from "./components/Practicing";
import Results from "./components/Results";
import FilterOption from "./components/FilterOption";

// classes
import filtersClass from "../../../../components/Filters/Filter/services/classes/filtersClass";
import filterClass from "../../../../components/Filters/Filter/services/classes/filterClass";
import filterOptionClass from "../../../../components/Filters/Filter/services/classes/filterOption";
import forceIncludeClass from "../../../../components/Filters/services/classes/forceIncludeClass";

// local errors
import startPracticingErrors from "./services/errors/startPracticing";

// local constants
import { OVERVIEW, PRACTICING, RESULTS } from "./services/constants";

// constants
import { SUCCESS, NO_CONTENT } from "../../../../services/errors/constants";
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';

const PracticeCards = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [currentView, updateView] = useState(OVERVIEW);

    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(false);

    const [fullPracticeCardList, updateFullPracticeCardList] = useState([]); // TODO: fetchedCards
    const [practiceCardList, updatePracticeCardList] = useState([]);
    // TODO: filteredCards
    const [practicedCards, updatePracticedCards] = useState([]);

    const [addPracticeCard, updateAddPracticeCard] = useState(false);
    const [addingType, updateAddingType] = useState(1);

    // hashmap with all handed in cards
    const [flashcardResults, updateFlashcardResults] = useState(new Map());

    // errors
    const [fetchError, updateFetchError] = useState(null);

    const validateStart = () => {

        let validStart = true;

        // check if list is not empty
        if (practiceCardList.length === 0) {
            validStart = false;
        }

        // if selected antive
            // check if any selected items are there
        if (filters.selectionActive) {
            if (practiceCardList.some((card) => card.selected)) {
                validStart = true;
            } else {
                validStart = false;
            }
        }

        // if validStart false
        if (!validStart) {
            // pop up error
            // display success
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_CONTENT,
                    updateLocally: null,
                    customErrors: startPracticingErrors
                }))
            );
        }
        
        return validStart;
        
    }

    const renderFilterItem = (i, data) => {
        
        return (
            <FilterOption
                key={i}
                data={data}

                addClicked={addingPracticeCard}
            />
        )
    }

    const addUserCorrectAndUpdateFullPracticeCardList = (sentList) => {
        if (sentList && Array.isArray(sentList)) {
            const listWithUserCorrect = sentList.map((card) => {
                card.userCorrect = flashcardResults.get(card.practiceCardId) === undefined ? undefined : flashcardResults.get(card.practiceCardId);
                return card;
            });
            updateFullPracticeCardList(listWithUserCorrect);
        }
    }

    const fetchCardsWithFetchFilters = async (params) => {
        const returned = await networking({
            updateContent: addUserCorrectAndUpdateFullPracticeCardList,
            toggleLoading: toggleLoading,

            accessableToAllUsers:true,

            api: practiceCardApi.getPracticeCardsBySubTopicIDAndTypeID,
            apiParams: params,
        
            updateError: updateFetchError,
        });

        if (returned.status === SUCCESS) {
            return returned.payload;
        }
        return [];
    }

    const [filters, updateFilters] = useState(
        new filtersClass({

            itemIdKey: "practiceCardId",
            fetchItems: fetchCardsWithFetchFilters,

            filters: [
                new filterClass({
    
                    idFilter: 0,
                    filterAttributeKey: "type",

                    selectOne: false,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterItem,

                    optionsTitles: [
                        new filterOptionClass({
                            title: "Definities",
                            keyValue: 1
                        }),
                        new filterOptionClass({
                            title: "Feitjes",
                            keyValue: 2
                        }),
                        new filterOptionClass({
                            title: "Gebeurtenissen",
                            keyValue: 4
                        })
                    ],

                    customUpdateFunc: true,

                }),
                new filterClass({
    
                    idFilter: 1,
                    filterAttributeKey: "creator",

                    selectOne: true,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterItem,
                    
                    optionsTitles: [
                        new filterOptionClass({
                            title: "Favorieten",
                            keyValue: 1
                        }),
                        new filterOptionClass({
                            title: "Eigen",
                            keyValue: 2
                        }),
                        new filterOptionClass({
                            title: "EtAlia",
                            keyValue: 3
                        })
                    ],
    
                    customUpdateFunc: true

                }),
                new filterClass({
    
                    idFilter: 2,
                    filterAttributeKey: "color",

                    selectOne: false,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterItem,
                    
                    optionsTitles: [
                        new filterOptionClass({
                            title: "Rood",
                            keyValue: "Red"
                        }),
                        new filterOptionClass({
                            title: "Light Rood",
                            keyValue: "LightRed"
                        }),
                        new filterOptionClass({
                            title: "Geel",
                            keyValue: "Orange"
                        }),
                        new filterOptionClass({
                            title: "Neutraal",
                            keyValue: ""
                        }),
                        new filterOptionClass({
                            title: "Light Groen",
                            keyValue: "LightGreen"
                        }),
                        new filterOptionClass({
                            title: "Groen",
                            keyValue: "Green"
                        })
                    ],

                }),
                new filterClass({
    
                    visible: false,
                    active: false,

                    idFilter: 3,
                    filterAttributeKey: "userCorrect",

                    selectOne: false,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterItem,
                    
                    optionsTitles: [
                        new filterOptionClass({
                            title: "Goed beantwoord",
                            keyValue: true
                        }), 
                        new filterOptionClass({
                            title: "Fout beantwoord",
                            keyValue: false
                        }),
                        new filterOptionClass({
                            title: "Overgeslagen",
                            keyValue: undefined
                        })
                    ],
                })
            ],
    
            // used inside Rilters.js to render filter
            renderFilter: renderFilter,

            selectingItemsPresentAsFilterOption: true,

        })
    );

    const fetchAndFilterCards = async () => {
        const returned = await filters.fetchWithFetchFilters({subTopicID: subTopicId});
        if (returned.status !== SUCCESS) {
            // error would have already been handeled by networking
            return;
        }
        const fullList = returned.payload;
        const filteredList = filters.applyLocalFiltersToList(fullList);
        updatePracticeCardList(filteredList);
    }

    const addingPracticeCard = (e, typeId) => {
        // make action above is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        updateAddingType(typeId);
        updateAddPracticeCard(true);
    }

    const [selectFlashcards, toggleSelectFlashcards] = useState(false);

    const returnView = () => {
        if (currentView === OVERVIEW) {
            return (
                <Overview
                    currentView={currentView}
                    updateView={updateView}
                    
                    loading={loading}
                    toggleLoading={toggleLoading}

                    filtersClass={filters}
                    updateFiltersClass={updateFilters}

                    fullPracticeCardList={fullPracticeCardList}
                    updateFullPracticeCardList={updateFullPracticeCardList}

                    practiceCardList={practiceCardList}
                    updatePracticeCardList={updatePracticeCardList}

                    selectFlashcards={selectFlashcards}
                    toggleSelectFlashcards={toggleSelectFlashcards}

                    rerender={rerender}
                    triggerReRender={triggerReRender}

                    nonFilterFetchParams={{subTopicID: subTopicId}}

                    validateStart={validateStart}

                />
            )
        } else if (currentView === PRACTICING) {
            return (
                <Practicing
                    updateView={updateView}

                    loading={loading}
                    toggleLoading={toggleLoading}

                    filtersClass={filters}
                    updateFiltersClass={updateFilters}

                    fullPracticeCardList={fullPracticeCardList}
                    updateFullPracticeCardList={updateFullPracticeCardList}

                    practiceCardList={practiceCardList}
                    updatePracticeCardList={updatePracticeCardList}

                    updatePracticedCards={updatePracticedCards}

                    nonFilterFetchParams={{subTopicID: subTopicId}}

                    flashcardResults={flashcardResults}
                />
            );
        } else if (currentView === RESULTS) {
            return (
                <Results
                    updateView={updateView}

                    loading={loading}
                    toggleLoading={toggleLoading}

                    filtersClass={filters}
                    updateFiltersClass={updateFilters}

                    fullPracticeCardList={fullPracticeCardList}
                    updateFullPracticeCardList={updateFullPracticeCardList}

                    practiceCardList={practiceCardList}
                    updatePracticeCardList={updatePracticeCardList}

                    practicedCards={practicedCards}

                    nonFilterFetchParams={{subTopicID: subTopicId}}

                    rerender={rerender}
                    triggerReRender={triggerReRender}

                    forceIncludeList={new forceIncludeClass({
                        forceIncludeList: practicedCards,
                        filterIds: [2]
                    })}

                    validateStart={validateStart}

                    flashcardResults={flashcardResults}

                />
            )
        }
    }

    return (
        <div className="PracticeCards">
            <div className="titlePracticeCards">
                Flashcards

                {
                    fullPracticeCardList.length === 0 ? null :
                    !loading ? null :
                    <div className="holdLoader">
                        <Loader />
                    </div>
                }
            </div>

            {
                returnView()
            }

            <LocalModal
                show={addPracticeCard}
                toggleShow={updateAddPracticeCard}
                component={
                    <AddPracticeCard
                        type={addingType}
                        toggleShow={updateAddPracticeCard}
                
                        triggerReRender={triggerReRender}
                    />
                }
            />

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchError}
            />

        </div>
    )
}

export default PracticeCards;
