
function user({
    userId,
    email,
    firstName,
    fullName,
    isVerified,
    roles,
    token
} = {}) {
    this.userId = userId;
    this.email = email;
    this.firstName = firstName;
    this.fullName = fullName;
    this.isVerified = isVerified;
    this.roles = roles;
    this.token = token;

}

export default user;
