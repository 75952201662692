
import parse from "html-react-parser";

// routing
import { useRouteMatch, Link } from 'react-router-dom';

// services
import cleanText from "../../../../../../services/cleanText";

// styles
import "./Exam.scss";

// constants
import { EDITOR_ACCESS } from "../../../../../../services/access/contentAccess/contentAccessConstants";

const Exam = (props) => {

    // routing
    let { url } = useRouteMatch();

    return (
        <div className={`sectExamView ${props.selected ? "sectSelectedExamView" : ""}`} >
            <div className="sectExamTitle" >{
                !props.data[props.dataKey].title ? null :
                parse(
                    cleanText({
                        textToClean: props.data[props.dataKey].title,
                        h1: true,
                        h2: true,
                        h3: true,
                        h4: true,
                        p: true,
                        list: true,
                    })
                )
            }</div>
            
            <div className="sectExtra" >
                {props.data[props.dataKey] && props.data[props.dataKey].questions ? `${props.data[props.dataKey].questions.length} vragen` : null}
                <Link 
                    className="sectStartExam" 
                    to={
                        props.userAccess === EDITOR_ACCESS ? 
                        `${url}/e-${props.data[props.dataKey].uuid}/Examen-Aanpassen`
                        :
                        `${url}/e-${props.data[props.dataKey].uuid}/Examen-Maken`
                    } >{
                    
                    props.userAccess === EDITOR_ACCESS ? 
                    "Aanpassen"
                    :
                    "Start"
                }</Link>
            </div>
        </div>
    )
}

export default Exam;
