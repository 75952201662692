
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

// constants
import { SUCCESS } from '../../errors/constants';

const textExplenationApi = {
    addTextExplBySubSubTopicId,
    getTextExplBySubTopicId,
    putTextExplByTextId,
    deleteTextEpleByTextExplId
};

async function addTextExplBySubSubTopicId(subTopicId, text) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/${subTopicId}/text`, 
            text,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

async function getTextExplBySubTopicId(subTopicId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subtopics/${subTopicId}/text`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

async function putTextExplByTextId(textId, text) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/texts/${textId}`, 
            text, 
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function deleteTextEpleByTextExplId(textId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/texts/${textId}`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

export default textExplenationApi;
