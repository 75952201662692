
import React, { useState, useEffect } from 'react';

// style
import "./MultipleChoice.scss";

// services local
import findEditedAnswer from "../../services/findEditedAnswer";

// classes
import errorClass from "../../../../../../services/errors/classes";

// compontents local
import Option from "./components/Option";

// components
import List from "../../../../../../components/List";

// constants
import { SUCCESS } from '../../../../../../services/errors/constants';
import { MAKE } from "../../../../services/tabConstants";

const MultipleChoice = (props) => {

    // string for future for select multiple
    const [selectedChanged, toggleSelectedChanged] = useState(false);

    useEffect(() => {
        // only for make exam
        if (props.examView === MAKE) {
            answerInitSelected();
        }
        
    }, [props.userAnswers]);
    
    // checks user answered earlier - del?
    const answerInitSelected = async() => {

        if (!props.userAnswers) {
            return null;
        }

        const userAnswerInfo = await props.userAnswers.find((answer) => answer.questionId === props.questionId);

        if (!userAnswerInfo) {
            return null;
        }

    }

    const optionClicked = (newAnswerId) => {

        // stop indicating that answer is not filled in
        props.updateQuestionError(new errorClass({
            errorCode: SUCCESS
        }));

        // check if multiple correct options
        if (!props.questionHub.multipleCorrectAnswers()) {
            // deselect
            if (props.questionHub.answerId[0] === newAnswerId) {
                props.questionHub.answerId = [];
            
            // select
            } else {
                props.questionHub.answerId = [newAnswerId];
            }
            
            // trigger every option to check if it is selected
            toggleSelectedChanged(value => !value);
        } else {
            
            // check if option already selected
            if (props.questionHub.answerId.includes(newAnswerId)) {
                props.questionHub.answerId = props.questionHub.answerId.filter(value => value != newAnswerId);
            } else {
                // add selected answer to selected answers
                props.questionHub.answerId = [...props.questionHub.answerId, newAnswerId];
            }
            
            toggleSelectedChanged(value => !value);

        }

        if (!props.userAnswers) {
            return null;
        }

        const userAnswer = props.userAnswers.find((answer) => answer.questionId === props.questionId);
        
        userAnswer.newAnswerId = newAnswerId;

    }

    // this is only possible for multiple choice questions!
    const answerCorrectChange = (answerNumber, newValue) => {
        
        if (!props.answerhubs) {
            return null;
        }

        if (newValue === false) {

            props.answerhubs.forEach(answerHub => {
                if (answerHub.number === answerNumber) {
                
                    answerHub.updateProperty("correct", newValue);
                    answerHub.updateProperty("points", 0);

                }
            });

        } else {

            // save heighest points of any answer
            let heighestPoints = 0;

            // get the highest amount of points any answers has
                // have to loop through all answers twice, otherwise misse correct answer after clicked answer
            props.answerhubs.map((answerHub) => {

                if (heighestPoints < answerHub.getCurrentValue("points")) {
                    heighestPoints = answerHub.getCurrentValue("points");
                }

            });

            // if there were no answers with points
            if (heighestPoints === 0) {

                const correctAnswers = props.questionHub.getCorrectAnswers();

                // check if points in on question level!
                if (props.points > 0 & correctAnswers.length === 0) {

                    heighestPoints = props.points;

                } else {

                    if (props.points > 0 & correctAnswers.length !== 0) {
                        // get the average points per answer and give these point to new answer -- use heighest points when poinst are sent in answer later!
                        heighestPoints = Math.ceil(props.points / correctAnswers.length);
                    } else {
                        // else just give 1 point to correct answer
                        heighestPoints = 1;
                    }
                    
                }
            }
            
            props.answerhubs.map((answerHub) => {

                if (answerHub.number === answerNumber) {
                    answerHub.updateProperty("correct", newValue);
                    answerHub.updateProperty("points", newValue ? heighestPoints : 0);
                } else {
                    if (props.oneCorrectAnswer && newValue) {
                        answerHub.updateProperty("correct", false);
                        answerHub.updateProperty("points", 0);

                    // make sure OG correct answer is also counted - currently done by adding the points to an edited version
                    }
                }
            });

        }

        props.updateAnswers();

        // update points state
        props.updatePoints();

        // reset errors and warnings
        if (props.updateAnswerWarning) {
            props.updateAnswerWarning(new errorClass({
                errorCode: SUCCESS
            }));
        }

    }

    // show questions in list
    const renderShowAnswers = (i, answerHub) => {

        return (
            <Option
                key={i}
                firstOption={i === 0}
                lastOption={props.answerhubs.length === i+1}

                examView={props.examView}
                answerAccess={props.answerAccess}

                questionHub={props.questionHub}

                answerhubs={props.answerhubs}
                answerHub={answerHub}

                selectedChanged={selectedChanged}

                updateType={props.updateType}

                oneCorrectAnswer={props.oneCorrectAnswer}
                updatePoints={props.updatePoints}

                answerCorrectChange={answerCorrectChange}

                editMCAnswers={props.editMCAnswers}

                // errors
                questionError={props.questionError} // !!!!!!!!! answerWarning already implemeted? 🤞
                updateQuestionError={props.updateQuestionError}
                alignQuestionError={props.alignQuestionError}
                
                // delete maybe?
                optionClicked={optionClicked}

                // selected={selectedId[0] === answer.answerId}
                // updateSelectedId={updateSelectedId}

                // validation
                validate={props.validate}

            />
        )
    }
    
    return (
        <>
                    
            {
                props.answerWarning.errorCode !== SUCCESS ?
                <div 
                    className="MultipleChoiceWarning"
                >{
                    props.answerWarning.description
                }</div>
                : null
            }
        
            <div className={`MultipleChoice ${props.answerWarning.errorCode !== SUCCESS ? "MultipleChoiceError" : ""}`} >
                
                <List
                    items={props.answerhubs}
                    renderItem={renderShowAnswers}
                />
            </div>
        </>
    )
}

export default MultipleChoice;
