
import React, { useState } from 'react';

// local elements
import Image from "../elements/Image";

// style
import "./Image.scss";

// classes
import errorClass from "../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../services/errors/constants";

const ImageInput = (props) => {

    const [formatError, updateFormatError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    return (
        <>{
            !props.errorClass || !props.dataClass ? null :
            
            <div className="imageInput" >
                <div className="imageInputTitle" >
                    {props.title}
                </div>
                {
                    props.errorClass.errorCode === SUCCESS ? null :
                    <div className="showError" >
                        {
                            props.errorClass.description
                        }
                    </div>
                }
                {
                    formatError.errorCode === SUCCESS ? null :
                    <div className="showError" >
                        {
                            formatError.description
                        }
                    </div>
                }
                <Image
                    value={props.dataClass[props.valueKey]}
                    dataObject={props.dataClass}
                    valueKey={props.valueKey}

                    customUpdateFunc={props.customUpdateFunc}

                    showError={props.errorClass.errorCode !== 200}
                    updateError={props.updateError}
                />
            </div>
        }</>
    )
}

export default ImageInput;
