
import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from '../../services/redux/actions/globalErrors.action';

// networking
import subjectApi from '../../services/Networking/subject/subject';
import booksApi from '../../services/Networking/books/books';

// style
import "./AddSubject.scss";

// components
import TextInput from '../input/Text/Text';
import Submit from '../input/elements/Submit';
import ImageInput from '../input/Image/Image';
import Loader from '../Loader';

// elements
import Title from '../../elements/ModalTitle/Title';

// classes
import NewSubject from './services/classes/newSubject';
import NewBook from '../AddBook/services/classes/newBook';
import errorClass from '../../services/errors/classes/errorClass';

// local services
import defaultTextInputErrors from '../../services/errors/defaultTextInputErrors';
import validateTextInput from '../../services/validate/textInput/textInput';

// services
import convertError from '../../services/errors/convertError';

// constants
import { INTERNAL_ERROR, SUCCESS } from '../../services/errors/constants';

const AddSubject = (props) => {

    // redux
    const dispatch = useDispatch();

    const [newSubject, updateNewSubject] = useState(props.existingData ? props.existingData : new NewSubject());

    // input errors
    const [subjectNameError, updateSubjectNameError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [bookTitleError, updateBookTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [bookAuthorError, updateBookAuthorError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const save = async() => {

        toggleLoading(true);

        let validInput = true;

        // validate title
        const titleValid = validateTextInput(newSubject.subjectName);

        const titleErrorTemp = convertError({
            errorCode: titleValid,
            updateLocally: updateSubjectNameError,
            customErrors: defaultTextInputErrors
        });
        if (!titleErrorTemp) {
            validInput = false;
        } else {
            if (titleErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(titleErrorTemp));
                validInput = false;
            }
        }

        if (validInput) {
            let newSubjectResponse;

            if (newSubject.subjectId) {
                // responseTopic = await topicApi.putTopicByTopicId(topicInfo.topicId, topicInfo);
            } else {
                newSubjectResponse = await subjectApi.postSubject(newSubject);
            }

            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!newSubjectResponse) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (newSubjectResponse.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: newSubjectResponse.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
                
                if (props.runOnSuccess) {
                    props.runOnSuccess();
                }
                props.toggleShow((value) => !value);
                if (props.triggerReRender) {
                    props.triggerReRender((value) => !value);
                }

            } else {
                // also show error locally

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: newSubjectResponse.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
                
            }
        }

        toggleLoading(false);
    }

    return (
        <div className="AddSubjectView">
            <Title
                title={props.existingData ? "VAK AANPASSEN" : "VAK TOEVOEGEN"}
            />

            <TextInput
                title="VAK NAAM"
                
                valueKey="subjectName"
                dataClass={newSubject}

                errorClass={subjectNameError}
                updateError={updateSubjectNameError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }
        </div>
    )
}

export default AddSubject;
