
import { useState } from "react";

// styles
import "./boolean.scss";

// classes
import errorClass from "../../../services/errors/classes/errorClass";

// constants
import { SUCCESS } from "../../../services/errors/constants";

const Boolean = (props) => {

    const [value, updateValue] = useState(props.data ? props.data[props.valueKey] : props.clientData ? props.clientData.getCurrentValue(props.valueKey) : null);

    const optionClicked = (newValue) => {

        if (props.customUpdateFunc) {
            props.customUpdateFunc(
                props.valueKey,
                newValue
            );
        } else if (props.data) {
            props.data[props.valueKey] = newValue;
        } else if (props.clientData) {
            props.clientData.updateProperty(props.valueKey, newValue);
        }

        updateValue(newValue);

        // reset error
        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }

        // reset warning
        if (props.updateWarning) {
            props.updateWarning(new errorClass({
                errorCode: SUCCESS
            }));
        }

    }

    return (
        <>{
            !props.data && !props.clientData ? null :
            <>
                {
                    !props.error ? null : props.error.errorCode === SUCCESS || !props.error.description || (props.error.description && props.error.description === "") ? null :
                    <div className={props.styles?.showError || "booleanInputShowError"} >
                        {
                            props.error.description
                        }
                    </div>
                }
            <div
                className="booleanInputView"
            >
                {
                    props.title ? 
                    <div className={`booleanInputTitle`} >{ props.title }:</div> 
                    : null
                }
                <div
                    className={`booleanInputItemView booleanInputItemOption ${value === true ? "booleanInputItemSelectedOption" : ""} ${props.error && props.error.errorCode !== SUCCESS ? "booleanInputError" : ""}`}
                    onClick={() => optionClicked(true)}
                >{props.trueOptionText ? props.trueOptionText : "Ja"}</div>
                <div
                    className={`booleanInputItemView ${props.error && props.error.errorCode !== SUCCESS ? "booleanInputError" : ""}`}
                > / </div>
                <div
                    className={`booleanInputItemView booleanInputItemOption ${value === false ? "booleanInputItemSelectedOption" : ""} ${props.error && props.error.errorCode !== SUCCESS ? "booleanInputError" : ""}`}
                    onClick={() => optionClicked(false)}
                >{props.falseOptionText ? props.falseOptionText : "Nee"}</div>
            </div>
            </>
        }</>
    )
}

export default Boolean;
