
function addTopicClass({
    title = "",
    subtitle = "",
    timeScale = "",
    url = "",
    extraInfo = "",
    type = null,
    backgroundImage = null,
} = {}) {

    this.title = title;
    this.subtitle = subtitle;
    this.timeScale = timeScale;
    this.url = url;
    this.extraInfo = extraInfo;
    this.type = type;
    this.backgroundImage = backgroundImage;
}

export default addTopicClass;
