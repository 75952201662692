
import { useState } from "react";

// styles
import "./Textarea.scss";

// classes
import errorClass from "../../../../services/errors/classes/errorClass";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

const Textarea = (props) => {
    // we're getting a warning because of this but it works
    const [typed, toggleTyped] = useState(false);

    const onChange = (newValue) => {

        const newText = newValue.target.value;

        if (props.customUpdateFunc) {
            props.customUpdateFunc(
                props.valueKey,
                newText
            );
        } else if (props.dataObject) {
            props.dataObject[props.valueKey] = newText;
        } else if (props.clientData) {
            props.clientData.updateProperty(props.valueKey, newText);
        }

        toggleTyped(true);

        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }
    }

    return (
        <textarea 
            className={`
                "TextareaInputBaseTextField
                ${props.resizeVertical && props.resizeHorizontal ? "resizeBoth" : ""}
                ${props.resizeVertical && !props.resizeHorizontal ? "resizeVertical" : ""}
                ${props.resizeHorizontal && !props.resizeVertical ? "resizeHorizontal" : ""}

                ${!props.errorClass ? "" : props.errorClass?.errorCode !== SUCCESS ? "TextareaInputTextError" : ""}
            `}
            name={props.valueKey}
            value={typed ? undefined : props.value}
            onChange={onChange}
            rows={5} // You can adjust the number of rows as needed
        />
    )
}

export default Textarea;
