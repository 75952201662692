
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const answerApi = {
    putAnswerByAnswerId,
    deleteAnswerByAnswerId
}

async function putAnswerByAnswerId({answerId, answerClass}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/answers/${answerId}`,
            answerClass,
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
}

async function deleteAnswerByAnswerId(answerId) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/answers/${answerId}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

export default answerApi;
