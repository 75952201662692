
// standard objects
import errorClass from "../classes";

// constants
import { NO_CONTENT } from "../constants";

const defaultImageErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Je moet een afbeelding toevoegen.",
                description: "Je moet een afbeelding toevoegen.",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default defaultImageErrors;
