
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const  examsApi = {
    addNewExamBySubTopicId,
    addExamBySubTopicId,
    getExamByExamId,
    getExamsBySubTopicId,
    putExamByExamId,
    deleteExamByExamId,
    removeQuestionByExamAndQuestionId,
    removeAnswerByQuestionIdAndAnswerId
}

async function addNewExamBySubTopicId({subTopicId, examObject} = {}) {
    const auth = await authHeader();
    examObject["subtopicId"] = subTopicId;
    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/exams`,
            examObject,
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
}

async function addExamBySubTopicId({examObject, currentState} = {}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/exams/formulate?state=${currentState}`,
            examObject,
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
}

async function getExamByExamId({examId, state}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/exams/${examId}?state=${state}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function getExamsBySubTopicId({subTopicId, state} = {}) {

    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subtopics/${subTopicId}/exams?state=${state}`,
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;

}

async function putExamByExamId({examId, examClass} = {}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/exams/${examId}`, 
            examClass,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function deleteExamByExamId(examId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/exams/${examId}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function removeQuestionByExamAndQuestionId({examId, questionId}) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/exams/${examId}/questions/${questionId}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function removeAnswerByQuestionIdAndAnswerId({questionId, answerId}) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/questions/${questionId}/answers/${answerId}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

export default examsApi;
