
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

// style
import "./Add.scss";

const Add = (props) => {
    return (
        <FontAwesomeIcon 
            onClick={props.onClick}
            className="Add"
            icon={faPlusCircle}
        />
    )
}

export default Add;
