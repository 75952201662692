
function linkThemeClass({
    title = "",
    linkThemeId,
    links = [],
} = {}) {

    this.title = title;
    this.linkThemeId = linkThemeId;
    this.links = links;

}

export default linkThemeClass;
