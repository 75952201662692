
// styles
import "./AssignmentInfo.scss";

// components
import Description from "./components/Description/Description";
import Overview from "./components/Overview/Overview";
import Settings from "./components/Settings/Settings";
import AssignedItems from "./components/AssignedItems/AssignedItems";

const AssignmentInfo = (props) => {

    return (
        <div
            className="assignmentInfoView"
        >   
            <div
                className="assignmentInfoSectionView"
            >
                <div
                    className="assignmentInfoTopView assignmentInfoTopViewLeft"
                >
                    <Overview
                        assignment={props.assignment}
                        assignmentStatistics={props.assignmentStatistics}
                        editingAssignment={props.editingAssignment}

                        gradingDeadlineChanged={props.gradingDeadlineChanged}
                        toggleGradingDeadlineChanged={props.toggleGradingDeadlineChanged}

                        // errors
                        deadlineError={props.deadlineError}
                        updateDeadlineError={props.updateDeadlineError}
                        gradingDeadlineError={props.gradingDeadlineError}
                        updateGradingDeadlineError={props.updateGradingDeadlineError}
                    />
                    <Settings
                        assignment={props.assignment}
                        editingAssignment={props.editingAssignment}
                        getAssignment={props.getAssignment}
                    />
                    <Description
                        assignment={props.assignment}
                        editingAssignment={props.editingAssignment}
                    />
                </div>
                {/* <div
                    className="assignmentInfoTopView assignmentInfoTopViewRight"
                >
                    <AssignmentColleague
                        assignment={props.assignment}
                    />
                    {
                        props.assignment.getCurrentValue("isAdmin") === false ? null :
                        <ShareContent
                            title="Medewerkers Toevoegen"
                            token={props.assignment?.getCurrentValue("addColleaguesToken")}
                        />
                    }
                </div> */}
            </div>

            <AssignedItems
                assignment={props.assignment}
                editingAssignment={props.editingAssignment}

                getAssignment={props.getAssignment}
            />
        </div>
    )
}

export default AssignmentInfo;
