
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from '../../../../services/redux/actions/globalErrors.action';

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// networking
import topicApi from '../../../../services/Networking/topic/topic';

// services
import convertError from '../../../../services/errors/convertError';
import userActions from '../../../../services/redux/actions/user.action';
import logOutErrors from '../../../../services/errors/logOutErrors';
import networking from '../../../../services/handleNetworking/networking/networking';
import booksApi from '../../../../services/Networking/books/books';
import access from '../../../../services/access/accessCheck/access';

// local services
import topicErrors from '../../services/errors/errors';

// style
import "./Subject.scss";

// components local
import Topic from '../Topic';

// components
import Loader from '../../../../components/Loader';
import List from '../../../../components/List';
import LocalModal from '../../../../components/LocalModal';
import AddTopic from '../../../Subject/AddTopic';
import AddBook from '../../../../components/AddBook/AddBook';

// constants
import { SUCCESS } from '../../../../services/errors/constants';
import { PREPARATORY, INFORMATIVE } from '../../../../services/constants/topicTypeConstants';
import { FORMER_EXAMS } from '../../services/constants';
import { ADMIN } from '../../../../services/access/constants';

const Subject = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    let location = useLocation();

    // topic id
    let { tijdvakID } = useParams();
    const topicId = tijdvakID ? parseInt(tijdvakID.replace("t-", "")) : null;

    // errors
    const [fetchError, updateFetchError] = useState(null);

    // book
    const [book, updateBook] = useState(null);

    // topics
    const [topics, updateTopics] = useState(null);

    // adding content
    const [addBook, toggleAddBook] = useState(false);
    const [addTopic, toggleAddTopic] = useState(false);

    // loading
    const [loadingFetch, toggleLoadingFetch] = useState(true);

    // logs user out
    const logOut = () => {
        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // log user in
        dispatch(userActions.logOut());

        // display success
        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: SUCCESS,
                updateLocally: null,
                customErrors: logOutErrors
            }))
        );
    }

    // fetch topics
    const fetchTopics = async(bookId) => {

        networking({

            updateContent: updateTopics,

            toggleLoading: toggleLoadingFetch,

            accessableToAllUsers:true,
            logOut:logOut,
                    
            api: topicApi.getTopicsByBookId,
            apiParams: {
                bookId: bookId, 
                type: PREPARATORY
            },
        
            updateError: updateFetchError,
            customErrors: topicErrors,

            history: history

        });

        // updateReport(null);

    }

    const fetchData = async() => {
        
        // fetch books in subject
        const booksResponse = await networking({
            api: booksApi.getBooks,
            apiParams: props.data.subjectId
        });
        if (!booksResponse || booksResponse.status !== SUCCESS) {
            updateBook(null);
            updateTopics(null);
            toggleLoadingFetch(false);
            return;
        }

        // use first book in subject
        const books = booksResponse.payload;
        const book = books[0];
        if (!book) {
            updateBook(null);
            updateTopics(null);
            toggleLoadingFetch(false);
            return;
        }
        updateBook(book);

        // fetch topics
        fetchTopics(book.bookId);

    }

    const getTabId = () => {
        return location.hash ? parseInt(location.hash.replace("#", "")) : FORMER_EXAMS
    }

    // fetch topics
    useEffect(() => {
        toggleLoadingFetch(true);
        fetchData();
    }, [props.selectedSubject]);

    const renderTopic = (i, data) => {

        if (data) {
            return (
                <Topic
                    index={data.topicId}
                    data={data}
    
                    isReport={false}
                    fetchData={fetchData}
    
                    tabID={location.hash ? parseInt(location.hash.replace("#", "")) : FORMER_EXAMS}
                />
            )
        }
    }

    return (
        <div
            className="examPrepSubjectView"
        >
            <div
                className="ExamPrepTitle"
            >
                {
                    props.data.name
                }

                {
                    book ? null : !user ? null : !user.user ? null : access({
                        accessLevel: ADMIN,
                        userRoles: user.user.roles
                    }) ? 
                    <div
                        className="examPrepPageAddBook"
                        onClick={() => toggleAddBook(true)}
                    >
                        Boek toevoegen
                    </div> : null
                }
            </div>

            {
                loadingFetch ? <Loader /> :

                <List
                    items={topics}
                    renderItem={renderTopic}
                />

            }

            {
                !book ? null : !user ? null : !user.user ? null : access({
                    accessLevel: ADMIN,
                    userRoles: user.user.roles
                }) ? 
                <div
                    className="examPrepPageAddTopic"
                    onClick={() => toggleAddTopic(true)}
                >
                    Hoofdstuk toevoegen
                </div> : null
            }

            <LocalModal
                show={addBook}
                toggleShow={toggleAddBook}
                component={
                    <AddBook
                        toggleShow={toggleAddBook}

                        subjectId={props.data.subjectId}

                        runOnSuccess={() => fetchData()}
                    />
                }
            />

            <LocalModal
                show={addTopic && book?.bookId}
                toggleShow={toggleAddTopic}
                component={
                    <AddTopic
                        toggleShow={toggleAddTopic}

                        bookId={book?.bookId}

                        runOnSuccess={() => fetchData()}
                    />
                }
            />
        </div>
    )
}

export default Subject;
