
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import sourcesApi from "../../../../services/Networking/sources";

// services
import convertError from "../../../../services/errors/convertError";
import access from "../../../../services/access/accessCheck";

// style
import "./Sources.scss";

// local components
import Source from "./components/Source";
import AddSource from "./components/AddSource";

// components
import Add from "../../../../elements/Add";
import List from "../../../../components/List";
import Loader from '../../../../components/Loader';
import LocalModal from '../../../../components/LocalModal';

// constants
import { EDITOR } from "../../../../services/access/constants";
import { SUCCESS, NO_RESPONSE } from "../../../../services/errors/constants";

const Sources = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(true);

    const [sourcesList, updateSourcesList] = useState([]);
    const [addSource, toggleAddSource] = useState(false);

    const fetchSources = async() => {
        const returned = await sourcesApi.getSourcesBySubTopicId(subTopicId);

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            updateSourcesList(returned.payload);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);

    }

    useEffect(() => {

        toggleLoading(true);

        fetchSources();
    
    }, [rerender]);

    const renderSource = (i, sourceData) => {
        return (
            <Source
                key={i}
                data={sourceData}

                triggerReRender={triggerReRender}
            />
        )
    }

    return (
        <div className="Sources" >
            <div className="SourcsTitle" >
                Bron Analyses

                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: EDITOR,
                        userRoles: user.user.roles
                    }) ?
                    <div className="addSource">
                        <Add
                            onClick={() => toggleAddSource(true)}
                        />
                    </div> : null
                }

                <LocalModal
                    show={addSource}
                    toggleShow={toggleAddSource}
                    component={
                        <AddSource
                            toggleShow={toggleAddSource}

                            triggerReRender={triggerReRender}
                        />
                    }
                />
            </div>

            <div className="SourcesView" >
            {
                loading ? <Loader /> :
                sourcesList.length === 0 ? 
                <div className="noSources" >
                    Er zijn nog geen bronnen voor dit hoofdstuk
                </div>
                :
                <List
                    items={sourcesList}
                    renderItem={renderSource}
                />
            }
            </div>
        </div>
    )
}

export default Sources;
