
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const examEvaluation = {
    postExamEvaluation
}

async function postExamEvaluation(examUUID, order) {
    const auth = await authHeader();
    const responseObj = await handleResponse(
        axios.post(
            `/exams/${examUUID}/ai-evaluate?order=${order}`,
            {},
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

export default examEvaluation
