
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const eventsApi = {
    addEventBySubTopicId,
    getEventsBySubTopicId,
    deleteEventByEventId,
    deleteThemeByThemeId,
    deleteChatByChatId
}

async function addEventBySubTopicId(subTopicId, eventClass) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/${subTopicId}/importantEvents`, 
            eventClass, 
            {
                headers: auth.payload
            } 
        )
    );

    return responseObj;

}

async function getEventsBySubTopicId(subTopicID) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subtopics/${subTopicID}/importantEvents`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function deleteEventByEventId(eventId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/events/${eventId}`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function deleteThemeByThemeId(themeId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/eventThemes/${themeId}`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function deleteChatByChatId(chatagoryId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/importantEvents/${chatagoryId}`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

export default eventsApi;
