
// networking
import takenExamsApi from "../../../../../../services/Networking/takenExams";

// services local
import initQuestionHubs from "../../../../services/initQuestionHubs";

const fetchTakenExam = async({
        examId,
        orderNumb,

        handleApiReturn, 
        updateExamClass, 
        updateQuestionHubs, 
    } = {}) => {
        const returned = await takenExamsApi.getTakenExamByExamIdAndOrder(examId, orderNumb);

        const fetchSuccessfull = await handleApiReturn(returned);

        if (fetchSuccessfull) {
            const tempExamClass = returned.payload;
            
            updateExamClass(tempExamClass);
            updateQuestionHubs(() => {
                return initQuestionHubs({sentQuestions: tempExamClass.questions});
            });

            return returned.payload;
        }

        return false;
}

export default fetchTakenExam;
