
// services
import handleEnvironment from "../handleEnvironment/handleEnvironment";

// constants
import { UVA_ENV } from "../handleEnvironment/constants";

const createExamURL = ({
    base,

    subjectId,
    bookId,
    tijdvakID,
    kaID,
    examID,
    oderNumb,

    examPage,

} = {}) => {
    if (handleEnvironment() === UVA_ENV) {
        return `/tentamen-voorbereidingen/${subjectId}/${bookId}/${tijdvakID}/${kaID}/${examID}${oderNumb ? `/${oderNumb}/Examen-Nakijken` : examPage ? `/${examPage}` : "" }`;
    }

    // Get the current pathname
    const currentPath = base ? base : window.location.pathname;
    if (currentPath.startsWith("/examen-voorbereidingen")) {
        return `/examen-voorbereidingen/${subjectId}/${bookId}/${tijdvakID}/${kaID}/${examID}${oderNumb ? `/${oderNumb}/Examen-Nakijken` : examPage ? `/${examPage}` : "" }`;
    }
    return `/geschiedenis/${tijdvakID}/${kaID}/${examID}/Examen-Maken`;

}

export default createExamURL;
