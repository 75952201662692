
function sendableChategoryClass({
    title = "",
    importantEventId = 0,
    eventThemes = []
} = {}) {
    this.title = title;
    this.importantEventId = importantEventId;
    this.eventThemes = eventThemes;
}

export default sendableChategoryClass;
