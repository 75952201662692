
// font awsome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

// style
import "./emptyListText.scss";

const emptyListText = () => {
    return (
        <>
        <FontAwesomeIcon 
            icon={faExclamationTriangle} 
            className="exclamationTriangle" 
        /> 
            Er zijn geen flashcards voor dit hoofdstuk met deze filters
        </>
    );
}

export default emptyListText;
