
const formatCheckedAnswers = (examId, questionHubs) => {

    let sendableFeedback = [];

    questionHubs.map((questionHub) => {
        questionHub.feedback?.map((feedback) => {

            if (feedback.put || feedback.post) {
                const correctAnswer = questionHub.getAnswers()[0];
                const answerId = correctAnswer.answerId;
                const maxPoints = correctAnswer.getCurrentValue("points");
    
                const sendableData = {
                    "questionId": questionHub.questionId,
                    "answerId": answerId,
                    "evaluationId": feedback.id || 0,
                    "correct": maxPoints === feedback.getCurrentValue("points"),
                    "points": feedback.getCurrentValue("points") || 0,
                    "feedback": feedback.getCurrentValue("feedback") || "",
                }
    
                sendableFeedback = [...sendableFeedback, sendableData];
            }
        });
    });

    return sendableFeedback;

}

export default formatCheckedAnswers;
