
// classes local
import sendableAnswerClass from "./sendableAnswerClass";

// constants
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../services/constants/typeConstants";

const sendableUserAnswers = (questionsHubs, handIn) => {

    if (!questionsHubs) {
        return false;
    }

    let sendableAnswers = [];

    questionsHubs.map((questionsHub) => {

        let sendableAnswer = {
            "questionId": questionsHub.questionId,
            "answers": []
        }

        if (questionsHub.getCurrentValue("type") === ASSAY_QUESTION) {
            // answer is filled in
            if (questionsHub.studentAnswer) {

                let studentAnswer = {
                    "answerId": questionsHub.answerId[0],
                    "selected": true,
                    "userResponse": questionsHub.studentAnswer
                }

                sendableAnswer["answers"] = [
                    ...sendableAnswer["answers"],
                    studentAnswer
                ]

                // sendableAnswers = [
                //     ...sendableAnswers, 
                //     new sendableAnswerClass({
                //         questionId: questionsHub.questionId,
                //         answerId: questionsHub.answerId[0],
                //         userResponse: questionsHub.studentAnswer,
                //     })
                // ];

            }
        } else if (questionsHub.getCurrentValue("type") === MULTIPLE_CHOICE_QUESTION) {

            // ansewr is filled in
            const answers = questionsHub.getNotDelAnswers();

            answers.forEach(answerHub => {
                let studentAnswer = {
                    "answerId": answerHub.answerId,
                    "selected": !questionsHub.answerId ? false : questionsHub.answerId.includes(answerHub.answerId) ? true : false,
                    "userResponse": ""
                }

                sendableAnswer["answers"] = [
                    ...sendableAnswer["answers"],
                    studentAnswer
                ]

            });

            // // loop through all selected answers
            // questionsHub.answerId.map((answerId) => {

            //     let studentAnswer = {
            //         "answerId": answerId,
            //         "selected": true,
            //         "userResponse": ""
            //     }
    
            //     sendableAnswer["answers"] = [
            //         ...sendableAnswer["answers"],
            //         studentAnswer
            //     ]

            //     // sendableAnswers = [
            //     //     ...sendableAnswers, 
            //     //     new sendableAnswerClass({
            //     //         questionId: questionsHub.questionId,
            //     //         answerId: answer,
            //     //         userResponse: "",
            //     //     })
            //     // ];

            // })
        }

        sendableAnswers = [
            ...sendableAnswers,
            sendableAnswer
        ]
        // if (sendableAnswer.answers.length > 0) {
        //     sendableAnswers = [
        //         ...sendableAnswers,
        //         sendableAnswer
        //     ]
        // }
    });
    
    return sendableAnswers;

}

export default sendableUserAnswers;
