
// classes
import buttonClass from "../../../../components/Alert/services/classes/ButtonClass/buttonClass"

// components
import LocalModal from "../../../../components/LocalModal"
import Alert from "../../../../components/Alert"

const UnsavedExamChanges = (props) => {

    return (
        <LocalModal
            show={props.showUnsavedWarning}
            toggleShow={props.toggleShowUnsavedWarning}
            component={
                <Alert
                    toggleShow={props.toggleShowUnsavedWarning} 
                
                    title="Onopgeslagen veranderingen"
                    description="Er zijn onopgeslagen veranderingen gededacteerd in de toets. Weet je zeker dat je wilt afsluiten?"
                
                    buttonClasses={[
                        new buttonClass({
                            title: "Afsluiten annueleren",
                            buttonFunc: () => props.toggleShowUnsavedWarning(false),
                        }),
                        new buttonClass({
                            title: "Afsluiten zonder op te slaan",
                            buttonFunc: () => props.close(true),
                        }),
                    ]}
                
                />
            }
        />
    )
}

export default UnsavedExamChanges
