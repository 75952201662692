
// react
import React, { useEffect } from 'react';

// components
import List from "../List";

// elements
import CheckBox from "../AlterContent/elements/CheckBox";

// styles
import "./Filters.scss";

const Filters = (props) => {

    const fetchAndFilterList = async(reFetchItems=true) => {
        let latestFetchedList = props.fullList;
        if (reFetchItems === true) {
            latestFetchedList = await props.filtersClass.fetchWithFetchFilters(props.nonFilterFetchParams);
            if (props.updateFetchedList) {
                props.updateFetchedList(latestFetchedList);
            }
        }
        const filteredList = props.filtersClass.applyLocalFiltersToList(latestFetchedList);
        props.updateFilteredList(filteredList);
    }

    useEffect(() => {
        fetchAndFilterList(true);
    }, [props.rerender]);

    const renderFilter = (i, Data) => {

        Data.optionClickedUpdate = fetchAndFilterList;

        if (Data.visible) {
            return (
                <div
                    key={Data?.idFilter}
                >{
                    props.filtersClass.renderFilter(i, Data)
                }</div>
            )
        }
    }

    const toggleSelectionActive = () => {
        props.updateFiltersClass((filtersClass) => {
            return filtersClass.cloneWithChanges({
                selectItemsPossible: !filtersClass.selectItemsPossible,
            });
        });
    };

    const toggleSelectAll = () => {

        props.filtersClass.allItemsSelected = !props.filtersClass.allItemsSelected;

        props.updateFilteredList((filteredList) => {
            filteredList.forEach((item) => {
                if (props.filtersClass.allItemsSelected) {
                    props.filtersClass.selectItem(item[props.filtersClass.itemIdKey]);
                } else {
                    props.filtersClass.deselectItem(item[props.filtersClass.itemIdKey]);
                }
            });
            return filteredList;
        });

        props.updateFiltersClass((filterClass) => {
            return filterClass.cloneWithChanges();
        });
    }

    return (
        <>{
            props.hideViewFilters ? null :
            <div className={`${props.styles?.view ?? ""}`} >
                <List 
                    items={props.filtersClass.filters}
                    renderItem={renderFilter}
                />

                {
                    !props.filtersClass.selectingItemsPresentAsFilterOption ? null :
                    <div className="selectionMenu" >
                        <div 
                            className="selectionText"
                            onClick={toggleSelectionActive}
                        >
                            <CheckBox
                                checked={props.filtersClass.selectItemsPossible}
                            />
                            
                            {"Selecteer Flashcards"}
                        </div>

                        {
                            !props.filtersClass.selectItemsPossible ? null :
                            <div 
                                className="selectionText"
                                onClick={toggleSelectAll}
                            >
                                <CheckBox
                                    checked={props.filtersClass.allItemsSelected}
                                />

                                {props.filtersClass.allItemsSelected ? "Alles Deselecteren" : "Alles Selecteren"}
                            </div>
                        }
                    </div>
                }
            </div>
        }</>
    )
}

export default Filters;
