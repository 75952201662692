
// local components
import Paragraph from "../Paragraph";

// style
import "./EntityInfo.scss";

const EntityInfo = (props) => {

    return (
        <div className="EntityInfoView" >
            { !props.image ? null : <div className="EntityInfoImage">
                <img 
                    className="imgEntityInfo"
                    src={props.image} 
                    alt="Bron Afbeelding" 
                />
            </div>}
            
            <div className="EntityInfoText" >
                <Paragraph
                    title={props.title}
                    description={props.description}
                />
            </div>
        </div>
    )
}

export default EntityInfo;
