
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// networking
import LinksApi from "../../../../../../services/Networking/Links";

// routing
import { useParams } from 'react-router-dom';

// services
import convertError from "../../../../../../services/errors/convertError";
import validateTextInput from "../../../../../../services/validate/textInput";
import defaultTextInputErrors from "../../../../../../services/errors/defaultTextInputErrors";
import networking from "../../../../../../services/handleNetworking/networking";

// style
import "./AddLink.scss";

// components
import TextInput from "../../../../../../components/input/Text";
import SelectInput from "../../../../../../components/input/Select";
import Submit from "../../../../../../components/input/elements/Submit";
import Loader from "../../../../../../components/Loader";

// elements
import Title from "../../../../../../elements/ModalTitle";

// classes
import errorClass from "../../../../../../services/errors/classes";
import option from "../../../../../../components/input/elements/Select/classes/option";

// local classes
import linkClass from "../../services/classes/linkClass";
import linkThemeClass from "../../services/classes/linkThemeClass";

// local constants
import { ARTICLE, VIDEO, PODCAST, BOOK, DIFFERENT } from "../../services/constants";

// constants
import { SUCCESS, NO_CONTENT } from "../../../../../../services/errors/constants";
import Redux from '../../../../../../components/Redux/Redux';
import { REDUX_ERRORS } from '../../../../../../components/Redux/services/constants';

const AddLink = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [linkData, updateLinkData] = useState(props.existingData ? new linkClass({
        title: props.existingData.title,
        creator: props.existingData.creator,
        type: props.existingData.type,
        url: props.existingData.url
    }) : new linkClass());

    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [creatorError, updateCreatorError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [typeError, updateTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [urlError, updateUrlError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    // states
    const [loading, toggleLoading] = useState(false);

    // errors
    const [saveLinkError, updateSaveLinkError] = useState(null);

    const validateField = ({
        valueKey,
        validate,
        updateError,
        customErrors
    }) => {
        // validate
        const valid = validate(linkData[valueKey]);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {

        let validInput = true;

        // validate title
        if (!validateField({
            valueKey: "title",
            validate: validateTextInput,
            updateError: updateTitleError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate creator
        if (!validateField({
            valueKey: "creator",
            validate: validateTextInput,
            updateError: updateCreatorError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate creator
        if (!validateField({
            valueKey: "type",
            validate: validateTextInput,
            updateError: updateTypeError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate url
        if (!validateField({
            valueKey: "url",
            validate: validateTextInput,
            updateError: updateUrlError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        return validInput;

    }

    const save = async() => {
        toggleLoading(true);

        const validInput = validateFields();

        if (validInput) {
            const sendableClass = new linkThemeClass({
                title: props.themeData.title,
                linkThemeId: props.themeData.linkThemeId,
                links: [linkData]
            });

            const response = await networking({
                toggleLoading: toggleLoading,
                errorOnSuccess: false,
    
                api: LinksApi.addLinkBySubTopicId,
                apiParams: {
                    subTopicId: subTopicId,
                    data: sendableClass
                },
    
                updateError: updateSaveLinkError,
            });

            if (response && response?.status === SUCCESS) {
                props.fetchLinks();
                props.toggleShow(false);
            }
        }
    }

    return (
        <div className="AddChategory" >
            <Title
                title={props.existingData ? "LINK AANPASSEN" : "LINK TOEVOEGEN"}
            />

            <TextInput
                title="Title"
                
                valueKey="title"
                dataClass={linkData}

                errorClass={titleError}
                updateError={updateTitleError}
            />

            <TextInput
                title="Producent"
                
                valueKey="creator"
                dataClass={linkData}

                errorClass={creatorError}
                updateError={updateCreatorError}
            />

            <SelectInput
                title="LINK TYPE"

                options={[
                    new option({
                        value: ARTICLE,
                        title: "Artiekel"
                    }),
                    new option({
                        value: VIDEO,
                        title: "Video"
                    }),
                    new option({
                        value: PODCAST,
                        title: "Podcast"
                    }),
                    new option({
                        value: BOOK,
                        title: "Boek"
                    }),
                    new option({
                        value: DIFFERENT,
                        title: "Anders"
                    })
                ]}

                valueKey="type"
                dataClass={linkData}
                updateDataClass={null}

                errorClass={typeError}
                updateError={updateTypeError}
            />

            <TextInput
                title="URL"
                
                valueKey="url"
                dataClass={linkData}

                errorClass={urlError}
                updateError={updateUrlError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={saveLinkError}
            />

        </div>
    )
}

export default AddLink;
