
// styles
import "./FilterOption.scss";

// elements
import CheckBox from "../../../../AlterContent/elements/CheckBox";

const FilterOption = (props) => {

    return (
        <div
            className="filterOption"
        >
            <CheckBox
                checked={props.data.selected}
            />
            {
                props.data.title
            }
        </div>
    )
}

export default FilterOption;
