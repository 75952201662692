
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const reportApi = {
    getSubmittedExamReports,
    getAssignmentItemSTDReports,
    getAssignmentItemMedianPointsReports,
    getAssignmentItemMedianPercentageReports,
}

async function getSubmittedExamReports(takenExamId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/reports/submittedExam/${takenExamId}`,
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function getAssignmentItemSTDReports({assignmentId, itemId}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/reports/${assignmentId}/item/${itemId}/stdDev`,
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function getAssignmentItemMedianPointsReports({assignmentId, itemId}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/reports/${assignmentId}/item/${itemId}/medianPoints`,
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function getAssignmentItemMedianPercentageReports({assignmentId, itemId}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/reports/${assignmentId}/item/${itemId}/medianPercentage`,
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

export default reportApi;
