
// TODO: move into filterClass
const updateSelected = ({
    options, 
    idClickedOption, 
    allOption = true,
    selectOne = true
    } = {}) => {

    const index = options.findIndex((option) => {
        return option.index === idClickedOption;
    });

    if (selectOne) {
        options.forEach((option) => option.selected = false);

        options[index].selected = true;

        return options;
    }

    if (allOption) {
        if (idClickedOption === 0) {
            const newValue = !options[index].selected;
            
            options.forEach((option) => option.selected = newValue);

            return options;
        }

        options[0].selected = false;
    }

    options[index].selected = !options[index].selected;
        
    return options;

}

export default updateSelected;
