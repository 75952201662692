
function takenExam ({
    id = null,
    title = ""
} = {}) {
    this.id = id;
    this.title = title;
}

export default takenExam;
