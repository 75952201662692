
// services
import handleLocalStorage from "../../../../../../../services/localStorage/localStorage";

const getFeedbackRoleOfUser = () => {

    // fetch user data
    const userData = handleLocalStorage({
        action: 'fetch',
        key: 'user',
    });
    let role = userData?.roles[0];

    if (!role) {
        // show some error!
        return;
    }
    return role === "Student" ? role : "Teacher";
}

export default getFeedbackRoleOfUser;
