
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const valitateSelect = (type) => {

    switch (type) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;

        case "null":
            return NO_CONTENT;

        case NaN:
            return NO_CONTENT;
    
        default:
            break;
    }

    switch (true) {
        case (type === 0):
            return NO_CONTENT;
        
        case (type === 1):
            return SUCCESS;
        
        case (type === 2):
            return SUCCESS;

        // wrong characters
        // case !validate(firstName):
        //     return BAD_REQUEST;
        
        default:
            return BAD_REQUEST;
    }

}

export default valitateSelect;
