
import React, { useState } from 'react';

// CkEditor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

// classes
import errorClass from "../../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

// style
import "./CkText.scss";
import "../assets/style.scss";

const CkTextField = (props) => {

    // we're getting a warning because of this but it works
    const [typed, toggleTyped] = useState(false);

    const onChange = (e, newValue) => {

        const newText = newValue.getData();

        if (props.customUpdateFunc) {
            props.customUpdateFunc(
                props.valueKey,
                newText
            );
        } else if (props.dataObject) {
            props.dataObject[props.valueKey] = newText;
            toggleTyped(true);
        } else if (props.clientData) {
            props.clientData.updateProperty(props.valueKey, newText);
            toggleTyped(true);
        }

        // reset error
        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }

        // reset warning
        if (props.updateWarning) {
            props.updateWarning(new errorClass({
                errorCode: SUCCESS
            }));
        }

    }

    return (

        <div className={props.showError || props.showWarning ? "CkEditorError" : "CkEditor"} >
            <CKEditor 
                editor={ BalloonEditor } 
                config={ props.config ? props.config : {} }
                data={ props.value }
                onChange={onChange}
            />
        </div>
    )

}

export default CkTextField;
