
function tab({
    id,
    title,
    component
} = {}) {

    this.id = id;
    this.title = title;
    this.component = component;

}

export default tab;
