
// services
import convertError from "../../errors/convertError";

// constants
import { SUCCESS, NO_CONTENT, INTERNAL_ERROR } from "../../errors/constants";

const identicalTexts = ({

    listTexts,

    updateError,
    customErrors,

} = {}) => {

    // validate input
    if (!listTexts) {
        return convertError({
            errorCode: NO_CONTENT,
            updateLocally: updateError,
            customErrors: customErrors
        });
    }
    if (listTexts.length == 0) {
        return convertError({
            errorCode: NO_CONTENT,
            updateLocally: updateError,
            customErrors: customErrors
        });
    }

    // check if all texts are equal
    const allEqual = listTexts.every(str => str === listTexts[0]);

    let errorCode = SUCCESS;

    if (allEqual == false) {
        errorCode = INTERNAL_ERROR;
    }

    return convertError({
        errorCode: errorCode,
        updateLocally: updateError,
        customErrors: customErrors
    });

}

export default identicalTexts;
