
import React, { useState, useEffect } from 'react';

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../../../services/redux/actions/globalErrors.action";

// services
import convertError from "../../../../../../../../services/errors/convertError";

// services local
import putTakenExam from "../../../../../../services/networking/takenExams/putTakenExam";

// style
import "./checkAnswer.scss";

// components
import Loader from '../../../../../../../../components/Loader';

// constants
import { USER_CORRECT, USER_IN_CORRECT, USER_HALF_CORRECT } from "../../../../services/constants/userCorrectConstants"; 

const CheckAnswer = (props) => {

    const [loadingCorrect, toggleLoadingCorrect] = useState(false);
    const [loadingIncorrect, toggleLoadingIncorrect] = useState(false);

    const optionClicked = async(correct_clicked) => {

        let clicked_in_const = correct_clicked ? USER_CORRECT : USER_IN_CORRECT;
        // reverse behaviour if the already selected option is clicked again
        let correct = props.userCorrect === clicked_in_const ? !correct_clicked : correct_clicked;
        
        props.updateUserPoints({
            userCorrect: correct
        });
    }
    
    return (
        <div className="checkAnswer" >
            <div 
                className={`
                    checkAnswerOption baseCorrect
                    ${props.userCorrect === USER_HALF_CORRECT ? "answerCorrectBorder" : ""}
                    ${props.userCorrect === USER_CORRECT ? "checkAnswerUserCorrect" : ""}
                    ${props.userCorrect === USER_CORRECT ? "" : "checkAnswerCorrect"}

                `}

                onClick={() => optionClicked(true)}
            >{
                loadingCorrect ? <Loader /> : "Goed"
            }</div>
            <div 
                className={`
                    checkAnswerOption baseInCorrect
                    ${props.userCorrect === USER_HALF_CORRECT ? "answerIncorrectBorder" : ""}
                    ${props.userCorrect === USER_IN_CORRECT ? "checkAnswerUserIncorrect" : ""}
                    ${props.userCorrect === USER_IN_CORRECT ? "" : "checkAnswerIncorrect"}
                `}
                
                onClick={() => optionClicked(false)}
            >{
                loadingIncorrect ? <Loader /> : "Fout"
            }</div>
        </div>
    )
}

export default CheckAnswer;
