
// standard objects
import errorClass from "../../../../../services/errors/classes";

// constants
import { MAIN_OPERATION_SUCCESS, SUCCESS } from "../../../../../services/errors/constants";

const handInErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Opgeslagen",
                description: "Uw aanpassingen zijn opgeslagen",
                advice: null,
                moreInfo: null,
            });

        case MAIN_OPERATION_SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Opgeslagen",
                description: "Uw aanpassingen zijn opgeslagen",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default handInErrors;
