
const settingPointsAndCorrect = ({
    newCorrectValue, 
    newPoints, 
    totalPossiblePoints
}) => {
    // to store new values based on given values
    let userCorrect = newCorrectValue;
    let userPoints = newPoints;

    if (newPoints === null && newCorrectValue === undefined) {
        return { userCorrect, userPoints };
    }

    if (newPoints) {
        // new points are given, so update user correct based on given points
        userCorrect = totalPossiblePoints === newPoints ? true : false;
    } else {
        userPoints = newCorrectValue ? totalPossiblePoints : 0;
    }

    return { userCorrect, userPoints };

}

export default settingPointsAndCorrect;
