
// local constants
import { ARTICLE, VIDEO, PODCAST, BOOK, DIFFERENT } from "../../constants";

function linkClass({
    title,
    creator,
    type = ARTICLE,
    url
} = {}) {

    this.title = title;
    this.creator = creator;
    this.type = type;
    this.url = url;

}

export default linkClass;
