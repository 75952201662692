
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../../../services/redux/actions/globalErrors.action";

// networking
import takenExamsApi from "../../../../../../../../services/Networking/takenExams";

// local services
import takenExams from "./services/takenExams";

// services
import convertError from "../../../../../../../../services/errors/convertError";
import access from "../../../../../../../../services/access/accessCheck";

// style
import "./Details.scss";

// components
import List from "../../../../../../../../components/List";
import Loader from "../../../../../../../../components/Loader";
import TakenExam from "../TakenExam";

// constants
import { SUCCESS, NO_RESPONSE } from "../../../../../../../../services/errors/constants";
import { EDITOR } from "../../../../../../../../services/access/constants";
import { DRAFT } from '../../../../../../../../services/constants/stateConstants';

const Details = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [grades, updateGrades] = useState([]);
    const [viewGrades, toggeleViewGrades] = useState(false);
    const [loadingGrades, toggleLoadingGrades] = useState(false);
    useEffect(() => {

        toggeleViewGrades(false)
    
    }, [props.data]);

    const fetchTakenExams = async() => {
        if (!props.data) {
            return null;
        }
        if (!props.data.uuid) {
            return null;
        }
        const returned = await takenExamsApi.getTakenExamOrdersByExamId(props.data.uuid);
        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoadingGrades(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            let takenList = takenExams(returned.payload);

            updateGrades(takenList);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoadingGrades(false);
    }

    useEffect(() => {

        if (!user) {
            return null;   
        }
        if (!user.user) {
            return null;
        }
        if (access({
            accessLevel: EDITOR,
            userRoles: user.user.roles
        })) {
            return null;
        }

        if (viewGrades) {
            toggleLoadingGrades(true);

            fetchTakenExams();
        }
    
    }, [viewGrades]);

    const renderGrade = (i, takenExam) => {
        return (
            <TakenExam
                key={i}
                examId={props.data.uuid}
                data={takenExam}
            />
        );
    }

    return (
        <div className="sectExamDtailsView">
            <div className="sectExamInfoItem">
                Vragen: {props.data.questions ? props.data.questions.length : ""}
            </div>
            <div className="sectExamInfoItem">
                Haalbare punten: {props.data.points}
            </div>
            <div className="sectExamInfoItem">
                Sort vragen: {props.data.category ? "oefenvragen" : "eindexamen"}
            </div>
            {
                !user ? null : !user.user ? null : access({
                    accessLevel: EDITOR,
                    userRoles: user.user.roles
                }) ?
                <div className="sectExamInfoItem">
                    Status: {props.data.state === DRAFT ? "Draft" : "Gepubliceerd"}
                </div> : null
            }

            <div className="sectExamGrades">
                <div
                    className="sectExamGradesTitle"
                    onClick={() => toggeleViewGrades((value) => !value)}    
                >
                    Jouw eerdere pogingen {viewGrades ? " verstoppen" : " zien"}
                </div>
                {
                    !viewGrades ? null :
                    <>{
                        !user ? 
                        <div className="accessResponses" >
                            Hiervoor moet je ingelogd zijn. Met een account
                            kan je hier dan makkelijk je oude antwoorden inzien.
                        </div> :
                        !user.user ? 
                        <div className="accessResponses">
                            Hiervoor moet je ingelogd zijn. Met een account
                            kan je hier dan makkelijk je oude antwoorden inzien.
                        </div> :

                        access({
                            accessLevel: EDITOR,
                            userRoles: user.user.roles
                        }) ?
                        <div className="accessResponses">
                            Op dit moment bent u ingelogd als editor, dit betekent 
                            dat u geen toetsen kunt maken en dat u dus ook geen eerdere 
                            pogingen kunt hebben voor het maken van toetsen.
                        </div> :

                        loadingGrades ? <Loader /> :
                        <List
                            items={grades}
                            renderItem={renderGrade}
                        />
                    }</>
                }
            </div>
            
        </div>
    )
}

export default Details;
