
// services
import getFeedbackRoleOfUser from "./getFeedbackRoleOfUser";

const feedbackByUser = (feedbackList) => {
    const userRole = getFeedbackRoleOfUser();

    const filteredFeedbackList = feedbackList.filter((feedback) => feedback.getCurrentValue("model")  === userRole);

    if (filteredFeedbackList.length === 0) {
        return null;
    }
    return filteredFeedbackList[0]
}

export default feedbackByUser;
