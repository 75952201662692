
// components
import Filter from "../../../../../components/Filters/Filter"

export const renderFilter = (i, data) => {
    return (
        <div
            key={i}
            className="filterFlashcards"
        >
            <Filter
                filterData={data}
            />
        </div>
    )
}
