
// Define the role hierarchy
export const ROLE_HIERARCHY = {
    "GPT-4.0": 1,
    "Student": 3,
    "Teacher": 2
};

export const MODEL_KEY = "model";
export const FEEDBACK_KEY = "feedback";
export const POINTS_KEY = "points";
