
// styles
import "./AssignedExamFilter.scss";

// components
import Filter from "../../../../../../../../../../components/Filters/Filter";

const renderFilter = (i, data) => {
    return (
        <div
            key={i}
            className="AssignedExamFilterView"
        >
            <Filter
                filterData={data}
            />
        </div>
    )
}

export default renderFilter;
