
import { ResponsiveContainer, Label, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// styling
import "./QuestionStatistics.scss";

const QuestionStatistics = (props) => {
    return (
        <div className="QuestionsStatisticsView" >
            <div
                className="QuestionsStatisticsTitle"
            >
                Vraag: {props.questionNumber}
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={props.data}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="points">
                        <Label value="Punten" position="insideBottom" offset={-10} />
                    </XAxis>
                    <YAxis>
                        <Label value="Aantal studenten" position="insideLeft" angle={-90} dy={50} />
                    </YAxis>
                    <Tooltip />
                    <Legend verticalAlign="top" align="left" wrapperStyle={{ paddingBottom: 10 }} />
                    <Bar dataKey="count" name="Hoeveelheid studenten met aangegeven punten" fill="#008000" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default QuestionStatistics;
