
import { SUCCESS, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const validateYear = (year) => {

    const yearInt = parseInt(year);

    switch (true) {
        case (yearInt < 4):
            return BAD_REQUEST;

        default:
            return SUCCESS;
    }
}

export default validateYear;
