
// style
import "./AlertTitle.scss";

const Title = (props) => {
    return (
        <div className="AlterTitle" >{
            props.title ? props.title : "Waarschuwing"
        }</div>
    )
}

export default Title;
