
import React, { useState } from 'react';

import parse from "html-react-parser";

// redux
import { useDispatch, useSelector } from 'react-redux';

// local components
import AddNewText from "../AddNewText";

// components
import AlterContent from "../../../../../../components/AlterContent";
import Loader from '../../../../../../components/Loader';

// services
import access from "../../../../../../services/access/accessCheck";

// api
import textExplenationApi from "../../../../../../services/Networking/textExplenation";

// style
import "./Text.scss";

// constants
import { EDITOR } from "../../../../../../services/access/constants";

const Text = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [textEditable, toggleTextEditable] = useState(false);
    const [loading, toggleLoading] = useState(false);

    const editText = () => {
        toggleTextEditable((value) => !value);
    }
    
    return (

        <>{
            loading ? <Loader /> :
            textEditable ? <AddNewText
            textId={props.textClass.textId}
            editText={true}
            initialNewText={props.textClass}

            deleteNewText={editText}

            triggerReRender={props.triggerReRender}
            toggleTextEditable={toggleTextEditable}

            toggleLoading={toggleLoading}
        /> 
        :
        <div className={"textExplenationHolder"} >
            {
                !user ? null : !user.user ? null : access({
                    accessLevel: EDITOR,
                    userRoles: user.user.roles
                }) ?
                <div className="AlterContentEdit" >
                    <AlterContent
                        edit={{
                            onClick:toggleTextEditable,
                        }}
                        delete={textEditable ? null : {
                            api: textExplenationApi.deleteTextEpleByTextExplId,
                            apiParams: props.textClass.textId,
                        }}

                        triggerReRender={props.triggerReRender}
                    />
                </div>
                : null
            }
            {
                parse(props.textClass.textExplanation)
            }
        </div>

        }</>
    )
}

export default Text;
