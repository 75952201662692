
// react
import React, { useState, useEffect } from 'react';

// style
import "./Feedback.scss";

// componenets
import LocalModal from "../LocalModal";

// components local
import FeedbackField from "./FeedbackField";

const Feedback = (props) => {

    const [folded, toggleFolded] = useState(true);

    return (
        <>{
            folded ?

            <div 
                className="feedbackButton"
                onClick={() => toggleFolded(value => !value)}
            >
                Feedback geven
            </div>

            :

            <LocalModal
            
                show={!folded}
                toggleShow={toggleFolded}
                component={
                    <FeedbackField
                        toggleFolded={toggleFolded}
                    />
                }
            
            />
        }</>
    )

}

export default Feedback;
