
import React, { useState, useEffect } from 'react';

// routing
import { useParams } from 'react-router-dom';

// font awsome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// components
import List from "../../../../../../../../components/List";
import AlterContent from "../../../../../../../../components/AlterContent";

// networking
import practiceCardApi from "../../../../../../../../services/Networking/practiceCards";

// networking classes
import starring from "../../../../../../../../services/Networking/practiceCards/classes/starring";

// style
import "./CardBanner.scss";

const CardBanner = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    const renderResponse = (i, reponseData) => {
        
        return (
            <div
                key={i}
            >
                {
                    reponseData ?
                    <FontAwesomeIcon 
                        icon={faCheckCircle} 
                        className="correctResponse" 
                    /> 
                    :
                    <FontAwesomeIcon 
                        icon={faTimesCircle} 
                        className="wrongResponse"
                    />
                }
            </div>
        )
    }

    return (
        <div className={props.results && !props.fullHistory ? "cardBannerView bannerLeft" : "cardBannerView"}>
            {
                props.results && !props.fullHistory ? null :
                <div className="cardResponses">{

                    <List
                        items={props.data.userResponses}
                        renderItem={renderResponse}
                    />

                }</div>
            }
            <AlterContent
                star={{
                    api: props.data.isFavourite ? practiceCardApi.unStarPracticeCards : practiceCardApi.starPracticeCards,
                    starApi: practiceCardApi.starPracticeCards,
                    unStarApi: practiceCardApi.unStarPracticeCards,

                    apiParams: new starring({
                        subTopicId: subTopicId,
                        cardId: props.data.practiceCardId
                    }),

                    starred: props.data.isFavourite

                }}
                edit={props.data.type === 4 ? null : {
                    onClick:props.toggleEditCard
                }}
                delete={{
                    api: practiceCardApi.deletePracticeCardsBySubTopicId,
                    apiParams: props.data.practiceCardId,
                }}

                triggerReRender={props.triggerReRender}
                isPersonal={props.data.isPersonal}
            />

        </div>
    )
}

export default CardBanner;

