
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// style
import "./AccessWarning.scss";

// services
import access from "../../../../../../services/access/accessCheck";

// constants
import {STUDENT, TEACHER} from "../../../../../../services/access/constants"

const AccessWarning = (props) => {
    // redux
    const user = useSelector(state => state.userReducer);

    const [moreInfo, toggleMoreInfo] = useState(false);

    const checkLoggedIn = () => {
        if (!user) {
            return false;
        }
        if (!user.user) {
            return false;
        }

        return true;
    }

    const checkUserAccess = () => {
        if (!user) {
            return false;
        }
        if (!user.user) {
            return false;
        }
        if (access({
            accessLevel: [STUDENT, TEACHER],
            userRoles: user.user.roles
        })) {
            return true;
        }

        return false;
    }

    return (
        <>{
            checkLoggedIn() ? 
            
            !checkUserAccess() ? null :
            props.existingData ? props.existingData.isPersonal ? null :
            <div className="loginWarning" >
                <p className="loginWarningTitle" >
                De originele versie kan je niet aanpassen, dus jouw aanpassingen worden toegevoegd
                inplaats van aangepast. 
                <div 
                    className="loginWarningMoreInfo" 
                    onClick={() => toggleMoreInfo((value) => !value)}
                >{moreInfo ? " Minder info" : " Meer info"}</div>
                </p>
                {
                    moreInfo ? <p>
                    Omdat jij deze informatie niet zelf hebt gecreëerd, kan je deze niet
                    aanpassen. Wanneer je op opslaan klikt, zal er een kopie worden gemaakt,
                    en zal de aangepaste versie toegevoegd worden aan jouw account.
                    </p> : null
                }
            </div> : null
            :
        
            <div className="loginWarning" >
                <p className="loginWarningTitle" >
                Om voor jezelf content toe te voegen of aan te passen op dit platform, 
                heb je een account nodig. Je kan gratis een account aanmaken door op 
                'GRATIS REGISTREREN' te klikken. Dit vind je rechts bovenin de pagina.
                </p>
                <p>
                Met een account kan je zelf definities en flashcards toevoegen en kan 
                je bijhouden hoe goed je deze, de oefentoetsen en oud eindexamens op
                deze website kent.
                </p>
            </div>
        }</>
    )
}

export default AccessWarning;
