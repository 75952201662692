
import { BAD_REQUEST, NO_CONTENT, SUCCESS } from "../../../../services/errors/constants";

export const bothDateAndTimeFilled = (date) => {
    if (date === null) {
        return NO_CONTENT;
    }
    if (!(date instanceof Date) || isNaN(date?.getTime())) {
        return BAD_REQUEST;
    }

    const pad = (num) => (num < 10 ? '0' + num : num);

    const yyyy = date?.getFullYear();
    const MM = pad(date?.getMonth() + 1);
    const dd = pad(date?.getDate());
    const HH = pad(date?.getHours());
    const mm = pad(date?.getMinutes());
    const ss = pad(date?.getSeconds());

    if (
        yyyy > 0 &&
        MM > 0 && MM <= 12 &&
        dd > 0 && dd <= 31 &&
        HH >= 0 && HH <= 23 &&
        mm >= 0 && mm <= 59 &&
        ss >= 0 && ss <= 59
    ) {
        return SUCCESS;
    }
    return NO_CONTENT;
};
