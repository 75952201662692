
// constants
import { ADD_ERROR, REMOVE_ERROR, EMPTY_ERROR_LIST } from "../constants";

const initialState = {
        arr: []
    };


const globalErrors = (state = initialState, action) => {

    switch (action.type) {
        case ADD_ERROR:
            return {
                ...state,
                arr: [action.payload]
            };
            // return {
            //     ...state,
            //     arr: [...state.arr, action.payload]
            // };

        case REMOVE_ERROR:
            return state;

        case EMPTY_ERROR_LIST:
            
            return {
                arr: []
            };
    
        default:
           
            return state;
    }

}

export default globalErrors;
