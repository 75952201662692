import { useState } from "react";

// styles
import "./Title.scss";

// classes
import errorClass from "../../../../services/errors/classes/errorClass";

// components
import TextInput from "../../../../components/input/Text/Text";
import Loader from "../../../../components/Loader";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

const Title = (props) => {

    const updateTitle = (valueKey, newText) => {
        props.assignment?.updateProperty(valueKey, newText);
    }

    return (
        <div
            className="assignmentTitle"
        >
            {
                props.editingAssignment ? 
                <TextInput
                    valueKey={props.valueKey || "title"}
                    clientData={props.assignment}

                    customUpdateFunc={updateTitle}

                    useBaseStyle={true}
                    
                    errorClass={props.titleError}
                    updateError={props.updateTitleError}
                />
                :
                props.assignment?.getCurrentValue(props.valueKey || "title")
            }
            {
                props.loading ? <Loader /> : null
            }
        </div>
    )
}

export default Title;
