
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

// style
import "./Edit.scss";

const Edit = (props) => {
    return (
        <FontAwesomeIcon 
            onClick={props.onClick}
            className="Edit"
            icon={faEdit}
        />
    )
}

export default Edit;
