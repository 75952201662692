
import { useState } from "react";

// styles
import "./SelectNumber.scss";

// components
import NumberEditable from "./components/NumberEditable";

const SelectNumber = (props) => {

    const [showOptions, toggleShowOptions] = useState(false);
    const updateNumber = (points) => {
        props.updateNumber(points);
        toggleShowOptions(false);
    }

    return (
        <div
            className="selectNumberView"
        >
            <div
                className={props.updateNumber ? "selectNumberTitleHover" : "selectNumberTitle" }
                onClick={ props.updateNumber ? () => toggleShowOptions((value) => !value) : null }
            >{
                props.updateNumber && showOptions ? "Opties sluiten" : props.number === null ? "Punten: selecteren" : `Punten: ${props.number} / ${props.heighestNumber}`
            }</div>

            {
                !showOptions ? "" :
                <NumberEditable
                    heighestNumber={props.heighestNumber}
                    updateNumber={updateNumber}
                />
            }
        </div>
    )
}

export default SelectNumber;
