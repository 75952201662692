
import { useState } from "react";

// routing
import { useHistory, useLocation } from 'react-router-dom';

// styles
import "./AddNewAssignedExam.scss";

// classes
import errorClass from "../../../../../../../../../../services/errors/classes/errorClass";

// services
import validateTextInput from "../../../../../../../../../../services/validate/textInput/textInput";
import defaultTextInputErrors from "../../../../../../../../../../services/errors/defaultTextInputErrors";
import convertError from "../../../../../../../../../../services/errors/convertError";
import networking from "../../../../../../../../../../services/handleNetworking/networking";
import assignedExamApi from "../../../../../../../../../../services/Networking/assignedExam/assignedExam";

// components
import TextInput from "../../../../../../../../../../components/input/Text/Text";
import Redux from "../../../../../../../../../../components/Redux/Redux";
import Submit from "../../../../../../../../../../components/input/elements/Submit";
import Loader from "../../../../../../../../../../components/Loader";
// elements
import AddAssignmentItemTypeTitle from "../../elements/AddAssignmentItemTypeTitle/AddAssignmentItemTypeTitle";

// constants
import { SUCCESS } from "../../../../../../../../../../services/errors/constants";
import { REDUX_ERRORS } from "../../../../../../../../../../components/Redux/services/constants";

const AddNewAssignedExam = (props) => {

    // routing
    let history = useHistory();
    let location = useLocation();

    // data
    const [exam, updateExam] = useState({title: ""});

    // loading
    const [loading, toggleLoading] = useState(false);

    // errors
    const [sendError, updateSendError] = useState(null);
    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const validateFields = () => {

        // validate
        const valid = validateTextInput(exam["title"]);

        // add title error
        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateTitleError,
            customErrors: defaultTextInputErrors
        });

        if (!errorTemp || errorTemp.errorCode !== SUCCESS) {
            return false;
        }

        return true;
    }

    const save = async() => {

        const inputValid = validateFields();

        if (inputValid) {
            const response = await networking({
                toggleLoading: toggleLoading,
                errorOnSuccess: false,
    
                api: assignedExamApi.postNewExamByAssignmentUuid,
                apiParams: { assignmentUuiD: props.assignmentUuiD, data: exam },
    
                updateError: updateSendError,
            });

            if (response && response.status === SUCCESS) {
                // route to exam
                const examId = response?.payload;
                history.push(`${location.pathname}/tentamen/${examId}/Aanpassen`);
            }
        }
    }

    return (
        <>
            <AddAssignmentItemTypeTitle title={"Toets toevoegen"} />

            <TextInput
                title="Title"

                valueKey="title"
                dataClass={exam}

                errorClass={titleError}
                updateError={updateTitleError}

            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

            <Redux
                showSuccess={true}
                varId={REDUX_ERRORS}
                
                reduxVar={sendError}
            />
        </>
    )
}

export default AddNewAssignedExam;
