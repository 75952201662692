

import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from '../../services/redux/actions/globalErrors.action';

// networking
import subjectApi from '../../services/Networking/subject/subject';
import booksApi from '../../services/Networking/books/books';

// style
import "./AddBook.scss";

// components
import TextInput from '../input/Text/Text';
import Submit from '../input/elements/Submit';
import ImageInput from '../input/Image/Image';
import Loader from '../Loader';

// elements
import Title from '../../elements/ModalTitle/Title';

// classes
import NewBook from './services/classes/newBook';
import errorClass from '../../services/errors/classes/errorClass';

// local services
import defaultTextInputErrors from '../../services/errors/defaultTextInputErrors';
import validateTextInput from '../../services/validate/textInput/textInput';

// services
import convertError from '../../services/errors/convertError';

// constants
import { INTERNAL_ERROR, SUCCESS } from '../../services/errors/constants';

const AddBook = (props) => {

    // redux
    const dispatch = useDispatch();

    const [newBook, updateNewBook] = useState(props.existingData ? props.existingData : new NewBook());

    // input
    const [subjectNameError, updateSubjectNameError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [bookTitleError, updateBookTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [bookAuthorError, updateBookAuthorError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const save = async() => {

        toggleLoading(true);

        let validInput = true;

        // validate title
        const titleValid = validateTextInput(newBook.title);

        const titleErrorTemp = convertError({
            errorCode: titleValid,
            updateLocally: updateBookTitleError,
            customErrors: defaultTextInputErrors
        });
        if (!titleErrorTemp) {
            validInput = false;
        } else {
            if (titleErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(titleErrorTemp));
                validInput = false;
            }
        }

        // validate author
        const authorValid = validateTextInput(newBook.title);

        const authorErrorTemp = convertError({
            errorCode: authorValid,
            updateLocally: updateBookAuthorError,
            customErrors: defaultTextInputErrors
        });
        if (!authorErrorTemp) {
            validInput = false;
        } else {
            if (authorErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(authorErrorTemp));
                validInput = false;
            }
        }

        if (validInput) {
            let newBookResponse;

            if (newBook.bookId) {
                // responseTopic = await topicApi.putTopicByTopicId(topicInfo.topicId, topicInfo);
            } else {
                newBookResponse = await booksApi.postBook(props.subjectId, newBook);
            }

            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!newBookResponse) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (newBookResponse.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: newBookResponse.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
                
                if (props.runOnSuccess) {
                    props.runOnSuccess();
                }
                props.toggleShow((value) => !value);
                if (props.triggerReRender) {
                    props.triggerReRender((value) => !value);
                }

            } else {
                // also show error locally

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: newBookResponse.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
                
            }
        }

        

        // // send data
        // if (validInput) {

            // let responseTopic;

            // if (topicInfo.topicId) {
            //     responseTopic = await topicApi.putTopicByTopicId(topicInfo.topicId, topicInfo);
            // } else {
            //     responseTopic = await topicApi.createTopic(props.bookId || 1, topicInfo);
            // }
            
            // // clear errors
            // dispatch(globalErrorsActions.emptyErrorList());

            // // nothing returned - something weard happend
            // if (!responseTopic) {

            //     dispatch(globalErrorsActions.addError(
            //         convertError({
            //             errorCode: INTERNAL_ERROR,
            //             updateLocally: null,
            //             customErrors: null
            //         })
            //     ));
            // }

            // // success returned, update with 200
            // if (responseTopic.status === SUCCESS) {

            //     // display success
            //     dispatch(globalErrorsActions.addError(
            //         convertError({
            //             errorCode: responseTopic.status,
            //             updateLocally: null,
            //             customErrors: addTopicErrors
            //         }))
            //     );
                
            //     if (props.runOnSuccess) {
            //         props.runOnSuccess();
            //     }
            //     props.toggleShow((value) => !value);
            //     if (props.triggerReRender) {
            //         props.triggerReRender((value) => !value);
            //     }

            // } else {
            //     // also show error locally

            //     // show auth errors publicly
            //     dispatch(globalErrorsActions.addError(
            //             convertError({
            //             errorCode: responseTopic.status,
            //             updateLocally: null,
            //             customErrors: addTopicErrors
            //         }))
            //     );
                
            // }
        // }

        toggleLoading(false);
    }

    return (
        <div className="AddSubjectView">
            <Title
                title={props.existingData ? "BOEK AANPASSEN" : "BOEK TOEVOEGEN"}
            />

            <TextInput
                title="BOEK TITEL"
                
                valueKey="title"
                dataClass={newBook}

                errorClass={bookTitleError}
                updateError={updateBookTitleError}
            />

            <TextInput
                title="BOEK AUTHOR"
                
                valueKey="author"
                dataClass={newBook}

                errorClass={bookAuthorError}
                updateError={updateBookAuthorError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }
        </div>
    )
}

export default AddBook;

