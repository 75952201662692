
const renderComponent = ({selectedTab, tabs} = {}) => {

    if (!tabs) {
        return null;
    }
    if (tabs.length === 0) {
        return null;
    }
    if (!selectedTab) {
        return null;
    }

    if (tabs.length === 1) {
        return tabs[0].component;
    }

    const tabObj = tabs.filter((tab) => tab.id === selectedTab);
    if (!tabObj) {
        return null;
    }
    if (tabObj.length === 0) {
        return null;
    }

    return tabObj[0].component;
}

export default renderComponent;
