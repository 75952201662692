
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// routing
import { useParams, useHistory, useRouteMatch, useLocation } from 'react-router-dom';

// style
import "./Exam.scss";

// classes local
import StickyButtonClass from "./components/StickyButtons/StickyButtonClass";

// services
import access from "../../services/access/accessCheck";

// components local
import Nav from "./components/Nav";
import ExamView from "./components/ExamView";
import StickyButtons from "./components/StickyButtons";

// constants
import { VIEW } from "./services/tabConstants";
import { EDITOR } from "../../services/access/constants";
import { VIEW_ACCESS_TABS, USE_ACCESS_TABS, EDITOR_ACCESS_TABS } from "./services/constants/tabListConstants";
import { VIEW_ACCESS, USE_ACCESS, EDITOR_ACCESS } from "../../services/access/contentAccess/contentAccessConstants";

const Exam = (props) => {

    // routing
    let history = useHistory();
    let { url } = useRouteMatch();

    // id's
    let { tijdvakID, kaID, examID } = useParams();
    const topicId = parseInt(tijdvakID?.replace("t-", ""));
    const subTopicId = parseInt(kaID?.replace("st-", ""));
    const examId = examID?.replace("e-", "");
    const [childId, updateChildId] = useState(null);
    
    let location = useLocation();

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // access - implemented to return Editors access for editors accounts and anyone else has view access
    // still implement: for the content itself later & the use_access access type!
    const [userAccess, updateUserAccess] = useState(!user ? VIEW_ACCESS : !user.user ? VIEW_ACCESS : access({
        accessLevel: EDITOR,
        userRoles: user.user.roles
    }) ? EDITOR_ACCESS : VIEW_ACCESS);
    useEffect(() => {
        // update page access if user changes
        updateUserAccess(!user ? VIEW_ACCESS : !user.user ? VIEW_ACCESS : access({
            accessLevel: EDITOR,
            userRoles: user.user.roles
        }) ? EDITOR_ACCESS : VIEW_ACCESS);
    
    }, [user]);

    const [examChat, updateExamChat] = useState(null);

    // inside every question
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(true);

    // exam comp var
    const [examView, updateExamView] = useState(location.hash ? parseInt(location.hash.replace("#", "")) : VIEW);
    const updateExamViewFunc = (newExamView) => {

        // update URL
        location.hash = `#${newExamView}`;
        history.push({
            hash: `#${newExamView}`
        })
        updateExamView(newExamView);
    }
    const [examTabs, updateExamTabs] = useState(VIEW_ACCESS_TABS);
    useEffect(() => {

        tabsFromContentAccess();

    }, [userAccess, user]);
    const tabsFromContentAccess = () => {
        
        if (userAccess === VIEW_ACCESS) {
            updateExamTabs(VIEW_ACCESS_TABS);
        } else if (userAccess === USE_ACCESS) {
            updateExamTabs(USE_ACCESS_TABS);
        } else if (userAccess === EDITOR_ACCESS) {
            updateExamTabs(EDITOR_ACCESS_TABS);
        }
    }

    const [stickyButtons, updateStickyButtons] = useState([]);
    useEffect(() => {

        creatingStickyButtonList();

    }, [childId, userAccess]);
    const creatingStickyButtonList = () => {
        updateStickyButtons(() => {
            let newList = [];
            if (userAccess === VIEW_ACCESS) {
                newList = [
                    ...newList,
                    new StickyButtonClass({
                        title: "Toets maken",
                        onClickFunc: null,
                        link: `${url}/Examen-Maken`,
                    })
                ];
            }

            if (userAccess === EDITOR_ACCESS) {
                newList = [
                    ...newList,
                    new StickyButtonClass({
                        title: "Toets aanpassen",
                        onClickFunc: null,
                        link: `${url}/Examen-Aanpassen`,
                    })
                ];
            }

            return newList;
        })
        
    }

    return (
        <div className="ExamRouting">
            <Nav
                initalTab={location.hash ? parseInt(location.hash.replace("#", "")) : VIEW}
                
                examId={examId}
                examChat={examChat}

                examTabs={examTabs}
                updateExamView={updateExamViewFunc}
            />

            <StickyButtons
                buttons={stickyButtons}
            />

            <ExamView
                examView={examView}
                userAccess={userAccess}

                examId={examId}
                updateChildId={updateChildId}
                updateExamChat={updateExamChat}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />

        </div>
    )
}

export default Exam;
