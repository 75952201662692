

// standard objects
import errorClass from "../../../../../../../../../../../services/errors/classes/errorClass";

// constants
import { NO_CONTENT } from "../../../../../../../../../../../services/errors/constants";

const titleErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "",
                description: "Dit veld is nog leeg. Wil je dit niet invullen?",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default titleErrors;
