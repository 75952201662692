
import { useState, useEffect } from "react";

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// constants
import { VIEW, MAKE, CHECK, ASSIGNMENT} from "../../services/tabConstants";

// networking local
import fetchExams from "../../services/networking/exams/fetchExams";

// services
import convertError from "../../../../services/errors/convertError";

// components
import View from "../../Views/View";
import TakenExams from "../../Views/TakenExams";

// constants
import { NO_RESPONSE, SUCCESS } from "../../../../services/errors/constants";
import { PUBLISHED } from "../../../../services/constants/stateConstants";

const ExamView = (props) => {

    // redux
    const dispatch = useDispatch();

    const [loading, toggleLoading] = useState(true);
    const [examClass, updateExamClass] = useState(null);

    // questionHubs
    const [questionHubs, updateQuestionHubs] = useState([]);

    useEffect(() => {

        gettingExamInfo();
    
    }, []);

    const gettingExamInfo = async() => {
        toggleLoading(true);
        const returnedExamInfo = await fetchExams({
            examId: props.examId,
            state: PUBLISHED,
            
            handleApiReturn: handleFetchApiReturn, 
            updateExamClass: updateExamClass, 
            updateQuestionHubs: updateQuestionHubs,
        });

        if (returnedExamInfo && returnedExamInfo.childId) {
            props.updateChildId(returnedExamInfo.childId);
        }
        toggleLoading(false);
    }

    const handleFetchApiReturn = async(returned, handingIn) => {
        
        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return false;
        }

        if (returned.status === SUCCESS) {
            
            toggleLoading(false);
            return true;

        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);
        return false;

    }

    const renderView = () => {

        if (props.examView === VIEW) {
            return (
                <View
                    examId={props.examId}
                    updateChildId={props.updateChildId}

                    userAccess={props.userAccess}

                    examView={props.examView}

                    loading={loading}

                    examClass={examClass}
                    updateExamClass={updateExamClass}

                    questionHubs={questionHubs}
        
                    showQuestionInfo={props.showQuestionInfo}
                    toggleShowQuestionInfo={props.toggleShowQuestionInfo}
                />
            )
        } else if (props.examView === CHECK) {
            return (
                <TakenExams
                    examId={props.examId}
                    updateExamChat={props.updateExamChat}

                    examView={props.examView}
                    
                    examClass={examClass}
        
                    showQuestionInfo={props.showQuestionInfo}
                    toggleShowQuestionInfo={props.toggleShowQuestionInfo}
                />
            )
        } else {
            return (
                <>hello!</>
            )
        }

    }

    return (
        <>{renderView()}</>
    )

}

export default ExamView;
