
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// router
import { useLocation, useHistory } from 'react-router-dom';

// services
import access from "../../../../services/access/accessCheck";
import deleteSubTopicErrors from "./services/errors";
import convertError from "../../../../services/errors/convertError";

// networking
import subTopicApi from "../../../../services/Networking/subTopic";

// components
import AlterContent from "../../../../components/AlterContent";
import LocalModal from "../../../../components/LocalModal";

// local components
import AddSubTopic from "../AddSubTopic";

// style
import "./Card.scss";

// constants
import { ADMIN } from "../../../../services/access/constants";
import { SUCCESS, INTERNAL_ERROR } from "../../../../services/errors/constants";

const Card = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    let location = useLocation();

    // load
    const [loadingDelete, toggleLoadingDelete] = useState(false);

    const [editSubTopic, toggleEditSubTopic] = useState(false);

    const route = (e) => {
        history.push(`${location.pathname}/st-${props.data.subtopicId}`);
    }

    return (
        <>
        <div
            onClick={() => route()}
            className="CardStyling"
        >
            <img 
                className="imgCard"
                src={props.data.backgroungImage} 
                alt="Italian Trulli" 
            />
            
            <div className="cardText" >
                <div className="CardTitle">
                    {props.data.title}
                </div>
                {
                    props.data.subtitle ?
                    <div className="CardDescipt">
                        {props.data.subtitle}
                    </div>
                    : null
                }
                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: ADMIN,
                        userRoles: user.user.roles
                    }) ? 
                    <AlterContent
                        edit={{
                            onClick:toggleEditSubTopic
                        }}
                        delete={{
                            api: subTopicApi.deleteSubTopicBySubTopicID,
                            apiParams: props.data.subtopicId,
                            accessLevel: ADMIN
                        }}

                        triggerReRender={props.triggerReRender}
                    /> : null
                }
            </div>
        </div>
        <LocalModal
            show={editSubTopic}
            toggleShow={toggleEditSubTopic}
            component={
                <AddSubTopic
                    toggleShow={toggleEditSubTopic}
                    existingData={props.data}

                    triggerReRender={props.triggerReRender}
                />
            }
        />
        </>
    )

}

export default Card;
