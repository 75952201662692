
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const takenExamsApi = {
    addTakenExamByExamId,
    takenExamValidated,
    getTakenExamOrdersByExamId,
    getTakenExamByExamIdAndOrder,
    getTakenExamByExamIdAndUserIdAndOrder,
    postCheckedQuestionByExamId,
    postCheckedQuestionByExamIdAndUserIdAndOrder,
}

async function addTakenExamByExamId({examId, userAnswers, state}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/exams/${examId}/takenExams?state=${state}`,
            userAnswers,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function takenExamValidated(takenExamId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/takenExams/${takenExamId}/validate`,
            {
                takenExamUUID: takenExamId
            },
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function getTakenExamOrdersByExamId(examId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/exams/${examId}/takenExams/orders`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getTakenExamByExamIdAndOrder(examId, order) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/exams/${examId}/takenExams/${order}`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getTakenExamByExamIdAndUserIdAndOrder({examUuid, userId, order}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/exams/${examUuid}/user/${userId}/takenExams/${order}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function postCheckedQuestionByExamId({
    examId, 
    order, 
    checkedQuestions
}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/exams/${examId}/evaluate?order=${order}`,
            checkedQuestions,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function postCheckedQuestionByExamIdAndUserIdAndOrder({
    examUuid, 
    userId,
    order,
    formattedFeedbackAndPoints
}) {
    const auth = await authHeader();
    const responseObj = await handleResponse(
        axios.post(
            `/exams/${examUuid}/evaluate/user/${userId}?order=${order}`,
            formattedFeedbackAndPoints,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

export default takenExamsApi;
