
import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../services/redux/actions/globalErrors.action";

// networking
import topicApi from "../../../services/Networking/topic";

// style
import "./AddTopic.scss"

// components
import TextInput from '../../../components/input/Text';
import Submit from "../../../components/input/elements/Submit";
import SelectInput from "../../../components/input/Select";
import ImageInput from "../../../components/input/Image";
import Loader from "../../../components/Loader";

// elements
import Title from "../../../elements/ModalTitle";

// classes
import addTopicClass from "./services/classes";
import errorClass from "../../../services/errors/classes";
import option from "../../../components/input/elements/Select/classes/option";

// constants
import { SUCCESS, INTERNAL_ERROR, UNAUTHORIZED } from "../../../services/errors/constants";

// local services
import addTopicErrors from "./services/errors/addTopic";
import defaultTextInputErrors from "../../../services/errors/defaultTextInputErrors";
import valitateSelect from "./services/validate/select";
import validateTextInput from "../../../services/validate/textInput";
import defaultImageErrors from "../../../services/errors/defaultImageErrors";
import valitateImage from "../../../services/validate/image";

// services
import convertError from "../../../services/errors/convertError";

const AddTopic = (props) => {

    // redux
    const dispatch = useDispatch();

    // const formatExistingData = () => {

    //     const existingTopic = props.existingData;
    //     existingTopic.type = `${existingTopic.type}`;
    //     return existingTopic;
    // }

    const [topicInfo, updateTopicInfo] = useState(props.existingData ? props.existingData : new addTopicClass());

    // input
    const [topicTitleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [subTitleError, updateSubTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [timeScaleError, updateTimeScaleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [extraInfoError, updateExtraInfoError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [topicTypeError, updateTopicTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [imageError, updateImageError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const save = async() => {

        toggleLoading(true);

        let validInput = true;

        // validate title
        const titleValid = validateTextInput(topicInfo.title);

        const titleErrorTemp = convertError({
            errorCode: titleValid,
            updateLocally: updateTitleError,
            customErrors: defaultTextInputErrors
        });

        if (!titleErrorTemp) {
            validInput = false;
        } else {
            if (titleErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(titleErrorTemp));
                validInput = false;
            }
        }

        // validate topic type
        const topicTypeValid = valitateSelect(topicInfo.type);

        const topicTypeErrorTemp = convertError({
            errorCode: topicTypeValid,
            updateLocally: updateTopicTypeError,
            customErrors: defaultTextInputErrors
        });

        if (!topicTypeErrorTemp) {
            validInput = false;
        } else {
            if (topicTypeErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(topicTypeErrorTemp));
                validInput = false;
            }
        }

        // validate backgroundImage
        const imageValid = valitateImage(topicInfo.backgroundImage);

        const imageErrorTemp = convertError({
            errorCode: imageValid,
            updateLocally: updateImageError,
            customErrors: defaultImageErrors
        });

        if (!imageErrorTemp) {
            validInput = false;
        } else {
            if (imageErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(imageErrorTemp));
                validInput = false;
            }
        }

        // create url for now
        topicInfo.url = `${topicInfo.title}${topicInfo.subtitle}`;

        // send data
        if (validInput) {

            let responseTopic;

            if (topicInfo.topicId) {
                responseTopic = await topicApi.putTopicByTopicId(topicInfo.topicId, topicInfo);
            } else {
                responseTopic = await topicApi.createTopic(props.bookId || 1, topicInfo);
            }
            
            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!responseTopic) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (responseTopic.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: responseTopic.status,
                        updateLocally: null,
                        customErrors: addTopicErrors
                    }))
                );
                
                if (props.runOnSuccess) {
                    props.runOnSuccess();
                }
                props.toggleShow((value) => !value);
                if (props.triggerReRender) {
                    props.triggerReRender((value) => !value);
                }

            } else {
                // also show error locally

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: responseTopic.status,
                        updateLocally: null,
                        customErrors: addTopicErrors
                    }))
                );
                
            }
        }

        toggleLoading(false);

    }

    return (
        <div className="AddTopic">
            <Title
                title={props.existingData ? "HOOFDSTUK AANPASSEN" : "HOOFDSTUK TOEVOEGEN"}
            />

            <TextInput
                title="TITEL"
                
                valueKey="title"
                dataClass={topicInfo}

                errorClass={topicTitleError}
                updateError={updateTitleError}
            />

            <TextInput
                title="SUB-TITEL"
                
                valueKey="subtitle"
                dataClass={topicInfo}

                errorClass={subTitleError}
                updateError={updateSubTitleError}
            />

            <TextInput
                title="TIJDSCHAAL"
                
                valueKey="timeScale"
                dataClass={topicInfo}

                errorClass={timeScaleError}
                updateError={updateTimeScaleError}
            />

            <TextInput
                title="EXTRA INFO"
                
                valueKey="extraInfo"
                dataClass={topicInfo}

                errorClass={extraInfoError}
                updateError={updateExtraInfoError}
            />

            <SelectInput
                title="HOOFDSTUK type"

                options={[
                    new option({
                        value: 0,
                        title: "."
                    }),
                    new option({
                        value: 1,
                        title: "Informerend"
                    }),
                    new option({
                        value: 2,
                        title: "Voorbereidend"
                    }),
                ]}

                valueKey="type"
                dataClass={topicInfo}
                updateDataClass={null}

                errorClass={topicTypeError}
                updateError={updateTopicTypeError}
            />

            <ImageInput
                title="AFBEELDING"

                valueKey="backgroundImage"
                dataClass={topicInfo}

                errorClass={imageError}
                updateError={updateImageError}
            />
            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }
        </div>
    )
}

export default AddTopic;
