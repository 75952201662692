
function EntityInfoClass({
    title = "",
    description = "",
    image = null
} = {}) {

    this.title = title;
    this.description = description;
    this.image = image;

}

export default EntityInfoClass;
