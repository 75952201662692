
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

// networking
import topicApi from "../../services/Networking/topic";

// text
import parse from "html-react-parser";
import cleanText from "../../services/cleanText";

// style
import "./Breadcrumb.scss";

// classes
import breadcrumbClass from "./classes";

// components
import List from "../List";

// constants
import { SUCCESS } from '../../services/errors/constants';
import { PREPARATORY, INFORMATIVE } from "../../services/constants/topicTypeConstants";

const Breadcrumb = (props) => {

    // routing stuff
    let { tijdvakID, kaID, examID, oderNumb } = useParams();
    const topicId = parseInt(tijdvakID ? tijdvakID.replace("t-", "") : null);
    const subTopicId = parseInt(kaID ? kaID.replace("st-", "") : null);
    const examId = parseInt(examID ? examID.replace("e-", "") : null);
    const orderNumbInt = parseInt(oderNumb ? oderNumb.replace("o-", "") : null);

    // determines exam prep or normal topic & sub topic page link
    const [type, updateType] = useState(null);
    
    const [crumbsList, updateCrumbsList] = useState([new breadcrumbClass({
        id: null,
        title: "Geschiedenis",
        link: "/geschiedenis"
    })]);

    // this function was there & I was to lazy to copy the class everywhere but its a bit redundent
    const addCrumb = ({
        id, 
        title,
        link
    }) => {

        return new breadcrumbClass({
            id: id,
            title: title,
            link: link
        });

    }

    // gets topic type to identify final exams on exam prep page
    const fetchTopic = async() => {
        const returned = await topicApi.getTopicByTopicId(topicId);
        if (!returned) {

        }

        if (returned.status === SUCCESS) {
            updateType(returned.payload.type);

        } else {

        }
    }
    useEffect(() => {

        fetchTopic();
    
    }, []);

    const addTopic = () => {        
        if (props.topicClass) {
            return addCrumb({
                id: props.topicClass.topicId,
                title: props.topicClass.title,
                link: `/geschiedenis/t-${props.topicClass.topicId}`
            });
        } else if (type === PREPARATORY) {
            return addCrumb({
                id: null,
                title: "Examens voorbereiden",
                link: `/examen-voorbereidingen`
            });
        } else if (topicId) {
            return addCrumb({
                id: topicId,
                title: "Hoofdstuk",
                link: `/geschiedenis/t-${topicId}`
            });
        }
    }

    const addSubTopic = () => {
        if (props.subTopicClass) {
            return addCrumb({
                id: props.subTopicClass.subtopicId, 
                title: props.subTopicClass.title,
                link: `/geschiedenis/t-${topicId}/st-${props.subTopicClass.subtopicId}`
            });
        } else if (type === PREPARATORY) {} else if (subTopicId) {
            return addCrumb({
                id: subTopicId,
                title: "Onderwerp",
                link: `/geschiedenis/t-${topicId}/st-${subTopicId}`
            });
        }
    }

    const addExam = () => {
        if (props.examClass) {
            return addCrumb({
                id: props.examClass.examId, 
                title: props.examClass.title ? props.examClass.title : "Examen",
                link: `/geschiedenis/t-${topicId}/st-${subTopicId}/e-${props.examClass.examId}`
            });
        } else {
            return addCrumb({
                id: examId, 
                title: "Examen",
                link: `/geschiedenis/t-${topicId}/st-${subTopicId}/e-${examId}`
            });
        }
    }

    const addExamOrder = () => {
        if (props.examClass && props.examOrder) {
            return addCrumb({
                id: null, 
                title: "examen nakijken",
                link: `/geschiedenis/t-${topicId}/st-${subTopicId}/e-${props.examClass.examId}`
            });
        }
    }

    const initateCrubsList = () => {

        let breadCrumbList = type === PREPARATORY ? [] : [new breadcrumbClass({
            id: null,
            title: "Geschiedenis",
            link: "/geschiedenis"
        })];

        // type not delivered & in exam page
        if (!type && examId) {
            // no breadCrums - would allow users to get to PREPARATORY topic & sub-topic pages
            updateCrumbsList(() => []);
            return null;
        }

        if (topicId) {
            breadCrumbList = [...breadCrumbList, addTopic()];
        }
        if (subTopicId) {
            breadCrumbList = [...breadCrumbList, addSubTopic()];
        }
        if (examId) {
            breadCrumbList = [...breadCrumbList, addExam()];
        }
        if (orderNumbInt) {
            breadCrumbList = [...breadCrumbList, addExamOrder()];
        }

        updateCrumbsList(() => breadCrumbList);
    }

    useEffect(() => {

        initateCrubsList();

    }, [type, props.topicClass, props.subTopicClass, props.examClass, props.examOrder]);


    const renderCrumb = (i, crumbData) => {

        if (crumbsList.length === i+1) {
            return (
                <div
                    key={i}
                    className="breadCrumbPlain"
                >{
                    parse(
                        cleanText({
                            textToClean: crumbData.title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            bold: true,
                            list: true,
                        })
                    )
                }
                </div>
            )
        }

        return (
            <>
            <Link
                key={i}
                to={crumbData.link} replace
            >
                <div
                    className="breadCrumb"
                >{
                    parse(
                        cleanText({
                            textToClean: crumbData.title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            bold: true,
                            list: true,
                        })
                    )
                }</div>
            </Link>
                <div className="breadCrumbArrow">{
                    ">"
                }</div>
                
            </>
        )
    }

    return (
        <>{
            crumbsList.length === 0 ? null : 
            <div
                className="breadCrumbs"
            >
                <List
                    items={crumbsList}
                    renderItem={renderCrumb}
                />
            </div>
        }</>
    )
}

export default Breadcrumb;
