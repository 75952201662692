
// constants
import { ASSAY_QUESTION } from "../../../../../services/constants/typeConstants";

class newQuestionClass {

    constructor({
        index = null,
        title = "",
        type = ASSAY_QUESTION,
        image = "",
        category = null,
        answers = [],
        oneCorrect = true, // nothing done with this when type is not multiple choice
    } = {}) {

        this.index = index;
        this.title = title;
        this.type = type;
        this.image = image;
        this.category = category;
        this.answers = answers;
        this.oneCorrect = oneCorrect;
    }
}

export default newQuestionClass;
