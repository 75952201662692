
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../services/errors/constants";

const validateEmail = (email) => {

    switch (email) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;

        // case !validate(email):
        //     return BAD_REQUEST;
        
        default:
            return SUCCESS;
    }
}

export default validateEmail;
