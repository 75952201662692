
// text
import parse from "html-react-parser";
import cleanText from "../../../../../../../../services/cleanText";

// style
import "./ShowAnswer.scss";

const ShowAnswer = (props) => {

    return (
        <div>{
            !props.title ? "" :
            
            parse(
                cleanText({
                    textToClean: props.title,
                })
            )
        }</div>
    )
}

export default ShowAnswer;
