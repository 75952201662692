
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// font awsome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// networking
import practiceCardApi from "../../../../../../services/Networking/practiceCards";

// networking classes
import starring from "../../../../../../services/Networking/practiceCards/classes/starring";

// services
import convertError from "../../../../../../services/errors/convertError";

// classes
import userResponse from "../../services/classes/useResponse";

// styles
import "./PracticeCard.scss";

// local elements
import Coloring from "./elements/Coloring";
import CardFront from "./elements/CardFront";
import CardBack from "./elements/CardBack";
import CardBanner from "./elements/CardBanner";

// components
import AlterContent from "../../../../../../components/AlterContent";
import LocalModal from "../../../../../../components/LocalModal";
import List from "../../../../../../components/List";
import Loader from '../../../../../../components/Loader';
import AddPracticeCard from "../../../AddPracticeCard";

// constants
import { SUCCESS, NO_RESPONSE } from "../../../../../../services/errors/constants";
import { OVERVIEW } from '../../services/constants';

const PracticeCard = (props) => {
    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [editCard, toggleEditCard] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const [viewFront, updateViewFront] = useState(true);

    const [selected, updateSelected] = useState(props.filtersClass.isItemSelected(props.data.practiceCardId));
    const updatedSelectedFunc = (e) => {
        // make action above is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        if (props.filtersClass.allItemsSelected === true) {
            props.updateFiltersClass((filtersClass) => {
                return filtersClass.cloneWithChanges({
                    allItemsSelected: false,
                });
            });
        }

        props.filtersClass.toggleSelection(props.data.practiceCardId);
        updateSelected((value) => !value);
    }

    useEffect(() => {
        updateSelected(props.filtersClass.isItemSelected(props.data.practiceCardId));
    }, [props.practiceCardList, props.filtersClass]);

    useEffect(() => {

        updateViewFront(true);
    
    }, [props.resetItemsState, props.filtersClass]);

    const renderResponse = (i, reponseData) => {
        
        return (
            <div
                key={i}
            >
                {
                    reponseData ?
                    <FontAwesomeIcon 
                        icon={faCheckCircle} 
                        className="correctResponse" 
                    /> 
                    :
                    <FontAwesomeIcon 
                        icon={faTimesCircle} 
                        className="wrongResponse"
                    />
                }
            </div>
        )
    }

    const response = async(e, correct) => {
        // make action above is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        toggleLoading(true);

        const sendableResponse = new userResponse({
            practiceCardId: props.data.practiceCardId,
            correct: correct
        });

        const returned = await practiceCardApi.responsePracticeCardsByPracticeCardId([sendableResponse]);

        if (!returned) {

            dispatch(globalErrorsActions.addError({
                errorCode: convertError(NO_RESPONSE),
                customErrors: null
            }));

            // something weard happend
            toggleLoading(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            props.triggerReRender((value) => !value);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                }))
            );
        }

        toggleLoading(false);
        updateViewFront(true);

    }

    return (
        <div className="PracticeCardContainer" >
            {
                !props.filtersClass.selectItemsPossible || props.currentView !== OVERVIEW ? null :

                <div
                    className={`cardSelected ${selected ? "selectedCard" : "notSelectedCard"}`}
                    onClick={(e) => updatedSelectedFunc(e)}
                >
                    Geselecteerd
                </div>

            }

            <div 
                className="PracticeCardView"
                onClick={() => updateViewFront((value) => !value)}
            >

                {
                    !props.color ?

                    <div className="noColorBuffer"></div> : 

                    <Coloring
                        color={props.color}
                    />
                }
                
                <CardBanner
                    data={props.data}

                    toggleEditCard={toggleEditCard}

                    triggerReRender={props.triggerReRender}

                    results={props.results}
                    fullHistory={props.fullHistory}

                />
                
                {
                    viewFront ? 
                    <CardFront
                        frontText={props.data.frontText}
                    />
                    :
                    <CardBack
                        backText={props.data.backText}
                    />
                }
                {
                    props.results ? null :
                    <div className="cardResponse">
                        {
                            viewFront ? null :
                            loading ? <Loader />
                            : 
                            <>
                                <FontAwesomeIcon 
                                    icon={faCheckCircle} 
                                    className="correctResponse" 
                                    onClick={(e) => response(e, true)}
                                />

                                <FontAwesomeIcon 
                                    icon={faTimesCircle} 
                                    className="wrongResponse"
                                    onClick={(e) => response(e, false)}
                                />
                            </>
                        }
                    </div>
                }
                <LocalModal
                    show={editCard}
                    toggleShow={toggleEditCard}
                    component={
                        <AddPracticeCard
                            toggleShow={toggleEditCard}
                            existingData={props.data}
                            type={props.data.type}
        
                            triggerReRender={props.triggerReRender}

                            isPersonal={props.data ? props.data.isPersonal : null}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default PracticeCard;
