
// react
import React, { useState } from 'react';

// services
import { toOverview } from '../../services/switchView';

// local components
import PracticeCard from "../PracticeCard";

// components
import List from '../../../../../../components/List';
import Filters from "../../../../../../components/Filters";
import CheckBox from '../../../../../../components/AlterContent/elements/CheckBox';

// style
import "./Results.scss";

// constants
import { PRACTICING } from "../../services/constants";

const Results = (props) => {

    const [fullHistory, toggleFullHistory] = useState(false);

    const userCorrectToColor = (userCorrect) => {
        if (userCorrect === true) {
            return "Green";
        } else if (userCorrect === false) {
            return "Red";
        } else {
            return "";
        }
    }

    const startPracticing = async() => {

        const validStart = await props.validateStart();
        if (!validStart) {
            return null;
        }

        if (props.filtersClass.selectItemsPossible) {
            props.updatePracticeCardList(() => {
                const filteredlist = props.filtersClass.toggleOnlyShowSelectedItems(props.fullPracticeCardList);
                return filteredlist;
            });
        }

        // make all filters invisible during practicing
        props.updateFiltersClass((filtersClass) => {

            filtersClass.filters.map((filter) => {
                filter.visible = false;
                filter.overridOnlyShowSelected = false;
            });

            filtersClass.selectingItemsPresentAsFilterOption = false;
            if (filtersClass.selectItemsPossible === true) {
                filtersClass.onlyShowSelectedItems = true;
            }

            return filtersClass;
        });

        props.toggleLoading(true);

        // switch to practicing
        props.updateView(PRACTICING);
    }

    const renderCard = (i, cardData) => {
        return (
            <PracticeCard
                key={i}
                data={cardData}

                results={true}
                fullHistory={fullHistory}

                color={fullHistory ? cardData.color : userCorrectToColor(props.flashcardResults.get(cardData.practiceCardId))}

                selectFlashcards={props.selectFlashcards}

                triggerReRender={props.triggerReRender}

                // resetItemsState={resetItemsState}
                // triggerredReset={triggerredReset}

                filtersClass={props.filtersClass}
            />
        )
    }

    return (
        <div className="ResultsView" >
            <div className="resultsOptions">

                <div className="resultsFilter" >
                    <div
                        className="resultsFilterItem"
                        onClick={() => toggleFullHistory((value) => !value)}
                    >
                        <CheckBox
                            checked={!fullHistory}
                        />
                        Net behaalde resultaten
                    </div>
                </div>

                <Filters
                    filtersClass={props.filtersClass}
                    updateFiltersClass={props.updateFiltersClass}

                    fullList={props.fullPracticeCardList}
                    updateFullList={props.updateFullPracticeCardList}

                    // might not need the list, just the update?
                    list={props.practiceCardList}
                    updateFilteredList={props.updatePracticeCardList}

                    forceIncludeList={props.forceIncludeList}

                    nonFilterFetchParams={props.nonFilterFetchParams}

                    rerender={props.rerender}
                />

                <div 
                    className="resultsButton" 
                    onClick={startPracticing}
                >
                    Opnieuw oefenen
                </div>

                <div 
                    className="resultsButton"
                    onClick={() => toOverview(props.updateFiltersClass, props.updateView)}
                >
                    Naar overzicht
                </div>
            </div>

            <div className="resultsCards" >
                <List
                    items={props.practiceCardList}
                    renderItem={renderCard}
                />
            </div>
        </div>
    )
}

export default Results;
