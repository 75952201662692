
// constants
import { ADD_ERROR, REMOVE_ERROR, EMPTY_ERROR_LIST } from "../constants";

const globalErrorsActions = {
    addError,
    removeError,
    emptyErrorList
};

function addError(errorCode) {
    return {
        type: ADD_ERROR,
        payload: errorCode
    };
}

function removeError(errorCode) {
    return {
        type: REMOVE_ERROR,
        payload: errorCode
    };
}

function emptyErrorList() {
    return {
        type: EMPTY_ERROR_LIST
    };
}

export default globalErrorsActions;
