
import "../classes";

// classes
import errorClass from "../../../services/errors/classes";

// constants
import { SUCCESS, INTERNAL_ERROR, NO_CONTENT, FORBIDDEN,
    UNAUTHORIZED, NOT_FOUND, CONFLICT, NO_RESPONSE, 
    UNSUPPORTED_MEDIA_TYPE
} from "../constants.js";

const defaultErrors = (errorCode) => {

    if (!errorCode || errorCode === undefined) {

        return new errorClass({
            errorCode: INTERNAL_ERROR,
            priority: 1,
            title: "errorCode in defaultErrors was was niet valide",
            description: "errorCode in defaultErrors was was niet valide",
            advice: null,
            moreInfo: null
        });

    }

    switch (errorCode) {

        case SUCCESS:
            
            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Gelukt",
                description: "Het is gelukt",
                advice: null,
                moreInfo: null
            });

        case NO_RESPONSE: 
            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Geen response",
                description: "Geen response, het kan zijn dat u geen internet heeft.",
                advice: null,
                moreInfo: null
            });

        case NO_CONTENT:
        
            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Geen response",
                description: "Er is geen informatie terug gestuurt",
                advice: null,
                moreInfo: null
            });

        case INTERNAL_ERROR:
    
            return new errorClass({
                errorCode: INTERNAL_ERROR,
                priority: 1,
                title: "Er is iets fout gegaan in ons systeem",
                description: "Er is iets fout gegaan in ons systeem. Wij doen ons best het te repareren.",
                advice: null,
                moreInfo: null
            });

        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: "U heeft geen toegang",
                description: "Je hebt geen toegang tot deze actie als je niet ingelogd bent.",
                advice: null,
                moreInfo: null
            });
        
        case FORBIDDEN:

            return new errorClass({
                errorCode: FORBIDDEN,
                priority: 1,
                title: "U mag dit niet doen met dit account",
                description: "U mag dit niet doen met dit account",
                advice: null,
                moreInfo: null
            });

        case NOT_FOUND:

            return new errorClass({
                errorCode: NOT_FOUND,
                priority: 1,
                title: "Niet gevonden",
                description: "De informatie is niet gevonden",
                advice: null,
                moreInfo: null
            });

        case CONFLICT:

            return new errorClass({
                errorCode: CONFLICT,
                priority: 1,
                title: "Duplicate informatie",
                description: "Duplicate informatie",
                advice: null,
                moreInfo: null
            });

        case UNSUPPORTED_MEDIA_TYPE:
            return new errorClass({
                errorCode: CONFLICT,
                priority: 1,
                title: "De format van de verstuurde informatie wordt niet ondersteund",
                description: "De format van de verstuurde informatie wordt niet ondersteund",
                advice: null,
                moreInfo: null
            });

        case 1700:
            return new errorClass({
                errorCode: CONFLICT,
                priority: 1,
                title: "Eén of meerder vragen zijn niet ingevuld",
                description: "Eén of meerder vragen zijn niet ingevuld",
                advice: null,
                moreInfo: null
            });

        default:
            return new errorClass({
                errorCode: INTERNAL_ERROR,
                priority: 1,
                title: "Er heeft een onbekende fout opgetreden",
                description: "Er heeft een onbekende fout opgetreden. Wij proberen het zo snel mogelijk op te lossen.",
                advice: null,
                moreInfo: null
            });
    }
}

export default defaultErrors;

