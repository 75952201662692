
const validateUrl = (string) => {
    let url;
        
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export default validateUrl;
