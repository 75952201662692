
// styles
import "./QuestionOverview.scss";

// components
import InfoItem from "../../../../../../../../../../../../components/InfoItem/InfoItem";

// constants
import { OVERVIEW_FOLDED } from "../../../../../../../../services/constants";

const QuestionOverview = (props) => {

    if (props.overviewWidth === OVERVIEW_FOLDED) {
        return (
            <div
                className="QuestionInExamOverviewFoldedView"
            >
                Vraag {props.data?.questionIndex}
            </div>
        )
    }

    return (
        <div
            className="QuestionInExamOverviewView"
        >
            <div
                className="QuestionInExamOverviewSectionView"
            >
                <InfoItem
                    title="Naam"
                    value={`Vraag ${props.data?.questionIndex}`}
                />
                <InfoItem
                    title="Gemiddeld behaalde punten"
                    value={`${props.data?.averagePoints}/${props.data?.maxPoints}`}
                />
            </div>
        </div>
    )
}

export default QuestionOverview;
