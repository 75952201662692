
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import practiceCardApi from "../../../../services/Networking/practiceCards";

// local services
import addPracticeCardErrors from "./services/errors/addPracticeCards.js";
import editPracticeCardErrors from "./services/errors/editPracticeCards";
import addWithoutAccess from "./services/errors/addWithoutAccess";

// services
import convertError from "../../../../services/errors/convertError";
import validateTextInput from "../../../../services/validate/textInput";
import defaultTextInputErrors from "../../../../services/errors/defaultTextInputErrors";
import access from "../../../../services/access/accessCheck";
import cleanText from "../../../../services/cleanText";
import loginAccessErrors from "../../../../services/access/loginAccessErrors";

// style
import "./AddPracticeCard.scss";

// local components
import AccessWarning from "./components/AccessWarning";

// components
import CkTextInput from "../../../../components/input/CkText";
import Submit from "../../../../components/input/elements/Submit";
import Loader from "../../../../components/Loader";

// elements
import Title from "../../../../elements/ModalTitle";

// classes
import errorClass from "../../../../services/errors/classes";
import CkEditorConfig from "../../../../components/input/elements/CkText/classes";

// local classes
import AddPracticeCardClass from "./services/classes";

// constants
import { STUDENT, EDITOR } from "../../../../services/access/constants";
import { SUCCESS, NO_CONTENT, UNAUTHORIZED } from "../../../../services/errors/constants";

const AddPracticeCard = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [practiceCardData, updatePracticeCardData] = useState(props.existingData ? props.existingData : new AddPracticeCardClass());
    const returnTypeTitle = () => {
        if (props.type === 1) {
            return "DEFINITIE";
        } else if (props.type === 2) {
            return "FEITJE";
        }
    }
    const typeTitle = returnTypeTitle();


    const [frontError, updateFrontError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [backError, updateBackError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    // function to check if user is logged in
    const checkLoggedIn = () => {
        if (!user) {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: UNAUTHORIZED,
                    updateLocally: null,
                    customErrors: loginAccessErrors
                })
            ));
            return false;
        }

        if (!user.user) {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: UNAUTHORIZED,
                    updateLocally: null,
                    customErrors: loginAccessErrors
                })
            ));
            return false;
        }

        return true;
    }

    const accessEdit = () => {

        if (!props.existingData) {
            return false;
        }

        if (!user) {
            // should not be possible to get here
            return false;
        }

        if (!user.user) {
            // should not be possible to get here
            return false;
        }

        if (access({
            accessLevel: STUDENT,
            userRoles: user.user.roles
        }) && props.isPersonal) {
            return true;
        }

        if (access({
            accessLevel: EDITOR,
            userRoles: user.user.roles
        })) {
            return true;
        }

        return false;

    }

    const validateField = ({
        valueKey,
        validate,
        updateError,
        customErrors
    }) => {
        // validate
        const valid = validate(practiceCardData[valueKey]);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {

        let validInput = true;

        // validate frontText
        if (!validateField({
            valueKey: "frontText",
            validate: validateTextInput,
            updateError: updateFrontError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate frontText
        if (!validateField({
            valueKey: "backText",
            validate: validateTextInput,
            updateError: updateBackError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        return validInput;

    }

    const save = async() => {
        if (!checkLoggedIn()) {
            return null;
        }

        toggleLoading(true);

        const validInput = await validateFields();
        
        if (validInput) {
            practiceCardData.frontText = cleanText({
                textToClean: practiceCardData.frontText,
                h1: true,
                h2: true,
                h3: true,
                h4: true,
                p: true,
                bold: true
            });
    
            practiceCardData.backText = cleanText({
                textToClean: practiceCardData.backText,
                h1: true,
                h2: true,
                h3: true,
                h4: true,
                p: true
            });

            let response;

            if (accessEdit()) {

                response = await practiceCardApi.putPracticeCardByCardId(
                    practiceCardData.practiceCardId,
                    practiceCardData
                )

            } else {
                
                const sendable = new AddPracticeCardClass({
                    frontText: practiceCardData.frontText,
                    backText: practiceCardData.backText,
                });

                response = await practiceCardApi.addPracticeCards(
                    subTopicId,
                    props.type,
                    sendable
                )
            }
            
            // nothing returned - something weard happend
            if (!response) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (response.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: !accessEdit() && props.existingData ? addWithoutAccess : addPracticeCardErrors
                    }))
                );

                props.triggerReRender((value) => !value);
                props.toggleShow(false);

            } else {
                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: props.existingData ? editPracticeCardErrors : addPracticeCardErrors
                    }))
                );
            }
        }

        toggleLoading(false);
    }

    return (
        <div className="AddPracticeCard" >
            <Title
                title={props.existingData ? `${typeTitle} AANPASSEN` : `${typeTitle} TOEVOEGEN`}
            />

            <AccessWarning
                existingData={props.existingData}
            />

            <CkTextInput
                config={new CkEditorConfig({
                    toolbar: ['italic', 'link'],
                })}
                
                title="VOORKANT"
                
                valueKey="frontText"
                dataClass={practiceCardData}

                errorClass={frontError}
                updateError={updateFrontError}
            />

            <CkTextInput
                config={new CkEditorConfig({
                    toolbar: ['bold', 'italic', 'link'],
                })}

                title="ACHTERKANT"
                
                valueKey="backText"
                dataClass={practiceCardData}

                errorClass={backError}
                updateError={updateBackError}
            />


            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

        </div>
    )
}

export default AddPracticeCard;
