
import React, { useState } from 'react';

// services
import uploadImage from "../../../../services/uploadImage";

// classes
import errorClass from "../../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

// style
import "../assets/style.scss";

const Image = (props) => {

    // we're getting a warning because of this but it works
    const [typed, toggleTyped] = useState(false);

    const onChange = async(newValue) => {
        toggleTyped(true);

        const newImage = newValue.target.files[0];

        const cleanImageClass = await uploadImage(newImage);

        if (cleanImageClass.status === SUCCESS) {
            if (props.customUpdateFunc) {
                props.customUpdateFunc(
                    props.valueKey,
                    cleanImageClass.payload
                );
            } else {
                props.dataObject[props.valueKey] = cleanImageClass.payload;
            }

            if (props.updateError) {
                props.updateError(new errorClass({
                    errorCode: SUCCESS
                }));
            }
        } else {
            if (props.updateError) {
                props.updateError(new errorClass({
                    errorCode: cleanImageClass.status,
                    priority: 2,
                    title: "Invalide format",
                    description: "De format van deze afbeelding is niet valide"
                }));
            }
        }
    }

    return (
        <input 
            type="file" 

            name={props.valueKey}

            className="uploadingImage"

            value={typed || props.dataObject[props.valueKey] ? undefined : props.value}
            onChange={onChange}
        />
    )

}

export default Image;
