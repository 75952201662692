
// classes
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { NO_CONTENT, BAD_REQUEST, NOT_FOUND } from "../../../../../../../services/errors/constants";

const firstNameErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Geen voornaam ingevult",
                description: "Dit vlak moet ingevult zijn.",
                advice: null,
                moreInfo: null,
            });

        case BAD_REQUEST:

            return new errorClass({
                errorCode: errorCode,
                title: "Volgens de wet is dit geen legale voornaam",
                description: "Volgens de wet is dit geen voornaam.",
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default firstNameErrors;
