
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// routing
import { useParams, useHistory } from 'react-router-dom';

// components
import List from "../../components/List";
import Loader from "../../components/Loader";
import LocalModal from "../../components/LocalModal";
import Breadcrumb from "../../components/Breadcrumb";
import Feedback from "../../components/Feedback";

// local components
import AddSubTopic from "./components/AddSubTopic";
import Title from "./components/Title";
import Card from "./components/Card";

// elements
import Add from "../../elements/Add";

// networking
import topicApi from "../../services/Networking/topic";
import subTopicApi from "../../services/Networking/subTopic";

// style
import "./Topic.scss";

// services
import access from "../../services/access/accessCheck";
import convertError from "../../services/errors/convertError";
import userActions from "../../services/redux/actions/user.action";
import logOutErrors from "../../services/errors/logOutErrors";

// local services
import topicErrors from "./services/errors";

// constants
import { ADMIN } from "../../services/access/constants";
import { SUCCESS, UNAUTHORIZED, NOT_FOUND, NO_CONTENT } from "../../services/errors/constants";

const Topic = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();

    // rendering
    const [rerender, triggerReRender] = useState(true);
    const triggerReRenderFunc = () => {
        triggerReRenderFunc((value) => !value);
    }

    const [addSubTopic, toggleAddSubTopic] = useState(false);

    // topic id
    let { tijdvakID } = useParams();
    const topicId = tijdvakID ? parseInt(tijdvakID.replace("t-", "")) : null;

    const [loadingTopic, updateLoadingTopic] = useState(true);
    const [loadingTopics, updateLoadingTopics] = useState(true);

    const [topicClass, updateTopicClass] = useState(null);
    const [subTopics, updateSubTopicsClass] = useState(null);

    // logs user out
    const logOut = () => {
        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // log user in
        dispatch(userActions.logOut());

        // display success
        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: SUCCESS,
                updateLocally: null,
                customErrors: logOutErrors
            }))
        );
    }

    const fetchTopic = async () => {

        const returned = await topicApi.getTopicByTopicId(topicId);

        if (!returned) {

            dispatch(globalErrorsActions.addError({
                errorCode: convertError(NO_CONTENT),
                customErrors: null
            }));

            // something weard happend
            updateLoadingTopic(false);
            return null;
        }

        if (returned.status === 200) {
            updateTopicClass(returned.payload);
        } else if (returned.status === UNAUTHORIZED) {
            // logout
            logOut();

            // add error to global error list
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status,
                    customErrors: null
            })));

            return null;
        } else if (returned.status === NOT_FOUND) {
            // page not found
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status,
                    customErrors: topicErrors
            })));

            // push to page not found page
            history.push("/niet-gevonden");
        } else {
            // page not found
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status,
                    customErrors: topicErrors
            })));
        }

        // handle errors


        updateLoadingTopic(false);

    }

    const fetchTopics = async () => {
        const returned = await subTopicApi.getSubTopicByTopicID({
            topicID: topicId,
            type: topicClass.type
        });

        if (!returned) {

            dispatch(globalErrorsActions.addError({
                errorCode: convertError(NO_CONTENT),
                updateLocally: null,
                customErrors: null
            }));

            // something weard happend
            updateLoadingTopic(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            updateSubTopicsClass(returned.payload);
        } else {

            // still add custom errors


            dispatch(globalErrorsActions.addError({
                errorCode: convertError(returned.status),
                updateLocally: null,
                customErrors: null
            }));
        }

        updateLoadingTopics(false);

    }

    useEffect(() => {

        updateLoadingTopic(true);

        fetchTopic();
    
    }, [rerender]);

    useEffect(() => {

        if (topicClass) {
            updateLoadingTopics(true);
            fetchTopics();
        }
    
    }, [topicClass]);

    const renderCard = (i, subTopicData) => {
        return (
            <Card
                key={i}
                data={subTopicData}

                triggerReRender={triggerReRender}
            />
        )
    }

    return (
        <div className="TopicView" >
            <Title
                title={topicClass ? topicClass.title : null}
                subTitle={topicClass ? topicClass.subtitle : null}
                
                loading={loadingTopic}
            />

            <Breadcrumb
                topicClass={topicClass}
            />

            <div className="subTopicsList">{
                loadingTopics ? <Loader /> :
                <List
                    items={subTopics}
                    renderItem={renderCard}
                    loading={loadingTopics}
                />
            }
            
            {
                !user ? null : !user.user ? null : access({
                    accessLevel: ADMIN,
                    userRoles: user.user.roles
                }) ? 
                <Add
                    onClick={() => toggleAddSubTopic(true)}
                /> : null
            }
            
            </div>

            <LocalModal
                show={addSubTopic}
                toggleShow={toggleAddSubTopic}
                component={
                    <AddSubTopic
                        toggleShow={toggleAddSubTopic}

                        triggerReRender={triggerReRender}
                    />
                }
            />

            <Feedback />

        </div>
    )
}

export default Topic;
