
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const validatePassword = (password) => {

    switch (password) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;
        
    }

    switch (password.length >= 6) {

        case false:
            return BAD_REQUEST;

        default:
            return SUCCESS;
    }
}

export default validatePassword;
