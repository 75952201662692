
// classes
import errorClass from "../classes/errorClass";

// constans
import { SUCCESS, INTERNAL_ERROR, NO_CONTENT } from "../constants";

const repeatPassword = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Geen wachtwoord ingevult",
                description: "Dit vlak moet ingevult zijn.",
                advice: null,
                moreInfo: null,
            });

        case INTERNAL_ERROR:

            return new errorClass({
                errorCode: INTERNAL_ERROR,
                title: "Wachtwoorden komen niet overeen",
                description: "Wachtwoorden komen niet overeen.",
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default repeatPassword;
