
// react
import { useState, useEffect } from "react";

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// constants
import { REDUX_ERRORS } from "./services/constants";
import { NO_RESPONSE, SUCCESS } from "../../services/errors/constants";

const Redux = (props) => {

    // redux
    const dispatch = useDispatch();
    const errors = useSelector(state => state.globalErrors);
    
    useEffect(() => {
        
        // only run there is an error to be shown
        if (props.reduxVar) {
            if (props.showSuccess ? true : props.reduxVar.errorCode !== SUCCESS) {
                if (props.varId === REDUX_ERRORS) {
                    const hi = [];
                    const found = hi.find((item) => item.errorCode === props.reduxVar);

                    if (errors?.arr && errors?.arr?.find((item) => item.errorCode === props.reduxVar.errorCode && props.reduxVar.errorCode === NO_RESPONSE)) {
                        return;
                    }
    
                    // clear errors
                    dispatch(globalErrorsActions.emptyErrorList());
        
                    // add errors to list
                    dispatch(globalErrorsActions.addError(props.reduxVar));
                }
            }
        }

    }, [props.reduxVar]);
    
    return (
        <></>
    )

}

export default Redux
