
// react
import { useState } from "react";

// style
import "./DeleteExamSubtopicPage.scss";

// networking
import examsApi from "../../../../../../../../services/Networking/exams/exams";

// services
import deleteNetworking from "../../../../../../../../services/handleNetworking/deleteNetworking/deleteNetworking";
import deleteErrors from "../../../../../../../../components/AlterContent/services/errors/delete/delete";

// components
import Close from "../../../../../../../../elements/Close/Close";
import Redux from "../../../../../../../../components/Redux/Redux";
import Loader from "../../../../../../../../components/Loader";

// constants
import { REDUX_ERRORS } from "../../../../../../../../components/Redux/services/constants";
import { SUCCESS } from "../../../../../../../../services/errors/constants";

const Delete = (props) => {

    const [loading, toggleLoading] = useState(false);

    const [deleteEngaged, toggleDeleteEngaged] = useState(false);

    // errors
    const [sendError, updateSendError] = useState(null);

    const deleteExam = async() => {

        const returned = await deleteNetworking({
    
            toggleLoading: toggleLoading,
            triggerReRender: props.triggerReRender,
        
            api: examsApi.deleteExamByExamId,
            apiParams:props.examId,
        
            updateError: updateSendError,
            customErrors:deleteErrors,
        });

        if (returned.status === SUCCESS && props.onSuccess) {
            props.onSuccess();
        }

    }
    
    return (
        <>
            <div 
                className="sectExamInfoDelete"
                onClick={deleteEngaged ? () => deleteExam() : () => toggleDeleteEngaged(true)}
            >{
                loading ? <Loader /> : "Delete"
            }</div>

            {deleteEngaged ? <Close onClick={() => toggleDeleteEngaged(false)} /> : null}
            
            <Redux
                showSuccess={true}
                varId={REDUX_ERRORS}
                
                reduxVar={sendError}
            />

        </>
    )
}

export default Delete
