
import { SUCCESS, NO_CONTENT } from "../../../../../../../services/errors/constants";

const validatePassword = (passward) => {

    switch (passward) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;
        
        default:
            return SUCCESS;
    }
}

export default validatePassword;
