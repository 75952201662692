
import parse from "html-react-parser";

// networking
import videosApi from "../../../../../../services/Networking/videos";

// services
import cleanText from "../../../../../../services/cleanText";
import iframeLink from "../../../../../../services/video/iframeLink";

// components
import AlterContent from "../../../../../../components/AlterContent";

// style
import "./Video.scss";

const Video = (props) => {

    return (
        <div className="videoLarge" >
            <div className="EditVideoContent" >
                <AlterContent
                    delete={{
                        api: videosApi.deleteVideoByVideoId,
                        apiParams: props.data.videoId,
                    }}
                    triggerReRender={props.triggerReRender}
                />
            </div>

            <div className="videoHolder" >
                <iframe src={iframeLink(props.data.url)} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>

            <div className="videoTitle" >
                {
                    parse(
                        cleanText({
                            textToClean: props.data.title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            list: true,
                        })
                    )
                }
            </div>

            <div className="videoCreator" >
                {
                    parse(
                        cleanText({
                            textToClean: props.data.creator,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            list: true,
                        })
                    )
                }
            </div>
        </div>
    )
}

export default Video;
