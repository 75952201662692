
// standard objects
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { SUCCESS, UNAUTHORIZED, FORBIDDEN } from "../../../../../../../services/errors/constants";

const editPracticeCardErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Toevoegen gelukt",
                description: "Toevoegen gelukt",
                advice: null,
                moreInfo: null,
            });

        // add incorrect password
        case FORBIDDEN:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: `Deze hoort niet bij jouw account`,
                description: `Deze hoort niet bij jouw account`,
                advice: null,
                moreInfo: null
            });

        // add incorrect password
        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: `Je hebt geen autouriteit om deze toe te voegen`,
                description: `Je hebt geen autouriteit om deze toe te voegen`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default editPracticeCardErrors;
