
import React from 'react';

// text
import parse from "html-react-parser";
import cleanText from "../../../../../../services/cleanText";

// style
import "./questionTitle.scss";

// components
import CkTextInput from "../../../../../../components/input/CkText";

// constants
import { VIEW, MAKE, CHECK, ASSIGNMENT, EDIT} from "../../../../services/tabConstants";
import { TITLE } from '../../../../services/constants/questionHubKeys';

const Title = (props) => {

    const updateTitle = (valueKey, newText) => {
        
        props.questionHub.updateProperty(valueKey, newText);

    }

    return (
        <div className="questionTitle" >
                
                <div className="questionHeader" >{
                    props.examView === EDIT ? "Vraag aanpassen" : "Vraag"
                }</div>

                {
                    props.examView === EDIT ?
                    <CkTextInput
                        
                        valueKey={TITLE}
                        dataClass={props.questionHub.getQuestion()}

                        customUpdateFunc={updateTitle}

                        warningClass={props.titleWarning}
                        updateWarning={props.updateTitleWarning}
                    />
                    :
                    parse(
                        cleanText({
                            textToClean: props.questionHub.getCurrentValue(TITLE),
                        })
                    )
                }
            </div>
    )
}

export default Title;
