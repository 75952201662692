
function AddVideoClass({
    url = "",
    creator = "",
    title = null,
} = {}) {

    this.url = url;
    this.creator = creator;
    this.title = title;

}

export default AddVideoClass;
