
// classes
import takenExam from "../classes/takenExam";

const takenExams = (takenExamsList) => {
    if (!takenExamsList) {
        return null;
    }

    let titleList = [
        "Vorige poging",
        "Een na vorige poging",
        "Twee na vorige poging",
        "Vier pogingenen geleden",
        "Fijf pogingenen geleden"
    ];

    takenExamsList.slice(Math.max(takenExamsList.length - 5, 0));

    let takenList = [];

    takenExamsList.map((item) => {
        
        const newItem = new takenExam({
            id: item,
            title: titleList.shift()
        });

        takenList.push(newItem);
    })

    return takenList;

}

export default takenExams;
