
function loginInfo({
    email = "",
    password = ""
} = {}) {

    this.email = email;
    this.password = password;

}

export default loginInfo;
