// services/errors/CustomErrorsManager.js
// classes
import errorClass from "../../../services/errors/classes";

// this.errorMap = {
//     [NO_CONTENT]: {
//         title: "Geen hoofdstukken",
//         description: "Er zijn nog geen hoofdstukken voor dit boek"
//     },
//     [NOT_FOUND]: {
//         title: "Pagina niet gevonden",
//         description: "Deze pagina lijkt niet te bestaan. Ga naar www.etalia.nl/geschiedenis."
//     }
//     // Add more predefined errors as needed
// };

class CustomErrorsManager {
    constructor(errorMap) {
        this.errorMap = errorMap;
    }

    getError(errorCode) {
        if (!errorCode) {
            return null;
        }

        const errorInfo = this.errorMap[errorCode];

        if (!errorInfo) {
            return null;
        }

        return new errorClass({
            errorCode: errorCode,
            title: errorInfo?.title || errorInfo?.description,
            description: errorInfo?.description || errorInfo?.title,
            moreInfo: null,
        });
    }

    addError(errorCode, title, description) {
        this.errorMap[errorCode] = { title, description };
    }

    removeError(errorCode) {
        delete this.errorMap[errorCode];
    }
}

export default CustomErrorsManager;
