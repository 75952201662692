
import { useState } from "react";

// styles
import "./assignedItems.scss";

// components
import List from "../../../../../../components/List";
import AssignedExam from "./components/AssignedExam/AssignedExam";
import LocalModal from "../../../../../../components/LocalModal";
import AddAssignedItems from "./components/AddAssignedItems/AddAssignedItems";

// constants
import { ASSIGNED_ITEM_TYPE_EXAM } from "./services/constants";

const AssignedItems = (props) => {

    // state
    const [showAddAssignmentItem, toggleShowAddAssignmentItem] = useState(false);
    const renderAssignmentItem = (i, assignmnetItem) => {

        switch (assignmnetItem?.type) {
            case ASSIGNED_ITEM_TYPE_EXAM:
                return (
                    <AssignedExam
                        key={assignmnetItem.itemUUID}
                        assignmnetItem={assignmnetItem}
                        assignment={props.assignment}

                        getAssignment={props.getAssignment}
                    />
                )
        
            default:
                break;
        }
    }

    return (
        <div
            className="AssignmentItemsView"
        >
            
            <div
                className="AssignmentItemsTitle"
            >
                Opdrachten

                {
                    props.assignment?.sent?.isAdmin === true ?
                    <div
                        className="AssignmentItemsAddItem"
                        onClick={() => toggleShowAddAssignmentItem(true)}
                    >
                        Toevoegen
                    </div> : null
                }
            </div>
            
            {
                props.assignment?.getCurrentValue("assignmentItems")?.length === 0 ?
                <div className="AssignmentItemsNoItems" >Nog geen opdrachten gekoppeld.</div>
                :
                <List
                    items={props.assignment?.getCurrentValue("assignmentItems")}
                    renderItem={renderAssignmentItem}
                />
            }

            <LocalModal
                show={showAddAssignmentItem}
                toggleShow={toggleShowAddAssignmentItem}
                component={
                    <AddAssignedItems
                        toggleShow={toggleShowAddAssignmentItem}
                    />
                }
            />

        </div>
    )
}

export default AssignedItems;
