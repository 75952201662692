
class ClientData {

    constructor({
        index = null,
        id = null,

        childLists = {},
        childSets = {},

        sent = null,
        post = null,
        put = null,

        createEmptyInstance
    } = {}) {
        this.index = index;
        this.id = id;

        this.childLists = childLists;
        this.childSets = childSets;

        this.sent = sent;
        this.post = post;
        this.put = put;

        this.propertyRemoved = null;
        this.propertyDeleted = null;

        // TODO: Check if this is really necissary or if createEmptyInstance can get deleted out of class
        // if (!createEmptyInstance) {
        //     throw Error("ClientData class requires createEmptyInstance");
        // }
        this.createEmptyInstance = createEmptyInstance;
    }

    /* 
        Get values
    */
    // getValue
    getCurrentValue = (key) => {
        if (this.sent) {
            if (this.put) {
                return this.put[key];
            }
            return this.sent[key]
        } else if (this.post) {
            return this.post[key]
        }
        return null;
    }
    getData = () => {
        if (!this.sent) {
            return this.post;
        }

        if (this.put) {
            return this.put;
        }

        if (this.sent) {
            return this.sent;
        }
        return null
    }

    /*
        Post values
    */
    addChildList = (childListKey, list, itemIdKey) => {
        if (!childListKey || !list) {
            return false;
        }

        this.childLists[childListKey] = list.map((item) => {
            return new ClientData({
                id: itemIdKey ? item[itemIdKey] : item,
                sent: item
            });
        });

        return true;
    }

    /*
        Put values
    */
    // update property
    updateProperty = (key, newValue) => {
        // check if question is not deleted
        if (this.getData() === null) {
            return null;
        }

        const valueToCompair = this.getData()[key]

        // make sure value is changed & than only add put if it doesn't exist
        if (!this.put && !this.post && valueToCompair !== newValue) {
            this.initPut();
        }

        // make sure this is not triggered if the type isn't changed
        if (valueToCompair !== newValue) {
            this.getData()[key] = newValue;
        }

        return newValue;
    }

    // remove property
    removeProperty = () => {

        // question is already removed (should not happen)
        if (this.propertyRemoved !== null) {
            return false;
        }
        // question is already deleted (should not happen)
        if (this.propertyDeleted !== null) {
            return false;
        }

        // if there is no sent there must be a post
        if (!this.sent) {
            // give questionRemove the value of post
            this.questionRemove = this.post
            return true;
        }

        if (this.put) {
            // give questionRemove the value of put
            this.propertyRemoved = this.put
            return true;
        }

        if (this.sent) {
            // give questionRemove the value of put
            this.propertyRemoved = this.sent
            return true;
        }

        // should not be able to get here
        return false;
    }

    // delete property
    deleteProperty = () => {

        // question is already removed (should not happen)
        if (this.propertyRemoved !== null) {
            return false;
        }
        // question is already deleted (should not happen)
        if (this.propertyDeleted !== null) {
            return false;
        }

        // if there is no sent there must be a post
        if (!this.sent) {
            // give propertyDeleted the value of post
            this.propertyDeleted = this.post
            return true;
        }

        if (this.put) {
            // give propertyDeleted the value of put
            this.propertyDeleted = this.put
            return true;
        }

        if (this.sent) {
            // give propertyDeleted the value of put
            this.propertyDeleted = this.sent
            return true;
        }
        
        // should not be able to get here
        return false;
    }

    /*
        init editing
    */
    initPut = () => {
        if (!this.put) {
            this.put = { ...this.sent };
        }
    }

    clone = () => {
        // Creating a new instance
        const cloned = new ClientData({
            index: this.index,
            id: this.id,
            childLists: this.childLists,
            childSets: this.childSets,
            sent: this.sent ? { ...this.sent } : null,
            post: this.post ? { ...this.post } : null,
            put: this.put ? { ...this.put } : null,
            createEmptyInstance: this.createEmptyInstance
        });

        // Assuming propertyRemoved and propertyDeleted are simple types or you want them reset/null
        cloned.propertyRemoved = this.propertyRemoved ? { ...this.propertyRemoved } : null;
        cloned.propertyDeleted = this.propertyDeleted ? { ...this.propertyDeleted } : null;

        return cloned;
    };
}

export default ClientData;
