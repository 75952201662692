
// react
import React, { useState, useEffect } from 'react';

import parse from "html-react-parser";

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// font awsome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// networking
import practiceCardApi from "../../../../../../services/Networking/practiceCards";

// services
import cleanText from "../../../../../../services/cleanText";
import convertError from "../../../../../../services/errors/convertError";
import getColorFilterFromFilters from '../../services/getColorFilterFromFilters';

// classes
import userResponse from "../../services/classes/useResponse";
import buttonClass from "../../../../../../components/Alert/services/classes/ButtonClass";

// style
import "./Practicing.scss"

// services
import { toOverview } from '../../services/switchView';

// elements
import Pointer from "../../../../../../elements/Pointer";

// components
import Loader from "../../../../../../components/Loader";
import Filters from "../../../../../../components/Filters";
import LocalModal from "../../../../../../components/LocalModal";
import Alert from "../../../../../../components/Alert";

// submit errors
import submitErrors from "./services/errors/submit";

// constants
import {UP, DOWN, LEFT, RIGHT} from "../../../../../../elements/Pointer/services/constants";
import { SUCCESS, NO_RESPONSE } from "../../../../../../services/errors/constants";
import { OVERVIEW, RESULTS } from "../../services/constants";

const Practicing = (props) => {

    // redux
    const dispatch = useDispatch();

    // alert
    const [showAlert, toggleShowAlert] = useState(false);

    const [loading, toggleLoading] = useState(false);
    const [loadingBack, toggleLoadingBack] = useState(false);

    const [front, toggleFront] = useState(true);

    const [index, updateIndex] = useState(0);

    useEffect(() => {

        updateIndex(index);
    
    }, [index]);

    const nextCard = () => {
        if (index === props.practiceCardList.length-1) {
            toResults();
        } else {
            updateIndex((value) => value+1);
            toggleFront(true);
        }
    }

    const formerCard = () => {
        if (index === 0) {
            
        } else {
            updateIndex((value) => value-1);
            toggleFront(true);
        }
    }

    const sendUserResponse = (correct) => {
        props.practiceCardList[index].userCorrect = correct;

        nextCard();
    }

    const sendResponses = async() => {

        props.flashcardResults.clear();

        let sendableResponse = [];

        const colorFilter = getColorFilterFromFilters(props.filtersClass);

        props.practiceCardList.map((value) => {

            if (colorFilter) {
                colorFilter.addItemToIgnore(value.practiceCardId);
            }

            if (value.userCorrect === null || value.userCorrect === undefined) {
            } else {
                const response = new userResponse({
                    practiceCardId: value.practiceCardId,
                    correct: value.userCorrect
                });
                
                sendableResponse.push(
                    response
                );

                props.flashcardResults.set(value.practiceCardId, value.userCorrect)
            }
        });

        const returned = await practiceCardApi.responsePracticeCardsByPracticeCardId(sendableResponse);
        if (!returned) {
            dispatch(globalErrorsActions.addError({
                errorCode: convertError(NO_RESPONSE),
                customErrors: null
            }));
            return false;
        }

        if (returned.status === SUCCESS) {
            return true;
        }

        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: returned.status,
                updateLocally: null,
                customErrors: submitErrors
            }))
        );
        return false;
    }

    const toResults = async() => {
        toggleLoading(true);
        const responseSent = await sendResponses();
        toggleLoading(false);

        if (!responseSent) {
            return null;
        }

        // make aplicable filters invisible during practicing
        props.updateFiltersClass((filtersClass) => {

            filtersClass.filters.map((filter) => {
                if (filter.filterAttributeKey === "userCorrect") {
                    filter.visible = true;
                    filter.active = true;
                    filter.overridOnlyShowSelected = true;
                } else {
                    filter.visible = false;
                }
            });

            filtersClass.selectItemsPossible = false;
            return filtersClass;
        });

        // why this?
        props.updatePracticedCards(props.practiceCardList);

        props.updateView(RESULTS);
    }

    // const toOverview = () => {

    //     if (props.filtersClass.selectItemsPossible) {
    //         props.updatePracticeCardList(() => {
    //             const filteredlist = props.filtersClass.toggleOnlyShowSelectedItems(props.fullPracticeCardList);
    //             return filteredlist;
    //         });
    //     }

    //     props.updateFiltersClass((filtersClass) => {

    //         filtersClass.filters.map((filter) => {
    //             if (filter.key !== "userCorrect") {
    //                 filter.visible = true;
    //             } else {
    //                 filter.visible = false;
    //             }
    //         });

    //         filtersClass.selectingItemsPresentAsFilterOption = true;
    //         filtersClass.onlyShowSelectedItems = false;

    //         return filtersClass;
    //     });

    //     props.updateView(OVERVIEW);
    // }

    const sendResultsAndToOverview = async() => {
        
        toggleLoadingBack(true);
        const responseSent = await sendResponses();
        toggleLoadingBack(false);

        if (responseSent) {
            toOverview(props.updateFiltersClass, props.updateView);
        }
        
    }

    return (
        <div className="PracticingView">
            <div className="practicingMenu">
                <div 
                    className="practicingMenuButton cancelPractice"
                    onClick={() => toggleShowAlert((value) => !value)}    
                >
                    Annueren
                </div>
                
                <div className="practicingMenuIndex" >{
                    props.loading ? <Loader /> :
                    `${index + 1} / ${props.practiceCardList.length}`
                }</div>
                    
                <div 
                    className="practicingMenuButton toResults"
                    onClick={toResults}
                >{
                    loading ? <Loader />
                    :
                    "Naar resultaten"
                }</div>

            </div>
            
            {
                props.loading ? null :
                
                <>
                    <div 
                        className={`practicingCard ${front ? "practicingCardFront" : "practicingCardBack"}`}
                        onClick={() => toggleFront((value) => !value)}
                    >{
                        
                        !props.practiceCardList ? null : !props.practiceCardList[index] ? null : front ? 
                            
                            parse(
                                cleanText({
                                    textToClean: `${props.practiceCardList[index].frontText }`, 
                                    h1: true,
                                    h2: true,
                                    h3: true,
                                    h4: true,
                                    p: true,
                                    bold: true
                                })
                            )
                            
                            :

                            parse(
                                cleanText({
                                    textToClean: `${props.practiceCardList[index].backText}`, 
                                    h1: true,
                                    h2: true,
                                    h3: true,
                                    h4: true,
                                    p: true,
                                    bold: false
                                })
                            )
                            
                    }</div>

                    <div className="practicingOptions" >
                        <div className="pointersHolder">
                            {
                                index === 0 ? null :
                                
                                <Pointer
                                    direction={LEFT}
                                    onClick={() => formerCard()}
                                />
                            }
                        </div>

                        <div>
                            <FontAwesomeIcon 
                                icon={faCheckCircle} 
                                className="useCorrect"
                                onClick={() => sendUserResponse(true)}
                            />

                            <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                className="userIncorrect"
                                onClick={() => sendUserResponse(false)}
                            />
                        </div>
                        
                        <div className="pointersHolder toResults">{
                            index === props.practiceCardList.length-1 ?
                                <>{
                                    loading ? 
                                    <Loader />
                                    :
                                    <div 
                                        className="practicingMenuButton"
                                        onClick={() => nextCard()}
                                    >
                                        Laatste overslaan
                                    </div>
                                }</>
                            :
                                <Pointer
                                    direction={RIGHT}
                                    onClick={() => nextCard()}
                                />
                        }</div>
                    </div>
                </>
            }
            
            <LocalModal
                show={showAlert}
                toggleShow={toggleShowAlert}
                component={
                    <Alert
                        toggleShow={toggleShowAlert} 
                    
                        title="Reacties opslaan?"
                        description="Wil je je reacties tot nu toe opslaan voordat je naar de overzicht pagina gaat?"
                    
                        buttonClasses={[
                            new buttonClass({
                                title: "Opslaan",
                                buttonFunc: sendResultsAndToOverview,
                            }),
                            new buttonClass({
                                title: "Verwijderen",
                                buttonFunc: () => toOverview(props.updateFiltersClass, props.updateView),
                            }),
                            new buttonClass({
                                title: "Verder oefenen",
                                buttonFunc: () => toggleShowAlert(false),
                            })
                        ]}
                    
                    />
                }

                loading={loadingBack}
            />

            <Filters
                filtersClass={props.filtersClass}
                updateFiltersClass={props.updateFiltersClass}

                fullList={props.fullPracticeCardList}
                updateFullList={props.updateFullPracticeCardList}

                // might not need the list, just the update?
                list={props.practiceCardList}
                updateFilteredList={props.updatePracticeCardList}

                nonFilterFetchParams={props.nonFilterFetchParams}

                rerender={props.rerender}
            />

        </div>
    )
}

export default Practicing;
