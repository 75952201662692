
import React, { useState, useEffect } from 'react';

// styles
import "./DateTime.scss";

// classes
import errorClass from '../../../services/errors/classes/errorClass';

// elements
import Time from "../elements/Time/Time";
import SelectDate from "../elements/SelectDate/SelectDate";

// constants
import { SUCCESS } from '../../../services/errors/constants';

const DateTime = (props) => {

    // Dear future me, I am sorry for this shitshow.

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [resetTyped, toggleResetTyped] = useState(false);

    // Effect to initialize state from props
    useEffect(() => {
        const currentDateTime = props.data ? props.data[props.valueKey] : props.clientData.getCurrentValue(props.valueKey);
        const currentDate = currentDateTime instanceof Date ? currentDateTime.toISOString().split('T')[0] : null;
        const currentTime = currentDateTime instanceof Date ? currentDateTime.toTimeString().split(' ')[0].slice(0, 5) : null;
        if (currentDateTime) {
            setSelectedDate(currentDate);
            setSelectedTime(currentTime);
        }
    }, [props.data, props.clientData, props.valueKey]);

    const updateDate = (valueKey, newValue) => {
        setSelectedDate(newValue);
        if (selectedTime !== null) {
            const [year, month, day] = newValue.split('-').map(Number);
            const [hours, minutes] = selectedTime.split(':').map(Number);

            const newDateTime = new Date(year, month - 1, day, hours, minutes, 59);
            if (props.data) {
                props.data[valueKey] = newDateTime;
            } else if (props.clientData) {
                props.clientData.updateProperty(valueKey, newDateTime);
            }
        }

        if (props.toggleAtLeatPartlyFilled) {
            props.toggleAtLeatPartlyFilled(true);
        }
    };

    const updateTime = (valueKey, newValue) => {
        setSelectedTime(newValue);
        if (selectedDate !== null) {
            const [hours, minutes] = newValue.split(':').map(Number);
            const [year, month, day] = selectedDate.split('-').map(Number);

            const newDateTime = new Date(year, month - 1, day, hours, minutes, 59);

            if (props.data) {
                props.data[valueKey] = newDateTime;
            } else if (props.clientData) {
                props.clientData.updateProperty(valueKey, newDateTime);
            }
        }

        if (props.toggleAtLeatPartlyFilled) {
            props.toggleAtLeatPartlyFilled(true);
        }
    };

    const cancleFilled = () => {
        setSelectedDate(null);
        setSelectedTime(null);
        toggleResetTyped((value) => !value);

        if (props.data) {
            props.data[props.valueKey] = null;
        } else if (props.clientData) {
            props.clientData.updateProperty(props.valueKey, null);
        }

        props.toggleAtLeatPartlyFilled(false);

        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }

        if (props.updateWarning) {
            props.updateWarning(new errorClass({
                errorCode: SUCCESS
            }));
        }
    }

    return (
        <>{
            !props.data && !props.clientData ? null :
            <div className={props.styles?.view || "dateTimeView"}>
                {
                    props.title ?
                    <div className={props.styles?.title || "dateTimeTitle"}>{props.title}:</div>
                    : null
                }

                <div className="dateTimeInputAndErrorView" >
                    {
                        !props.errorClass ? null : props.errorClass.errorCode === 200 ? null :
                        <div className="CKTextshowError" >
                            {
                                props.errorClass.description
                            }
                        </div>
                    }
                    {
                        !props.warningClass ? null : props.warningClass.errorCode === 200 ? null :
                        <div className="CKTextshowWarning" >
                            {
                                props.warningClass.description
                            }
                        </div>
                    }
                    <div className="dateTimeInputView" >
                        <SelectDate
                            value={selectedDate}
                            valueKey={props.valueKey}
                            dataObject={props.data}
                            clientData={props.clientData}
                            customUpdateFunc={updateDate}
                            styles={
                                props.styles?.children?.time ||
                                {
                                    input: "dateTimeNumberInput"
                                }
                            }

                            resetTyped={resetTyped}

                            showError={props.errorClass ? props.errorClass.errorCode !== 200 : false}
                            updateError={props.updateError}

                            showWarning={props.warningClass ? props.warningClass.errorCode !== 200 : false}
                            updateWarning={props.updateWarning}
                        />

                        <Time
                            value={selectedTime}
                            valueKey={props.valueKey}
                            dataObject={props.data}
                            clientData={props.clientData}
                            customUpdateFunc={updateTime}
                            styles={
                                props.styles?.children?.time ||
                                {
                                    input: "dateTimeNumberInput"
                                }
                            }

                            resetTyped={resetTyped}

                            showError={props.errorClass ? props.errorClass.errorCode !== 200 : false}
                            updateError={props.updateError}

                            showWarning={props.warningClass ? props.warningClass.errorCode !== 200 : false}
                            updateWarning={props.updateWarning}
                        />

                        {
                            props.notRequired && props.atLeatPartlyFilled ? <div onClick={() => cancleFilled()} className="dateTimeCancle" >Veld legen</div> : null
                        }
                    </div>
                </div>
            </div>
        }</>
    );
};

export default DateTime;
