
// constants
import { OVERVIEW } from "./constants";

export const toOverview = (updateFiltersClass, updateView, toggleLoading) => {

    updateFiltersClass((filtersClass) => {

        filtersClass.filters.map((filter) => {

            if (filter.filterAttributeKey === "color") {
                filter.clearItemsToIgnore();
            }

            if (filter.filterAttributeKey !== "userCorrect") {
                filter.visible = true;
            } else {
                filter.visible = false;
                filter.active = false;
                filter.overridOnlyShowSelected = false;
            }
        });

        filtersClass.selectingItemsPresentAsFilterOption = true;
        filtersClass.onlyShowSelectedItems = false;
        if (filtersClass.noSelectedItems() === false) {
            filtersClass.selectItemsPossible = true;
        }

        return filtersClass;
    });

    if (toggleLoading) {
        toggleLoading(true);
    }

    updateView(OVERVIEW);
}
