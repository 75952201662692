
import React, { useState } from 'react';

import parse from "html-react-parser";

// style
import "./Event.scss";

// networking
import eventsApi from "../../../../../../services/Networking/events";

// services
import cleanText from "../../../../../../services/cleanText";

// classes
import AddPracticeCardClass from "../../../AddPracticeCard/services/classes";

// components
import AlterContent from "../../../../../../components/AlterContent";
import LocalModal from "../../../../../../components/LocalModal";
import AddPracticeCard from "../../../../components/AddPracticeCard";

const Event = (props) => {

    const [editEvent, toggleEditEvent] = useState(false);

    return (
        <>{
            !props.data ? null :

            <div className="EventHolder">
                <div className="EventTitle" >{
                    parse(cleanText({
                        textToClean: props.data.title,
                        h1: true,
                        h2: true,
                        h3: true,
                        h4: true,
                        p: true,
                        bold: true,
                    }))
                }</div>

                <div className="EventDescipt">{
                    parse(cleanText({
                        textToClean: props.data.description,
                        h1: true,
                        h2: true,
                        h3: true,
                        h4: true,
                        p: true,
                        bold: false,
                    }))
                }</div>

                <AlterContent
                    // edit={{
                    //     onClick:toggleEditEvent
                    // }}
                    delete={{
                        api: eventsApi.deleteEventByEventId,
                        apiParams: props.data.eventId,
                    }}
                    rerender={props.triggerReRender}
                />

                <LocalModal
                    show={editEvent}
                    toggleShow={toggleEditEvent}
                    component={
                        <AddPracticeCard
                            toggleShow={toggleEditEvent}
                            existingData={new AddPracticeCardClass({
                                frontText: props.data.title,
                                backText: props.data.description,
                                practiceCardId: props.data.eventId
                            })}
                            type={4}
        
                            triggerReRender={props.triggerReRender}
                        />
                    }
                />
            </div>
        }</>
    )
}

export default Event;
