
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

// temp:
import infoObj from "../../classes/infoObj";
import { SUCCESS } from "../../errors/constants";
import { ASSIGNMENT_ADMIN, VIEW_CHECKED_SUBMITTIONS_ON_ADMIN_SHARED, VIEW_SUBMITTION_AFTER_CHECKED_SHARED } from "../../constants/assignmentsConstants";

const assignmentApi = {
    postAssignments,
    postUserToAssignment,
    postShowFeedback,
    putAssignments,
    getAssignments,
    getAssignedAssignments,
    getAssignment,
    getAssignmentStatistics,
    getAssignedExamByExamAndAssignmentUUID,
    deleteAssignment,
};

async function postAssignments( data ) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/assignments`, 
            data,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function postUserToAssignment( token ) {
    const auth = await authHeader();

    // const responseObj = await handleResponse(
    //     axios.post(
    //         `/assignments/adduser/${token}`,
    //         {
    //             headers: auth.payload
    //         }
    //     )
    // );
    // return responseObj;

    // Helper function to handle timeout
    const timeout = (ms) => new Promise((resolve) =>
        setTimeout(resolve, ms)
    );

    // Wait for the timeout to complete
    await timeout(3000);

    return new infoObj({
        status: SUCCESS,
        payload: {
            assignmentId: 1
        }
    });
}

async function postShowFeedback( assignmentUuid ) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.post(
            `/assignments/${assignmentUuid}/showFeedback`, 
            {},
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function putAssignments({ assignmentUuid, data }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/assignments/${assignmentUuid}`, 
            data,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getAssignments() {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/assignments/owned`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getAssignedAssignments() {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/assignments/assigned`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getAssignment( assignmentID ) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/assignments/${assignmentID}`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

    return new infoObj({
        status: SUCCESS,
        payload: {
            assignmentId: 1,
            title: "Oefententamen",
            description: "Dit is een oefententamen.",
            deadline: new Date(), // "23:59, 02/03/2023"

            userRole: ASSIGNMENT_ADMIN,

            colleagues: [
                {
                    id: 1,
                    name: "Henk"
                },
                {
                    id: 2,
                    name: "Joan"
                }
            ],
            addColleaguesToken: {
                active: true,
                token: "dsafhwea_dosa92fdal-dwjklfahse20-dfsad",
            },

            submittionsViewableToAssignees: false,

            // settings: ...,
            graders: [
                {title: "Docenten", selected: true},
                {title: "AI", selected: true},
                {title: "Studenten", selected: true},
            ],

            retries: 1000,

            showAssigneeNameDuringGrading: true,

            handinActiveExamsOnDeadline: true,
            
            whenAssigneesSeeSubmittedAssignments: VIEW_SUBMITTION_AFTER_CHECKED_SHARED,
            whenAssigneesSeeCheckAssignments: VIEW_CHECKED_SUBMITTIONS_ON_ADMIN_SHARED,

            description: "<p>Very important assignment!</p>",

            assignedItems: [
                {
                    type: 1,
                    title: "Oefententamen",
                    examId: 1,
                    uuid: "40df27aa-c84f-11ee-b1d1-000d3ab074e6",
                }
            ]
        }
    });
}

async function getAssignmentStatistics( assignmentID ) {
    const auth = await authHeader();

    // const responseObj = await handleResponse(
    //     axios.get(
    //         `/assignments/${assignmentID}`, 
    //         {
    //             headers: auth.payload
    //         }
    //     )
    // );
    // return responseObj;

    return new infoObj({
        status: SUCCESS,
        payload: {
            assignmentId: 1,
            students: 234,
            studentsStarted: 183, // amount of students that started with one or more of the related items
            studentsSubmitted: 39, // amount of students that submitted all related items
            amountOfLateSubmissions: 0,
        }
    });
}

async function getAssignedExamByExamAndAssignmentUUID({assignmentId, examId} = {}) {

    const auth = await authHeader();

    // const responseObj = await handleResponse(
    //     axios.get(
    //         `/assignments/${assignmentId}/exams/${examId}`,
    //         {
    //             headers: auth.payload
    //         }
    //     )
    // );
    // return responseObj;

    return new infoObj({
        status: SUCCESS,
        payload: {
            examId: 1,
            uuid: "40df27aa-c84f-11ee-b1d1-000d3ab074e6",
            category: 1,
            title: "Oefententamen",
            points: 30,
            state: 2,

            // students made exam
            studentsStarted: 183,
            submittedExams: 39,
            submittedQuestions: 390,
            consentToUseAI: 157,
            
            // checking exam stats
            questionsCheckByAI: 340,
            questionsCheckByTeacher: 56,
            questionsReviewedByTeacher: 157,
            questionsCheckedByStudents: 0,

            // student performance stats
            averagePoints: 23,

            // question stats
            questions: [
                {
                    questionId: 813,
                    category: 1,
                    points: 3,
                    title: "Question",
                    type: 1,

                    averagePoints: 2.3,
                    percentageOfStudentsZeroPoints: 23,
                    percentageOfStudentsFullPoints: 46
                },
            ],
        }
    });
}

async function deleteAssignment( assignmentUuid) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/assignments/${assignmentUuid}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

export default assignmentApi;
