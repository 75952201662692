

// standard objects
import errorClass from "../classes";

// constants
import { SUCCESS, UNAUTHORIZED, NOT_FOUND } from "../constants";

const logOutErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Je bent uitgelogd",
                description: "Je bent uitgelogd",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default logOutErrors;
