
class filterOptionClass {

    constructor({
        index,
        title,
        keyValue,
        selected = false
    } = {}) {
        this.index = index;
        this.title = title;        
        this.keyValue = keyValue;
        this.selected = selected;
    }
}

export default filterOptionClass;
