
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// style
import "./Close.scss";

const Close = (props) => {
    return (
        <FontAwesomeIcon 
            onClick={props.onClick}
            className={props.light ? "closeLight" : "closeItem"}
            icon={faTimesCircle}
        />
    )
}

export default Close;
