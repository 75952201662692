
import React, { useEffect, useState } from 'react';

// style
import "./ValidateMail.scss";

// networking
import authApi from "../../services/Networking/authentication";

// services
import networking from '../../services/handleNetworking/networking/networking';

// errors local
import validateMailErrors from "./services/errors/validateMailErrors";

// components
import Loader from '../../components/Loader/Loader';
import getTokenFromURL from '../../services/getTokenFromURL/getTokenFromURL';
import Redux from '../../components/Redux/Redux';

// constants
import { REDUX_ERRORS } from '../../components/Redux/services/constants';

const ValidateMail = (props) => {

    const [loading, toggleLoading] = useState(true);
    const [mailValidated, toggleMailValidated] = useState(false);

    // errors
    const [fetchValidationError, updateFetchValidationError] = useState(null);

    const token = getTokenFromURL(window.location.href) ? getTokenFromURL(window.location.href).token[0] : null;
    const mail = getTokenFromURL(window.location.href) ? getTokenFromURL(window.location.href).email[0] : null;

    const validateMail = async() => {

        const validationReturned = await networking({

            // updateContent: null,

            // toggleLoading: toggleLoading,

            accessableToAllUsers: true,
            // logOut:logOut,
            
            api: authApi.confirmMailAdress,
            apiParams: {
                token: token,
                mailadress: mail
            },
        
            updateError: updateFetchValidationError,
            customErrors: validateMailErrors,

            // history: history // maybe not?

        });

        if (validationReturned.status === 200) {
            toggleMailValidated(true);
        } else {
            toggleMailValidated(false);
        }

        toggleLoading(false);

    }

    useEffect(() => {

        validateMail();

    }, []);

    return (

        <div
            className="validateMailView"
        >
            <>{
            loading ?

                <>
                    Je mail adress wordt gevalideerd, even wachten...
                    <Loader />
                </>

                :

                mailValidated ?

                    "Je mail adress is geconfirmed 🥳"

                    :

                    "Je mail adress is niet gevalideerd, er is iets fout gegaan! 🥺"
            }</>

            <Redux
            
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchValidationError}
            
            />

        </div>

    )

}

export default ValidateMail;
