
// services
import getScatterQuestionNumber from "./getScatterQuestionNumber";

export const processStdData = (sentData) => {
    let scatterData = [];
    let avgData = [];

    const valuesSeen = new Set();
    
    sentData.forEach((data, index) => {
        const questionNumber = index + 1;

        data.studentPercentages.forEach((studentPercentage) => {
            const newQuestionsNumber = getScatterQuestionNumber(valuesSeen, sentData.length, questionNumber, studentPercentage);
            
            scatterData.push({ question: newQuestionsNumber, y: studentPercentage});
            valuesSeen.add(`${newQuestionsNumber}_${studentPercentage}`);
        });

        avgData.push({ 
            question: questionNumber, 
            std: [data.avgPercentage - data.stdDev, data.avgPercentage + data.stdDev],
            errorBarY: [data.stdDev, data.stdDev],
            low: data.avgPercentage - data.stdDev,
            high: data.avgPercentage + data.stdDev,
            avgPercentage: data.avgPercentage,
            maxPoints: 100,
            missedPoints: 100 - data.avgPercentage
        });
    });
    return { scatterData, avgData };
}

export const processQuartilePercentageData = (sentData) => {
    let scatterData = [];
    let kwartileData = [];

    const valuesSeen = new Set();
    
    sentData.forEach((data, index) => {
        const questionNumber = index + 1;

        data.studentPercentages.forEach((studentPercentage) => {
            const newQuestionsNumber = getScatterQuestionNumber(valuesSeen, sentData.length, questionNumber, studentPercentage);
            
            scatterData.push({ question: newQuestionsNumber, y: studentPercentage});
            valuesSeen.add(`${newQuestionsNumber}_${studentPercentage}`);
        });

        kwartileData.push({ 
            question: questionNumber, 
            kwartiles: [Math.min(data.q1, data.q3), Math.max(data.q1, data.q3)],
            errorBarY: [data.median - Math.min(data.q1, data.q3), Math.max(data.q1, data.q3) - data.median],
            q1: Math.min(data.q1, data.q3),
            q3: Math.max(data.q1, data.q3),
            median: data.median,
            maxPoints: 100,
            missedPoints: 100 - data.median
        });
    });
    return { scatterData, kwartileData };
}

export const processQuartilePointsData = (sentData) => {
    let scatterData = [];
    let kwartileData = [];

    const valuesSeen = new Set();

    let heighestPoints = 0;
    
    sentData.forEach((data, index) => {
        const questionNumber = index + 1;

        data?.studentPoints.forEach((studentPercentage) => {
            const newQuestionsNumber = getScatterQuestionNumber(valuesSeen, sentData.length, questionNumber, studentPercentage);
            
            scatterData.push({ question: newQuestionsNumber, y: studentPercentage});
            valuesSeen.add(`${newQuestionsNumber}_${studentPercentage}`);
        });

        kwartileData.push({ 
            question: questionNumber, 
            kwartiles: [Math.min(data.q1, data.q3), Math.max(data.q1, data.q3)],
            errorBarY: [data.median - Math.min(data.q1, data.q3), Math.max(data.q1, data.q3) - data.median],
            q1: Math.min(data.q1, data.q3),
            q3: Math.max(data.q1, data.q3),
            median: data.median,
            maxPoints: data.maxPoints,
            missedPoints: data.maxPoints - data.median
        });

        if (data.maxPoints > heighestPoints) {
            heighestPoints = data.maxPoints;
        }
    });
    return { scatterData, kwartileData, heighestPoints };
}

export const processQuartilePointsForQuestions = (newGraphData) => {

    const quartilePointsPerQuestion = newGraphData?.map((questionData, index) => {
        const maxPoints = questionData.maxPoints;
        const studentPoints = questionData.studentPoints;

        // Initialize an array of objects for each point value
        const pointCounts = Array.from({ length: maxPoints + 1 }, (_, index) => ({
            points: index,
            count: 0,
            maxPoints: maxPoints,
        }));

        // Count occurrences of each point value
        studentPoints.forEach(point => {
            const roundedPoint = Math.round(point);

            if (roundedPoint >= 0 && roundedPoint <= maxPoints) {
                pointCounts[roundedPoint].count++;
            }
        });
        return {
            questionNumber: index+1,
            stats: pointCounts
        };
    });

    return quartilePointsPerQuestion;
}
