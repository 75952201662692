
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

// style
import "./Checked.scss";

const Checked = (props) => {

    return (
        <>{
            props.checked ?
            <FontAwesomeIcon 
                onClick={props.onClick}
                className={props.customStyle ? "" : "Checked"}
                icon={faCheckCircle}
            />
            :
            <FontAwesomeIcon 
                onClick={props.onClick}
                className={props.customStyle ? "" : "Checked"}
                icon={faCircle}
            />
        }</>
    )
}

export default Checked;
