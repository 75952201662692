
import React, { useState, useEffect } from 'react';
import parse from "html-react-parser";

// styles
import "./Description.scss";

// components
import InfoItem from "../../../../../../components/InfoItem/InfoItem";

// classes
import errorClass from '../../../../../../services/errors/classes/errorClass';

// constants
import { SUCCESS } from '../../../../../../services/errors/constants';
import CkTextInput from '../../../../../../components/input/CkText/CkText';

const Description = (props) => {

    // errors
    const [descriptionWarning, updateDescriptionWarning] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    return (
        <div
            className="assignmentDescriptionView"
        >
            <div
                className="assignmentOverviewTitle"
            >
                Opdracht beschrijving
            </div>

            {
                props.editingAssignment ? 

                <CkTextInput
                        
                    valueKey={"info"}
                    clientData={props.assignment}

                    warningClass={descriptionWarning}
                    updateWarning={updateDescriptionWarning}
                />
                :
                props.assignment?.getCurrentValue("info") ? 
                <div
                    className="assignmentDescription"
                >{
                    parse(props.assignment?.getCurrentValue("info"))
                }</div>
                :
                <div
                    className="assignmentNoDescription"
                >{
                    props.assignment?.getCurrentValue("isAdmin") === true ?
                    "Nog geen beschrijving ingevuld"
                    :
                    "Geen beschrijving beschikbaar"
                }</div>
            }
        </div>
    )
}

export default Description;
