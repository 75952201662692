
// style
import "./Alert.scss";

// local components
import Title from "./components/Title";
import Description from "./components/Description";
import Button from "./components/Button";

// components
import List from "../List";
import Loader from "../Loader"

const Alert = (props) => {

    const renderButton = (i, data) => {
        return (
            <Button
                key={i}
                data={data}
            />
        )
    }

    return (
        <div className="Alert" >
            <Title
                title={props.title}
            />

            <Description
                description={props.description}
            />

            <div className="AlertButtons">{
                props.loading ? 

                <Loader />

                :
                
                <List
                    items={props.buttonClasses}
                    renderItem={renderButton}
                />
            }</div>

        </div>
    );

}

export default Alert;
