
function List(props) {
    
    if (!props.items) {

        // handel error!

        return null;
    }

    const list = () => {
        const items = props.items.map((itemData, i) => {
            if (itemData === undefined || itemData === null) {
                
                // handel error!

            } else {
                return (
                    props.renderItem(
                        i,
                        itemData
                    )
                )
            }
        })

        return items;
    }

    return (
        <>{
            list()
        }</>
    )
}

export default List;
