
// networking
import takenExamsApi from "../../../../../../services/Networking/takenExams";

// local services
import getTakenExamTitles from "../../../../services/getTakenExamTitles";

const fetchExamOrder = async({
    examId,

    handleApiReturn,

    updateOrder,
} = {}) => {

    if (!examId || !handleApiReturn || !updateOrder) {
        return null;
    }

    const returned = await takenExamsApi.getTakenExamOrdersByExamId(examId);
    const fetchSuccessfull = await handleApiReturn(returned);

    if (fetchSuccessfull) {
        const takenExams = returned.payload;

        updateOrder(() => {
            return getTakenExamTitles({takenExams: takenExams});
        });

        return returned.payload;
    }

    return false;
}

export default fetchExamOrder;
