
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import examsApi from "../../../../services/Networking/exams";

// services
import convertError from "../../../../services/errors/convertError";
import access from "../../../../services/access/accessCheck";
import processExams from './services/processExams';

// style
import "./Exams.scss";

// components
import Add from "../../../../elements/Add";
import Loader from '../../../../components/Loader';
import LocalModal from '../../../../components/LocalModal';
import ColumnList from "../../../../components/ColumnList";
import AddExam from '../../../../components/AddExam/AddExam';

// local components
import Exam from "./components/Exam";
import ExamInfo from "./components/ExamInfo";

// constants
import { SUCCESS, NO_RESPONSE } from "../../../../services/errors/constants";
import { VIEW_ACCESS, USE_ACCESS, EDITOR_ACCESS } from "../../../../services/access/contentAccess/contentAccessConstants";
import { EDITOR } from "../../../../services/access/constants";
import { ALL, DRAFT, PUBLISHED } from '../../../../services/constants/stateConstants';

const Exams = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);
    
    // access - implemented to return Editors access for editors accounts and anyone else has view access
    // still implement: for the content itself later & the use_access access type!
    const [userAccess, updateUserAccess] = useState(!user ? VIEW_ACCESS : !user.user ? VIEW_ACCESS : access({
        accessLevel: EDITOR,
        userRoles: user.user.roles
    }) ? EDITOR_ACCESS : VIEW_ACCESS);
    useEffect(() => {
        // update page access if user changes
        updateUserAccess(!user ? VIEW_ACCESS : !user.user ? VIEW_ACCESS : access({
            accessLevel: EDITOR,
            userRoles: user.user.roles
        }) ? EDITOR_ACCESS : VIEW_ACCESS);
    
    }, [user]);

    // state
    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(true);
    const [addExam, toggleAddExam] = useState(false);

    // data
    const [listExams, updateListExams] = useState([]);

    const fetchExams = async() => {
        
        const returned = await examsApi.getExamsBySubTopicId({
            subTopicId: subTopicId, 
            state: !user ? PUBLISHED : !user.user ? PUBLISHED : access({
                accessLevel: EDITOR,
                userRoles: user.user.roles
            }) ? ALL : PUBLISHED 
        });
        
        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            const processedExams = processExams(returned.payload, !user ? false : !user.user ? false : access({
                accessLevel: EDITOR,
                userRoles: user.user.roles
            }));
            updateListExams(processedExams);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);
    }

    useEffect(() => {

        toggleLoading(true);

        fetchExams();
    
    }, [user, rerender]);

    const renderExam = (i, examData, dataSelected) => {
        if (!examData) {
            return null;
        }
        const dataKey = examData.draftExam ? "draftExam" : "publishedExam";
        return (
            <Exam
                key={i}
                data={examData}
                dataKey={dataKey}
                selected={dataSelected && dataSelected[dataKey] ? dataSelected[dataKey].uuid === examData[dataKey].uuid : false}
                
                userAccess={userAccess}
            />
        )
    }

    const renderExamInfo = (examData, updateDataSelected) => {
        if (!examData) {
            return;
        }
        const dataKey = examData.draftExam ? "draftExam" : "publishedExam";
        return (
            <ExamInfo
                key={examData[dataKey].uuid}
                data={examData}
                updateDataSelected={updateDataSelected}

                userAccess={userAccess}

                triggerReRender={triggerReRender}
            />
        )
    }

    return (
        <div className="examsView">
            <div className="examsTitle">
                Oefentoetsen
                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: EDITOR,
                        userRoles: user.user.roles
                    }) ?
                    <div className="addItemInTab">
                        <Add
                            onClick={() => toggleAddExam(true)}
                        />
                    </div> : null
                }
                <LocalModal
                    show={addExam}
                    toggleShow={toggleAddExam}
                    component={
                        <AddExam
                            type={1}
                            toggleShow={toggleAddExam}

                            subTopicType={props.subTopicClass ? props.subTopicClass.type : null}
                    
                            triggerReRender={triggerReRender}
                        />
                    }
                />
            </div>

            {
                loading && listExams.length === 0 ? <Loader /> :
                
                !listExams ? null :
                listExams.length === 0 ?
                <div className="noExamsWarning" >
                    Wij hebben nog geen oefentoetsen voor dit hoofdstuk
                </div>
                :
                <ColumnList
                    items={listExams}
                    renderItem={renderExam}

                    renderInfo={renderExamInfo}
                />
            }
        </div>
    )
}

export default Exams;
