
import React, { useState } from 'react';

// classes
import errorClass from "../../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

// style
import "./Text.scss";
import "../assets/style.scss";

const TextField = (props) => {
    // we're getting a warning because of this but it works
    const [typed, toggleTyped] = useState(false);

    const onChange = (newValue) => {

        const newText = newValue.target.value;

        if (props.customUpdateFunc) {
            props.customUpdateFunc(
                props.valueKey,
                newText
            );
        } else if (props.dataObject) {
            props.dataObject[props.valueKey] = newText;
        } else if (props.clientData) {
            props.clientData.updateProperty(props.valueKey, newText);
        }

        toggleTyped(true);

        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }
    }

    return (
        <input 
            type="text" 
            className={props.showError ? "textError" : null}

            name={props.valueKey}
            disabled={props.disabled}
            value={typed ? undefined : props.value} 
            onChange={onChange}
        />
    )

}

export default TextField;
