
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// style
import "./Pointer.scss";

// constants
import {UP, DOWN, LEFT, RIGHT} from "./services/constants";

const Pointer = (props) => {

    const returnDirection = () => {
        switch (props.direction) {
            case UP:
                return faChevronUp;

            case DOWN:
                return faChevronDown;

            case LEFT:
                return faChevronLeft;

            case RIGHT:
                return faChevronRight;
            default:
                break;
        }
    }

    return (
        <FontAwesomeIcon 
            onClick={props.onClick}
            className={props.cssOff ? "" : "Pointer"}
            icon={returnDirection()}
        />
    )
}

export default Pointer;
