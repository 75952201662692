
// classes local
import questionPut from "../../../components/Question/services/classes/questionPut";
import answerHubClass from "../answerHubClass";

// services
import clean from "../../../../../services/uploadImage/services/clean";

// constants
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../../services/constants/typeConstants";
import { SUCCESS } from "../../../../../services/errors/constants";

class questionHubClass {

    constructor({
        number = null,
        questionId = null,
        
        // sent
        sentQuestion = null,

        // add question || add answer to question
        questionPost = null,
        
        // edit questions
        questionPut = null,
        
        // taken exams
        takenQuestionPost = null,
        takenQuestionPut = null,

        // delete questions
        questionRemove = null,
        questionDelete = null,

        // taking exam
        answerId = [],
        studentAnswer = null,

        // answers
        answers = [new answerHubClass({
            number: 0
        })],

        // feedback
        feedback = [],

        // might del this?!
        // only used to remember correct answers on switch away from multiple correct options
        correctAnswers = [],

    } = {}) {

        this.number = number;
        this.questionId = questionId;

        this.sentQuestion = sentQuestion;

        this.questionPost = questionPost;

        this.questionPut = questionPut;

        this.questionRemove = questionRemove;
        this.questionDelete = questionDelete;

        this.takenQuestionPost = takenQuestionPost;
        this.takenQuestionPut = takenQuestionPut;

        this.answerId = sentQuestion ? sentQuestion.type === ASSAY_QUESTION ? sentQuestion.answers ? sentQuestion.answers[0] ? [sentQuestion.answers[0].answerId] : answerId : answerId : answerId : answerId;
        this.studentAnswer = studentAnswer;

        this.answers = answers;
        this.feedback = feedback;

        this.correctAnswers = correctAnswers;

    }

    // add update order numb - to make sure that order nub is changed everywhere in question hub

    /* 
        Get values on question
    */
    // getValue
    getCurrentValue = (key) => {
        if (this.sentQuestion) {
            if (this.questionPut) {
                return this.questionPut[key];
            }
            return this.sentQuestion[key]
        } else if (this.questionPost) {
            return this.questionPost[key]
        }
        return null;
    }

    getQuestion = () => {
        if (!this.sentQuestion) {
            return this.questionPost;
        }

        if (this.questionPut) {
            return this.questionPut;
        }

        if (this.sentQuestion) {
            return this.sentQuestion;
        }
        
        return null

    }

    // gets answers for question
    getAnswers = () => {

        // make sure there is a question to get
        if (this.answers) {

            // implement ordering
            // allAnswers.sort((a, b) => a.number - b.number)

            // return the answers
            return this.answers;

        }

        // there was no answer to get
        return null;

    }

    // get answers not deleted, reference is lost so return can probabily not be used to update values of the answers
    getNotDelAnswers = () => {

        let answers = this.getAnswers();

        if (!this.getAnswers()) {
            return null;
        }

        return answers.filter((answer) => !answer.answerDeleted());

    }

    getImageForSave = () => {
        const image = this.getCurrentValue("image");
        if (!image) {
            return "";
        }

        const cleanImage = clean(image);
        if (cleanImage.status === SUCCESS) {
            return cleanImage.payload;
        }
        return image;
    }

    oneCorrect = () => {
        
        const correctAnswers = this.getCorrectAnswers();
        if (!correctAnswers) {
            return null;
        }

        // this was < 2! Not sure why?! Let's see if shit breaks
        if (correctAnswers.length === 1) {
            return true;
        }
        return false;

    }

    /*
        Get values on question
    */
    // update property
    updateProperty = (key, newValue) => {

        // check if question is not deleted
        if (this.getQuestion() === null) {
            return null;
        }

        const valueToCompair = this.getQuestion()[key]

        // make sure value is changed & than only add put if it doesn't exist
        if (!this.questionPut && !this.questionPost && valueToCompair !== newValue) {
            this.initQuestionPut();
        }

        // make sure this is not triggered if the type isn't changed
        if (valueToCompair !== newValue) {
            this.getQuestion()[key] = newValue;
        }

        return newValue;

    }

    /* 
        Edit values on question
    */
    // remove question
    removeQuestion = () => {

        // question is already removed (should not happen)
        if (this.questionRemove !== null) {
            return null;
        }
        
        // question is already deleted (should not happen)
        if (this.questionDelete !== null) {
            return null;
        }

        // if there is no sent ther must be a put
        if (!this.sentQuestion) {
            
            // give questionRemove the value of post
            this.questionRemove = this.questionPost

            return true;
        }

        if (this.questionPut) {
            
            // give questionRemove the value of put
            this.questionRemove = this.questionPut

            return true;
        }

        if (this.sentQuestion) {

            // give questionRemove the value of put
            this.questionRemove = this.sentQuestion

            return true;

        }

        // should not be able to get here
        return null;

    }

    // delete question
    deleteQuestion = () => {

        // question is already removed (should not happen)
        if (this.questionRemove !== null) {
            return null;
        }
        
        // question is already deleted (should not happen)
        if (this.questionDelete !== null) {
            return null;
        }

        // if there is no sent ther must be a put
        if (!this.sentQuestion) {
            
            // give questionRemove the value of post
            this.questionDelete = this.questionPost;

            return true;
        }

        if (this.questionPut) {
            
            // give questionRemove the value of put
            this.questionDelete = this.questionPut;

            return true;
        }

        if (this.sentQuestion) {

            // give questionRemove the value of put
            this.questionDelete = this.sentQuestion;

            return true;
        }
        
    }

    /*
        Get values on answers
    */
    // get points
    // Really need updating! Will not return correct points!
    questionPoints = () => {

        let totalPoints;

        if (!this.answers) {
            return 0;
        }

        totalPoints = this.getPointsFromAnswers();

        if (totalPoints !== null) {
            return totalPoints;
        }

        // something went wrong
        return null;

        // return this.sentQuestion.points;

    }

    // can probabily be fused with above!!! - questionPoints!
    getPointsFromAnswers = () => {
        let totalPoints = null;
        this.answers.map((answer) => {

            // make sure answer is valid
            if (answer) {
                
                // make sure answer not deleted & answer points value is valid
                if (!answer.answerDeleted() & answer.getCurrentValue("points") !== undefined) {

                    // add points if points is not null
                    if (totalPoints) {
                        totalPoints += answer.getCurrentValue("points");
                    
                        // init adding points
                    } else {
                        totalPoints = answer.getCurrentValue("points");
                    }
                }
            }
        });

        return totalPoints;
    }

    addAllAnswerPoints = (answers) => {
        if (!answers) {
            return null;
        }
        let totalPoints = 0;

        answers.map((answer) => {
            if (answer.sentAnswer) {
                if (answer.correct) {
                    totalPoints += answer.points;
                }
            }
        });

        return totalPoints;
    }

    // correct answers
    getCorrectAnswers = () => {
        // list to save correct answers
        let correctAnswers = [];

        // get answers
        const questionAnswers = this.getAnswers();

        // loop through answers
        questionAnswers.map((answer) => {
            // check if answer is correct
            if (!answer.answerDeleted() & answer.getCurrentValue("correct") === true) {
                // add correct answer to list
                correctAnswers = [...correctAnswers, answer];
            }
        });

        // return list of correct answers
        return correctAnswers;

    }

    // Returns true if multiple correct answers - NOT TESTED!
    multipleCorrectAnswers = () => {

        if (this.getCorrectAnswers()) {
            if (this.getCorrectAnswers().length > 1) {
                return true;
            }

            return false;

        }

        // something weard happend, invalid correct answers
        return null;

    }

    // do I need this? - will have to be updated for modern points
    initPutCorrectAnswer = () => {

        // ensure valid answers
        if (!this.answers) {
            return null;
        }

        if (this.getCurrentValue("type") === ASSAY_QUESTION) {
            // ensure valid answer
            if (!this.answers[0]) {
                this.answers[0].initAnswerPut();
            }
        }

        // do this for all correct answers!!! given multiple choice option!!

        const correctAnswer = this.answers.find((answer) => answer.answerCorrect());
        if (!correctAnswer) {
            return false;
        }

    }

    /*
        init editing
    */
    // generate questionPost
    initQuestionPut = () => {
        if (!this.questionPut ) {
            this.questionPut = new questionPut({
                title: this.sentQuestion.title,
                type: this.sentQuestion.type,
                image: this.sentQuestion.image, //this.sentQuestion.image
                category: this.sentQuestion.category,
                scoredPoints: this.sentQuestion.scoredPoints,
                totalPoints: this.sentQuestion.totalPoints,
            });
        }
    }

    /* 
        update values
    */
    // update answers list based on type change
    updateAnswersToType = () => {

        // check if put is initated & there is a sent question (so its not a new question)
        if (!this.questionPut && this.sentQuestion) {
            
            // init put Q
            this.initQuestionPut();

        }

        // ensure there is a valid question
        if (!this.questionPut & !this.questionPost) {
            // both Put and Post are not valid
            return null;
        }

        // ensure valid return getQuestion
        if (!this.getQuestion()) {
            return null;
        }

        // ensure answers valid
        if (!this.getNotDelAnswers()) {
            return null;
        }
        
        // implement by changing values inside answers!
        if (this.getQuestion().type === ASSAY_QUESTION) {

            // no answers present
            if (this.getNotDelAnswers().length === 0) {

                // create one answer
                this.answers = [new answerHubClass({number: 0})];
            
            // one answer present
            } else if (this.getNotDelAnswers().length === 1) {

                // loop though answers and make sure the one not deleted answer is correct
                this.answers.forEach((answer) => {
                    if (!answer.answerDeleted()) {

                        if (answer.getCurrentValue("correct") === false) {
                            // make the one answer correct and give it one point
                            answer.updateProperty("correct", true);
                            answer.updateProperty("points", 1);
                        }
        
                    }
                });
            
            // multiple answers present
            } else {

                // get all correct answers
                const correctAnswers = this.getCorrectAnswers();
                if (!correctAnswers) {
                    return null;
                }

                // if there are no correct answers
                if (correctAnswers.length === 0) {

                    // delete all answers
                    this.answers.forEach((answer) => {
                        answer.deleteAnswer();
                    });

                    // create new new answer
                    this.answers = [
                        ...this.answers, 
                        new answerHubClass({number: this.answers[this.answers.length - 1].number + 1})
                    ];
                
                // there is a correct answer
                } else {

                    // keep only the first correct answer delete the rest
                    let assayAnswerSelected = false;
                    this.answers.forEach((answer) => {
                        
                        // only do something if answer not deleted
                        if (!answer.answerDeleted()) {
                            
                            // select this answer as assay answer
                            // if no assay answer selected and answer is correct
                            if (!assayAnswerSelected && answer.answerCorrect()) {
                                assayAnswerSelected = true;
                            } else {
                                // delete answer
                                answer.deleteAnswer();
                            }
                        }
                    });

                }

            }

            return true;

        } else if (this.getQuestion().type === MULTIPLE_CHOICE_QUESTION) {
        
            // no answers present
            if (this.answers.length === 0) {
            
                // create three answers
                this.answers = [
                    new answerHubClass({number: 0}), 
                    new answerHubClass({number: 1}), 
                    new answerHubClass({number: 2})
                ];

                // set first answer as correct
                this.answers[0].updateProperty("correct", true);
                this.answers[0].updateProperty("points", 1);
            
            // only one answer present
            } else if (this.answers.length === 1) {

                // add one answer
                this.answers = [
                    ...this.answers, 
                    new answerHubClass({number: this.answers[this.answers.length - 1].number + 1}),
                    new answerHubClass({number: this.answers[this.answers.length - 1].number + 2})
                ];
                
            } else {

                // make all deleted answers un deleted
                this.answers.map((answer) => answer.answerDelete = null);
            
            }
            
            return true;
        } else {
                        
            // delete all questions
            this.answers.forEach((answer) => {
                answer.deleteAnswer();
            });
        }

        // question type is neither multiple choice nor assay question
        return null;

    }

    updateTypeToAnswers = () => {

        if (this.getAnswers().length > 1) {
            // change type to multiple choice
            this.updateProperty("type", MULTIPLE_CHOICE_QUESTION);
        } else {
            // change type to assay answer
            this.updateProperty("type", ASSAY_QUESTION);
        }

    }

    addAnswer = () => {

        if (this.getAnswers()) {

            // get length of all answers del or noneDel
            const heighestNumb = this.getAnswers().length;

            // add new answer at indix of string length
            this.answers = [...this.answers, new answerHubClass({
                number: heighestNumb
            })];

            // make sure it becomes multiple choice if Q has more than 1 answer
            this.updateTypeToAnswers();

        }
    }

}

export default questionHubClass;
