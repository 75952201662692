
// react
import React, { useState, useEffect } from 'react';

// local elements
import CkTextField from "../elements/CkText";

// style
import "./CkText.scss"

const CkTextInput = (props) => {
    
    return (
        <>{
            !props.dataClass && !props.clientData ? null :
            
            <div className={props.useBaseStyle ? "CKTextInputBase" : "CKTextInput"} >
                {
                    !props.title ? null :
                    <div className="CKTextInputTitle" >
                        {props.title}
                    </div>
                }

                {
                    
                    !props.errorClass ? null : props.errorClass.errorCode === 200 ? null :
                    <div className="CKTextshowError" >
                        {
                            props.errorClass.description
                        }
                    </div>

                }

                {
                    
                    !props.warningClass ? null : props.warningClass.errorCode === 200 ? null :
                    <div className="CKTextshowWarning" >
                        {
                            props.warningClass.description
                        }
                    </div>

                }

                <CkTextField
                    config={props.config}
                    
                    value={props.dataClass ? props.dataClass[props.valueKey] : props.clientData.getCurrentValue(props.valueKey)}

                    valueKey={props.valueKey}
                    dataObject={props.dataClass}
                    clientData={props.clientData}

                    customUpdateFunc={props.customUpdateFunc}

                    showError={props.errorClass ? props.errorClass.errorCode !== 200 : false}
                    updateError={props.updateError}

                    showWarning={props.warningClass ? props.warningClass.errorCode !== 200 : false}
                    updateWarning={props.updateWarning}
                />
            </div>
        }</>
    )
}

export default CkTextInput;
