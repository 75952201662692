
const getColorFilterFromFilters = (filtersClass) => {
    const colorFilter = filtersClass.filters.filter((filter) => filter.filterAttributeKey === "color");
    if (colorFilter.length === 1) {
        return colorFilter[0];
    }
    return null;
}

export default getColorFilterFromFilters;
