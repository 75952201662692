
function buttonClass({
    title = "Begrepen",
    buttonFunc = null,
} = {}) {

    this.title = title;
    this.buttonFunc = buttonFunc;
}

export default buttonClass;
