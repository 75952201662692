
// styles
import "./SelectQuestions.scss";

// components
import BooleanSelect from "../../../../../../../../../../components/input/basicInput/booleanSelect/BooleanSelect";
import List from "../../../../../../../../../../components/List";

const SelectQuestions = (props) => {

    const selectAllClicked = () => {
        if (props.allItems?.length === props.selectedItems?.length) {
            props.updateSelectedItems([]);
            props.updatePageStatesWithItemsCount(0);
        } else {
            props.updateSelectedItems(props.allItems);
            props.updatePageStatesWithItemsCount(props.allItems?.length);
        }
    }

    const updateSelectedItem = (dataOfSelected, alreadySelected) => {
        if (props.selectOne) {
            props.updateSelectedItems([dataOfSelected]);
            props.updatePageStatesWithItemsCount(1);
        } else {
            props.updateSelectedItems((selectedItems) => {
                if (alreadySelected) {
                    return selectedItems.filter(item => item.questionNumber !== dataOfSelected?.questionNumber);
                } else {
                    const updatedItems = [...selectedItems, dataOfSelected];
                    return updatedItems.sort((a, b) => a.questionNumber - b.questionNumber);
                }
            });
            if (alreadySelected) {
                props.updatePageStatesWithItemsCount(props.selectedItems?.length - 1);
            } else {
                props.updatePageStatesWithItemsCount(props.selectedItems?.length + 1);
            }
        }
    }

    const renderItem = (i, data) => {
        const selected = props.selectedItems.some((item) => item?.questionNumber === data?.questionNumber);
        return (
            <BooleanSelect
                title={`Vraag: ${data?.questionNumber}`}
                selected={selected}
                toggleSelected={() => updateSelectedItem(data, selected)}

                styling={{
                    view: "SelectQuestionsViewBooleanSelectView"
                }}
            />
        )
    }

    return (
        <div className="SelectQuestionsView" >
            <div className="SelectQuestionsTitle" >
                Vragen Selecteren
            </div>
            <div className="SelectQuestionsListQuestionsView" >
                {
                    props.selectOne ? null :
                    <BooleanSelect
                        title={props.allItems?.length === props.selectedItems?.length ? "Alles Deselecteren" : "Alles" }
                        selected={props.allItems?.length === props.selectedItems?.length}
                        toggleSelected={selectAllClicked}

                        styling={{
                            view: "SelectQuestionsViewBooleanSelectView"
                        }}
                    />
                }

                <List
                    items={props.allItems}
                    renderItem={renderItem}
                />
            </div>
        </div>
    )
}

export default SelectQuestions;
