
// react
import React, { useState, useEffect } from 'react';

// components
import List from "../List";

// local components
import Tab from "./components/Tab";

// styles
import "./PageNav.scss";

// local services
import renderComponent from "./services/renderComp";

const PageNav = (props) => {

    const [selectedTab, updatedSelectedTab] = useState(props.initalTab || props.initalTab === 0 ? props.initalTab : 1);

    useEffect(() => {

        if (!(props.initalTab === undefined)) {
            // update value
            updatedSelectedTab(props.initalTab || props.initalTab === 0 ? props.initalTab : 1);
        }
    }, [props.initalTab]);

    const renderTab = (i, tabData) => {

        // if (props.tabs.length < selectedTab) {
        //     updatedSelectedTab(1);
        // }

        // check if selected        
        return (
            <Tab
                key={i}
                selected={tabData.id === selectedTab ? true : false}

                updateSelected={updatedSelectedTab}
                updatedCustomSelectedTab={props.updatedCustomSelectedTab}

                updateURL={props.updateURL}
                tabData={tabData}
            />
        )
    }

    return (
        <div className={`pagNavView ${props.tabsAlignRight ? "pageNavViewEnd" : "pageNavViewStart"}`} >
            {
                !props.tabs ? null :
                props.tabs.length > (props.showOne ? 0 : 1) ? 
                <>
                <div className={`tabs ${props.tabsAlignRight ? "pageNavViewEnd" : "pageNavViewStart"}`} >
                    <List
                        items={props.tabs}
                        renderItem={renderTab}
                    />
                </div>
                {
                    props.noLine ? null :
                    <hr className="lineTabs" />
                }
                </>

                : null
            }

            {
                props.tabs ? props.customRender ? null : 
                    renderComponent({
                        selectedTab: selectedTab,
                        tabs: props.tabs
                    }) : null
            }

        </div>
    )
}

export default PageNav;
