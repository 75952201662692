
import { useState } from "react";

// redux
import { useDispatch } from "react-redux";

// styles
import "./Delete.scss";

// services
import networking from "../../services/handleNetworking/networking/networking";
import convertError from "../../services/errors/convertError";
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";
import deleteErrors from "../AlterContent/services/errors/delete/delete";

// constants
import { NO_CONTENT, SUCCESS } from "../../services/errors/constants";

const Delete = (props) => {

    // redux
    const dispatch = useDispatch();
    
    const [loading, toggleLoading] = useState(false);
    const [initDelete, toggleInitDelete] = useState(false);

    const changeInitDeleteValue = (e, newValue) => {
        // make sure route is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        toggleInitDelete(newValue);
    }

    const deleteItem = async(e) => {
        // make sure route is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        toggleLoading(true);

        if (props.api) {
            // fetch books in subject
            const response = await networking({
                api: props.api,
                apiParams: props.id
            });

            // nothing returned - something weard happend
            if (!response) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (response.status === SUCCESS || response.status === NO_CONTENT) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: deleteErrors
                    }))
                );

                toggleLoading(false);

                if (props.triggerReRender) {
                    props.triggerReRender((value) => !value);
                }
                if (props.runOnSuccess) {
                    props.runOnSuccess();
                }
                
            } else {
                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: deleteErrors
                    }))
                );
            }
            toggleLoading(false);
        }
    }

    return (
        <div
            className="deleteView"
        >{
            initDelete ?
            <>
                <div
                    className="confirmDelete"
                    onClick={(e) => deleteItem(e)}
                >
                    Verwijder
                </div>
                <div
                    className="deleteButton deleteButtonMarginLeft"
                    onClick={(e) => changeInitDeleteValue(e, false)}
                >
                    Annuleren
                </div>
            </>
            :
            <div
                className="deleteButton"
                onClick={(e) => changeInitDeleteValue(e, true)}
            >
                Verwijder
            </div>
            
        }</div>
    )

}

export default Delete;
