
function sendableThemeClass({
    title = "",
    eventThemeId = 0,
    events = []
} = {}) {
    this.title = title;
    this.eventThemeId = eventThemeId;
    this.events = events;
}

export default sendableThemeClass;
