
// style
import "./LocalModal.scss";

// components
import Backdrop from "./components/Backdrop";

const LocalModal = (props) => {

    const toggleShow = (e) => {
        if (props.toggleShow) {
            props.toggleShow((value) => !value);
        }

        if (props.runOnToggle) {
            props.runOnToggle();
        }
    }

    return (
        <>

            {
                props.show ? 
                    <Backdrop toggleShow={toggleShow} />
                    : null
            }

            {
                props.show ? 
                <div className="modalHolder">
                    {
                        props.component
                    }
                </div>
                    
                    : null
            }

        </>
    )
}

export default LocalModal;
