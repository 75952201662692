
// classes
import { DRAFT, PUBLISHED } from "../../../../../services/constants/stateConstants";
import ExamVersions from "./classes/ExamVersions";

const processExams = (recievedExams, accessToDraft) => {
    const examMap = new Map();

    recievedExams.forEach(exam => {
        if (exam.state === PUBLISHED) {
            let examInstance = examMap.get(exam.examId) || new ExamVersions();
            examInstance.setPublished(exam);
            examMap.set(exam.examId, examInstance);
        } else if (exam.state === DRAFT && accessToDraft === true) {
            if (exam.parentId) {
                // If draft exam has a parent ID
                let examInstance = examMap.get(exam.parentId) || new ExamVersions();
                examInstance.setDraft(exam);
                examMap.set(exam.parentId, examInstance);
            } else {
                // If draft exam is standalone
                let examInstance = examMap.get(exam.examId) || new ExamVersions();
                examInstance.setDraft(exam);
                examMap.set(exam.examId, examInstance);
            }
        }
    });
    return Array.from(examMap.values());
}

export default processExams;
