
import { useState, useEffect } from "react";

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// styles
import "./Overview.scss";

// classes
import filtersClass from '../../../../../../../../components/Filters/Filter/services/classes/filtersClass';
import filterClass from '../../../../../../../../components/Filters/Filter/services/classes/filterClass';
import filterOptionClass from '../../../../../../../../components/Filters/Filter/services/classes/filterOption/filterOption';

// services
import assignedExamApi from '../../../../../../../../services/Networking/assignedExam/assignedExam';
import networking from '../../../../../../../../services/handleNetworking/networking/networking';

// utils
import toggleOverviewWidth from "../../../../utils/toggleOverviewWidth";
import selectedSplit from '../../../../utils/selectedSplit';

// components
import renderFilterOption from './components/FilterOption/FilterOption';
import renderFilter from './components/Filter/Filter';
import UnfoldedOverview from './components/UnfoldedOverview/UnfoldedOverview';
import TopBar from "./components/TopBar/TopBar";

// constants
import { OVERVIEW_EXPANDED, OVERVIEW_FOLDED, VIEW_EXAMS, VIEW_QUESTIONS } from "../../../../services/constants";
import { SUCCESS } from '../../../../../../../../services/errors/constants';

const Overview = (props) => {

    // routing
    let { assignmentID, examID } = useParams();

    // states
    const [loading, toggleLoading] = useState(true);

    // data
    const [fetchedItems, updateFetchedItems] = useState([]);
    const [items, updateItems] = useState([]);

    // errors
    const [getItemsError, updateGetItemsError] = useState(null);

    const fetchItems = async() => {
        if (selectedSplit(filters) === VIEW_EXAMS) {
            return await fetchExams();
        } else {
            return await fetchQuestions();
        }
    }

    const fetchExams = async() => {
        const returned = await networking({
            updateContent: updateFetchedItems,
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: assignedExamApi.getTakenExamsWithAssignmentId,
            apiParams: {
                assignmentUuid: assignmentID,
                examUuid: examID,
            },

            updateError: updateGetItemsError,
        });

        if (returned.status === SUCCESS) {
            return returned.payload;
        }
        return [];
    }

    const fetchQuestions = async() => {

        const returned = await networking({
            updateContent: updateFetchedItems,
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: assignedExamApi.getHandedInQuestionsWithAssignmentId,
            apiParams: 1,

            updateError: updateGetItemsError,
        });

        if (returned.status === SUCCESS) {
            return returned.payload;
        }
        return [];

    }

    const [filters, updateFilters] = useState(
        new filtersClass({

            itemIdKey: "practiceCardId",
            fetchItems: fetchItems,

            filters: [
                new filterClass({
                    
                    title: "Filteren op punten",
                    idFilter: 0,
                    filterAttributeKey: "Splits",

                    selectOne: true,
                    allOption: false,
                    allSelected: false,
    
                    renderFilterItem: renderFilterOption,

                    optionsTitles: [
                        new filterOptionClass({
                            title: "Per examen nakijken",
                            keyValue: VIEW_EXAMS,
                            selected: true,
                        }),
                        // new filterOptionClass({
                        //     title: "Per vraag nakijken",
                        //     keyValue: VIEW_QUESTIONS
                        // }),
                    ],

                    customUpdateFunc: true,

                }),
                new filterClass({
                    
                    title: "Filteren op punten",
                    idFilter: 1,
                    filterAttributeKey: "scoredPoints",

                    selectOne: true,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterOption,

                    optionsTitles: [
                        new filterOptionClass({
                            title: "0 punten",
                            keyValue: 0
                        }),
                    ],

                    customUpdateFunc: false,

                }),
                new filterClass({
    
                    idFilter: 2,
                    filterAttributeKey: "checkedByAI",

                    selectOne: true,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterOption,
                    
                    optionsTitles: [
                        new filterOptionClass({
                            title: "Geen AI feedback",
                            keyValue: false,
                        }),
                        new filterOptionClass({
                            title: "Met AI feedback",
                            keyValue: true
                        }),
                    ],
    
                    customUpdateFunc: false
                }),
                new filterClass({
    
                    idFilter: 3,
                    filterAttributeKey: "checkedByTeacher",

                    selectOne: true,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterOption,
                    
                    optionsTitles: [
                        new filterOptionClass({
                            title: "Geen docent feedback",
                            keyValue: false,
                        }),
                        new filterOptionClass({
                            title: "Met docent feedback",
                            keyValue: true,
                        }),
                    ],
    
                    customUpdateFunc: false
                }),
                new filterClass({
    
                    idFilter: 4,
                    filterAttributeKey: "validated",

                    selectOne: true,
                    allOption: true,
                    allSelected: true,
    
                    renderFilterItem: renderFilterOption,
                    
                    optionsTitles: [
                        new filterOptionClass({
                            title: "Bekeken door docent",
                            keyValue: true,
                        }),
                        new filterOptionClass({
                            title: "Niet bekeken door docent",
                            keyValue: false
                        }),
                    ],
    
                    customUpdateFunc: false
                }),
                // new filterClass({
    
                //     idFilter: 5,
                //     filterAttributeKey: "MissedDeadline",

                //     selectOne: true,
                //     allOption: true,
                //     allSelected: true,
    
                //     renderFilterItem: renderFilterOption,
                    
                //     optionsTitles: [
                //         new filterOptionClass({
                //             title: "Deadline gehaald",
                //             keyValue: 1,
                //         }),
                //         new filterOptionClass({
                //             title: "Deadline niet gehaald",
                //             keyValue: 2
                //         }),
                //     ],
    
                //     customUpdateFunc: false
                // }),
            ],
    
            // used inside Rilters.js to render filter
            renderFilter: renderFilter,

            selectingItemsPresentAsFilterOption: true,

        })
    );

    const directlyToCheckingClicked = (userId, order) => {
        props.updateOverviewWidth(OVERVIEW_FOLDED);
        props.updateSelectedExamParams({
            examUuid: examID,
            userId: userId,
            order: order
        });
        // updateSelectedItemId(id);
    }

    const changeOverviewWidth = () => {
        if (!props.selectedExamParams && items?.length > 0) {
            const firstExam = items[0];
            props.updateSelectedExamParams({
                examUuid: examID,
                userId: firstExam?.userId,
                order: firstExam?.order
            });
        }
        toggleOverviewWidth(props.updateOverviewWidth);
    }

    return (
        <div
            className={`assignedExamOverviewView ${props.overviewWidth === OVERVIEW_EXPANDED ? "assignedExamOverviewExpanded" : "assignedExamOverviewViewFolded" }`}
        >
            <TopBar
                loading={loading}
                toggleLoading={toggleLoading}

                assignmentUuid={assignmentID}
                assignment={props.assignment}
                updateAssignment={props.updateAssignment}

                overviewWidth={props.overviewWidth}
                changeOverviewWidth={changeOverviewWidth}
            />

            <UnfoldedOverview
                loading={loading}
                toggleLoading={toggleLoading}

                filters={filters}
                updateFilters={updateFilters}

                rerenderFetchedList={props.rerenderFetchedList}
                fetchedItems={fetchedItems}
                updateFetchedItems={updateFetchedItems}

                items={items}
                updateItems={updateItems}

                overviewWidth={props.overviewWidth}
                directlyToCheckingClicked={directlyToCheckingClicked}

                selectedExamParams={props.selectedExamParams}
            />
        </div>
    )
}

export default Overview;
