
// classes
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { NO_CONTENT, BAD_REQUEST, NOT_FOUND } from "../../../../../../../services/errors/constants";

const levelErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Geen school nevau ingevult",
                description: "Dit vlak moet ingevult zijn.",
                advice: null,
                moreInfo: null,
            });

        case BAD_REQUEST:

            return new errorClass({
                errorCode: errorCode,
                priority: 2,
                title: "EtAlia is op dit moment gemaakt voor bovenbouw HAVO en VWO",
                description: "EtAlia is op dit moment gemaakt voor bovenbouw HAVO en VWO. Je kan wel alvast een account aanmaken.",
                moreInfo: null,
            });

        default:
            return null;
    }
}

export default levelErrors;
