
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const valitateRole = (role) => {

    switch (role) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;

        case "null":
            return NO_CONTENT;

        case "Teacher":
            return BAD_REQUEST;

        // wrong characters
        // case !validate(firstName):
        //     return BAD_REQUEST;
        
        default:
            return SUCCESS;
    }
}

export default valitateRole;
