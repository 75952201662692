
function infoObj({
    payload = null,
    status
} = {}) {

    this.payload = payload;
    this.status = status;

}

export default infoObj;
