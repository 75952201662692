
// local constants
import { IMAGE } from "../../constants";

function sourceTypeClass({
    sourceType = IMAGE
} = {}) {

    this.sourceType = sourceType;

}

export default sourceTypeClass;
