
// standard objects
import CustomErrorsManager from "../../../../../../../services/errors/classes/CustomErrorsManager";
import errorClass from "../../../../../../../services/errors/classes/errorClass";

// constants
import { INTERNAL_ERROR, NO_CONTENT } from "../../../../../../../services/errors/constants";

const validationErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "",
                description: "De rode vlakjes in toets moeten allemaal ingevuld zijn voordat je de toets kan opslaan of publiceren.",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export const validationErrorsClass = new CustomErrorsManager({
    [NO_CONTENT]: {
        title: "De rode vlakken in toets moeten allemaal ingevuld zijn voordat je de toets kan opslaan of publiceren."
    }
});

export default validationErrors;
