
function CkEditorConfig({
    toolbar = [],
} = {}) {

    this.toolbar = toolbar;

}

export default CkEditorConfig;
