
// standard Objects
import infoObj from "../../../classes/infoObj";

// constants
import { NO_RESPONSE } from "../../../errors/constants";

function handleResponse(promise) {
    
    return promise.then((response) => {

        const responseObj = new infoObj({
            payload: response.data.data,
            status: response.status
        });

        return responseObj;

    }).catch((error) => {

        if (!error?.response) {
            // no internet connection
            const responseObj = new infoObj({
                payload: {},
                status: NO_RESPONSE
            });

            return responseObj;
        }

        const responseObj = new infoObj({
            payload: error.response.data,
            status: error.response.status
        })

        return responseObj;
    });
}

export default handleResponse;
