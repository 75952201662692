
import parse from "html-react-parser";

// services
import cleanText from "../../../../../../../../services/cleanText";

// style
import "./CardBack.scss";

const CardBack = (props) => {
    return (
        <div className="cardBackView">

            <div className="cardBack">{

                parse(
                    cleanText({
                        textToClean: `${props.backText}`,
                        h1: true,
                        h2: true,
                        h3: true,
                        h4: true,
                        p: true,
                        bold: false
                    })
                )

            }</div>

        </div>
    )
}

export default CardBack;
