
class examInfoSendableClass {

    constructor({
        title,
        category,
        state,
    } = {}) {

        this.title = title;        
        this.category = category;
        this.state = state;

    }
}

export default examInfoSendableClass;
