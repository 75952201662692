
// constants
import { OVERVIEW_EXPANDED, OVERVIEW_FOLDED } from "../services/constants"

const toggleOverviewWidth = (updateOverviewWidth) => {
    updateOverviewWidth((value) => {
        if (value === OVERVIEW_FOLDED) {
            return OVERVIEW_EXPANDED;
        }
        return OVERVIEW_FOLDED;
    });
}

export default toggleOverviewWidth;
