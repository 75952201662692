
const preventParentActions = (e) => {
    if (!e) e = window.event;

    if (e) {
        if (e.preventDefault) e.preventDefault();
        if (e.stopPropagation) e.stopPropagation();
        e.cancelBubble = true;
    }
};


export default preventParentActions;
