
function userInfoClass({

    firstName = "",
    lastName = "",
    email = "",
    schoolName = "",
    schoolYear = "",
    schoolLevel = "",
    
} = {}) {

    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.schoolName = schoolName;
    this.schoolYear = schoolYear;
    this.schoolLevel = schoolLevel.toString();

}

export default userInfoClass;