
import { useState, useEffect } from "react";

// styles
import "./QuestionsStatistics.scss";
import "../../../../../../../../assets/universalStyles.scss";

// services
import calculatePageCount from "../../../../../../../../components/PageListNavigator/services/calculatePageCount";
import calculateItemOnPageIndexRange from "../../../../../../../../components/PageListNavigator/services/calculateItemOnPageIndexRange";
import calculateNewCurrentPageIndex from "../../../../../../../../components/PageListNavigator/services/calculateNewCurrentPageIndex";

// components
import BooleanSelect from "../../../../../../../../components/input/basicInput/booleanSelect/BooleanSelect";
import NumberInput from "../../../../../../../../components/input/number/Number";
import PageListNavigator from "../../../../../../../../components/PageListNavigator/PageListNavigator";
import List from "../../../../../../../../components/List";
import QuestionStatistics from "./components/QuestionStatistics/QuestionStatistics";
import SelectQuestions from "./components/SelectQuestions/SelectQuestions";

const QuestionsStatistics = (props) => {

    const [selectedItems, updateSelectedItems] = useState([]);

    const [currentPageIndex, updateCurrentPageIndex] = useState(0);
    const [firstItemOnPageIndex, updateFirstItemOnPageIndex] = useState(0);
    const [lastItemOnPageIndex, updateLastItemOnPageIndex] = useState(0);

    const [amountOfPages, updateAmoutOfPages] = useState(1);
    const [selectOneQuestion, toggleSelectOneQuestion] = useState(false);
    const [amountOfQuestionsOnOnePage, updateAmountOfQuestionsOnOnePage] = useState(1);

    const changeSelectOneQuestion = (newValue) => {
        if (selectOneQuestion) {
            toggleSelectOneQuestion(false);
        } else {
            const firstItem = selectedItems?.length > 0 ? selectedItems[0] : props.quartilePointsQuestions.length > 0 ? props.quartilePointsQuestions[0] : null;
            updateSelectedItems(firstItem ? [firstItem] : []);
            updatePageStatesWithItemsCount(firstItem ? 1 : 0);
            toggleSelectOneQuestion(true);
        }
    }

    const updateQuestionsPerPage = (newAmountOfQuestionsOnOnePage) => {
        if (newAmountOfQuestionsOnOnePage === "") {
            // show warning?
            return;
        }
        if (newAmountOfQuestionsOnOnePage > props.quartilePointsQuestions?.length) {
            updatePageStatesWithItemsPerPage(selectedItems?.length ?? 1);
            // add warning
            return;
        } else if (newAmountOfQuestionsOnOnePage < 0) {
            updatePageStatesWithItemsPerPage(1);
            return;
        }
        updatePageStatesWithItemsPerPage(newAmountOfQuestionsOnOnePage);
    }

    const updatePageStatesWithItemsCountAndItemsPerPage = (lengthItemsList, itemsPerPage) => {
        const newPageCount = calculatePageCount(lengthItemsList ?? 0, itemsPerPage);
        const newCurrentPageIndex = calculateNewCurrentPageIndex(firstItemOnPageIndex, itemsPerPage || 1);
        const {firstIndex, lastIndex} = calculateItemOnPageIndexRange(newCurrentPageIndex, itemsPerPage || 1, lengthItemsList ?? 0);

        updateCurrentPageIndex(newCurrentPageIndex);
        updateAmoutOfPages(newPageCount);
        updateFirstItemOnPageIndex(firstIndex);
        updateLastItemOnPageIndex(lastIndex);
        updateAmountOfQuestionsOnOnePage(lengthItemsList ?? 1);
    }

    const updatePageStatesWithItemsPerPage = (itemsPerPage) => {
        const newPageCount = calculatePageCount(selectedItems?.length ?? 0, itemsPerPage ?? 1);
        const newCurrentPageIndex = calculateNewCurrentPageIndex(firstItemOnPageIndex, itemsPerPage ?? 1);
        const {firstIndex, lastIndex} = calculateItemOnPageIndexRange(newCurrentPageIndex, itemsPerPage ?? 1, selectedItems?.length ?? 0);

        updateAmoutOfPages(newPageCount);
        updateCurrentPageIndex(newCurrentPageIndex);
        updateFirstItemOnPageIndex(firstIndex);
        updateLastItemOnPageIndex(lastIndex);
        updateAmountOfQuestionsOnOnePage(itemsPerPage ?? 1);
    }

    const updatePageStatesWithItemsCount = (lengthItemsList) => {
        const newPageCount = calculatePageCount(lengthItemsList, amountOfQuestionsOnOnePage);
        const newCurrentPageIndex = calculateNewCurrentPageIndex(firstItemOnPageIndex, amountOfQuestionsOnOnePage || 1);
        const {firstIndex, lastIndex} = calculateItemOnPageIndexRange(newCurrentPageIndex, amountOfQuestionsOnOnePage || 1, lengthItemsList ?? 0);

        updateAmoutOfPages(newPageCount);
        updateCurrentPageIndex(newCurrentPageIndex);
        updateFirstItemOnPageIndex(firstIndex);
        updateLastItemOnPageIndex(lastIndex);
    }

    useEffect(() => {
        if (props.quartilePointsQuestions) {
            updateSelectedItems(props.quartilePointsQuestions);
            updatePageStatesWithItemsCountAndItemsPerPage(props.quartilePointsQuestions?.length, props.quartilePointsQuestions?.length);
        }
    }, [props.quartilePointsQuestions]);

    const renderQuestionStatistics = (i, data) => {
        return (
            <QuestionStatistics
                key={data?.questionNumber}
                questionNumber={data?.questionNumber}
                data={data?.stats}
            />
        )
    }

    return (
        <div className="QuestionStatisticsView columnView" >
            <div className="titleView" >
                Studentenprestaties Per Vraag
            </div>

            <div className="rowView" >
                <div className="columnView" >
                    <BooleanSelect
                        title="Eén vraag selecteren"
                        selected={selectOneQuestion}
                        toggleSelected={changeSelectOneQuestion}
                    />
                    <BooleanSelect
                        title="Meerdere vragen selecteren"
                        selected={!selectOneQuestion}
                        toggleSelected={changeSelectOneQuestion}
                    />
                </div>

                <div className="columnView" >
                    <NumberInput
                        inlineTitle="Hoeveel vragen per pagina"

                        number={amountOfQuestionsOnOnePage || 1}
                        customUpdateFunc={updateQuestionsPerPage}
                    />
                </div>
            </div>

            <SelectQuestions
                selectOne={selectOneQuestion}
                allItems={props.quartilePointsQuestions}

                selectedItems={selectedItems}
                updateSelectedItems={updateSelectedItems}

                amountOfQuestionsOnOnePage={amountOfQuestionsOnOnePage}
                updatePageStatesWithItemsCount={updatePageStatesWithItemsCount}
            />

            {
                amountOfPages === 1 ? null :
                <div className="QuestionsStatisticsNavigatorView" >
                    <PageListNavigator
                        currentPageIndex={currentPageIndex}
                        updateIndex={updateCurrentPageIndex}

                        firstItemOnPageIndex={firstItemOnPageIndex}
                        updateFirstItemOnPageIndex={updateFirstItemOnPageIndex}

                        lastItemOnPageIndex={lastItemOnPageIndex}
                        updateLastItemOnPageIndex={updateLastItemOnPageIndex}

                        itemsPerPage={amountOfQuestionsOnOnePage}
                        itemsLength={selectedItems?.length ?? 0}
                        amountOfPages={amountOfPages}
                    />
                </div>
            }

            {
                props.loading === false && props.quartilePointsQuestions?.length === 0 ?
                "No questions"
                :
                selectedItems?.length === 0 ?
                <div className="QuestionStatisticsNoQuestions" >Geen vragen geselecteerd</div>
                :
                <List
                    items={selectedItems?.slice(firstItemOnPageIndex, lastItemOnPageIndex+1)}
                    renderItem={renderQuestionStatistics}
                />
            }
        </div>
    )
}

export default QuestionsStatistics;
