
// routing
import { useRouteMatch, Link, useParams } from 'react-router-dom';

// style
import "./takenExam.scss";

const TakenExam = (props) => {

    // id's
    let { tijdvakID, kaID, examID, oderNumb } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // routing
    let { url } = useRouteMatch();

    return (
        <Link 
            className="takenExam_TakenExam"
            to={`${url}/${props.takenExam.takenExamId}/Examen-Nakijken`}
        >{
            !props.takenExam? null : props.takenExam.title
        }</Link>
    )

}

export default TakenExam;
