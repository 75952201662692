
// styles
import "./NumberEditable.scss";

// components
import List from "../../../List";

const NumberEditable = (props) => {

    const renderNumberOption = (i, number) => {

        return (
            <div
                key={i}
                className="numberEditableOption"
                onClick={() => props.updateNumber(number)}
            >{

                `Punten: ${number}`

            }</div>
        )
    }

    return (
        <>{
            props.heighestNumber ? 
            <div
                className="numberEditableView"
            >
                <List
                    items={Array(props.heighestNumber+1).fill().map((x,i)=>i)}
                    renderItem={renderNumberOption}
                />
            </div> : null
        }</>
    )
}

export default NumberEditable;
