
// style
import "./Title.scss";

// components
import Loader from "../../../../components/Loader";

const Title = (props) => {

    return (
        <div className="subTopicTitleView" >
            <div className="subTopicTitle" >{
                
                props.loading ? <Loader /> : 
                
                props.title
            
            }</div>

            <div className="subTopicSubTitle" >{
                
                props.loading ? <Loader /> : 
                
                props.subTitle

            }</div>
        </div>
    )
}

export default Title;
