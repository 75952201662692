
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";
import Redux from '../../components/Redux/Redux';

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// networking
import topicApi from "../../services/Networking/topic";

// services
import convertError from "../../services/errors/convertError";
import userActions from "../../services/redux/actions/user.action";
import logOutErrors from "../../services/errors/logOutErrors";
import networking from '../../services/handleNetworking/networking/networking';

// local services
import topicErrors from "./services/errors";

// style
import "./ExamPrepPage.scss";

// services
import subjectApi from '../../services/Networking/subject/subject';
import handleEnvironment from '../../services/handleEnvironment/handleEnvironment';
import access from '../../services/access/accessCheck/access';

// components local
import Topic from "./components/Topic";
import Subject from './components/Subject/Subject';

// components
import PageNav from "../../components/PageNav/PageNav";
import LocalModal from '../../components/LocalModal';
import AddSubject from '../../components/AddSubject/AddSubject';

// classes
import tab from "../../components/PageNav/services/classes/tab";

// constants
import { SUCCESS } from "../../services/errors/constants";
import { PREPARATORY } from '../../services/constants/topicTypeConstants';
import { FORMER_EXAMS } from './services/constants';
import { REDUX_ERRORS } from '../../components/Redux/services/constants';
import { ADMIN } from "../../services/access/constants";
import { UVA_ENV } from '../../services/handleEnvironment/constants';

const ExamPrepPage = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    let location = useLocation();

    // topic id
    let { tijdvakID } = useParams();
    const topicId = tijdvakID ? parseInt(tijdvakID.replace("t-", "")) : null;

    // errors
    const [fetchError, updateFetchError] = useState(null);

    // save info
    const [subjects, updateSubjects] = useState(null);
    const [topics, updateTopics] = useState(null);
    const [report, updateReport] = useState(null);

    // selected subject
    const [selectedSubject, updateSelectedSubject] = useState(location.hash ? parseInt(location.hash.replace("#", "")) : null);
    useEffect(() => {
        if (subjects) {
            updateSelectedSubject(location.hash ? parseInt(location.hash.replace("#", "")) : subjects[0].subjectId);
        }
    }, [subjects]);
    
    // add subject and book
    const [showAddSubject, toggleShowAddSubject] = useState();

    // loading
    const [loadingFetch, toggleLoadingFetch] = useState(true);

    // logs user out
    const logOut = () => {
        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // log user in
        dispatch(userActions.logOut());

        // display success
        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: SUCCESS,
                updateLocally: null,
                customErrors: logOutErrors
            }))
        );
    }

    // fetch subjects
    const fetchSubjects = async() => {

        networking({

            updateContent: updateSubjects,

            toggleLoading: toggleLoadingFetch,

            accessableToAllUsers:true,
            logOut:logOut,

            api: subjectApi.getSubjects,
            apiParams: {
                bookId: 1, 
                type: PREPARATORY
            },
        
            updateError: updateFetchError,
            customErrors: topicErrors,
            history: history
        });
        updateReport(null);
    }

    const getTabId = () => {
        return location.hash ? parseInt(location.hash.replace("#", "")) : FORMER_EXAMS
    }

    // fetch topics
    useEffect(() => {
        if (!subjects) {
            toggleLoadingFetch(true);

            fetchSubjects();
        }
    }, [user]);

    // render topics
    const renderTopics = (i, data) => {

        // check quality
        if (data) {

            return (
                <Topic
                    index={i}
                    data={data}

                    isReport={topics ? false : true}

                    tabID={location.hash ? parseInt(location.hash.replace("#", "")) : FORMER_EXAMS}
                />
            )
        }

        // no data no component
        return (<></>)

    }

    return (

        <div
            className="examPrepView"
        >
            <div
                className="ExamPrepTitle"
            >{
                handleEnvironment() === UVA_ENV ? "Oefententamens" :
                "Oud eindexamens"
            }</div>

            {
                !user ? null : !user.user ? null : access({
                    accessLevel: ADMIN,
                    userRoles: user.user.roles
                }) ? 
                <div
                    className="examPrepPageAddSubjectAndBook"
                    onClick={() => toggleShowAddSubject((value) => !value)}
                >
                    Vak toevoegen
                </div> : null
            }

            {
                !subjects || subjects.length === 0 ? null :
                <PageNav
                    initalTab={selectedSubject}
                    updateURL={true}
                    updatedCustomSelectedTab={updateSelectedSubject}

                    tabs={
                        subjects.map((subject) => new tab({
                            id: subject.subjectId,
                            title: subject.name,
                            component: <Subject
                                selectedSubject={selectedSubject}
                                data={subject}
                            />
                        }))
                    }
                />
            }

            <LocalModal
                show={showAddSubject}
                toggleShow={toggleShowAddSubject}
                component={
                    <AddSubject
                        toggleShow={toggleShowAddSubject}

                        runOnSuccess={() => fetchSubjects()}
                    />
                }
            />
            
            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchError}
            />

        </div>

    )

}

export default ExamPrepPage;
