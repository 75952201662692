
// styles
import "./InfoItem.scss";

const InfoItem = (props) => {

    return (
        <div
            className={`InfoItemView ${props.marginBottom ? "InfoItemMarginBottom" : ""}`}
        >
            <div className="InfoItemTitle" >{ props.title }:</div>
            <div className={`InfoItemValue ${props.infoItemAditionalStyle || ""}`} >{ props.value }</div>
        </div>
    )
}

export default InfoItem;
