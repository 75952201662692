
class infoColumnClass {

    constructor({
        title,
        infoRowList,

    } = {}) {
        
        this.title = title;
        this.infoRowList = infoRowList;

    }

}

export default infoColumnClass;
