
// services
import handleLocalStorage from "../../../localStorage";
import { SUCCESS, UNAUTHORIZED } from "../../../errors/constants";

// standard Objects
import infoObj from "../../../classes/infoObj";

async function authHeader() {

    const user = await handleLocalStorage({
        action: "fetch",
        key: "user",
        data: null
    });

    if (user) {
        if (user.token) {            
            return new infoObj({
                payload: { 
                    'Authorization': `Bearer ${user.token}`,
                    'Access-Control-Allow-Origin' : '*',
                    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
                },
                status: SUCCESS
            });
        } else {
            return new infoObj({
                payload: {},
                status: UNAUTHORIZED
            });
        }
    }

    return new infoObj({
        payload: {},
        status: UNAUTHORIZED
    });

}

export default authHeader;
