
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import videosApi from "../../../../services/Networking/videos";

// services
import convertError from "../../../../services/errors/convertError";
import access from "../../../../services/access/accessCheck";

// style
import "./Videos.scss";

// local components
import Video from "./components/Video";
import AddVideo from "./components/AddVideo";

// components
import Add from "../../../../elements/Add";
import List from "../../../../components/List";
import Loader from '../../../../components/Loader';
import LocalModal from '../../../../components/LocalModal';

// constants
import { EDITOR } from "../../../../services/access/constants";
import { SUCCESS, NO_RESPONSE } from "../../../../services/errors/constants";

const Videos = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(true);

    const [videossList, updateVideosList] = useState([]);
    const [addVideo, toggleAddVideo] = useState(false);

    const fetchVideos = async() => {
        const returned = await videosApi.getVideosBySubTopicId(
            subTopicId
        );

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            updateVideosList(returned.payload);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);

    }

    useEffect(() => {

        toggleLoading(true);

        fetchVideos();
    
    }, [rerender]);

    const renderVideo = (i, videoData) => {
        return (
            <Video
                key={i}
                data={videoData}

                triggerReRender={triggerReRender}
            />
        )
    }

    return (
        <div className="Videos">
            <div className="VideosTitle" >
                Video Uitleg
                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: EDITOR,
                        userRoles: user.user.roles
                    }) ?
                    <div className="addVideo">
                        <Add
                            onClick={() => toggleAddVideo(true)}
                        />
                    </div> : null
                }
                <LocalModal
                    show={addVideo}
                    toggleShow={toggleAddVideo}
                    component={
                        <AddVideo
                            toggleShow={toggleAddVideo}

                            triggerReRender={triggerReRender}
                        />
                    }
                />
            </div>

            <div className="OverViewVideos" >
            {
                loading ? <Loader /> :
                
                !videossList ? null :
                videossList.length === 0 ? 
                <div className="noVideosWarning" >
                    Wij hebben nog geen videos voor dit hoofdstuk
                </div>
                :
                <List
                    items={videossList}
                    renderItem={renderVideo}
                />
            }
            </div>

        </div>
    )
}

export default Videos;
