import axios from 'axios';

// const instance = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// });
const instance = axios.create({
    baseURL: window.REACT_APP_API_URL
});

export default instance;
