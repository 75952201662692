
// routing
import { useRouteMatch, Link } from 'react-router-dom';

// local components
import QuestionPreview from "./components/QuestionPreview";

// components
import List from "../../../../../../../../components/List";

// style
import "./ExamPreview.scss";

const ExamPreview = (props) => {

    // routing
    let { url } = useRouteMatch();

    const renderQuestion = (i, questionData) => {
        return (
            <QuestionPreview
                key={i}
                number={i}
                data={questionData}    
            />
        )
    }

    return (
        <div className="sectExamPreview">
            <List
                items={props.data.questions}
                renderItem={renderQuestion}
            />

            <Link 
                className="overviewToView"
                to={`${url}/e-${props.data.uuid}`} >
                Examen bekijken
            </Link>

        </div>
    )
}

export default ExamPreview;
