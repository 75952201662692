
import React, { useState, useEffect } from 'react';

// style
import "./questionPoints.scss";

// classes
import errorClass from "../../../../../../../../services/errors/classes";

// services local
import findEditedAnswer from "../../../../services/findEditedAnswer";
import correctAnswers from "../../../../services/correctAnswers";

// components local
import PointsEditable from "./components/PointsEditable";

// components
import NumberInput from "../../../../../../../../components/input/number";

// constants
import { VIEW, MAKE, CHECK, ASSIGNMENT, EDIT} from "../../../../../../services/tabConstants";
import { SUCCESS } from "../../../../../../../../services/errors/constants";
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../../../../../services/constants/typeConstants";

const Points = (props) => {

    const [userPoints, updateUserPoints] = useState(props.scoredPoints);
    useEffect(() => {
    
        updateUserPoints(props.scoredPoints);

    }, [props.scoredPoints]);

    const [pointsAssignable, togglePointsAssignable] = useState(false);

    const [pointsError, updatePointsError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const updatePoints = (valueKey, viewValue) => {

        // check if this is necissary? - not sure what it does
        props.questionHub.initPutCorrectAnswer();

        if (props.questionType === ASSAY_QUESTION) {
            // and currently open question have only 1 answer
            
            for (let i=0; i<props.answerhubs.length; i++) {
                if (!props.answerhubs[i].answerDeleted()) {
                    props.answerhubs[i].updateProperty(valueKey, viewValue);
                    break;
                }
            }

            // props.answerhubs[0].updateProperty(valueKey, viewValue);
        } else {
            // get all correct answers - should only be one if they end up here!
            const correctAnswers = props.questionHub.getCorrectAnswers();

            // should only be editable here if there is one correct answer
            if (correctAnswers.length > 1) {
                return null;
            }
            
            // update the points of the correct answer
            props.questionHub.getAnswers().map((answer) => {
                
                // make sure answer correct & not deleted
                if (answer.answerCorrect() && !answer.answerDeleted()) {
                    // !!! old inputs !!!
                    answer.updateProperty(
                        valueKey,
                        viewValue,
                        props.points
                    );
                }
            });

        }

        // update points
        props.updatePoints(viewValue);

    }

    return (
        <div className="questionPoints">
            <div
                className={`
                    ${props.examView === CHECK  && props.questionHub.getCurrentValue("type") === ASSAY_QUESTION ? "questionPointsHover" : "holdPointsOnLine"}
                `}
                onClick={
                    // only works if question has answers
                    !props.questionHub.getAnswers() ? null :

                    props.examView === CHECK && props.questionHub.getCurrentValue("type") === ASSAY_QUESTION ? 

                        // toggle assign points for checking - NOT IMPLEMENTED YET FOR MULTIPLE CHOICE QUESTIONS & MAINLY WITH MULTIPLE CORRECT ANSWERS
                        () => togglePointsAssignable((value) => !value) 
                        : null
                }
            >
                {
                    pointsAssignable ? "Opties sluiten" :
                    <>
                        {
                            !props.answerhubs ? "Punten: 0" :
                            props.examView === EDIT ? 
                                <div 
                                    className={
                                        props.examView === EDIT && props.questionHub.getCorrectAnswers().length === 1 && props.questionType ?
                                        "pointsStyleEditable"
                                        :
                                        "pointsStyle"
                                    }
                                >
                                    {"Punten: "}
                                </div>
                                :
                                "Punten: "
                        }

                        {
                            props.examView === CHECK ? 
                                // show points user has obtained
                                `${props.scoredPoints || 0} / `
                                : null
                        }

                        {
                            // only works if question has answers
                            !props.answerhubs ? null :
                            
                            props.examView === EDIT && props.questionHub.getCorrectAnswers().length === 1 && props.questionType
                            ?
                                <NumberInput
                                    valueKey="points"
                                    dataClass={{points: props.questionHub.questionPoints()}}

                                    customUpdateFunc={updatePoints}

                                    useBaseStyle={true}

                                    errorClass={pointsError}
                                    updateError={updatePointsError}

                                    warningClass={props.pointsWarning}
                                    updateWarning={props.updatePointsWarning}
                                />
                            :
                            // calculate total points
                            props.questionHub.questionPoints() ? props.questionHub.questionPoints() : 0
                        }
                    </>
                }
                
            </div>

            {
                !props.questionHub ? null: !props.questionHub.getAnswers() ? null :
                
                pointsAssignable ? 
                    // show assignable points options
                    <PointsEditable
                        
                        questionHub={props.questionHub}
                    
                        togglePointsEditable={togglePointsAssignable}

                        // scored points
                        updateUserPoints={props.updateUserPoints}

                        // questionPoints={props.question.points ? props.question.points : 0}
                        // questionId={props.question.questionId}
                        // questionType={props.questionType}
                    />
                    : null
            }

        </div>
    )
}

export default Points;
