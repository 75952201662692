
//redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// style
import "./GlobalErrors.scss";

// elements
import Close from "../../elements/Close";

const GlobalErrors = (props) => {

    // redux
    const dispatch = useDispatch();
    const errors = useSelector(state => state.globalErrors);

    const renderErrors = () => {
        if (!errors) {
            return null;
        }
        if (!errors.arr) {
            return null;
        }

        const items = errors.arr.map((error, i) => {
            return (
                <div key={i} className="globalError" >
                    {
                        error.description
                    }
                </div>
            )
        })

        return items;
    }

    const closeErrors = () => {
        dispatch(globalErrorsActions.emptyErrorList());
    }

    return (
        errors.arr.length > 0 ?
        
            <div className="errorView" >
                <div className="GlobalErrorsTitle" >
                    Let op! 
                    <Close
                        onClick={closeErrors}
                    />

                </div>

                <hr className="lineErrorTitle" />
                
                <div className="GlobalErrorsBody" >
                    {
                        renderErrors()
                    }
                </div>
            </div>
        
        : null
    );

}

export default GlobalErrors;
