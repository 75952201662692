
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

export const videosApi = {
    addVideoBySubTopicId,
    getVideosBySubTopicId,
    deleteVideoByVideoId
}

async function addVideoBySubTopicId(subTopicId, videosData) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/${subTopicId}/videos`,
            videosData,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

async function getVideosBySubTopicId(subTopicId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subtopics/${subTopicId}/videos`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

async function deleteVideoByVideoId(videoId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/videos/${videoId}`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

export default videosApi;
