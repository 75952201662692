
function sendableEventClass({
    title = "",
    description = ""
} = {}) {
    this.title = title;
    this.description = description;
}

export default sendableEventClass;
