
// styles
import "./AssignmentStudents.scss";

// components
import SharingAssignment from "./components/SharingAssignment/SharingAssignment";
import ShareContent from "../../../../components/ShareContent/ShareContent";

const Students = (props) => {

    return (
        <div
            className="studentsView"
        >
            <SharingAssignment
                assignment={props.assignment}
            />
            {/* <ShareContent
                title="Met studenten delen"
                usersTitle="Studenten"
                token={{
                    users: 250,
                    active: true,
                    token: "dsafhwea_dasefs21sa-dwjklfahse20-dfsad",
                }}
            /> */}
        </div>
    )
}

export default Students;
