
import { useLocation } from "react-router-dom";

// styles
import "./AssignedExam.scss";

// services
import tab from "../../../../components/PageNav/services/classes/tab";

// components
import PageNav from "../../../../components/PageNav";
import Grading from "./components/Grading/Grading";
import Satistics from "./components/Satistics/Satistics";

// constants
import { OVERVIEW_EXPANDED } from "./services/constants";

const GradeAssignedExam = (props) => {

    // routing
    let location = useLocation();

    // fetch assignment info & check if user is inside colleagues
    

    return (
        <div
            className=""
        >
            <PageNav
                initalTab={location.hash ? parseInt(location.hash.replace("#", "")) : 1}
                updateURL={true}

                tabs={[
                    new tab({
                        id: 1,
                        title: "Nakijken",
                        component: <Grading />
                    }),
                    new tab({
                        id: 2,
                        title: "Statistieken",
                        component: <Satistics />
                    }),
                ]}
            />
        </div>
    )
}

export default GradeAssignedExam;
