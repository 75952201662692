
function addSourceClass({
    title = "",
    analyse = "",
    note1 = "",
    image = "",
    video = null,
} = {}) {

    this.title = title;
    this.analyse = analyse;
    this.note1 = note1;
    this.image = image;
    this.video = video;
}

export default addSourceClass;
