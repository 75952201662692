
import defaultErrors from "../defaultErrors";

const convertError = ({
        errorCode,
        updateLocally = null,
        customErrors = null
    }) => {

    if (customErrors) {
        const errorClass = customErrors(errorCode);

        if (errorClass) {

            if (updateLocally) {

                updateLocally((value) => errorClass);

                // maybe get rid of this null? It should be fine to return errorClass no?!
                // return null;
            }

            return errorClass;
        }
    }

    const errorClass = defaultErrors(errorCode);

    if (updateLocally) {

        updateLocally((value) => errorClass);

    }

    return errorClass;

}

export default convertError;
