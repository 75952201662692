
import { SUCCESS, UNSUPPORTED_MEDIA_TYPE } from "../../../errors/constants";

const validate = (image) => {

    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        // image format not supported
        return UNSUPPORTED_MEDIA_TYPE;
    } else {
        return SUCCESS;
    }
    
}

export default validate;
