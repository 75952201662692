import { bothDateAndTimeFilled } from "../../../components/input/DateTime/services/validate";
import convertError from "../../errors/convertError";
import { defaultDateInputErrors, defaultDateInputNotRequiredErrors } from "../../errors/defaultDateInputErrors/defaultDateInputErrors";
import defaultTextInputErrors from "../../errors/defaultTextInputErrors";
import validateTextInput from "../textInput/textInput";

// constants
import { SUCCESS } from "../../errors/constants";

const validateSaveAssignment = ({
    assignment,
    gradingDeadlineChanged,
    
    updateTitleError,
    updateDeadlineError,
    updateGradingDeadlineError,
} = {}) => {

    let error = SUCCESS;

    // validate name
    const valid = validateTextInput(assignment["name"]);
    // add title error
    const errorTemp = convertError({
        errorCode: valid,
        updateLocally: updateTitleError,
        customErrors: defaultTextInputErrors
    });
    if (!errorTemp || errorTemp.errorCode !== SUCCESS) {
        error = valid
    }

    // validate dateTime
    const submissionDate = bothDateAndTimeFilled(assignment["submittingDeadline"]);
    updateDeadlineError(defaultDateInputErrors.getError(submissionDate));
    if (submissionDate !== SUCCESS) {
        error = error !== SUCCESS ?? submissionDate;
    }

    // validate gradingDeadline
    if (gradingDeadlineChanged) {
        const gradingDeadline = bothDateAndTimeFilled(assignment["gradingDeadline"]);
        updateGradingDeadlineError(defaultDateInputNotRequiredErrors.getError(gradingDeadline))
        if (gradingDeadline !== SUCCESS) {
            error = error !== SUCCESS ?? gradingDeadline;
        }
    }

    return error === SUCCESS;
}

export default validateSaveAssignment;
