
// classes
import infoColumnClass from "../classes/infoColumnClass";
import infoRowClass from "../classes/infoRowClass";

// services
import translateExamCategory from "../../../../services/translateExamCategory";
import namingAttempts from "../namingTakenExams";

// constants
import { DRAFT } from "../../../../../../services/constants/stateConstants";

const examInfoConstructor = ({
    tijdvakID=null, 
    kaID=null, 
    examID=null, 
    orderId = null,
    examInfo = null,
    editors = null,
    takenExams = null,
    updateTakenExam = null,

    isEditor = false,
} = {}) => {

    let infoColumns = [];
    
    if (examInfo) {

        let examUserInfoColum;
        let examUserInfoList = [];

        // exam info
        let examInfoList = [
            new infoRowClass({
                title: "Vragen",
                value: examInfo.questions.length
            }),
            new infoRowClass({
                id: "category",
                title: "Categorie",
                value: translateExamCategory(examInfo.category)
            }),
            ...(isEditor ? [new infoRowClass({
                title: "Status",
                value: examInfo.state === DRAFT ? "Draft" : "Gepubliceerd"
            })] : [])
        ];

        // if info on user add that info in seperate column
        if (examInfo.grade) {
            examUserInfoList.push(
                new infoRowClass({
                    title: "Huidige cijfer",
                    value: examInfo.grade
                }),
                new infoRowClass({
                    title: "Punten",
                    value: `${examInfo.scoredPoints || examInfo.scoredPoints === 0 ? `${examInfo.scoredPoints} / ` : ""} ${examInfo.points ? examInfo.points : examInfo.totalPoint}`
                }),
            );

            examUserInfoColum = new infoColumnClass({
                title: "Jouw Info",
                infoRowList: examUserInfoList
            });

            infoColumns.push(examUserInfoColum);
            
        } else {
            // if no user info just add obtainable points
            examInfoList.unshift(
                new infoRowClass({
                    title: "Haalbare punten",
                    value: `${examInfo.scoredPoints ? `${examInfo.scoredPoints} / ` : ""} ${examInfo.points || examInfo.points === 0 ? examInfo.points : examInfo.totalPoint}`
                })
            );
        }

        let infoColum = new infoColumnClass({
            title: examInfo.grade ? "Toets Info" : "Info",
            infoRowList: examInfoList
        });

        infoColumns.push(infoColum);

    }

    if (editors) {
        let listEditors = [];

        editors.map((editor) => {
            const newEditor = new infoRowClass({
                value: editor
            })

            listEditors.push(newEditor);
        });
        
        const editorsColum = new infoColumnClass({
            title: editors.length > 1 ? "Bewerkers" : "Bewerker",
            infoRowList: listEditors
        });

        infoColumns.push(editorsColum);

    }

    if (takenExams) {
        let listTakenExams = namingAttempts({
            tijdvakID: tijdvakID, 
            kaID: kaID, 
            examID: examID,
            orderId: orderId,
            takenExams: takenExams,
            showAmount: 5,
            onClick: updateTakenExam
        });
        
        const takenExamsColum = new infoColumnClass({
            title: takenExams.length > 1 ? "Eerdere pogingen" : "Eerdere poging",
            infoRowList: listTakenExams,
        });

        infoColumns.push(takenExamsColum);
    }

    return infoColumns;

}

export default examInfoConstructor
