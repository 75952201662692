import constructParams from "../../../../utils/constructParams"; // TODO delete
import ItemSelection from "../ItemSelection/ItemSelection";

class filtersClass { // TODO: Filters

    constructor({

        itemIdKey = "id",
        fetchItems,

        filters,

        renderFilter,

        selectedItems = null,
        selectingItemsPresentAsFilterOption = false,
        onlyShowSelectedItems = false,
        selectItemsPossible = false,
        allItemsSelected = false,

    }) {

        this.itemIdKey = itemIdKey;
        this.fetchItems = fetchItems;

        // list of filters for list
        this.filters = filters;

        // renders filter option
        this.renderFilter = renderFilter;

        this.selectedItems = selectedItems ? selectedItems : new ItemSelection();
        this.selectingItemsPresentAsFilterOption = selectingItemsPresentAsFilterOption;
        this.onlyShowSelectedItems = onlyShowSelectedItems;
        this.selectItemsPossible = selectItemsPossible;
        this.allItemsSelected = allItemsSelected;

    }

    // Method to clone the class with optional changes
    cloneWithChanges(changes) {
        // Create a new instance with the same properties
        return new filtersClass({
            itemIdKey: changes?.itemIdKey || this.itemIdKey,
            fetchItems: changes?.fetchItems || this.fetchItems,
            filters: changes?.filters || this.filters,
            renderFilter: changes?.renderFilter || this.renderFilter,
            selectingItemsPresentAsFilterOption: changes?.selectingItemsPresentAsFilterOption !== undefined ? changes?.selectingItemsPresentAsFilterOption : this.selectingItemsPresentAsFilterOption,
            selectedItems: this.selectedItems,
            onlyShowSelectedItems: changes?.onlyShowSelectedItems !== undefined ? changes?.onlyShowSelectedItems : this.onlyShowSelectedItems,
            selectItemsPossible: changes?.selectItemsPossible !== undefined ? changes?.selectItemsPossible : this.selectItemsPossible,
            allItemsSelected: changes?.allItemsSelected !== undefined ? changes?.allItemsSelected : this.allItemsSelected,
        });
    }

    getFilter = (filterKey) => {
        return this.filters.find((filter) => filter.filterAttributeKey === filterKey);
    }

    updateOnSelected = () => {
        this.selectAll = !this.selectAll;
    }

    constructFilterParamsForFetch = () => {
        let filterParamsForFetch = {};

        this.filters.forEach(filter => {
            if (filter.customUpdateFunc) { // old customUpdateFunc
                // get sendable type filter id's
                const selectedOptions = filter.getIndexesOfSelectedOptions();
                filterParamsForFetch[filter.filterAttributeKey] = selectedOptions;
            }
        });

        return filterParamsForFetch;
    }

    applyLocalFiltersToList = (fetchedList) => {

        let listWithLocalFiltersApplied = fetchedList;

        if (this.onlyShowSelectedItems === true) {
            listWithLocalFiltersApplied = listWithLocalFiltersApplied.filter((item) => this.selectedItems.isItemSelected(item[this.itemIdKey]));
            this.filters.forEach((filter) => {
                if (filter.overridOnlyShowSelected && filter.active && !filter.customUpdateFunc) {
                    listWithLocalFiltersApplied = filter.applyFilterToList(listWithLocalFiltersApplied, this.itemIdKey);
                }
            });
        } else {
            this.filters.forEach((filter) => {
                if (filter.active && !filter.customUpdateFunc) {
                    listWithLocalFiltersApplied = filter.applyFilterToList(listWithLocalFiltersApplied, this.itemIdKey);
                }
            });
        }

        return listWithLocalFiltersApplied;
    }

    fetchWithFetchFilters = async(nonFilterParams) => {

        const params = {
            ...nonFilterParams,
            ...this.constructFilterParamsForFetch()
        };

        return await this.fetchItems(params);
    }

    // selecting items
    selectItem(itemId) {
        this.selectedItems.selectItem(itemId);
    }
    deselectItem(itemId) {
        this.selectedItems.deselectItem(itemId);
    }
    toggleSelection(itemId) {
        this.selectedItems.toggleSelection(itemId);
    }
    isItemSelected(itemId) {
        return this.selectedItems.isItemSelected(itemId);
    }
    clearSelection() {
        this.selectedItems.clearSelection();
    }
    noSelectedItems() {
        return this.selectedItems.isEmpty();
    }

    toggleOnlyShowSelectedItems = (fetchedList) => {
        this.onlyShowSelectedItems = !this.onlyShowSelectedItems;

        return this.applyLocalFiltersToList(fetchedList);
    }
}

export default filtersClass;
