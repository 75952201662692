
const createEmptyFeedback = () => {
    return {
        model: "",
        feedback: "",
        points: null,
    }
}

export default createEmptyFeedback;
