
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

// constants
import { SUCCESS } from '../../errors/constants';

const subjectApi = {
    postSubject,
    getSubjects,
};

async function postSubject(data) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subjects?subjectName=${data.subjectName}`, 
            {}, 
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function getSubjects() {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subjects`, 
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

export default subjectApi;
