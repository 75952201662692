
// constants
import { PRACTICE_EXAM } from "../../../../../services/constants/examCategoryConstants";
import { DRAFT } from "../../../../../services/constants/stateConstants";

class Exam {

    constructor({
        uuid = "",
        title = "",
        category = PRACTICE_EXAM,
        state = DRAFT,
        questions = [],
        questionsToRemove = [],
    } = {}) {

        this.uuid = uuid;
        this.title = title;
        this.category = category;
        this.state = state;
        this.questions = questions;
        this.questionsToRemove = questionsToRemove;

    }

}

export default Exam;
