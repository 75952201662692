
import React, { useState } from 'react';

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

// local elements
import TextField from "../elements/Text";
import Password from "../elements/Password";

// style
import "./Password.scss";

const PasswordInput = (props) => {

    const [viewPassword, toogleViewPassword] = useState(false);

    return (
        <>{
            !props.errorClass || !props.dataClass ? null :
            
            <div className="passwordInput" >
                <div className="passwordInputTitle" >
                    {props.title}
                    {/* Move eye to seperate element within NavBar */}
                    {
                        viewPassword ?
                            <FontAwesomeIcon 
                                onClick={() => toogleViewPassword(false)}
                                className="viewPW"
                                icon={faEyeSlash} 
                            />
                            :
                            <FontAwesomeIcon 
                            onClick={() => toogleViewPassword(true)}
                            className="viewPW"
                            icon={faEye} 
                        />
                    }
                </div>

                {
                    props.errorClass.errorCode === 200 ? null :
                    <div className="passwordShowError" >
                        {
                            props.errorClass.description
                        }
                    </div>
                }

                {
                    viewPassword ?
                        <TextField
                            value={props.dataClass[props.valueKey]}
                            dataObject={props.dataClass}
                            valueKey={props.valueKey}
                            showError={props.errorClass.errorCode !== 200}
                            updateError={props.updateError}
                        />
                        :
                        <Password
                            value={props.dataClass[props.valueKey]}
                            dataObject={props.dataClass}
                            valueKey={props.valueKey}
                            showError={props.errorClass.errorCode !== 200}
                            updateError={props.updateError}
                        />
                }
            </div>
        }</>
    )
}

export default PasswordInput;
