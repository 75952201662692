
class forceIncludeClass {

    constructor({

        forceIncludeList,
        filterIds,

    }) {

        // the list with forced items
        this.forceIncludeList = forceIncludeList;

        // the list of filter id's these items need to be percisted for
        this.filterIds = filterIds;

    }

}

export default forceIncludeClass;

