
// classes
import infoObj from "../../classes/infoObj/infoObj";

// services
import convertError from "../../errors/convertError/convertError";

// constants
import { NO_CONTENT, SUCCESS } from "../../errors/constants";

const deleteNetworking = async({

    runSatisfied = true,
    
    toggleLoading = null,
    triggerReRender = null,

    errorOnSuccess = true,

    api,
    apiParams,

    updateError,
    customErrors=null,

} = {}) => {

    // make sure conditions are satified before running
    if (!runSatisfied) {
        return null;
    }

    if (toggleLoading) {
        toggleLoading(true);
    }

    const response = await api(apiParams);

    // nothing returned - something weard happend
    if (!response) {

        convertError({
            errorCode: NO_CONTENT,
            updateLocally: updateError,
            customErrors: null // no response has nothing to do with the action, so standard errors will be shown
        });

        return new infoObj({
            status: NO_CONTENT
        });

    }

    // success returned, update with 200
    if (response.status === SUCCESS || response.status === NO_CONTENT) {

        if (errorOnSuccess) {
            // display success
            convertError({
                errorCode: response.status,
                updateLocally: updateError,
                customErrors: customErrors
            });
        }

        if (toggleLoading) {
            toggleLoading(false);
        }
        
        if (triggerReRender) {
            triggerReRender((value) => !value);
        }

        return new infoObj({
            status: SUCCESS
        });
        
    } else {

        // show auth errors publicly
        convertError({
            errorCode: response.status,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (toggleLoading) {
            toggleLoading(false);
        }

        return new infoObj({
            status: response.status
        });
    }
    
}

export default deleteNetworking
