import React from 'react';
import "./AlertDescription.scss";

const Description = (props) => {
    
    // Function to convert newline characters to <br /> elements
    const formatDescription = text => {
        if (!text) {return;}
        // Split the text on every newline and filter out empty strings if they exist
        return text.split('\\n').filter(line => line).map((line, index, array) => (
            <React.Fragment key={index}>
                {line}
                {index !== array.length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <div className="AlertDescription">
            {formatDescription(props.description)}
        </div>
    );
}

export default Description;
