
// classes
import errorClass from "../../../../services/errors/classes";

// constants
import { NOT_FOUND } from "../../../../services/errors/constants";

const validateMailErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NOT_FOUND:

            return new errorClass({
                errorCode: errorCode,
                title: "E-mailadres niet gevonden.",
                description: "E-mailadres niet gevonden.",
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default validateMailErrors;
