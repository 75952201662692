
// style
import "./Coloring.scss"

const Coloring = (props) => {
    return (
        <div
            className={`colorIndicator ${props.color}`}
            onClick={(e) => props.updatedSelectedFunc(e)}
        >
            
        </div>
    )
}

export default Coloring;
