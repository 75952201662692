
// classes
import errorClass from "../../../services/errors/classes";

// constants
import { NO_CONTENT, NOT_FOUND } from "../../../services/errors/constants";

const topicErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                title: "Geen hoofdstukken",
                description: "Er zijn nog geen hoofdstukken voor dit boek",
                moreInfo: null,
            });

        case NOT_FOUND:

            return new errorClass({
                errorCode: errorCode,
                title: "Pagina niet gevonden",
                description: "Deze pagina lijkt niet te bestaan. Ga naar www.etalia.nl/geschiedenis.",
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default topicErrors;
