
// services
import validateTextInput from "../../../../services/validate/textInput/textInput";
import defaultSelectInputErrors from "../../../../services/errors/defaultSelectInputErrors/defaultSelectInputErrors";
import validateType from "./services/validateType/validateType";
import convertError from "../../../../services/errors/convertError/convertError";

// errors local
import titleErrors from "./services/errors/titleErrors";
import pointsErrors from "./services/errors/pointsErrors";
import answerErrors from "./services/errors/answerErrors/answerErrors";
import MultipleChoiceErrors from "./services/errors/MultipleChoiceErrors/MultipleChoiceErrors";

// constants
import { TITLE, CATEGORY, TYPE } from "../constants/questionHubKeys";
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../constants/typeConstants";
import { CORRECT, POINTS, TITLE_ANSWER } from "../constants/answerHubKeys";
import { INTERNAL_ERROR, NO_CONTENT, SUCCESS } from "../../../../services/errors/constants";

const validateEditQuestion = ({

    // question info
    questionHub,

    // question
    updateQuestionError = null,

    // title
    updateTitleWarning = null,

    // category
    updateCategoryError = null,

    // type
    updateTypeError = null,

    // points
    updatePointsWarning = null,

    // answer warning - used for both MC (general) and assay answers
    updateAnswerWarning = null,

    // ensure showing errors
    toggleShowInfo = null,
    toggleEditMCAnswers = null,


} = {}) => {

    let warning = SUCCESS;
    let error = SUCCESS;

    if (questionHub.questionRemove) {
        return {
            warning: warning,
            error: error
        }
    }

    // title
    const titleValid = validateTextInput(questionHub.getCurrentValue(TITLE));
    if (updateTitleWarning) {
        convertError({
            errorCode: titleValid,
            updateLocally: updateTitleWarning,
            customErrors: titleErrors
        });
    }
    if (titleValid !== SUCCESS) {
        // question not valid
        warning = NO_CONTENT;
    }

    // validate category
    const catValid = validateType(questionHub.getCurrentValue(CATEGORY));
    if (updateCategoryError) {
        convertError({
            errorCode: catValid,
            updateLocally: updateCategoryError,
            customErrors: defaultSelectInputErrors
        });

        // unfold info if there is an error
        toggleShowInfo(true);

    }
    if (catValid !== SUCCESS) {
        // question not valid
        error = NO_CONTENT;
    }

    // type
    const typeValid = validateType(questionHub.getCurrentValue(TYPE));
    if (updateTypeError) {
        convertError({
            errorCode: typeValid,
            updateLocally: updateTypeError,
            customErrors: defaultSelectInputErrors
        });

        // unfold info if there is an error
        toggleShowInfo(true);

    }
    if (typeValid !== SUCCESS) {
        // question not valid
        error = NO_CONTENT;
    }

    // answer
    // assay answers
    if (questionHub.getCurrentValue(TYPE) == ASSAY_QUESTION) {

        const answers = questionHub.getNotDelAnswers();

        if (!answers) {
            return {
                warning: INTERNAL_ERROR,
                error: INTERNAL_ERROR
            };
            throw new Error("In validateEditQuestion, questionHub has invalid return for getNotDelAnswers fuction.");
        }
        if (answers.length !== 1) {
            return {
                warning: INTERNAL_ERROR,
                error: INTERNAL_ERROR
            };
            throw new Error("In validateEditQuestion, questionHub does not have 1 non deleted answer. This should not be possible.");
        }
        if (!answers[0].getCurrentValue(CORRECT)) {
            return {
                warning: INTERNAL_ERROR,
                error: INTERNAL_ERROR
            };
            throw new Error("In validateEditQuestion, the one answer is not correct.");
        }

        // zero points warning
        if (answers[0].getCurrentValue(POINTS) === 0) {

            if (updatePointsWarning) {
                // add points error
                convertError({
                    errorCode: NO_CONTENT,
                    updateLocally: updatePointsWarning,
                    customErrors: pointsErrors
                });
            }

            // question not valid
            warning = NO_CONTENT;

        }

        // empty answer warning
        const answerValid = validateTextInput(answers[0].getCurrentValue(TITLE_ANSWER));
        if (updateAnswerWarning) {
            convertError({
                errorCode: answerValid,
                updateLocally: updateAnswerWarning,
                customErrors: answerErrors
            });
        }
        if (answerValid !== SUCCESS) {
            // question not valid
            warning = NO_CONTENT;
        }

    } else if (questionHub.getCurrentValue(TYPE) == MULTIPLE_CHOICE_QUESTION) {

        // check if any correct answers
        const correctAnswers = questionHub.getCorrectAnswers();
        if (!correctAnswers) {
            return {
                warning: INTERNAL_ERROR,
                error: INTERNAL_ERROR
            };
        }
        if (correctAnswers.length === 0) {

            if (updateAnswerWarning) {
                convertError({
                    errorCode: NO_CONTENT,
                    updateLocally: updateAnswerWarning,
                    customErrors: MultipleChoiceErrors
                });
            }

            // directly switch to selecting correct answer mode
            if (toggleEditMCAnswers) {
                toggleEditMCAnswers(false);
            }

            // question not valid
            warning = NO_CONTENT;

        // one correct answer & only zero points
        } else if (correctAnswers.length === 1) {

            if (correctAnswers[0].getCurrentValue(CORRECT) && correctAnswers[0].getCurrentValue(POINTS) === 0) {
                if (updatePointsWarning) {
                    // add points error
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: updatePointsWarning,
                        customErrors: pointsErrors
                    });
                }

                // question not valid
                warning = NO_CONTENT;

            }
        
        }

        const answers = questionHub.getNotDelAnswers()
        if (!answers) {
            return {
                warning: INTERNAL_ERROR,
                error: INTERNAL_ERROR
            };
            throw new Error("In validateEditQuestion, questionHub has invalid return for getNotDelAnswers fuction.");
        }

        answers.forEach(answer => {
            
            if (correctAnswers.length > 1) {
                // check if answer correct & zero points
                if (answer.getCurrentValue(CORRECT) && answer.getCurrentValue(POINTS) === 0) {

                    // question not valid
                    warning = NO_CONTENT;

                }
            }


            if (validateTextInput(answer.getCurrentValue(TITLE_ANSWER)) !== SUCCESS) {

                // switch to editing text in answer
                if (toggleEditMCAnswers) {
                    toggleEditMCAnswers(true);
                }

                // question not valid
                warning = NO_CONTENT;
                
            }

        });

    }

    // test
    if ((warning !== SUCCESS || error !== SUCCESS) && updateQuestionError) {
        convertError({
            errorCode: NO_CONTENT,
            updateLocally: updateQuestionError,
            customErrors: null
        });
    } else if ((warning === SUCCESS || error === SUCCESS) && updateQuestionError) {
        // to remove error if all question errors are gone
        convertError({
            errorCode: SUCCESS,
            updateLocally: updateQuestionError,
            customErrors: null
        });
    }

    // add points error
    return {
        warning: warning,
        error: error
    }

}

export default validateEditQuestion;
