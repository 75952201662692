
import "./Loader.scss";

// inporting font awsome stuff
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Loader(props) {
    return (
        <div className="Loader">
            <FontAwesomeIcon className={"LoadWheel" } onClick={ props.whenClicked } icon={faSpinner} />
        </div>
    )
}

export default Loader;
