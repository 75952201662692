
function AddPracticeCardClass({
    frontText = "",
    backText = "",
    practiceCardId = null,
} = {}) {

    this.frontText = frontText;
    this.backText = backText;
    this.practiceCardId = practiceCardId;

}

export default AddPracticeCardClass;
