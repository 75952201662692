
// routing
import { useLocation } from 'react-router-dom';

// styles
import "./Overview.scss";

// components
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import DateTime from "../../../../../../components/input/DateTime/DateTime";
import formatDateForUser from '../../../../../../services/dateTime/formatDateForUser/formatDateForUser';

const Overview = (props) => {

    // url
    let location = useLocation();
    
    return (
        <div
            className="assignmentOverviewView"
        >
            <div
                className="assignmentOverviewTitle"
            >
                Algemene informatie
            </div>

            {
                props.editingAssignment ?
                <DateTime
                    title="Deadline voor het inleveren"

                    valueKey="submittingDeadline"
                    clientData={props.assignment}

                    errorClass={props.deadlineError}
                    updateError={props.updateDeadlineError}
                />
                :
                <InfoItem
                    title="Deadline voor het inleveren"
                    value={formatDateForUser(props.assignment?.getCurrentValue("submittingDeadline")) || "Nog geen deadline ingesteld"}
                />
            }

            {
                props.assignment?.getCurrentValue("isAdmin") === false && !props.assignment?.getCurrentValue("gradingDeadline")?.toString() ? null :
                props.editingAssignment ?
                <DateTime
                    title="Deadline voor het nakijken"

                    valueKey="gradingDeadline"
                    clientData={props.assignment}

                    notRequired={true}
                    atLeatPartlyFilled={props.gradingDeadlineChanged}
                    toggleAtLeatPartlyFilled={props.toggleGradingDeadlineChanged}

                    errorClass={props.gradingDeadlineError}
                    updateError={props.updateGradingDeadlineError}
                />
                :
                <InfoItem
                    title="Deadline voor het nakijken"
                    value={formatDateForUser(props.assignment?.getCurrentValue("gradingDeadline")) || "Geen deadline voor nakijken ingesteld"}
                />
            }

            {
                location.pathname.endsWith('/opdracht-maken') ?
                null :
                <>
                    {/* <InfoItem
                        title="Hoveelheid studenten"
                        value={props.assignmentStatistics?.students}
                    />
                    <InfoItem
                        title="Hoveelheid studenten gestart"
                        value={props.assignmentStatistics?.studentsStarted}
                    />
                    <InfoItem
                        title="Hoveelheid studenten klaar met opdracht"
                        value={props.assignmentStatistics?.studentsSubmitted}
                    />
                    <InfoItem
                        title="Percentage studenten klaar met opdracht"
                        value={!props.assignmentStatistics?.students ? "0.0 %" : `${(props.assignmentStatistics?.studentsSubmitted / props.assignmentStatistics?.students * 100).toFixed(2)} %`}
                    /> */}
                    <InfoItem
                        title={props.assignment?.getCurrentValue("isAdmin") === false ? "Je kunt de feedback inzien" : "Studenten kunnen feedback inzien"}
                        value={props.assignment?.getCurrentValue("canViewFeedback") ? "Ja" : "Nog niet"}
                    />
                </>
            }
        </div>
    )
}

export default Overview;
