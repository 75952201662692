
import { useRouteMatch, Link } from 'react-router-dom';

// style
import "./TakenExam.scss";

const TakenExam = (props) => {
    let { url } = useRouteMatch();
    
    return (
        <div
            className="sectTakenExam"
        >
            
            {props.data ? props.data.title : null}
            
            <Link
                to={`${url}/e-${props.examId}/${props.data.id}/Examen-Nakijken`}
                className="sectViewTakenExam"
            >
                Inkijken
            </Link>
        </div>
    )
}

export default TakenExam;
