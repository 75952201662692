
import { useState } from "react";

// routing
import { useParams } from "react-router-dom";

// styles
import "./AddAssignedItems.scss";

// classes
import option from "../../../../../../../../components/input/elements/Select/classes/option";
import errorClass from "../../../../../../../../services/errors/classes/errorClass";

// components
import SelectInput from "../../../../../../../../components/input/Select/Select";

// constants
import { SUCCESS } from "../../../../../../../../services/errors/constants";
import AddAssignedExamItem from "./components/AddAssignedExamItem/AddAssignedExamItem";
import { ADD_EXAM_ITEM, ADD_HANDIN_PDF_ITEM } from "./components/AddAssignedExamItem/services/constants";
import Title from "../../../../../../../../elements/ModalTitle/Title";

const AddAssignedItems = (props) => {

    // routing
    let { assignmentID } = useParams();
    
    // data
    const [selectedType, updateSelectedType] = useState({"type": ADD_EXAM_ITEM});
    const [createNewItemPerType, updateCreateNewItemPerType] = useState({[ADD_EXAM_ITEM]: true});

    const onTypeChange = (newValue) => {
        updateSelectedType({
            "type": newValue
        });
    }

    const onCreateNewItemPerTypeChange = (key, newValue) => {
        updateCreateNewItemPerType((currentState) => {
            currentState[key] = newValue;
            return currentState;
        });
    }

    // errors
    const [typeError, updateTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    return (
        <div
            className="AddAssignedItemsView"
        >
            {/* <div
                className="AddAssignedItemsTitle"
            >
                Geef opdracht op
            </div> */}

            <Title title={"Opdracht opgeven"} />

            <SelectInput
                title="Opdracht type"

                options={[
                    new option({
                        value: ADD_EXAM_ITEM,
                        title: "Tentamen"
                    }),
                    new option({
                        value: ADD_HANDIN_PDF_ITEM,
                        title: "PDF inleveren"
                    }),
                    new option({
                        value: "null",
                        title: "."
                    })
                ]}
                
                valueKey="type"
                dataClass={selectedType}
                funcOnChange={onTypeChange}

                errorClass={typeError}
                updateError={updateTypeError}
            />


            {
                selectedType?.type === "null" ? null :
                <SelectInput
                    title="Nieuw maken"

                    options={[
                        new option({
                            value: true,
                            title: "Ja"
                        }),
                        new option({
                            value: false,
                            title: "Nee"
                        }),
                        new option({
                            value: "null",
                            title: "."
                        })
                    ]}
                    
                    valueKey={selectedType?.type}
                    dataClass={createNewItemPerType}
                    customUpdateFunc={onCreateNewItemPerTypeChange}

                    errorClass={typeError}
                    updateError={updateTypeError}
                />
            }

            {
                selectedType?.type === ADD_EXAM_ITEM ?
                <AddAssignedExamItem
                    typeKey={ADD_EXAM_ITEM}
                    createNewItemPerType={createNewItemPerType}
                    assignmentUuiD={assignmentID}
                /> : null
            }
        </div>
    )
}

export default AddAssignedItems;
