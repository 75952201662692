
import React, { useState, useEffect } from 'react';

// text
import parse from "html-react-parser";
import cleanText from "../../../../../../../../services/cleanText";

// style
import "./MultipleChoiceOption.scss";

// classes local
import editAnswerClass from "../../../../services/classes/editAnswerClass";

// classes
import errorClass from "../../../../../../../../services/errors/classes";

// services local
import findEditedAnswer from "../../../../services/findEditedAnswer";
import findCorrectAnswer from "../../../../services/findCorrectAnswer";
import titleErrors from './services/errors/titleErrors/titleErrors';

// services
import convertError from '../../../../../../../../services/errors/convertError/convertError';
import validateTextInput from '../../../../../../../../services/validate/textInput/textInput';
import pointsErrors from '../../../../../../services/validateEditQuestion/services/errors/pointsErrors/pointsErrors';

// components
import CkTextInput from "../../../../../../../../components/input/CkText";
import NumberInput from "../../../../../../../../components/input/number";

// local comopnents
import DelOption from "./components/delOption";

// elements
import Checked from "../../../../../../../../elements/Checked";

// constants
import { MAKE, CHECK, EDIT} from "../../../../../../services/tabConstants";
import { NO_CONTENT, SUCCESS } from '../../../../../../../../services/errors/constants';
import { CORRECT, POINTS, TITLE_ANSWER } from '../../../../../../services/constants/answerHubKeys';

const Option = (props) => {

    const [selected, toggleSelected] = useState(false);
    useEffect(() => {
        
        if (props.questionHub.answerId.find((answerId) => answerId === props.answerHub.answerId)) {
            toggleSelected(true);
        } else {
            toggleSelected(false);
        }

    }, [props.selectedChanged])

    const [answerDeleted, toggleAnsewerDeleted] = useState(props.answerHub.answerDelete !== null ? true : false );

    const [editAnswerError, updateEditAnswerError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [pointsError, updatePointsError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const updateTitle = (valueKey, newText) => {
        props.answerHub.updateProperty(valueKey, newText, props.questionHub.questionPoints());
    }

    const updatePoints = (valueKey, viewValue) => {

        props.answerHub.updateProperty(valueKey, viewValue);

        props.updatePoints();

    }

    const preventOptionClick = (e) => {
        e.stopPropagation();
    }

    // warnings
    const [titleWarning, updateTitleWarning] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [pointsWarning, updatePointsWarning] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    useEffect(() => {

        const correctAnswers = props.questionHub.getCorrectAnswers();
        if (!correctAnswers) {
            return null;
        }

        if (props.validate) {

            // title
            const titleValid = validateTextInput(props.answerHub.getCurrentValue(TITLE_ANSWER));
            convertError({
                errorCode: titleValid,
                updateLocally: updateTitleWarning,
                customErrors: titleErrors
            });

            if (correctAnswers.length > 1) {
                // points
                if (props.answerHub.getCurrentValue(CORRECT) && props.answerHub.getCurrentValue(POINTS) === 0) {
                    // add points error
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: updatePointsWarning,
                        customErrors: pointsErrors
                    });
                }
            }
        }

    }, [props.validate])

    useEffect(() => {

        if (titleWarning.errorCode === SUCCESS && pointsWarning.errorCode === SUCCESS) {
            if (props.alignQuestionError) {
                props.alignQuestionError();
            }
        }

    }, [titleWarning, pointsWarning])

    return (
        
        <>{

            answerDeleted ? null :

        <div 
            className={`
                MultipleChoiceOption

                ${props.questionError.errorCode !== SUCCESS ? "MultipleChoiceOptionError" : ""}

                ${props.examView === MAKE || (props.examView === EDIT && !props.editMCAnswers) ? "multipleChoiceClickable" : ""}
                
                ${props.firstOption ? "FirstMultipleChoiceOption" : ""}
                ${props.lastOption ? "LastMultipleChoiceOption" : ""}

                ${ 
                    props.answerAccess ? 
                        props.answerHub.getCurrentValue("correct") ?
                        "CorrectMultipleChoiceOption" : ""
                    : ""
                }

                // still update!
                ${
                    (props.examView === CHECK && !props.userChoice && props.correct)
                    ? "InCorrectMultipleChoiceOption" : ""
                }

                // still update!
                ${selected && props.examView === MAKE ? "multipleChoiceSelected" : ""}

                ${props.editMCAnswers ? "editMCAnswers" : ""}
            `}

            onClick={
                props.examView === MAKE ?
                    () => props.optionClicked(props.answerHub.answerId)
                    :
                    props.examView === EDIT ?
                        props.editMCAnswers ?
                            // () => props.answerHub.initAnswerPut(props.questionHub.questionPoints())
                            null
                            :
                            () => props.answerCorrectChange(props.answerHub.number, !props.answerHub.getCurrentValue("correct"))
                        : null
            }
        >
            <div
                className="MultipleChoiceOptionContent"
            >
                {
                    props.examView !== EDIT ? null :
                    props.questionHub.getCorrectAnswers().length <= 1 ? null :
                    <div 
                        className="pointsInputOption"
                        onClick={(e) => preventOptionClick(e)}
                    >
                        <NumberInput
                            valueKey="points"
                            dataClass={props.answerHub.getAnswer()}

                            customUpdateFunc={updatePoints}

                            useBaseStyle={true}

                            resetTyped={props.answerhubs}

                            warningClass={pointsWarning}
                            updateWarning={updatePointsWarning}
                        />
                    </div>
                }
                
                {
                    props.examView === CHECK ?
                    <div className="MCOptionCheck" >
                        <Checked
                            checked={props.answerHub.getCurrentValue("userChoice")}
                            customStyle={true}
                        />
                    </div>
                    :null
                }
                
                {
                    props.editMCAnswers ?

                    <CkTextInput
                                
                        valueKey="title"
                        dataClass={props.answerHub.getAnswer()}

                        useBaseStyle={true}

                        customUpdateFunc={updateTitle}

                        warningClass={titleWarning}
                        updateWarning={updateTitleWarning}

                    />

                    :

                    <div className="optionText" >
                        {parse(
                            cleanText({
                                textToClean: props.answerHub.getCurrentValue("title"),
                                h1: true,
                                h2: true,
                                h3: true,
                                h4: true,
                                p: true,
                            })
                        )}
                    </div>
                    
                }
            </div>

            {
                props.examView === EDIT ?
                <DelOption

                    questionHub={props.questionHub}
                    answerHub={props.answerHub}

                    updateType={props.updateType}
                    updatePoints={props.updatePoints}

                    toggleAnsewerDeleted={toggleAnsewerDeleted}

                /> : null
            }

        </div>

        }</>

    )
}

export default Option;
