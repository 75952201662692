
import React, { useState } from 'react';

import parse from "html-react-parser";

// networking
import sourcesApi from "../../../../../../services/Networking/sources";

// services
import cleanText from "../../../../../../services/cleanText";
import iframeLink from "../../../../../../services/video/iframeLink";

// style
import "./Source.scss";

// components
import AlterContent from "../../../../../../components/AlterContent";

const Source = (props) => {

    const [showAnalysis, toggleShowAnalysis] = useState(false);

    return (
        <div className="Source" >
            <div className="SourceTitle" >
                
                <div className="sourceTitleText" >{
                    parse(
                        cleanText({
                            textToClean: props.data.title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            list: true,
                        })
                    )
                }</div>
                
                <AlterContent
                    delete={{
                        api: sourcesApi.deleteSourceBySourceId,
                        apiParams: props.data.sourceAnalasyId,
                    }}
                    triggerReRender={props.triggerReRender}
                />
            </div>

            <hr className="lineSource" />

            <div className="sourceInfo" >
                {
                    props.data.image ? 
                    <img 
                        className="imgSource"
                        src={props.data.image} 
                        alt="Bron Afbeelding" 
                    />
                    :
                    <div className="sourceVideoHolder" >
                        <iframe src={iframeLink(props.data.video)} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                }

                <div className="analysis" >
                    {
                        showAnalysis ?
                        parse(
                            cleanText({
                                textToClean: props.data.analyse,
                                h1: true,
                                h2: true,
                                h3: true,
                                h4: true
                            })
                        )
                        :
                        null
                    }
                    <div 
                        className="toggleShowAnalysis"
                        onClick={() => toggleShowAnalysis((value) => !value)}
                    >
                        {showAnalysis ? "Analyse verstoppen" : "Analyse bekijken"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Source;
