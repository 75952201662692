
// networking
import takenExamsApi from "../../../../../../services/Networking/takenExams";

const postTakenExam = async({
        examId,
        userAnswers,
        state,

        handleApiReturn,
    } = {}) => {
    
        const returned = await takenExamsApi.addTakenExamByExamId({
            examId, 
            userAnswers,
            state
        });

        const successFull = await handleApiReturn(returned, true);

        if (successFull) {
            return true;
        } else {
            return;
        }

}

export default postTakenExam;
