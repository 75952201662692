
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import videosApi from "../../../../../../services/Networking/videos";

// local services


// services
import convertError from "../../../../../../services/errors/convertError";
import validateTextInput from "../../../../../../services/validate/textInput";
import defaultTextInputErrors from "../../../../../../services/errors/defaultTextInputErrors";
import access from "../../../../../../services/access/accessCheck";
import cleanText from "../../../../../../services/cleanText";
import convertVideoUrl from "../../../../../../services/video/convertVideoUrl";

// style
import "./AddVideo.scss";

// components
import TextInput from '../../../../../../components/input/Text';
import Submit from "../../../../../../components/input/elements/Submit";
import Loader from "../../../../../../components/Loader";

// elements
import Title from "../../../../../../elements/ModalTitle";

// classes
import errorClass from "../../../../../../services/errors/classes";
import CkEditorConfig from "../../../../../../components/input/elements/CkText/classes";

// local classes
import AddVideoClass from "./services/classes/AddVideoClass";

// constants
import { STUDENT, EDITOR } from "../../../../../../services/access/constants";
import { SUCCESS, NO_CONTENT, UNAUTHORIZED } from "../../../../../../services/errors/constants";

const AddVideo = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [videoData, updateVideoData] = useState(props.existingData ? props.existingData : new AddVideoClass());

    const [urlError, updateUrlError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [creatorError, updateCreatorError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const validateField = ({
        valueKey,
        validate,
        updateError,
        customErrors
    }) => {
        // validate
        const valid = validate(videoData[valueKey]);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {

        let validInput = true;

        // validate url
        if (!validateField({
            valueKey: "url",
            validate: validateTextInput,
            updateError: updateUrlError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate creator
        if (!validateField({
            valueKey: "creator",
            validate: validateTextInput,
            updateError: updateCreatorError,
            customErrors: defaultTextInputErrors
        })) validInput = false


        // validate title
        if (!validateField({
            valueKey: "title",
            validate: validateTextInput,
            updateError: updateTitleError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        return validInput;

    }

    const save = async() => {

        toggleLoading(true);

        const validInput = await validateFields();

        if (validInput) {

            let response;

            videoData.url = convertVideoUrl(videoData.url);

            if (props.existingData) {
            } else {
                response = await videosApi.addVideoBySubTopicId(
                    subTopicId, 
                    [videoData]
                );
            }

            // nothing returned - something weard happend
            if (!response) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (response.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );

                props.triggerReRender((value) => !value);
                props.toggleShow(false);

            } else {
                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
            }
        }

        toggleLoading(false);

    }

    return (
        <div className="AddVideo" >
            <Title
                title={props.existingData ? "VIDEO AANPASSEN" : "VIDEO TOEVOEGEN"}
            />

            <TextInput
                title="URL"
                
                valueKey="url"
                dataClass={videoData}

                errorClass={urlError}
                updateError={updateUrlError}
            />

            <TextInput
                title="Producent"
                
                valueKey="creator"
                dataClass={videoData}

                errorClass={creatorError}
                updateError={updateCreatorError}
            />

            <TextInput
                title="Title"
                
                valueKey="title"
                dataClass={videoData}

                errorClass={titleError}
                updateError={updateTitleError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

        </div>
    )
}

export default AddVideo;
