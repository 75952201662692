
// classes
import errorClass from "../classes/errorClass";

// constants
import { NO_CONTENT } from "../constants";

const defaultSelectInputErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "",
                description: "Dit vlak moet ingevult zijn.",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }
}

export default defaultSelectInputErrors;
