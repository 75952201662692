
// react
import React, { useState } from 'react';

// local components
import SavingNewText from "../SavingNewText";

// components
import CkTextInput from "../../../../../../components/input/CkText";

// style
import "./AddNewText.scss";

// classes
import errorClass from "../../../../../../services/errors/classes";
import newTextClass from "../../../../../../services/Networking/textExplenation/classes";

// constants
import { SUCCESS, NO_RESPONSE } from "../../../../../../services/errors/constants";

const AddNewText = (props) => {

    const [newText, updateNewText] = useState(props.initialNewText ? new newTextClass({
        textExplanation: props.initialNewText.textExplanation
    }) : new newTextClass({
        textExplanation: ""
    }));
    const [newTextError, updateNewTextError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    return (
        <div className="addNewTextHolder" >
            <CkTextInput
                value={newText.textExplanation}
                dataClass={newText}
                valueKey={"textExplanation"}
                errorClass={newTextError}
                updateError={updateNewTextError}
            />

            <div className="InTextSaveNewText" >
                <SavingNewText
                    newTextClass={newText}
                    deleteNewText={props.deleteNewText}
                    updateNewTextError={updateNewTextError}

                    textId={props.textId}
                    editText={props.editText}

                    triggerReRender={props.triggerReRender}
                    toggleTextEditable={props.toggleTextEditable}

                    toggleLoading={props.toggleLoading}
                />
            </div>
        </div>
    )
}

export default AddNewText;
