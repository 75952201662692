
// services
import getFeedbackRoleOfUser from "./getFeedbackRoleOfUser";

const feedbackIsFromUser = (roleRelatedToFeedback) => {
    const userRole = getFeedbackRoleOfUser();
    return userRole === roleRelatedToFeedback;
}

export default feedbackIsFromUser;
