
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const tokenApi = {
    refreshToken,
    toggleTokenActivation,
}

async function refreshToken(token) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/token/refresh/${token}`, 
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
}

async function toggleTokenActivation(token) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/token/deactivate/${token}`, 
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
}

export default tokenApi;
