
import { useState } from "react";

import { useLocation } from 'react-router-dom';

// styles
import "./ExamFeedbackItem.scss";

// classes
import errorClass from "../../../../../services/errors/classes/errorClass";

// services
import networking from "../../../../../services/handleNetworking/networking";
import commentsApi from "../../../../../services/Networking/comments/comments";
import deleteNetworking from "../../../../../services/handleNetworking/deleteNetworking";
import validateTextInput from "../../../../../services/validate/textInput/textInput";
import convertError from "../../../../../services/errors/convertError";
import defaultTextInputErrors from "../../../../../services/errors/defaultTextInputErrors";

// components
import TextareaInput from "../../../../../components/input/Textarea/Textarea";
import Loader from "../../../../../components/Loader";

// constants
import { SUCCESS } from "../../../../../services/errors/constants";
import { GRADER, STUDENT } from "./services/constants";

const Feedback = (props) => {

    // routing
    const location = useLocation();
    const roleOfUserBasedOnPage = location.pathname.endsWith('/Nakijken') ? GRADER : STUDENT;

    // state
    const [editable, toggleEditable] = useState(props.feedback?.sent ? false : true);
    const [loading, toggleLoading] = useState(false);
    const [initDelete, toggleInitDelete] = useState(false);

    // errors
    const [commentInputError, updateCommentInputError] = useState(new errorClass({errorCode: SUCCESS}));
    const [saveExamError, updateSaveExamError] = useState(null);
    const [deleteExamError, updateDeleteExamError] = useState(null);

    const cancleAddingComment = () => {
        if (props.feedback?.post) {
            props.updateFeedbackList((feedbackList) => {
                return feedbackList.filter((item, index) => index !== props.index);
            });
        } else {
            toggleEditable(false);
        }
    }

    const saveComment = async() => {

        const valid = validateTextInput(props.feedback?.post?.comment || props.feedback.put?.comment);
        convertError({
            errorCode: valid,
            updateLocally: updateCommentInputError,
            customErrors: defaultTextInputErrors
        });
        if (valid !== SUCCESS) {
            return;
        }

        let returned;
        if (props.feedback?.post) {
            returned = await networking({
                toggleLoading: toggleLoading,
                errorOnSuccess: false,
    
                api: commentsApi.postComment,
                apiParams: {
                    takenExamUuid: props.takenExamUuid,
                    comment: props.feedback.post
                },
    
                updateError: updateSaveExamError,
            });
        } else if (props.feedback?.put) {
            returned = await networking({
                toggleLoading: toggleLoading,
                errorOnSuccess: false,
    
                api: commentsApi.putComment,
                apiParams: {
                    commentId: props.feedback?.id,
                    comment: props.feedback.put
                },
    
                updateError: updateSaveExamError,
            });
        }
        if (returned && returned.status === SUCCESS) {
            props.getExam();
            toggleEditable(false);
        }
    }

    const deleteComment = async() => {
        toggleLoading(true);
        const returned = await deleteNetworking({
            api: commentsApi.deleteComment,
            apiParams: props.feedback?.id,

            updateError: updateDeleteExamError,
        });

        if (returned && returned.status === SUCCESS) {
            props.getExam();
        } else {
            toggleLoading(false);
        }
    }

    return (
        <div
            className="examFeedbackItemView"
        >{
            editable ? 
            <div className="examFeedbackItemInputView" >
                <div className="examFeedbackItemInputComentTitle" >Nieuw {loading ? <div className="examFeedbackItemInputComentTitleLoader"><Loader /></div> : null}</div>
                <TextareaInput
                    valueKey={"comment"}
                    clientData={props.feedback}

                    useBaseStyle={true}

                    errorClass={commentInputError}
                    updateError={updateCommentInputError}
                />
                <div className="examFeedbackItemInputButtonsView" >
                    <div className="examFeedbackItemInputButton" onClick={saveComment} >Opslaan</div>
                    <div className="examFeedbackItemInputButton" onClick={cancleAddingComment} >Annuleren</div>
                </div>
            </div>
            :
            <>
                <div className="examFeedbackItemInputComentRoleView" >
                    <div className="examFeedbackItemInputComentTitle" >{props.feedback?.getCurrentValue("role") === GRADER ? "Docent" : "Leerling"} {loading ? <div className="examFeedbackItemInputComentTitleLoader"><Loader /></div> : null}</div>
                    {
                        roleOfUserBasedOnPage === props.feedback?.getCurrentValue("role") && !loading ? 
                        <>{
                            initDelete ?
                            <>
                                <div className="examFeedbackItemInputComentDelete" onClick={deleteComment} >Verwijderen</div>
                                <div className="examFeedbackItemInputComentEdit" onClick={() => toggleInitDelete(false)} >Annuleren</div>
                            </>
                            :
                            <>
                                <div className="examFeedbackItemInputComentEdit" onClick={() => toggleEditable(true)} >Aanpassen</div>
                                <div className="examFeedbackItemInputComentDelete" onClick={() => toggleInitDelete(true)} >Verwijderen</div>
                            </>
                        }</> : null
                    }
                </div>
                <pre className="examFeedbackItemInputComentBody" >{props.feedback?.sent?.comment}</pre>
            </>
        }</div>
    )
}

export default Feedback;
