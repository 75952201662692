
import { useState, useEffect } from 'react';

// routing
import { useParams, useLocation } from 'react-router-dom';

// style
import "./Assignment.scss";

// classes
import tab from '../../components/PageNav/services/classes/tab';
import ClientData from '../../services/classes/clientData/ClientData';
import errorClass from '../../services/errors/classes/errorClass';

// networking
import assignmentApi from '../../services/Networking/assignment/assignment';

// components
import PageNav from "../../components/PageNav";
import AssignmentInfo from './Views/AssignmentInfo/AssignmentInfo';
import Students from './Views/Students/Students';
import networking from '../../services/handleNetworking/networking/networking';
import Redux from '../../components/Redux/Redux';
import Loader from '../../components/Loader';
import SavingButtons from './components/SavingButtons/SavingButtons';
import Title from './components/Title/Title';

// constants
import { REDUX_ERRORS } from '../../components/Redux/services/constants';
import { SUCCESS } from '../../services/errors/constants';

const Assignment = (props) => {

    // routing
    let location = useLocation();
    let { assignmentID } = useParams();

    // loading
    const [loadingGet, toggleLoadingGet] = useState(true);
    const [loadingGetStatistics, toggleLoadingGetStatistics] = useState(true);

    // state
    const [editingAssignment, toggleEditingAssignment] = useState(false);
    const [gradingDeadlineChanged, toggleGradingDeadlineChanged] = useState(false);

    // data
    const [assignment, updateAssignment] = useState(null);
    const [assignmentStatistics, updateAssignmentStatistics] = useState(null);

    // errors
    const [getAssignmentError, updateGetAssignmentError] = useState(null);
    const [getAssignmentStatisticsError, updateGetAssignmentStatisticsErrorError] = useState(null);
    const [titleError, updateTitleError] = useState(new errorClass({errorCode: SUCCESS}));
    const [deadlineError, updateDeadlineError] = useState(new errorClass({errorCode: SUCCESS}));
    const [gradingDeadlineError, updateGradingDeadlineError] = useState(new errorClass({ errorCode: SUCCESS }));

    useEffect(() => {
        if (assignment) {
            toggleGradingDeadlineChanged(assignment?.getCurrentValue("gradingDeadline") ? true : false)
        }
    }, [assignment]);
    
    const formatAssignmnetData = (assignmentData) => {

        // const graders = assignmentData.graders.map((graderData) => {
        //     return new ClientData({
        //         id: graderData.title,
        //         sent: newAssignment ? null : graderData,
        //         post: newAssignment ? graderData : null
        //     })
        // })

        if (assignmentData.submittingDeadline) {
            assignmentData.submittingDeadline = new Date(assignmentData.submittingDeadline);
        }
        if (assignmentData.gradingDeadline) {
            assignmentData.gradingDeadline = new Date(assignmentData.gradingDeadline);
        }

        updateAssignment(
            new ClientData({
                id: assignmentID,
                // childLists: {
                //     graders: graders
                // },
                sent: assignmentData,
            })
        );
    }
    const getAssignment = async() => {
        await networking({
            updateContent: formatAssignmnetData,
            toggleLoading: toggleLoadingGet,
            errorOnSuccess: false,

            api: assignmentApi.getAssignment,
            apiParams: assignmentID,

            updateError: updateGetAssignmentError,
        });
    }

    const getAssignmentStatistics = () => {
        networking({
            updateContent: updateAssignmentStatistics,
            toggleLoading: toggleLoadingGetStatistics,
            errorOnSuccess: false,

            api: assignmentApi.getAssignmentStatistics,
            apiParams: assignmentID,

            updateError: updateGetAssignmentStatisticsErrorError,
        });
    }

    useEffect(() => {
        getAssignmentDataAndStats();
    }, []);

    const getAssignmentDataAndStats = () => {
        getAssignment();
        getAssignmentStatistics();
    }

    return (
        <div
            className="assignmentView"
        >{
            loadingGet && !assignment ? <Loader /> : !assignment ? "Something went wrong" :
            <>
                <SavingButtons
                    assignmentUuid={assignmentID}
                    assignment={assignment}
                    getAssignment={getAssignment}
                    getAssignmentDataAndStats={getAssignmentDataAndStats}

                    editingAssignment={editingAssignment}
                    toggleEditingAssignment={toggleEditingAssignment}

                    gradingDeadlineChanged={gradingDeadlineChanged}
                    updateTitleError={updateTitleError}
                    updateDeadlineError={updateDeadlineError}
                    updateGradingDeadlineError={updateGradingDeadlineError}
                />

                <Title
                    valueKey="name"
                    assignment={assignment}

                    editingAssignment={editingAssignment}
                    loading={loadingGetStatistics || loadingGet}

                    titleError={titleError}
                    updateTitleError={updateTitleError}
                />

                <PageNav
                    initalTab={location.hash ? parseInt(location.hash.replace("#", "")) : 1}
                    updateURL={true}

                    tabs={
                        assignment.getCurrentValue("isAdmin") === false ?
                        [
                            new tab({
                                id: 1,
                                title: "Opdracht",
                                component: <AssignmentInfo
                                    // data
                                    assignment={assignment}
                                    assignmentStatistics={assignmentStatistics}
    
                                    // states
                                    editingAssignment={editingAssignment}
    
                                    // functions
                                    getAssignment={getAssignmentDataAndStats}
                                />
                            }),
                        ]
                        :
                        [
                        new tab({
                            id: 1,
                            title: "Opdracht",
                            component: <AssignmentInfo
                                // data
                                assignment={assignment}
                                assignmentStatistics={assignmentStatistics}

                                // states
                                editingAssignment={editingAssignment}
                                gradingDeadlineChanged={gradingDeadlineChanged}
                                toggleGradingDeadlineChanged={toggleGradingDeadlineChanged}

                                // functions
                                getAssignment={getAssignment}

                                // errors
                                deadlineError={deadlineError}
                                updateDeadlineError={updateDeadlineError}
                                gradingDeadlineError={gradingDeadlineError}
                                updateGradingDeadlineError={updateGradingDeadlineError}
                            />
                        }),
                        new tab({
                            id: 2,
                            title: "Studenten",
                            component: <Students

                                // data
                                assignment={assignment}
                                assignmentStatistics={assignmentStatistics}

                                // assignment={
                                //     { 
                                //         students: 250,
                                //         startedAssignments: 176,
                                //         completedAsingment: 84,
                                //         percentageOfStudentsThatCompletedAssignment: (84/176 * 100).toFixed(2),
                                //         deadline: "Very important assignmnet",
                                //         shareAnswers: "Na docenten hebben nagekeken",
                                //         checkPolicy: "Docent kijkt na"
                                //     }
                                // }
                            />
                        })
                    ]}
                />

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentStatisticsError}
                />
            </>
        }</div>
    )
}

export default Assignment;
