
import React, { useState } from 'react';

// classes
import ClientData from '../../../../../../services/classes/clientData/ClientData';

// style
import "./QuestionFeedbacks.scss";

// components
import Feedback from './components/Feedback/Feedback';
import List from '../../../../../../components/List';

// services
import findHighestRoleFeedback from './services/findHighestRoleFeedback';
import createEmptyFeedback from '../../../../services/createEmptyFeedback/createEmptyFeedback';
import feedbackByUser from './services/feedbackByUser';
import feedbackIsFromUser from './services/feedbackIsFromUser';

// constants
import { FEEDBACK_KEY, POINTS_KEY } from './services/constants';
import { placeUserFeedbackLast } from './services/sortFeedbackList';

const Feedbacks = (props) => {

    const [editingFirstItem, toggleEditingFirstItem] = useState(false);
    const [showAllFeedback, toggleShowAllFeedback] = useState(false);

    const engageEditFeedback = (engagedFeedback) => {
        // check if current feedback is by the current user
        if (!feedbackIsFromUser(engagedFeedback.getCurrentValue("model"))) {
            // check for existing feedback by users
            const foundFeedbackByUser = feedbackByUser(props.feedbackList);
            if (foundFeedbackByUser) {
                // backup old feedback
                props.updateBackupUserFeedback(foundFeedbackByUser.clone());

                // add engaged feedback values to user feedback if present
                if (engagedFeedback.getCurrentValue(FEEDBACK_KEY)) {
                    foundFeedbackByUser.updateProperty(FEEDBACK_KEY, engagedFeedback.getCurrentValue(FEEDBACK_KEY));
                }
                if (engagedFeedback.getCurrentValue(POINTS_KEY)) {
                    foundFeedbackByUser.updateProperty(POINTS_KEY, engagedFeedback.getCurrentValue(POINTS_KEY));
                    // restore points
                    props.updateUserPoints({
                        userPoints: engagedFeedback.getCurrentValue("points")
                    });
                }

                // update feedback list
                props.updateFeedbackList(null, true);

            } else {
                // create new feeddback item
                const newFeedback = new ClientData({
                    post: createEmptyFeedback(),
        
                    createEmptyInstance: createEmptyFeedback,
                });

                // add engaged feedback values to user feedback if present
                if (engagedFeedback.getCurrentValue(FEEDBACK_KEY)) {
                    newFeedback.updateProperty(FEEDBACK_KEY, engagedFeedback.getCurrentValue(FEEDBACK_KEY));
                }
                if (engagedFeedback.getCurrentValue(POINTS_KEY)) {
                    newFeedback.updateProperty(POINTS_KEY, engagedFeedback.getCurrentValue(POINTS_KEY));
                    // restore points
                    props.updateUserPoints({
                        userPoints: engagedFeedback.getCurrentValue(POINTS_KEY),
                        feedbackListAlreadyUpdated: true
                    });
                }

                // create new feedback
                props.updateFeedbackList(newFeedback);
            }
        } else {
            props.updateFeedbackListDirectly((oldFeedbackList) => placeUserFeedbackLast(oldFeedbackList));
        }

        // engage editing
        toggleEditingFirstItem(true);
    }

    const addNewFeedback = () => {
        // check for existing feedback by users
        const foundFeedbackByUser = feedbackByUser(props.feedbackList);
        if (foundFeedbackByUser) {
            // add found feedback to backup
            props.updateBackupUserFeedback(foundFeedbackByUser.clone());

            // filter out existing user feedback
            props.questionHub.feedback = props.questionHub.feedback.filter((feedback) => !feedbackIsFromUser(feedback.getCurrentValue("model")));

        }
        // create new empty feeddback item
        const newFeedback = new ClientData({
            post: createEmptyFeedback(),

            createEmptyInstance: createEmptyFeedback,
        });

        // add new feedback to feedback list
        props.updateFeedbackList(newFeedback);

        // update points
        props.updateUserPoints({
            userPoints: newFeedback.getCurrentValue(POINTS_KEY),
            feedbackListAlreadyUpdated: true
        });

        toggleEditingFirstItem(true);

    }

    const restoreBackUpFeedback = (addFeedbackToNewFeedback = false) => {

        // filter out any existing user feedback
        props.questionHub.feedback = props.questionHub.feedback.filter((feedback) => !feedbackIsFromUser(feedback.getCurrentValue("model")));

        if (addFeedbackToNewFeedback) {
            let userFeedback = feedbackByUser(props.feedbackList).getCurrentValue("feedback");
            userFeedback = `${userFeedback}<br><br>Jouw eerder geschreven feedback:<br>${props.backupUserFeedback.getCurrentValue("feedback")}`;
            props.backupUserFeedback.updateProperty("feedback", userFeedback);
            props.updateFeedbackList(props.backupUserFeedback, false);

        } else {
            props.updateFeedbackList(props.backupUserFeedback, false);
        }

        // restore points
        props.updateUserPoints({
            userPoints: props.backupUserFeedback.getCurrentValue("points")
        });
        props.updateBackupUserFeedback(null);
    }

    const updatePoints = (points, editable, existingFeedback) => {
        props.updateUserPoints({
            userPoints: points,
            feedbackListAlreadyUpdated: true
        });

        const foundFeedbackByUser = feedbackByUser(props.feedbackList);
        if (foundFeedbackByUser) {
            if (!editable) {
                // add old feedback to backup
                props.updateBackupUserFeedback(foundFeedbackByUser.clone());
            }

            foundFeedbackByUser.updateProperty(POINTS_KEY, points);
            props.updateFeedbackList(foundFeedbackByUser, true);

        } else {
            const feedbackWithUpdatedPoints = new ClientData({ post: { points: points, feedback: existingFeedback ?? "" }, createEmptyInstance: createEmptyFeedback });
            props.updateFeedbackList(feedbackWithUpdatedPoints);
        }
    }

    const renderFeedback = (i, data) => {
        return (
            <Feedback
                key={`${props.questionHub.questionId}_${data.id}`}

                updatePoints={updatePoints}

                backupUserFeedback={props.backupUserFeedback}
                updateBackupUserFeedback={props.updateBackupUserFeedback}
                restoreBackUpFeedback={restoreBackUpFeedback}

                engageEditFeedback={engageEditFeedback}

                editingFirstItem={editingFirstItem}
                editable={feedbackIsFromUser(data.getCurrentValue("model")) ? true : false}
                editing={editingFirstItem}
                feedback={data}

                questionHub={props.questionHub}
                updateUserPoints={props.updateUserPoints}

                showAllFeedback={showAllFeedback}

            />
        )
    }

    return (
        <div
            className="feedbacksView"
        >
            {
                props.questionHub?.feedback?.length === 0 ? null :
                <>
                <div
                    className="feedbacksTitleView"
                >
                    <div
                        className="feedbacksTitleViewTitle"
                    >
                        <div
                            className="feedbacksTitle"
                        >
                            Feedback
                        </div>
                    </div>
                    
                    {
                        props.feedbackList.length > 1 ?
                        <div
                            className="feedbacksShowAllButton"
                            onClick={() => toggleShowAllFeedback((value) => !value)}
                        >{
                            showAllFeedback ?
                            "Bekijk laatste feedback"
                            :
                            "Bekijk alle feedback"
                        }</div> : ""
                    }
                </div>
                
                {
                    showAllFeedback ?
                    <List
                        items={props.feedbackList}
                        renderItem={renderFeedback}
                    />
                    :
                    props.questionHub?.feedback?.length === 0 ? null :
                    <Feedback
                        updatePoints={updatePoints}

                        engageEditFeedback={engageEditFeedback}

                        backupUserFeedback={props.backupUserFeedback}
                        updateBackupUserFeedback={props.updateBackupUserFeedback}
                        restoreBackUpFeedback={restoreBackUpFeedback}

                        editingFirstItem={editingFirstItem}
                        editable={true}
                        editing={editingFirstItem}
                        feedback={findHighestRoleFeedback(props.feedbackList) || null}

                        questionHub={props.questionHub}
                        updateUserPoints={props.updateUserPoints}

                        showAllFeedback={showAllFeedback}
                    />
                }
                </>
            }
            {
                editingFirstItem ? "" :
                props.questionHub?.feedback?.length === 0 ?
                <div
                    className={`feedbacksNewFeedbackButton`}
                    onClick={() => addNewFeedback()}
                >{
                    props.questionHub?.feedback?.length === 0 ? "Feedback schrijven" : "Niewe feedback schrijven"
                }</div> : null
            }
        </div>
    )
}

export default Feedbacks;
