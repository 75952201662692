
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import parse from "html-react-parser";

// style
import "./Link.scss";

// networking
import LinksApi from "../../../../../../services/Networking/Links";

// services
import cleanText from "../../../../../../services/cleanText";
import validateUrl from "../../../../../../services/validate/url";

// components
import AlterContent from "../../../../../../components/AlterContent";

const LinkView = (props) => {

    const renderPart = (text) => {
        
        return (
            !text ? null :
            ` - ${parse(
                cleanText({
                    textToClean: text,
                    h1: true,
                    h2: true,
                    h3: true,
                    h4: true,
                    p: true,
                })
            )}`
        )
    }

    const renderType = () => {
        if (props.data.type === 1) {
            return " - Artikel";
        } else if (props.data.type === 2) {
            return " - Video";
        } else if (props.data.type === 3) {
            return " - Podcast";
        } else if (props.data.type === 4) {
            return " - Boek";
        } else {
            return "";
        }
    }

    return (
        <>{
            !validateUrl(props.data.url) ?
            <div className="Link" >
                <div className="LinkTitle" >
                    Deze link werkt niet meer 🥺
                </div>
                <AlterContent
                    // edit={{
                    //     onClick:toggleEditEvent
                    // }}
                    delete={{
                        api: LinksApi.deleteLinkByThemeLinkIdAndLinkId,
                        apiParams: {
                            linkThemeId: props.linkThemeId,
                            linkId: props.data.linkId
                        },
                    }}
                    rerender={props.triggerReRender}
                />
            </div>
            :
            <a 
                className="Link workingLink"
                href={props.data.url}
                target={props.samePage ? "_self" : "_blank"} key={props.i}
                >
                <div className="LinkTitle" >
                    {
                        parse(cleanText({
                            textToClean: props.data.title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                        }))
                    }
                    {
                        renderPart(props.data.creator)
                    }
                    {
                        renderPart(props.data.nameOrganisation)
                    }
                    {
                        renderType()
                    }
                </div>
                <AlterContent
                    // edit={{
                    //     onClick:toggleEditEvent
                    // }}
                    delete={{
                        api: LinksApi.deleteLinkByThemeLinkIdAndLinkId,
                        apiParams: {
                            linkThemeId: props.linkThemeId,
                            linkId: props.data.linkId
                        },
                    }}
                    rerender={props.triggerReRender}
                />
            </a>
        }</>
    )
}

export default LinkView;
