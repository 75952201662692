
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";
import arrayToString from "../services/arrayToString";

const practiceCardApi = {
    addPracticeCards,
    getPracticeCardsBySubTopicIDAndTypeID,
    getAllPracticeCardsBySubTopicId,
    putPracticeCardByCardId,
    deletePracticeCardsBySubTopicId,
    responsePracticeCardsByPracticeCardId,

    starPracticeCards,
    unStarPracticeCards
};

async function addPracticeCards(subTopicID, typeID, practiceCardClass) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/${subTopicID}/practiceCards?type=${typeID}`, 
            practiceCardClass, 
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;

}

async function getPracticeCardsBySubTopicIDAndTypeID({subTopicID, type, creator} = {}) {

    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subtopics/practiceCards?${creator ? `${arrayToString("contentFilter", creator)}&` : ""}${type ?`${ arrayToString("filter", type)}&` : ""}subtopicIds=${subTopicID}`, 
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function getAllPracticeCardsBySubTopicId(subTopicID) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.get(
            `/subTopics/${subTopicID}`, 
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function putPracticeCardByCardId(cardId, cardObject) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.put(
            `/practiceCards/${cardId}`,
            cardObject,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function deletePracticeCardsBySubTopicId(cardId) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/practiceCards/${cardId}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

// {
//     "practiceCardId": practiceCardId,
//     "correct": answerCorrect
//   }
async function responsePracticeCardsByPracticeCardId(practiceCardResponses) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.post(
            `/practiceCards/response`,
            [...practiceCardResponses],
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function starPracticeCards(starringClass) {

    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/${starringClass.subTopicId}/practiceCards/${starringClass.cardId}/markAsFavourite`,
            {},
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function unStarPracticeCards(starringClass) {

    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/subtopics/${starringClass.subTopicId}/practiceCards/${starringClass.cardId}/removeAsFavourite`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}


export default practiceCardApi;
