
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../errors/constants";

const valitateImage = (type) => {

    switch (type) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;
        
        case "null":
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;

        // wrong characters
        // case !validate(firstName):
        //     return BAD_REQUEST;
        
        default:
            return SUCCESS;
    }
}

export default valitateImage;
