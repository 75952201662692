
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const sourcesApi = {
    addSourcesBySubSubTopicId,
    getSourcesBySubTopicId,
    putSourcesBySourceId,
    deleteSourceBySourceId
}

async function addSourcesBySubSubTopicId(subTopicId, sourcesData) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/${subTopicId}/sourceAnalysis`,
            sourcesData,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getSourcesBySubTopicId(subTopicId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subtopics/${subTopicId}/sourceAnalysis`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function putSourcesBySourceId(sourceId, sourceClass) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.put(
            `/sourceAnalysis/${sourceId}`,
            sourceClass,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function deleteSourceBySourceId(sourceId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/sourceAnalysis/${sourceId}`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

export default sourcesApi;
