
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// networking
import subTopicApi from "../../services/Networking/subTopic";

// style
import "./SubTopic.scss";

// classes
import tab from "../../components/PageNav/services/classes";

// components
import PageNav from "../../components/PageNav";
import Feedback from "../../components/Feedback";

// import Breadcrumb from "../../../mainContentHolderComponents/breadcrumb";
import Breadcrumb from "../../components/Breadcrumb";

// local components
import Title from "./components/Title";
import Definitions from "./components/Definitions";
import TextExplenation from "./components/TextExplenation";
import Events from "./components/Events";
import Videos from "./components/Videos";
import PracticeCards from "./components/PracticeCards";
import Exams from "./components/Exams";
import Sources from "./components/Sources";
import Links from "./components/Links";

// local services
import subTopicErrors from "./services/errors";

// services
import convertError from "../../services/errors/convertError";

// constants
import { SUCCESS, UNAUTHORIZED, NOT_FOUND, NO_CONTENT, NO_RESPONSE } from "../../services/errors/constants";

const SubTopic = (props) => {

    // redux
    const dispatch = useDispatch();

    // routing
    let history = useHistory();
    let location = useLocation();
    
    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    const [loadingTopics, updateLoadingTopics] = useState(true);
    const [subTopicClass, updateSubTopicsClass] = useState(null);

    const fetchTopics = async () => {
        
        const returned = await subTopicApi.getSubTopicBySubTopicID(
            subTopicId
        );

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            updateLoadingTopics(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            updateSubTopicsClass(returned.payload);
        } else if (returned.status === UNAUTHORIZED) {
            // logout


            // add error to global error list
            dispatch(
                globalErrorsActions.addError(
                    convertError({
                        errorCode: returned.status,
                        customErrors: null
                    })
                )
            );

            return null;
        }  else if (returned.status === NOT_FOUND) {
            // page not found
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status,
                    customErrors: subTopicErrors
                })
            ));

            // push to page not found page
            history.push("/niet-gevonden");
            return null;
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status,
                    customErrors: subTopicErrors
                })
            ));
        }

        updateLoadingTopics(false);

    }
    
    useEffect(() => {

        updateLoadingTopics(true);

        fetchTopics();
    
    }, []);

    return (
        <div className="SubTopicView" >

            <Title
                title={subTopicClass ? subTopicClass.title : null}
                subTitle={subTopicClass ? subTopicClass.subtitle : null}
                loading={loadingTopics}
            />

            <Breadcrumb
                subTopicClass={subTopicClass}
            />

            <PageNav
                initalTab={location.hash ? parseInt(location.hash.replace("#", "")) : 3}
                updateURL={true}

                tabs={[
                    new tab({
                        id: 3,
                        title: "Samenvatting",
                        component: <TextExplenation />
                    }),
                    new tab({
                        id: 1,
                        title: "Definities",
                        component: <Definitions />
                    }),
                    new tab({
                        id: 2,
                        title: "Gebeurtenissen",
                        component: <Events />
                    }),
                    new tab({
                        id: 4,
                        title: "Video Uitleg",
                        component: <Videos />
                    }),
                    new tab({
                        id: 5,
                        title: "Flashcards",
                        component: <PracticeCards />
                    }),
                    new tab({
                        id: 6,
                        title: "Oefentoetsen",
                        component: <Exams subTopicClass={subTopicClass} />
                    }),
                    new tab({
                        id: 7,
                        title: "Bronnen",
                        component: <Sources />
                    }),
                    new tab({
                        id: 8,
                        title: "Interessante links",
                        component: <Links />
                    })
                ]}
            />
            
        </div>
    )
}

export default SubTopic;
