
import React, { useState } from 'react';

import parse from "html-react-parser";

// redux
import { connectAdvanced, useSelector } from 'react-redux';

// routing
import { useParams } from 'react-router-dom';

// networking
import practiceCardApi from "../../../../../services/Networking/practiceCards";

// networking classes
import starring from "../../../../../services/Networking/practiceCards/classes/starring";

// local components
import AddPracticeCard from "../../../components/AddPracticeCard";

// components
import AlterContent from "../../../../../components/AlterContent";
import LocalModal from "../../../../../components/LocalModal";

// style
import "./Definition.scss";

// services
import cleanText from "../../../../../services/cleanText";

// constants
import { ADMIN, STUDENT, EDITOR } from "../../../../../services/access/constants";

const Definition = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    const [editDefiniton, toggleEditDefinition] = useState(false);

    // redux
    const user = useSelector(state => state.userReducer);

    return (
        <>{
            !props.data ? null :    
            <div className="definition" >
                <div>
                    <div className="definitionFront" >
                    {
                        parse(
                            cleanText({
                                textToClean: `${props.data.frontText}: `,
                                h1: true,
                                h2: true,
                                h3: true,
                                h4: true,
                                p: true,
                                bold: true
                            })
                        )
                    }
                    </div>
                    <div className="definitionBack" >
                    {
                        parse(
                            cleanText({
                                textToClean: props.data.backText,
                                h1: true,
                                h2: true,
                                h3: true,
                                h4: true,
                                p: true,
                            })
                        )
                    }
                    </div>
                </div>

                <div className="alterContentDef" >
                    <div className="creatorDef" >
                    {
                        props.data.isPersonal ? 
                            !user ? null :
                            !user.user ? null :
                            user.user.fullName ? user.user.fullName.split(" ")[0] : "Eigen" : "EtAlia"
                    }
                    </div>
                    <AlterContent
                        star={{
                            api: props.data.isFavourite ? practiceCardApi.unStarPracticeCards : practiceCardApi.starPracticeCards,
                            starApi: practiceCardApi.starPracticeCards,
                            unStarApi: practiceCardApi.unStarPracticeCards,

                            apiParams: new starring({
                                subTopicId: subTopicId,
                                cardId: props.data.practiceCardId
                            }),

                            starred: props.data.isFavourite

                        }}
                        edit={{
                            onClick:toggleEditDefinition
                        }}
                        delete={{
                            api: practiceCardApi.deletePracticeCardsBySubTopicId,
                            apiParams: props.data.practiceCardId,
                        }}
                        runOnSuccessfulDelete={props.fetchDefinitions}
                        rerender={props.triggerReRender}
                        isPersonal={props.data.isPersonal}
                    />
                </div>
                <LocalModal
                    show={editDefiniton}
                    toggleShow={toggleEditDefinition}
                    component={
                        <AddPracticeCard
                            toggleShow={toggleEditDefinition}
                            existingData={props.data}
                            type={1}
        
                            triggerReRender={props.triggerReRender}

                            isPersonal={props.data ? props.data.isPersonal : null}
                        />
                    }
                />
            </div>
        }</>
    )
}

export default Definition;
