
import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import sourcesApi from "../../../../../../services/Networking/sources";

// style
import "./AddSource.scss";

// components
import CkTextInput from "../../../../../../components/input/CkText";
import TextInput from '../../../../../../components/input/Text';
import Submit from "../../../../../../components/input/elements/Submit";
import SelectInput from "../../../../../../components/input/Select";
import ImageInput from "../../../../../../components/input/Image";
import Loader from "../../../../../../components/Loader";

// elements
import Title from "../../../../../../elements/ModalTitle";

// local classes
import sourceTypeClass from "./services/classes/sourceType";

// classes
import addSourceClass from "./services/classes/addSource/addSource";
import errorClass from "../../../../../../services/errors/classes";
import option from "../../../../../../components/input/elements/Select/classes/option";
import CkEditorConfig from "../../../../../../components/input/elements/CkText/classes";

// local constants
import { IMAGE, VIDEO } from "./services/constants";

// constants
import { SUCCESS, NO_CONTENT } from "../../../../../../services/errors/constants";

// services
import defaultTextInputErrors from "../../../../../../services/errors/defaultTextInputErrors";
import validateTextInput from "../../../../../../services/validate/textInput";
import convertVideoUrl from "../../../../../../services/video/convertVideoUrl";
import cleanText from "../../../../../../services/cleanText";
import defaultImageErrors from "../../../../../../services/errors/defaultImageErrors";
import valitateImage from "../../../../../../services/validate/image";

// services
import convertError from "../../../../../../services/errors/convertError";

const AddSource = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();

    const [sourceData, updateSourceData] = useState(props.existingData ? props.existingData : new addSourceClass());
    
    const [sourceType, updateSourceType] = useState(new sourceTypeClass());
    const updateTypeSource = (key, newValue) => {
        updateSourceType(new sourceTypeClass({
            sourceType: parseInt(newValue)
        }));
    }

    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [analysisError, updateAnalysisError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [noteError, updateNoteError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [typeError, updateTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [imageError, updateImageError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [videoError, updateVideoError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const validateField = ({
        valueKey,
        validate,
        updateError,
        customErrors
    }) => {
        // validate
        const valid = validate(sourceData[valueKey]);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {

        let validInput = true;

        // validate title
        if (!validateField({
            valueKey: "title",
            validate: validateTextInput,
            updateError: updateTitleError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate analyse
        if (!validateField({
            valueKey: "analyse",
            validate: validateTextInput,
            updateError: updateAnalysisError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // // validate note1
        // if (!validateField({
        //     valueKey: "note1",
        //     validate: validateTextInput,
        //     updateError: updateNoteError,
        //     customErrors: defaultTextInputErrors
        // })) validInput = false

        if (sourceType.sourceType === IMAGE) {
            // validate image
            if (!validateField({
                valueKey: "image",
                validate: validateTextInput,
                updateError: updateImageError,
                customErrors: defaultTextInputErrors
            })) validInput = false
        } else {
            // validate video
            if (!validateField({
                valueKey: "video",
                validate: validateTextInput,
                updateError: updateVideoError,
                customErrors: defaultTextInputErrors
            })) validInput = false
        }

        return validInput;

    }

    const save = async() => {
        toggleLoading(true);

        const validInput = await validateFields();

        if (validInput) {

            let response;

            // clean title
            sourceData.title = cleanText({
                textToClean: `${sourceData.title}`, 
                h1: true,
                h2: true,
                h3: true,
                h4: true,
                p: true,
                bold: true,
                list: true
            });
            
            if (sourceType.sourceType === VIDEO) {
                sourceData.video = convertVideoUrl(sourceData.video);
            }

            if (props.existingData) {
            } else {
                response = await sourcesApi.addSourcesBySubSubTopicId(
                    subTopicId, 
                    [sourceData]
                );
            }

            // nothing returned - something weard happend
            if (!response) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (response.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );

                props.triggerReRender((value) => !value);
                props.toggleShow(false);

            } else {
                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
            }
        }


        toggleLoading(false);
    }

    return (
        <div className="AddSource" >
            <Title
                title={props.existingData ? "BRON AANPASSEN" : "BRON TOEVOEGEN"}
            />

            <CkTextInput
                config={new CkEditorConfig({
                    toolbar: ['link'],
                })}

                title="TITEL"
                
                valueKey="title"
                dataClass={sourceData}

                errorClass={titleError}
                updateError={updateTitleError}
            />

            <CkTextInput
                config={new CkEditorConfig({
                    toolbar: ['bold', 'italic', 'link'],
                })}

                title="ANALYSE"
                
                valueKey="analyse"
                dataClass={sourceData}

                errorClass={analysisError}
                updateError={updateAnalysisError}
            />

            {/* <TextInput
                title="NOTITIE - Nog niet zichtbaar"
                
                valueKey="note1"
                dataClass={sourceData}

                errorClass={noteError}
                updateError={updateNoteError}
            /> */}

            <SelectInput
                title="BRON TYPE"

                options={[
                    new option({
                        value: IMAGE,
                        title: "Afbeelding"
                    }),
                    new option({
                        value: VIDEO,
                        title: "Video"
                    }),
                ]}

                valueKey="sourceType"
                dataClass={sourceType}
                updateDataClass={updateTypeSource}

                errorClass={typeError}
                updateError={updateTypeError}
            />

            {
                sourceType.sourceType === IMAGE ? 
                <ImageInput
                    title="AFBEELDING"

                    valueKey="image"
                    dataClass={sourceData}

                    errorClass={imageError}
                    updateError={updateImageError}
                />
                :
                <TextInput
                    title="VIDEO URL"
                    
                    valueKey="video"
                    dataClass={sourceData}

                    errorClass={videoError}
                    updateError={updateVideoError}
                />
            }

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }
        </div>
    )
}

export default AddSource;
