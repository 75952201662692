
// services
import settingPointsAndCorrect from "./services/settingPointsAndCorrect";

// constants
import { USER_CORRECT, USER_CORRECT_NO_RESPONSE, USER_HALF_CORRECT, USER_IN_CORRECT } from "../constants/userCorrectConstants";

const updateUserPoints = ({
    newCorrectValue,
    newPoints,

    questionHub,
    updatePoints,
    toggleUserCorrect,
}) => {
    const correctAnswers = questionHub.getCorrectAnswers();
    // handle no correct answer present for this question
    if (!correctAnswers || correctAnswers.length === 0) {
        if (newPoints) {
            updatePoints(newPoints);
        }
        toggleUserCorrect(USER_CORRECT_NO_RESPONSE);
        return;
    }
    if (correctAnswers.length > 1) {
        // this should not be possible yet
        return;
    }
    const correctAnswer = correctAnswers[0];

    // get total possible point based on the correct answer
    const totalPossiblePoints = correctAnswer.getCurrentValue("points");

    // get values
    const { userCorrect, userPoints } = settingPointsAndCorrect({
        newCorrectValue,
        newPoints,
        totalPossiblePoints
    });

    // set values
    // update values
    correctAnswer.updateProperty(
        "userCorrect",
        userCorrect
    );
    correctAnswer.updateProperty(
        "scorePoints",
        userPoints
    );
    
    if (newPoints === null && newCorrectValue === undefined) {
        updatePoints(userPoints);
        toggleUserCorrect(USER_HALF_CORRECT);
        return userPoints;
    }

    // update states
    if (userPoints) {
        // update states
        if (totalPossiblePoints === userPoints) {
            toggleUserCorrect(USER_CORRECT);
        } else if (userPoints !== 0) {
            toggleUserCorrect(USER_HALF_CORRECT);
        } else {
            toggleUserCorrect(USER_IN_CORRECT);
        }
        updatePoints(userPoints);
        return userPoints;
    } else {
        // update states
        updatePoints(userPoints);
        toggleUserCorrect(userCorrect ? USER_CORRECT : USER_IN_CORRECT);
        return userPoints;
    }
}

export default updateUserPoints;
