
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// style
import "./ResetPassword.scss";

// networking
import authApi from "../../services/Networking/authentication/authentication";

// classes local
import userDataClass from './services/classes/userDataClass/userDataClass';

// classes
import errorClass from '../../services/errors/classes/errorClass';

// services
import networking from '../../services/handleNetworking/networking';
import validateTextInput from '../../services/validate/textInput/textInput';
import convertError from '../../services/errors/convertError/convertError';
import identicalTexts from '../../services/validate/identicalTexts/identicalTexts';
import getTokenFromURL from '../../services/getTokenFromURL';

// components
import TextInput from '../../components/input/Text/Text';
import PasswordInput from '../../components/input/Password/Password';
import Submit from '../../components/input/elements/Submit/Submit';
import Loader from '../../components/Loader/Loader';
import Redux from '../../components/Redux/Redux';

// errors
import sendUserData from './services/errors/sendUserData/sendUserData';
import defaultErrors from '../../services/errors/defaultErrors/defaultErrors';
import defaultTextInputErrors from '../../services/errors/defaultTextInputErrors/defaultInputErrors';
import repeatPassword from '../../services/errors/repeatPassword/repeatPassword';


// constants
import { SUCCESS } from '../../services/errors/constants';
import { REDUX_ERRORS } from '../../components/Redux/services/constants';

const ResetPassword = (props) => {

    // redux
    const dispatch = useDispatch();

    // get token
    const token = getTokenFromURL(window.location.href).token[0];

    const [userData, updateUserData] = useState(new userDataClass());

    // replaces send button with confermation
    const [sendSuccessfull, updateSendSuccessfull] = useState(false);

    // errors send
    const [sendErrors, updateSendErrors] = useState(null);

    // errors fields
    const [emailError, updateEmailError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [passwordError, updatePasswordError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [repeatPasswordError, updateRepeatPasswordError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    // submit
    const [loading, toggleLoading] = useState(false);

    const sendResetPassword = async() => {

        // start loading
        toggleLoading(true);

        let validInput = true;

        // check if emailError not empty
        const emailValid = validateTextInput(userData.email);

        const emailErrorTemp = convertError({
            errorCode: emailValid,
            updateLocally: updateEmailError,
            customErrors: defaultTextInputErrors
        });

        if (!emailErrorTemp) {
            validInput = false;
        } else {
            if (emailErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(emailErrorTemp));
                validInput = false;
            }
        }

        // check if passwordError not empty
        const passwordValid = validateTextInput(userData.password);

        const passwordErrorTemp = convertError({
            errorCode: passwordValid,
            updateLocally: updatePasswordError,
            customErrors: defaultTextInputErrors
        });

        if (!passwordErrorTemp) {
            validInput = false;
        } else {
            if (passwordErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(passwordErrorTemp));
                validInput = false;
            }
        }

        // check if repeatPasswordError not empty
        const confirmPasswordValid = validateTextInput(userData.confirmPassword);

        const confirmPasswordErrorTemp = convertError({
            errorCode: confirmPasswordValid,
            updateLocally: updateRepeatPasswordError,
            customErrors: defaultTextInputErrors
        });

        if (!confirmPasswordErrorTemp) {
            validInput = false;
        } else {
            if (confirmPasswordErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(confirmPasswordErrorTemp));
                validInput = false;
            }
        }

        // check if passwordError == repeatPasswordError
        if (validInput) {
            const passwordsEqual = identicalTexts({
                listTexts: [userData.password, userData.confirmPassword],
    
                updateError: updateRepeatPasswordError,
                customErrors: repeatPassword,
            });

            if (!passwordsEqual) {
                validInput = false;
            } else {
                if (passwordsEqual.errorCode !== SUCCESS) {
                    // update global error list <-- Maybe not necissary?!
                    validInput = false;
                }
            }
        }

        if (validInput) {

            networking({

                toggleLoading: toggleLoading,
            
                accessableToAllUsers: false, // if it is true and unauthorized is returned, it means the user needs to re-login
                logOut: null,
            
                errorOnSuccess: true,
                
                api: authApi.resetPassword,
                apiParams: {
                    userData: userData, 
                    token: token
                },
            
                updateError: updateSendErrors,
                customErrors: sendUserData,
            
                history: null // to rerount to page not found if NOT_FOUND is returned
    
            });

        } else {

            // end loading
            toggleLoading(false);

        }

    };

    // check for send successfull
    useEffect(() => {

        if (sendErrors) {
            if (sendErrors.errorCode == SUCCESS) {
                updateSendSuccessfull(true);
            }
        }

    }, [sendErrors]);

    return (
        <div
            className="resetPasswordView"
        >

            <div
                className="resetPasswordTitle"
            >

                Wachtwoord Wijzigen

            </div>

            <TextInput
            
                title="E-MAIL"
                    
                valueKey="email"
                dataClass={userData}

                errorClass={emailError}
                updateError={updateEmailError}

            />

            <PasswordInput
                
                title="WACHTWOORD"
        
                valueKey="password"
                dataClass={userData}

                errorClass={passwordError}
                updateError={updatePasswordError}

            />

            <PasswordInput
                
                title="HERHAAL WACHTWOORD"
        
                valueKey="confirmPassword"
                dataClass={userData}

                errorClass={repeatPasswordError}
                updateError={updateRepeatPasswordError}

            />

            {
                
                loading ? <Loader /> :

                
                sendSuccessfull ?
                
                    <div
                        className="resetPasswordSent"
                    >
                        Verstuurd
                    </div>

                :
                
                    <Submit
                
                        value="VERSTUREN"
                        onClick={sendResetPassword}

                    />
            
            }

            <Redux
                showSuccess={true}
                varId={REDUX_ERRORS}
                
                reduxVar={sendErrors}
            />

        </div>
    )

}

export default ResetPassword;
