
// local elements
import TextField from "../elements/Text";

// style
import "./Text.scss"

const TextInput = (props) => {

    return (
        <>{
            !props.errorClass || (!props.dataClass && !props.clientData) ? null :
            
            <div className={props.useBaseStyle ? "textInputBase" : "textInput"} >
                {
                    !props.title ? null :
                    <div className="textInputTitle" >
                        {props.title}
                    </div>
                }
                {
                    props.errorClass.errorCode === 200 ? null :
                    <div className="showError" >
                        {
                            props.errorClass.description
                        }
                    </div>
                }
                <TextField
                    value={props.dataClass ? props.dataClass[props.valueKey] : props.clientData.getCurrentValue(props.valueKey)}
                    disabled ={props.disabled??false}

                    dataObject={props.dataClass}
                    clientData={props.clientData}

                    customUpdateFunc={props.customUpdateFunc}

                    valueKey={props.valueKey}
                    showError={props.errorClass.errorCode !== 200}
                    updateError={props.updateError}
                />
            </div>
        }</>
    )
}

export default TextInput;
