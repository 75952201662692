
import React, { useState } from 'react';

import parse from "html-react-parser";

// redux
import { useDispatch, useSelector } from 'react-redux';

// networking
import LinksApi from "../../../../../../services/Networking/Links";

// services
import cleanText from "../../../../../../services/cleanText";
import access from "../../../../../../services/access/accessCheck";

// local component
import LinkView from "../Link";
import AddThemeLink from "../AddThemeLink";
import AddLink from "../AddLink";

// components
import Add from "../../../../../../elements/Add";
import List from "../../../../../../components/List";
import AlterContent from "../../../../../../components/AlterContent";
import LocalModal from "../../../../../../components/LocalModal";

// style
import "./LinksTheme.scss";

// constants
import { EDITOR } from "../../../../../../services/access/constants";

const LinksTheme = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [addLink, toggleAddLink] = useState(false);

    const renderLink = (i, linkData) => {
        return (
            <LinkView
                key={i}
                data={linkData}

                linkThemeId={props.data.linkThemeId}
                rerender={props.triggerReRender}
            />
        )
    }

    return (
        <div className="LinksThemeView" >
            {
                !props.showTitles ? null :
                <div className="LinksThemeTitle" >
                    {
                        parse(cleanText({
                            textToClean: props.data.title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            bold: true,
                            list: true
                        }))
                    }
                    {
                        !user ? null : !user.user ? null : access({
                            accessLevel: EDITOR,
                            userRoles: user.user.roles
                        }) ?
                        <Add
                            onClick={() => toggleAddLink(true)}
                        />
                        : null
                    }
                    <LocalModal
                        show={addLink}
                        toggleShow={toggleAddLink}
                        component={
                            <AddLink
                                themeData={props.data}
                                toggleShow={toggleAddLink}

                                fetchLinks={props.fetchLinks}
                            />
                        }
                    />

                    <AlterContent
                        // edit={{
                        //     onClick:toggleEditChategoryFunc
                        // }}
                        delete={{
                            api: LinksApi.deleteLinkThemeByLinkThemeId,
                            apiParams: props.data.linkThemeId,
                        }}
                        rerender={props.triggerReRender}
                    />
                </div>
            }

            <List
                items={props.data.links}
                renderItem={renderLink}
            />
        </div>
    )
}

export default LinksTheme;
