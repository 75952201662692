
import { useState, useEffect } from "react";

// services
import assignmentApi from "../../../services/Networking/assignment/assignment";
import networking from "../../../services/handleNetworking/networking";

// components
import Redux from "../../Redux/Redux";

// constants
import { REDUX_ERRORS } from "../../Redux/services/constants";

const FetchAssignment = (props) => {

    // errors
    const [getAssignmentError, updateGetAssignmentError] = useState(null);

    const getAssignment = async() => {
        await networking({
            updateContent: props.updateAssignment,
            toggleLoading: props.toggleLoading,
            errorOnSuccess: false,

            api: assignmentApi.getAssignment,
            apiParams: props.assignmentUuid,

            updateError: updateGetAssignmentError,
        });
    }

    useEffect(() => {
        getAssignment();
    }, []);

    return (
        <>
            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={getAssignmentError}
            />
        </>
    )
}

export default FetchAssignment;
