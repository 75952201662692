
// react
import { useState } from "react";

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// style
import "./AddExam.scss";

// networking
import examsApi from "../../services/Networking/exams/exams";

// services
import validateTextInput from "../../services/validate/textInput/textInput";
import convertError from "../../services/errors/convertError/convertError";
import defaultTextInputErrors from "../../services/errors/defaultTextInputErrors/defaultInputErrors";

// components
import TextInput from "../input/Text/Text";
import Loader from "../Loader/Loader";
import SelectInput from "../input/Select/Select";
import Redux from "../Redux/Redux";

// elements
import Title from "../../elements/ModalTitle/Title";
import Submit from "../input/elements/Submit/Submit";

// classes
import ExamClass from "./services/classes/examClass";
import errorClass from "../../services/errors/classes/errorClass";
import option from "../input/elements/Select/classes/option/option";

// constants
import { NO_CONTENT, SUCCESS } from "../../services/errors/constants";
import { FINAL_EXAM, PRACTICE_EXAM } from "../../services/constants/examCategoryConstants";
import { INFORMATIVE } from "../../services/constants/topicTypeConstants";
import { REDUX_ERRORS } from "../Redux/services/constants";

const AddExam = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = props.topicId ? props.topicId : tijdvakID ? parseInt(tijdvakID.replace("t-", "")) : undefined;
    const subTopicId = props.subTopicId ? props.subTopicId : kaID ? parseInt(kaID.replace("st-", "")) : undefined;

    // routing
    let history = useHistory();
    let location = useLocation();

    // redux
    const dispatch = useDispatch();

    const [exam, updateExam] = useState(new ExamClass({
        category: props.subTopicType ? props.subTopicType === INFORMATIVE ? PRACTICE_EXAM : FINAL_EXAM : PRACTICE_EXAM
    }));

    // loading
    const [loading, toggleLoading] = useState(false);

    // errors
    const [sendError, updateSendError] = useState(null);

    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [categoryError, updateCategoryError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const validateFields = () => {

        // validate
        const valid = validateTextInput(exam["title"]);

        // add title error
        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateTitleError,
            customErrors: defaultTextInputErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }

        return true;
    }

    // save
    const save = async() => {
        
        toggleLoading(true);

        const validInput = await validateFields();

        if (validInput) {

            let response;

            if (!props.existingData) {
                response = await examsApi.addNewExamBySubTopicId({subTopicId: subTopicId, examObject: exam});
            }

            // nothing returned - something weard happend
            if (!response) {

                convertError({
                    errorCode: NO_CONTENT,
                    updateLocally: updateSendError,
                    customErrors: null
                });
                
            }

            // success returned, update with 200
            if (response.status === SUCCESS) {

                // display success
                convertError({
                    errorCode: response.status,
                    updateLocally: updateSendError,
                    customErrors: null
                });

                props.triggerReRender((value) => !value);
                props.toggleShow(false);

                // go to new exam page
                if (props.topicId && props.subTopicId) {
                    history.push(`geschiedenis/t-${props.topicId}/st-${props.subTopicId}/e-${response.payload}/Examen-Aanpassen`);
                } else {
                    history.push(`${location.pathname}/e-${response.payload}/Examen-Aanpassen`);
                }

            } else {
                // show auth errors publicly
                convertError({
                    errorCode: response.status,
                    updateLocally: updateSendError,
                    customErrors: null
                });
            }


        }

        toggleLoading(false);

    }

    return (
        <div className="AddExam" >
            <Title title={"Toets toevoegen"} />

            <TextInput
                title="Title"

                valueKey="title"
                dataClass={exam}

                errorClass={titleError}
                updateError={updateTitleError}
            />
            
            <SelectInput
                title="Toetscategorie"

                options={[
                    new option({
                        value: PRACTICE_EXAM,
                        title: "Oefentoets"
                    }),
                    new option({
                        value: FINAL_EXAM,
                        title: "Eindexamen"
                    }),
                ]}

                valueKey="category"
                dataClass={exam}
                updateDataClass={null}

                errorClass={categoryError}
                updateError={updateCategoryError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

            <Redux
                showSuccess={true}
                varId={REDUX_ERRORS}
                
                reduxVar={sendError}

            />

        </div>
    )
}

export default AddExam;
