
import React, { useState } from 'react';

// classes
import errorClass from "../../../../services/errors/classes";

// style
import "../assets/style.scss";

const Password = (props) => {
    
    const [typed, toggleTyped] = useState(false);

    const onChange = (newValue) => {

        const newText = newValue.target.value;

        props.dataObject[props.valueKey] = newText;

        toggleTyped(true);

        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: 200
            }));
        }

    }

    return (
        <input 
            type="password" 
            className={props.showError ? "textError" : null}

            name={props.valueKey}

            value={typed ? undefined : props.value} 
            onChange={onChange} 
        />
    )

}

export default Password;
