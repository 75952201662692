
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../services/redux/actions/globalErrors.action";

// router
import { useLocation, useHistory } from 'react-router-dom';

// services
import access from "../../../services/access/accessCheck";
import deleteTopicErrors from "./services/errors";
import convertError from "../../../services/errors/convertError";

// networking
import topicApi from "../../../services/Networking/topic";

// components
import AlterContent from "../../../components/AlterContent";
import LocalModal from "../../../components/LocalModal";

// local components
import AddTopic from "../AddTopic";

// style
import "./CardStyle.scss";

// constants
import { ADMIN } from "../../../services/access/constants";

function Card(props) {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    let location = useLocation();

    // load
    const [loadingDelete, toggleLoadingDelete] = useState(false);

    const [editTopic, toggleEditTopic] = useState(false);

    const route = (e) => {
        history.push(`${location.pathname}/t-${props.data.topicId}`);
    }

    return (
        <>
        <div 
            onClick={() => route()}
            // to={`geschiedenis/t-${props.data.topicId}`}
            className="TopicCardStyling"
        >
            <div className="topicImageHolder" >
                <img 
                    className="topicImgCard"
                    src={props.data.backgroundImage} 
                    alt={`${props.data.title} afbeelding`}
                />
            </div>
            <div className="topicCardText" >
                <div className="topicCardTitle">
                    {props.data.title}
                </div>
                {
                    props.data.subtitle ?
                    <div className="topicCardDescipt">
                        {props.data.subtitle}
                    </div>
                    : null
                }
            </div>
            
            {
                !user ? null : !user.user ? null : access({
                    accessLevel: ADMIN,
                    userRoles: user.user.roles
                }) ? 
                    <div className="AlterContentTopic">
                        <AlterContent 
                            edit={{
                                onClick:toggleEditTopic,
                            }}
                            delete={{
                                api: topicApi.deleteTopicByTopicID,
                                apiParams: props.data.topicId,
                                accessLevel: ADMIN
                            }}

                            triggerReRender={props.triggerReRender}
                        />
                    </div> : null
            }
        </div>

        <LocalModal
            show={editTopic}
            toggleShow={toggleEditTopic}
            component={
                <AddTopic
                    toggleShow={toggleEditTopic}
                    existingData={props.data}

                    triggerReRender={props.triggerReRender}
                />
            }
        />
        </>
    )
}

export default Card;
