
const getScatterQuestionNumber = (seenValues, lastQuestionNumber, questionNumber, studentPercentage) => {
    let newQuestionsNumber = questionNumber;
    while (seenValues.has(`${newQuestionsNumber}_${studentPercentage}`)) {
        const changeValue = 0.015;
        if (lastQuestionNumber === questionNumber) {
            newQuestionsNumber -= changeValue;
        } else if (questionNumber === newQuestionsNumber) {
            newQuestionsNumber = newQuestionsNumber + changeValue;
        } else if (questionNumber === 1) {
            newQuestionsNumber += changeValue;
        } else if (newQuestionsNumber - questionNumber > 0) {
            newQuestionsNumber = questionNumber - (newQuestionsNumber - questionNumber);
        } else if (questionNumber - newQuestionsNumber < 0) {
            newQuestionsNumber = questionNumber + (newQuestionsNumber - questionNumber) + changeValue;
        }
    }

    return newQuestionsNumber;
}

export default getScatterQuestionNumber;
