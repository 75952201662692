
function userDataClass({

    email = "",
    password = "",
    confirmPassword = "",
    
} = {}) {

    this.email = email;
    this.password = password;
    this.confirmPassword = confirmPassword;

}

export default userDataClass;
