
import React, { useState, useEffect } from 'react';

// style
import "./PointsEditable.scss";

// components
import List from "../../../../../../../../../../components/List";

// constants
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../../../../../../../services/constants/typeConstants";

const PointsEditable = (props) => {

    const updatePointsOneCorrect = (points) => {

        props.updateUserPoints({
            userPoints: points
        });

        // toggle close
        props.togglePointsEditable(false);
    }

    const renderPointsOptions = (i, points) => {

        return (
            <div
                key={i}
                className="pointsOptions"
                onClick={() => updatePointsOneCorrect(points)}
            >{

                `Punten: ${points}
                ${
                    props.questionType === MULTIPLE_CHOICE_QUESTION || 
                    (
                        props.questionType === ASSAY_QUESTION && 
                        props.questionHub.getAnswers().length === 1
                    ) ? 
                    ` / ${props.questionPoints}`
                    : ""
                }
                `

            }</div>
        )
    }

    const renderAnswersPoints = (i, answer) => {
        return (
            <div
                key={i}
            >
                {answer.title}
            </div>
        )
    }

    return (
        <div className="PointsEditableHolder" >{
            !props.questionHub || !props.questionHub.getAnswers() ? "" :
            // make sure there are answers
            props.questionHub.getAnswers().length === 0 ?
            ""
            :
            // find for one correct answer options
            (props.questionHub.getCurrentValue("type") === MULTIPLE_CHOICE_QUESTION && props.questionHub.oneCorrect()) || 
            (props.questionHub.getCurrentValue("type") === ASSAY_QUESTION && props.questionHub.getAnswers().length === 1) ? 
            
                <List
                    items={props.questionHub.getCurrentValue("totalPoints") ? Array(props.questionHub.getCurrentValue("totalPoints")+1).fill().map((x,i)=>i) : []}
                    renderItem={renderPointsOptions}
                />

            :
            // can only get here with multiple choice question with more than 1 correct answer - 0 correct checked in parent
            <List
                items={props.questionHub.getAnswers()}
                renderItem={renderAnswersPoints}
            />
        }</div>
    )
}

export default PointsEditable;
