
import { useState } from "react";

// styles
import "./AddAssignedExamItem.scss";

// components
import AddNewAssignedExam from "../AddNewAssignedExam/AddNewAssignedExam";

// constants
import { ADD_EXAM_ITEM } from "./services/constants";

const AddAssignedExamItem = (props) => {
    
    return (
        <>{
            props.createNewItemPerType[ADD_EXAM_ITEM] === true ?
            <AddNewAssignedExam
                assignmentUuiD={props.assignmentUuiD}
            />
            :
            <div>ToDo</div>
        }</>
    )
}

export default AddAssignedExamItem;
