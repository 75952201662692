
// react
import React, { useState, useEffect } from 'react';

// routing
import { useHistory, useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// styling
import "./SubjectStyle.scss";

// networking
import topicApi from "../../services/Networking/topic";

// services
import access from "../../services/access/accessCheck";
import convertError from "../../services/errors/convertError";
import userActions from "../../services/redux/actions/user.action";
import logOutErrors from "../../services/errors/logOutErrors";

// local services
import topicErrors from "./errors";

// components
import List from "../../components/List";
import LocalModal from "../../components/LocalModal";
import Feedback from "../../components/Feedback";

// components local
import Card from "./Card";
import Loader from "../../components/Loader";
import AddTopic from "./AddTopic";

// elements
import Add from "../../elements/Add";

// constants
import { ADMIN } from "../../services/access/constants";
import { SUCCESS, UNAUTHORIZED, NOT_FOUND, NO_CONTENT } from "../../services/errors/constants";
import { INFORMATIVE, PREPARATORY } from '../../services/constants/topicTypeConstants';

function Subject(props) {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    const location = useLocation();

    // rendering
    const [rerender, triggerReRender] = useState(true);
    const triggerReRenderFunc = () => {
        triggerReRender((value) => !value);
    }

    const [addTopic, toggleAddTopic] = useState(false);

    const [topics, updateTopics] = useState(null);
    const [loadingTopics, updateLoadingTopics] = useState(true);

    // logs user out
    const logOut = () => {
        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // log user in
        dispatch(userActions.logOut());

        // display success
        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: SUCCESS,
                updateLocally: null,
                customErrors: logOutErrors
            }))
        );
    }
    
    const fetchSubject = async () => {

        const topicTypes = !user ? [INFORMATIVE] : !user.user ? [INFORMATIVE] : access({
            accessLevel: ADMIN,
            userRoles: user.user.roles
        }) ? [INFORMATIVE, PREPARATORY] : INFORMATIVE

        const returned = await topicApi.getTopicsByBookId({bookId: 1, type: topicTypes});

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_CONTENT,
                    customErrors: null
                })
            ));

            // something weard happend
            updateLoadingTopics(false);
            return null;
        }

        if (returned.status === SUCCESS) {

            // make sure list isn't empty
            if (returned.payload.length === 0) {
            
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        customErrors: topicErrors
                    }))
                );

            } else {
                // add success
                updateTopics(returned.payload);
            }

            updateLoadingTopics(false);

        } else if (returned.status === UNAUTHORIZED) {
            // logout
            logOut();

            // add error to global error list
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));

            return null;

        } else if (returned.status === NOT_FOUND) {
            // page not found
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status,
                    customErrors: topicErrors
            })));

            // push to page not found page
            history.push("/niet-gevonden");
        } else {
            // add custom errors
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status,
                    customErrors: topicErrors
            })));
        }

        updateLoadingTopics(false);
    }

    // fetch topics
    useEffect(() => {
        if (!topics) {
            updateLoadingTopics(true);
        }

        fetchSubject();
    
    }, [rerender, location]);

    // function that returns item in list
    const renderCard = (i, topicData) => {
        return (
            <Card 
                key={i}
                data={topicData}

                triggerReRender={triggerReRender}
            />
        );
    }

    return (
        <div className="SubjectView">

            {

                loadingTopics ? <Loader /> :

                <List
                    items={topics}
                    renderItem={renderCard}
                />

            }
            {
                !user ? null : !user.user ? null : access({
                    accessLevel: ADMIN,
                    userRoles: user.user.roles
                }) ? 
                <Add
                    onClick={() => toggleAddTopic(true)}
                /> : null
            }

            <LocalModal
                show={addTopic}
                toggleShow={toggleAddTopic}
                component={
                    <AddTopic
                        toggleShow={toggleAddTopic}

                        triggerReRender={triggerReRender}
                    />
                }
            />
        </div>
    );
}

export default Subject;
