
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../errors/constants";

const validateTextInput = (textValue) => {

    switch (textValue) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;

        default:
            return SUCCESS;
    }
}

export default validateTextInput;
