
import React, { useState, useEffect } from 'react';

// style
import "./AssayAnswers.scss";

// classes
import errorClass from "../../../../../../services/errors/classes";

// services
import convertError from '../../../../../../services/errors/convertError/convertError';

// services local
import findEditedAnswer from "../../services/findEditedAnswer";
import findCorrectAnswer from "../../services/findCorrectAnswer";

// components local
import ShowAnswer from "./components/ShowAnswer";
import AnswerInput from "./components/AnswerInput";
import CheckAnswer from "./components/checkAnswer";

// components
import List from "../../../../../../components/List";
import CkTextInput from "../../../../../../components/input/CkText";

// constants
import { VIEW, MAKE, CHECK, ASSIGNMENT, EDIT} from "../../../../services/tabConstants";
import { SUCCESS } from "../../../../../../services/errors/constants";

const AssayAnswers = (props) => {

    // show questions in list
    const renderShowAnswers = (i, answer) => {

        // make new for edit
        if (props.examView === VIEW) {
            return (
                <ShowAnswer
                    key={i}
    
                    title={answer.getAnswer().title}
                    correct={answer.getAnswer().correct}
    
                />
            )
        } else if (props.examView === CHECK) {

            return (
                <div key={i} className="holdAnswersQ" >
                    <div className="questionHeader" >
                        Jouw antwoord
                    </div>
                    <ShowAnswer
                        key={i}
        
                        title={answer.getCurrentValue("userResponse")}
        
                    />

                    <div className="questionHeader" >
                        Het correcte antwoord
                    </div>
                    <ShowAnswer
                        key={i}
        
                        title={answer.getCurrentValue("title")}
        
                    />

                    <CheckAnswer
                        // checking
                        updateUserPoints={props.updateUserPoints}
                        userCorrect={props.userCorrect}
                    />

                </div>
            )
        } else if (props.examView === EDIT) {

            const updateTitle = (valueKey, newText) => {
                
                // update property
                answer.updateProperty(valueKey, newText);

            }

            return (
                
                answer.answerDeleted() ? null :
                <div
                    key={i}
                    className="holdAnswersQ"
                >
                    <CkTextInput
                        valueKey="title"
                        dataClass={answer.getAnswer()}

                        customUpdateFunc={updateTitle}

                        useBaseStyle={true}
                        
                        warningClass={props.answerWarning}
                        updateWarning={props.updateAnswerWarning}
                    />
                </div>
            )
        }
        
    }

    const renderAnswerInput = (i, answer) => {

        return (
            answer.answerDeleted() ? null :
                <AnswerInput
                    key={i}

                    handedIn={props.handedIn}

                    answerHub={answer}
                    questionHub={props.questionHub}

                    questionError={props.questionError}
                    updateQuestionError={props.updateQuestionError}

                    answer={answer}
                    answerId={answer.answerId}
                />
        )
    }

    const renderCorrectList = () => {
        
        if (props.answerAccess) {
            return (
                <List
                    items={props.answers}
                    renderItem={renderShowAnswers}
                />
            )
        } else if (props.examView === MAKE) {
            return (
                <List
                    items={props.answers}
                    renderItem={renderAnswerInput}
                />
            )
        }

    }

    return (
        <div className="AssayAnswer" >{
            
            renderCorrectList()
            
        }</div>
    )
}

export default AssayAnswers;
