
// components
import FilterOption from "../../../../../../../../../../components/Filters/Filter/components/FilterItem/FilterOption";

const renderFilterOption = (i, data) => {
    return (
        <FilterOption
            key={i}
            data={data}
        />
    )
}

export default renderFilterOption;
