
// services local
import getTitle from "./getTitle";

const getTakenExamTitles = ({
    takenExams
} = {}) => {

    if (!takenExams) {
        return null;
    }

    let takenExamsObjs = [];

    let count = 0;
    takenExams.map((takenExam) => {

        const newTakenExamObj = {
            takenExamId: takenExam,
            title: getTitle(count)
        };

        takenExamsObjs = [...takenExamsObjs, newTakenExamObj];

        count = count + 1;
    });

    return takenExamsObjs;

}

export default getTakenExamTitles;
