
// react
import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// styles
import "./SavingNewText.scss";

// networking
import textExplenationApi from "../../../../../../services/Networking/textExplenation";

// services
import convertError from "../../../../../../services/errors/convertError";
import validateTextInput from "../../../../../../services/validate/textInput";
import defaultTextInputErrors from "../../../../../../services/errors/defaultTextInputErrors";
import addTextErrors from "./services/errors/addTextErrors";

// classes
import newTextClass from "../../../../../../services/Networking/textExplenation/classes";

// components
import Loader from '../../../../../../components/Loader';

// constants
import { SUCCESS, NO_CONTENT, UNAUTHORIZED } from "../../../../../../services/errors/constants";

const SavingNewText = (props) => {

    // redux
    const dispatch = useDispatch();

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    const [loading, toggelLoading] = useState(false);

    const validateField = ({
        validate,
        updateError,
        customErrors
    }) => {

        // validate
        const valid = validate(props.newTextClass.textExplanation);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const saveNewText = async() => {

        if (props.editText) {
            props.toggleLoading(true);
        } else {
            toggelLoading(true);
        }        

        let validInput = true;

        // validate frontText
        if (!validateField({
            validate: validateTextInput,
            updateError: props.updateNewTextError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        if (validInput) {

            let response;

            if (props.editText) {
                response = await textExplenationApi.putTextExplByTextId(props.textId, props.newTextClass);
            } else {
                response = await textExplenationApi.addTextExplBySubSubTopicId(subTopicId, [props.newTextClass]);
            }

            // nothing returned - something weard happend
            if (!response) {
    
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }
    
            // success returned, update with 200
            if (response.status === SUCCESS) {
    
                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
                
                if (props.editText) {
                    props.toggleTextEditable((value) => !value);
                } else {
                    props.deleteNewText(new newTextClass());
                }
                props.triggerReRender((value) => !value);
            } else {
                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: addTextErrors
                    }))
                );
            }
        }

        if (props.editText) {
            props.toggleLoading(false);
        } else {
            toggelLoading(false);
        }
    }

    return (
        <div className="saveOrDelNewText" >
            <div
                className="saveNewText"
                onClick={() => saveNewText()}
            >
                {
                    loading ? <Loader /> :
                    "Opslaan"
                }
            </div>

            <div className="deleteNewText" onClick={() => props.deleteNewText(props.newTextClass)} >
                Aanpassingen verwijderen
            </div>
        </div>
    )
}

export default SavingNewText;
