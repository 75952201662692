
// react
import React, { useState, useEffect } from 'react';

// styles
import "./ColumnList.scss";

const ColumnList = (props) => {

    const [dataSelected, updateDataSelected] = useState(props.items ? props.items[0] : null);

    const list = () => {
        if (!props.items) {
            return null;
        }

        const items = props.items.map((itemData, i) => {
            if (itemData === undefined || itemData === null) {
                
                // handel error!

            } else {
                return (
                    <div
                        className="listItem"
                        onClick={() => updateDataSelected(itemData)}
                    >{
                    props.renderItem(
                        i,
                        itemData,
                        dataSelected
                    )
                    }</div>
                )
            }
        })

        return items;
    }

    return (
        <div className="ColumnListView">
            <div className="LeftColumn" >{
                list()
            }</div>
            <div className="RightColumn" >
                {props.renderInfo ? props.renderInfo(dataSelected, updateDataSelected) : null}
            </div>
        </div>
    )
}

export default ColumnList
