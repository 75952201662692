
import { useState, useEffect } from "react";

// style
import "./SharingAssignment.scss";

// services
import networking from "../../../../../../services/handleNetworking/networking";
import assignmentLinkApi from "../../../../../../services/Networking/assignmentLink/assignmentLink";

// components
import Loader from "../../../../../../components/Loader";
import Redux from "../../../../../../components/Redux/Redux";
import CopyToClipboard from "../../../../../../elements/CopyToClipboard/CopyToClipboard";
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import List from "../../../../../../components/List";

// constants
import { REDUX_ERRORS } from "../../../../../../components/Redux/services/constants";
import { SUCCESS } from "../../../../../../services/errors/constants";
import AssignmentLink from "./components/AssignmentLink/AssignmentLink";

const SharingAssignment = (props) => {

    // data
    const [activeLink, updateActiveLink] = useState(null);
    const [assignmentLinks, updateAssignmentLinks] = useState([]);

    // state
    const [loadingGet, toggleLoadingGet] = useState(false);
    const [loadingPost, toggleLoadingPost] = useState(false);
    const [loadingDeactivate, toggleLoadingDeactivate] = useState(false);

    // errors
    const [getAssignmentLinksError, updateGetAssignmentLinksError] = useState(null);
    const [postAssignmentLinksError, updatePostAssignmentLinksError] = useState(null);
    const [deactivateError, updateDeactivateError] = useState(null);

    const fetchAssignmentLinks = async() => {
        const returned = await networking({
            updateContent: updateAssignmentLinks,
            toggleLoading: toggleLoadingGet,

            errorOnSuccess: false,

            api: assignmentLinkApi.getAssignmentLinks,
            apiParams: props.assignment?.id,

            updateError: updateGetAssignmentLinksError,
        });
        if (returned && returned.status === SUCCESS) {
            const links = returned.payload;
            let activeLinks = links.filter(link => link.active === true);
            if (activeLinks?.length > 1) {
                // ERROR: should currently not be possible
            }
            if (activeLinks?.length === 1) {
                updateActiveLink(activeLinks[0]);
            }
        }
    }
    useEffect(() => {
        fetchAssignmentLinks();
    }, []);

    const postAssignmentLink = async() => {

        const data = {
            "canAIGrade": props.assignment.getCurrentValue("canAIGrade"),
            "canViewFeedback": props.assignment.getCurrentValue("canViewFeedback"),
            "canStudentGrade": props.assignment.getCurrentValue("canStudentGrade"),
            "canSubmittAfterDeadline": props.assignment.getCurrentValue("canSubmittAfterDeadline"),
            "maxAssignees": 10000
        };

        const returned = await networking({
            toggleLoading: toggleLoadingPost,

            errorOnSuccess: false,

            api: assignmentLinkApi.postAssignmentLink,
            apiParams: {
                assignmentUuid: props.assignment?.id,
                data: data
            },

            updateError: updatePostAssignmentLinksError,
        });
        if (returned && returned.status === SUCCESS) {
            fetchAssignmentLinks();
        }
    }
    
    const deactivateLink = async() => {
        const returned = await networking({
            toggleLoading: toggleLoadingDeactivate,

            errorOnSuccess: false,

            api: assignmentLinkApi.putDeactivateAssignmentLink,
            apiParams: {
                assignmentUuid: props.assignment?.id,
                token: activeLink?.token
            },

            updateError: updateDeactivateError,
        });

        if (returned && returned.status === SUCCESS) {
            fetchAssignmentLinks();
        }
    }

    const renderLink = (i, data) => {
        return (
            <AssignmentLink
                assignmentLink={data}
                assignment={props.assignment}
            />
        )
    }

    return (
        <div
            className="sharingAssignmentLinkView"
        >{
            loadingGet ? <Loader /> :
            <>
                <div
                    className="sharingAssignmentLinkTitle"
                >
                    Met studenten delen

                    {
                        activeLink === null ?
                            loadingPost === true ? <Loader /> :
                            <div className="sharingAssignmentLinkAddButton" onClick={postAssignmentLink} >
                                Deellink genereren
                            </div>
                        :
                        loadingDeactivate === true ? <Loader /> :
                            <div
                                className="shareContentClickable"
                                onClick={() => deactivateLink()}
                            >{
                                activeLink?.active ? "Deactiveer link" : "Activeer link"
                            }</div>
                    }
                </div>

                {
                    assignmentLinks.length === 0 ?
                    <div className="sharingAssignmentLinkNoLinks">Nog geen deellink gegenereerd.</div>
                    :
                    activeLink === null ? <div className="sharingAssignmentLinkNoLinks">Geen active deellink voor deze assignment gevonden, genereer een nieuwe deellink.</div> :
                    <>
                        <List
                            items={assignmentLinks}
                            renderItem={renderLink}
                        />
                    </>
                }

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentLinksError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={postAssignmentLinksError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={deactivateError}
                />
            </>
        }</div>
    )
}

export default SharingAssignment;
