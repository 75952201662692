
// constants
import { LOGIN, LOG_OUT } from "../constants";

const userActions = {
    login,
    logOut
};

function login(user) {
    return {
        type: LOGIN,
        payload: user
    };
}

function logOut(user) {
    return {
        type: LOG_OUT,
        payload: user
    };
}

export default userActions;
