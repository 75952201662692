
import { useState, useEffect } from "react";

// routing
import { useParams, useLocation } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// styles
import "./ReviewFeedbackOnAssignedExam.scss";

// classes
import ClientData from "../../../../services/classes/clientData/ClientData";
import StickyButtonClass from "../../components/StickyButtons/StickyButtonClass";

// services
import networking from "../../../../services/handleNetworking/networking";
import assignmentApi from "../../../../services/Networking/assignment/assignment";
import takenExamsApi from "../../../../services/Networking/takenExams/takenExams";
import formatCheckedAnswers from "../CheckExam/services/formatCheckedAnswers";
import assignmentUserApi from "../../../../services/Networking/AssignmentUser/AssignmentUser";

// components
import ExamInfoAssignedExam from "../../components/ExamInfo/ExamInfoAssignedExam";
import ExamFeedback from "../../components/ExamFeedback/ExamFeedback";
import Title from "../../components/Title";
import Loader from "../../../../components/Loader";
import Redux from "../../../../components/Redux/Redux";
import List from "../../../../components/List";
import Question from "../../components/Question";
import initQuestionHubs from "../../services/initQuestionHubs";
import StickyButtons from "../../components/StickyButtons";
import ExamPointsGraph from "../../components/Statistics/ExamPointsGraph";
import AutogradingPermissions from "../../components/AutogradingPermissions/AutogradingPermissions";
import GenerateFeedbackButton from "./components/GenerateFeedbackButton";

// constants
import { REDUX_ERRORS } from "../../../../components/Redux/services/constants";
import { CHECK } from "../../services/tabConstants";
import { MAIN_OPERATION_SUCCESS, SUCCESS } from "../../../../services/errors/constants";

const ReviewFeedbackOnAssignedExam = (props) => {

    // routing
    let { assignmentID, examID } = useParams();

    // redux
    const user = useSelector(state => state.userReducer);

    /*
        props:
            - SelectedViewType - might be in filters, exam or question
            - Title
            - Questions
    */
    // data
    const [exam, updateExam] = useState(null);
    const [assignment, updateAssignment] = useState(null);

    // states
    const [loadingGet, toggleLoadingGet] = useState(true);
    const [getAssignmentLoading, toggleGetAssignmentLoading] = useState(false);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [showExamInfo, toggleShowExamInfo] = useState(true);
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(true);
    const [showAutogradingPermissions, toggleShowAutogradingPermissions] = useState(false);

    // errors
    const [getExamError, updateGetExamError] = useState(null);
    const [getAssignmentError, updateGetAssignmentError] = useState(null);

    const formatExam = (fetchedExam) => {
        const formattedQuestions = initQuestionHubs({ sentQuestions: fetchedExam?.questions });
        updateExam(
            new ClientData({
                id: fetchedExam.examUUID,
                childLists: {
                    feedback: [],
                    questions: formattedQuestions,
                },
                sent: fetchedExam,
            })
        )
    }
    const getExam = async() => {
        const returned = await networking({
            updateContent: formatExam,
            toggleLoading: toggleLoadingGet,
            errorOnSuccess: false,

            api: assignmentUserApi.getLatestSubmission,
            apiParams: {
                assignmentUuid: assignmentID,
                itemUuid: examID
            },

            updateError: updateGetExamError,
        });
    }

    const getAssignment = async() => {
        await networking({
            updateContent: updateAssignment,
            toggleLoading: toggleGetAssignmentLoading,
            errorOnSuccess: false,

            api: assignmentApi.getAssignment,
            apiParams: assignmentID,

            updateError: updateGetAssignmentError,
        });
    }

    useEffect(() => {
        getExam();
        getAssignment();
    }, []);
    const save = async() => {
        const formattedFeedbackAndPoints = formatCheckedAnswers(
            examID,
            exam?.childLists?.questions
        );
        const returned = await networking({
            toggleLoading: toggleLoadingSaving,
            errorOnSuccess: false,

            api: takenExamsApi.postCheckedQuestionByExamId,
            apiParams: {
                examId: examID, 
                order: exam?.sent?.order, 
                checkedQuestions: formattedFeedbackAndPoints
            },

            updateError: updateGetAssignmentError,
        });

        if (returned && (returned.status === SUCCESS || returned.status === MAIN_OPERATION_SUCCESS)) {
            getExam();
        }
    }

    const renderQuestion = (i, questionHub) => {
        return (
            <Question
                key={questionHub.questionId}

                examView={CHECK}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    return (
        <div
            className="ReviewFeedbackOnAssignedExamView"
        >{
            loadingGet && !exam ? <Loader /> :
            !exam ? "Geen tentamen geselecteerd" :
            <>
                <StickyButtons
                    buttons={[
                        loadingSaving ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Opslaan",
                            onClickFunc: save,
                            link: null,
                    
                        }),
                        new StickyButtonClass({
                            customComponent: assignment?.canAIGrade ? <GenerateFeedbackButton exam={exam} showAutogradingPermissions={showAutogradingPermissions} toggleShowAutogradingPermissions={toggleShowAutogradingPermissions} /> : <></>
                        }),
                    ]}
                />
                
                <Title
                    title={exam?.getCurrentValue("title")}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}

                    loading={loadingGet || getAssignmentLoading}
                />

                {
                    showExamInfo ?
                    <>
                        <ExamInfoAssignedExam
                            exam={exam}
                            assignment={assignment}
                            assignmentUuid={assignmentID}

                            view={CHECK}
                        />

                        <ExamFeedback
                            exam={exam}
                            updateExam={updateExam}
                        />
                    </> : null
                }

                <ExamPointsGraph
                    exam={exam}
                    takenExamUuId={exam?.sent?.uuid}
                />

                {
                    !exam || !exam?.childLists?.questions ? null :
                    <List
                        items={exam?.childLists?.questions}
                        renderItem={renderQuestion}
                    />
                }

                {/* <AutogradingPermissions /> */}

                {/* 
                    Some settings:
                        Get latest exam handed in for 
                */}
                
                {/* <CheckExam
                    examId={"40deeefd-c84f-11ee-b1d1-000d3ab074e6"}
                    orderNumber={25}
                /> */}
                <AutogradingPermissions
                    examID={examID}
                    examOrder={exam?.sent?.order}
                    runNext={getExam}

                    waitForGeneratedFeedback={true}
                    showAutogradingPermissions={showAutogradingPermissions}
                    toggleShowAutogradingPermissions={() => toggleShowAutogradingPermissions(false)}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={getExamError}
                />

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentError}
                />
            </>
        }</div>
    )
}

export default ReviewFeedbackOnAssignedExam;
