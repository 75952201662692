
const getTitle = (count) => {

    switch (count) {
        case 0:
            return "Vorige poging"
            break;
        
        case 1:
            return "Op een na laatste poging"
            break;
            
        case 2:
            return "Op twee na laatste poging"
            break;
    
        case 3:
            return "Vier pogingen geleden"
            break;
    
        case 4:
            return "Vijf pogingen geleden"
            break;
    
        default:
            break;
    }

}

export default getTitle
