
const formatTime = (date) => {
    if (!date) return undefined;
    if (date instanceof Date) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }
    return date
}

export default formatTime
