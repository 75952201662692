
import { Link } from "react-router-dom";

// services
import access from "../../../../../../services/access/accessCheck/access";
import createExamURL from "../../../../../../services/createExamURL/createExamURL";

// style
import "./ExamPrepExam.scss";

// constants
import { EDITOR } from "../../../../../../services/access/constants";

const Exam = (props) => {

    const returnURL = (examPage) => {

        return createExamURL({
            subjectId: "s-1",
            bookId: "b-1",
            tijdvakID: `t-${props.topicId}`,
            kaID: `st-${props.subtopicId}`,
            examID: `e-${props.examId}`,
        
            examPage,
        });
    }

    return (
        <Link
            className="examPrepRowItemExam"

            to={
                !props.user ? 
                returnURL("Examen-Maken")
                : !props.user.user ? 
                returnURL("Examen-Maken")
                : access({
                    accessLevel: EDITOR,
                    userRoles: props.user.user.roles
                }) ? 
                returnURL("Examen-Aanpassen")
                : 
                returnURL() // returnURL("Examen-Maken")
                }
        >
            <div className="examPrepRowItemTitleExam" >{
                props.data.title
            }</div>
        </Link>
    )

}

export default Exam;
