
// services local
import initQuestionHubs from "../initQuestionHubs/initQuestionHubs";

const initExam = ({

    fetchedExam,

    updateExamClass,
    updateQuestionHubs,

} = {}) => {

    if (!fetchedExam || !updateExamClass || !updateQuestionHubs) {
        return null;
    }

    updateExamClass(fetchedExam);

    updateQuestionHubs(() => {

        return initQuestionHubs({sentQuestions: fetchedExam.questions});

    });

}

export default initExam;
