
// networking
import examsApi from "../../../../../../services/Networking/exams/exams";

// services
import networking from "../../../../../../services/handleNetworking/networking";

// local errors
import handInErrors from "../../../../services/errors/handInErrors/handInErrors";

// constants
import { SUCCESS } from "../../../../../../services/errors/constants";

const postExam = async({

    // info to send
    postExam,
    oldState,

    // errors
    updateError,

    // info from parent function
    examIsUpdatedCurrentState,
    
} = {}) => {

    // // check if there are any questions to post - no posting exam without questions from here, ever!
    // if (postExam.questions.length === 0 && postExam.questionsToRemove.length === 0) {
    //     return examIsUpdatedCurrentState;
    // }

    // send
    const postExamSentSuccess = await networking({

        accessableToAllUsers: false,
                
        api: examsApi.addExamBySubTopicId,
        apiParams: {
            examObject: postExam,
            currentState: oldState,
        },
    
        updateError: updateError,
        customErrors: handInErrors,

    });

    if (postExamSentSuccess.status == SUCCESS) {
        examIsUpdatedCurrentState = true;
    }

    return {
        examIsUpdated: examIsUpdatedCurrentState, 
        newExamId: postExamSentSuccess.payload
    };

}

export default postExam;

// {
//     "uuid":"a65677de-397b-432f-9be2-c646b3bb4236",
//     "title":"First Assigned Exam!",
//     "category":3,
//     "state":1,
//     "questions":[
//         {
//             "questionId":923,
//             "title":"<p>t</p>",
//             "type":1,
//             "image":"",
//             "category":1,
//             "state":2,
//             "answers":[
//                 {
//                     "answerId":1554,
//                     "title":"<p>t</p>",
//                     "correct":true,
//                     "points":1,"state":1
//                 }
//             ],
//             "answersToRemove":[]
//         }
//     ],
//     "questionsToRemove":[],
// }
