
import { SUCCESS, NO_CONTENT, NOT_FOUND } from "../../../../../../../services/errors/constants";

const validateConfirmPassword = (password, confirmPassword) => {

    switch (confirmPassword) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;
        
        case password:
            return SUCCESS;

        // wrong characters
        // case !validate(firstName):
        //     return BAD_REQUEST;
        
        default:
            return NOT_FOUND;
    }
}

export default validateConfirmPassword;
