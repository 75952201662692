
import React, { useState } from 'react';

// style
import "./ExamView.scss";

// services local
import examInfoConstructor from "../../components/ExamInfo/services/constructor";

// components
import Title from "../../components/Title";
import Loader from '../../../../components/Loader';
import List from "../../../../components/List";

// components local
import Question from "../../components/Question";
import ExamInfo from "../../components/ExamInfo";

const View = (props) => {
    
    // questions
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(true);

    const [showExamInfo, toggleShowExamInfo] = useState(true);

    const renderQuestion = (i, questionHub) => {

        if (!questionHub) {
            return null;
        }

        return (
            <Question
                key={i}

                userAccess={props.userAccess}

                examView={props.examView}
                examClass={props.examClass}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}

            />
        )
    }

    return (
        <div className="examView" >

{
                props.loading ? <Loader /> : !props.examClass ? null :
            
                <Title
                    examView={props.examView}
                
                    title={props.examClass ? props.examClass.title : ""}
                    examClass={props.examClass}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}
                />
            
            }

            {
                props.loading ? null : !showExamInfo ? null : !props.examClass ? null :
                <ExamInfo
                    examView={props.examView}                
                    examClass={props.examClass}
                    infoColumns={examInfoConstructor({
                        examInfo: props.examClass,
                        editors: ["EtAlia"],
                    })}
                />
            }

            {

                !props.questionHubs ? null :
                props.questionHubs.length === 0 ?
                ""
                :
                <List
                    items={props.questionHubs}
                    renderItem={renderQuestion}
                />

            }

        </div>
    )
}

export default View;
