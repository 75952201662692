
import React, { useState } from 'react';

// networking
import authApi from "../../../../services/Networking/authentication";

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";
import userActions from "../../../../services/redux/actions/user.action"; 

// style
import "./Register.scss";

// classes
import regInfo from "./services/classes";
import errorClass from "../../../../services/errors/classes";
import option from "../../../../components/input/elements/Select/classes/option";

// components
import TextInput from '../../../../components/input/Text';
import PasswordInput from "../../../../components/input/Password";
import SelectInput from "../../../../components/input/Select";
import Submit from "../../../../components/input/elements/Submit";
import Loader from "../../../../components/Loader";

// elements
import Title from "../../../../elements/ModalTitle";

// constants
import { INTERNAL_ERROR, SUCCESS, UNAUTHORIZED, CONFLICT } from "../../../../services/errors/constants";

// local services
import authRegisterErrors from "./services/errors/authRegister";
import firstNameErrors from "./services/errors/firstName";
import validateFirstName from "./services/validate/firstName";
import lastNameErrors from "./services/errors/lastName";
import validateLastName from "./services/validate/lastName";
import emailErrors from "./services/errors/email";
import validateEmail from "../services/validate/email";
import passwordErrors from "./services/errors/password";
import validatePassword from "./services/validate/password";
import repeatPasswordErrors from "./services/errors/repeatPassword";
import validateConfirmPassword from "./services/validate/confirmPassword";
import roleErrors from "./services/errors/type";
import valitateRoleInital from "./services/validate/roleInitial";
import valitateRole from "./services/validate/role";
import levelErrors from "./services/errors/schoolLevel";
import valitateLevelInital from "./services/validate/schoolLevelInitial";
import valitateLevel from "./services/validate/schoolLevel";
import yearErrors from "./services/errors/schoolYear";
import validateYearInitial from "./services/validate/schoolYearInitial";
import validateYear from "./services/validate/schoolYear";

// services
import convertError from "../../../../services/errors/convertError";
import loginInfo from "../Login/services/classes";
import authUserErrors from "../Login/services/errors/authUser";

const Register = (props) => {

    // redux
    const dispatch = useDispatch();

    const [userInfo, updateUserInfo] = useState(new regInfo());

    // input
    const [firstNameError, updateFirstNameError] = useState(new errorClass({
        errorCode: 200
    }));
    const [lastNameError, updateLastNameError] = useState(new errorClass({
        errorCode: 200
    }));
    const [emailError, updateEmailError] = useState(new errorClass({
        errorCode: 200
    })); // this need universal error!!!
    const [schoolLevelError, updateSchoolLevelError] = useState(new errorClass({
        errorCode: 200
    }));
    const [schoolYearError, updateSchoolYearError] = useState(new errorClass({
        errorCode: 200
    }));
    const [passwordError, updatePasswordError] = useState(new errorClass({
        errorCode: 200
    }));
    const [repeatPasswordError, updateRepeatPasswordError] = useState(new errorClass({
        errorCode: 200
    }));
    const [roleError, updateRoleError] = useState(new errorClass({
        errorCode: 200
    }));

    // submit
    const [loading, toggleLoading] = useState(false);

    const login = async() => {

        const newloginInfo = new loginInfo({
            email: userInfo.email,
            password: userInfo.password
        });

        // send information to back-end
        const authResponse = await authApi.login(newloginInfo);

        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // nothing returned - something weard happend
        if (!authResponse) {

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: INTERNAL_ERROR,
                    updateLocally: null,
                    customErrors: null
                })
            ));
        }

        // success returned, update with 200 & update login
        if (authResponse.status === SUCCESS) {

            // log user in
            dispatch(userActions.login(authResponse.payload));

            // display success
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: authResponse.status,
                    updateLocally: null,
                    customErrors: authUserErrors
                }))
            );

            props.toggleShow(false);

        } else {
            // show auth errors publicly
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: authResponse.status,
                    updateLocally: null,
                    customErrors: authUserErrors
                })
            ));
        }
    }

    const updateUserInfoState = (valueKey, newValue) => {
        updateUserInfo((value) => {

            const newRegInfo = new regInfo({
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                // schoolId: value.schoolId,
                schoolLevel: value.schoolLevel,
                schoolYear: value.schoolYear,
                password: value.password,
                confirmPassword: value.confirmPassword,
                role: value.role,
                // profileImage: value.profileImage
            });

            newRegInfo[valueKey] = newValue;

            // validate level
            const levelValid = valitateLevelInital(newRegInfo.schoolLevel);

            const levelErrorTemp = convertError({
                errorCode: levelValid,
                updateLocally: updateSchoolLevelError,
                customErrors: levelErrors
            });

            if (!levelErrorTemp) {
            } else {
                if (levelErrorTemp.errorCode !== 200) {
                    // update global error list
                    dispatch(globalErrorsActions.addError(levelErrorTemp));
                } else {
                    updateSchoolLevelError(new errorClass({
                        errorCode: 200
                    }));
                }
            }

            // validate year
            const yearValid = validateYearInitial(newRegInfo.schoolYear);

            const yearErrorTemp = convertError({
                errorCode: yearValid,
                updateLocally: updateSchoolYearError,
                customErrors: yearErrors
            });

            if (!yearErrorTemp) {
            } else {
                if (yearErrorTemp.errorCode !== 200) {
                    // update global error list
                    dispatch(globalErrorsActions.addError(yearErrorTemp));
                } else {
                    updateSchoolYearError(new errorClass({
                        errorCode: 200
                    }));
                }
            }

            // validate role
            const roleValid = valitateRoleInital(newRegInfo.role);

            const roleErrorTemp = convertError({
                errorCode: roleValid,
                updateLocally: updateRoleError,
                customErrors: roleErrors
            });

            if (!roleErrorTemp) {
            } else {
                if (roleErrorTemp.errorCode !== 200) {
                    // update global error list
                    dispatch(globalErrorsActions.addError(roleErrorTemp));
                } else {
                    updateRoleError(new errorClass({
                        errorCode: 200
                    }));
                }
            }

            return newRegInfo;
        })
    }

    const submit = async(e) => {

        // start loading
        toggleLoading(true);

        e.preventDefault();

        let validInput = true;

        // validate first name
        const firstNameValid = validateFirstName(userInfo.firstName);

        const firstNameErrorTemp = convertError({
            errorCode: firstNameValid,
            updateLocally: updateFirstNameError,
            customErrors: firstNameErrors
        });

        if (!firstNameErrorTemp) {
            validInput = false;
        } else {
            if (firstNameErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(firstNameErrorTemp));
                validInput = false;
            }
        }

        // validate last name
        const lastNameValid = validateLastName(userInfo.lastName);

        const lastNameErrorTemp = convertError({
            errorCode: lastNameValid,
            updateLocally: updateLastNameError,
            customErrors: lastNameErrors
        });

        if (!lastNameErrorTemp) {
            validInput = false;
        } else {
            if (lastNameErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(lastNameErrorTemp));
                validInput = false;
            }
        }

        // validate mail
        const mailValid = validateEmail(userInfo.email);

        const emailErrorTemp = convertError({
            errorCode: mailValid,
            updateLocally: updateEmailError,
            customErrors: emailErrors
        });

        if (!emailErrorTemp) {
            validInput = false;
        } else {
            if (emailErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(emailErrorTemp));
                validInput = false;
            }
        }

        // validate password
        const passwordValid = validatePassword(userInfo.password);

        const passwordErrorTemp = convertError({
            errorCode: passwordValid,
            updateLocally: updatePasswordError,
            customErrors: passwordErrors
        });

        if (!passwordErrorTemp) {
            validInput = false;
        } else {
            if (passwordErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(passwordErrorTemp));
                validInput = false;
            }
        }

        // validate confirmPassword
        const confirmPasswordValid = validateConfirmPassword(userInfo.password, userInfo.confirmPassword);

        const confirmPasswordErrorTemp = convertError({
            errorCode: confirmPasswordValid,
            updateLocally: updateRepeatPasswordError,
            customErrors: repeatPasswordErrors
        });

        if (!confirmPasswordErrorTemp) {
            validInput = false;
        } else {
            if (confirmPasswordErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(confirmPasswordErrorTemp));
                validInput = false;
            }
        }

        // validate role
        const roleValid = valitateRole(userInfo.role);

        const roleErrorTemp = convertError({
            errorCode: roleValid,
            updateLocally: updateRoleError,
            customErrors: roleErrors
        });

        if (!roleErrorTemp) {
            validInput = false;
        } else {
            if (roleErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(roleErrorTemp));
                validInput = false;
            }
        }

        // validate level
        const levelValid = valitateLevel(userInfo.schoolLevel);

        const levelErrorTemp = convertError({
            errorCode: levelValid,
            updateLocally: updateSchoolLevelError,
            customErrors: levelErrors
        });

        if (!levelErrorTemp) {
            validInput = false;
        } else {
            if (levelErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(levelErrorTemp));
                validInput = false;
            }
        }

        // validate year
        const yearValid = validateYear(userInfo.schoolYear);

        const yearErrorTemp = convertError({
            errorCode: yearValid,
            updateLocally: updateSchoolYearError,
            customErrors: yearErrors
        });

        if (!yearErrorTemp) {
            validInput = false;
        } else {
            if (yearErrorTemp.errorCode !== 200) {
                // update global error list
                dispatch(globalErrorsActions.addError(yearErrorTemp));
                validInput = false;
            }
        }

        if (validInput) {

            // sending information
            const authResponse = await authApi.register(userInfo);

            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!authResponse) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200 & update login
            if (authResponse.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: authRegisterErrors
                    }))
                );

                // log user in
                login();

                props.toggleShow(false);

            } else {
                // show auth errors locally
                if (authResponse.status === CONFLICT) {
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: updateEmailError,
                        customErrors: authRegisterErrors
                    });
                } else if (authResponse.status === UNAUTHORIZED) {
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: null
                    });
                }

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(convertError({
                    errorCode: authResponse.status,
                    updateLocally: null,
                    customErrors: authRegisterErrors
                })));
            }

        } else {
            
        }

        // stop loading
        toggleLoading(false);

    }

    return (
        <form className="register" >
            <Title
                title="REGISTREREN"
            />

            <TextInput
                title="VOORNAAM"
                
                valueKey="firstName"
                dataClass={userInfo}

                errorClass={firstNameError}
                updateError={updateFirstNameError}
            />

            <TextInput
                title="ACHTERNAAM"
                
                valueKey="lastName"
                dataClass={userInfo}

                errorClass={lastNameError}
                updateError={updateLastNameError}
            />

            <TextInput
                title="E-MAIL"
                
                valueKey="email"
                dataClass={userInfo}

                errorClass={emailError}
                updateError={updateEmailError}
            />

            <PasswordInput
                title="WACHTWOORD"
                
                valueKey="password"
                dataClass={userInfo}

                errorClass={passwordError}
                updateError={updatePasswordError}
            />

            <PasswordInput
                title="HERHAAL WACHTWOORD"

                valueKey="confirmPassword"
                dataClass={userInfo}

                errorClass={repeatPasswordError}
                updateError={updateRepeatPasswordError}
            />

            <SelectInput
                title="ACCOUNTTYPE"

                options={[
                    new option({
                        value: "Student",
                        title: "Student"
                    }),
                    new option({
                        value: "Teacher",
                        title: "Docent"
                    }),
                    new option({
                        value: "null",
                        title: "."
                    })
                ]}
                
                valueKey="role"
                dataClass={userInfo}
                updateDataClass={updateUserInfoState}

                errorClass={roleError}
                updateError={updateRoleError}
            />

            <SelectInput
                title="NIVEAU"

                options={[
                    new option({
                        value: "null",
                        title: "Kies optie"
                    }),
                    new option({
                        value: "5",
                        title: "Gymnasium"
                    }),
                    new option({
                        value: "1",
                        title: "VWO"
                    }),
                    new option({
                        value: "4",
                        title: "HAVO/VWO"
                    }),
                    new option({
                        value: "2",
                        title: "HAVO"
                    }),
                    new option({
                        value: "3",
                        title: "VMBO"
                    }),
                    // new option({
                    //     value: "praktijkonderwijs",
                    //     title: "praktijkonderwijs"
                    // }),
                    new option({
                        value: "0",
                        title: "anders"
                    })
                ]}
                valueIsString={true}
                
                valueKey="schoolLevel"
                dataClass={userInfo}
                updateDataClass={updateUserInfoState}

                errorClass={schoolLevelError}
                updateError={updateSchoolLevelError}
            />

            <SelectInput
                title="SCHOOLJAAR"

                options={[
                    new option({
                        value: "null",
                        title: "Kies optie"
                    }),
                    new option({
                        value: "6",
                        title: "6"
                    }),
                    new option({
                        value: "5",
                        title: "5"
                    }),
                    new option({
                        value: "4",
                        title: "4"
                    }),
                    new option({
                        value: "3",
                        title: "3"
                    }),
                    new option({
                        value: "2",
                        title: "2"
                    }),
                    new option({
                        value: "1",
                        title: "1"
                    })
                ]}
                valueIsString={true}
                
                valueKey="schoolYear"
                dataClass={userInfo}
                updateDataClass={updateUserInfoState}

                errorClass={schoolYearError}
                updateError={updateSchoolYearError}
            />

            <div className="submit">{
                    loading ? <Loader /> :
                    
                    <Submit
                        value="REGISTREREN"
                        onClick={submit}
                    />
            }</div>

        </form>
    )
}

export default Register;
