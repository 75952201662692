
import "./Paragraph.scss";

const Paragraph = (props) => {

    return (
        <div className="Paragraph" >

            <div className="PTitle">{
                props.title
            }</div>

            <div className="PDescription" >{
                props.description
            }</div>
            
        </div>
    )
}

export default Paragraph;
