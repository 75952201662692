
// style
import "./Footer.scss";

// external components
import { SocialIcon } from 'react-social-icons';

const Footer = (props) => {

    return (

        <div
            className="FooterView"
        >

            <div>
                <SocialIcon url="https://www.facebook.com/EtAlia.nl" target="_blank" bgColor="#EDE8E2" fgColor="#574A3F" style={{marginRight: '10px'}}/>
                <SocialIcon url="https://instagram.com/etalia.nl" target="_blank" bgColor="#EDE8E2" fgColor="#574A3F" style={{marginRight: '10px'}}/>
                <SocialIcon url="https://www.tiktok.com/@etalia_ed" target="_blank" bgColor="#EDE8E2" fgColor="#574A3F" style={{marginRight: '10px'}}/>
                <SocialIcon url="https://www.youtube.com/channel/UCSwBuDx2UyiNoOVQJaVk08g" target="_blank" bgColor="#EDE8E2" fgColor="#574A3F"/>
            </div>
            <br/>
            <br/>
            Disclaimer: De informatie op deze website is afkomstig van de 
            overheid en andere door of namens EtAlia geraadpleegde bronnen. 
            Op de door of in opdracht van EtAlia vervaardigde teksten en ander 
            materiaal op deze website, rusten intellectuele eigendomsrechten, 
            waaronder auteursrechten. Voor het overnemen en publiceren van 
            (onderdelen van) deze website, is voorafgaande toestemming vereist 
            van EtAlia. Voor vragen hierover kunt u contact met ons opnemen 
            via olaf@etalia.nl. De content op EtAlia is door geschiedenisstudenten, 
            docenten en historici verzameld en gecreëerd. Wij doen 
            ons best de informatie foutloos te publiceren maar wij kunnen hier 
            niet garant voor staan. EtAlia is dus ook niet verantwoordelijk voor 
            behaalde cijfers na het leren van vakken met EtAlia. Mocht je een 
            fout tegenkomen, dan kan jij mij mailen op olaf@etalia.nl of op de 
            feedbackknop drukken en dan passen wij het zo snel mogelijk aan.

        </div>

    )

}

export default Footer;
