
import { useState, useEffect } from 'react';

// routing
import { useHistory, useParams } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import globalErrorsActions from '../../../../services/redux/actions/globalErrors.action';

// styles
import "./AssignmentAccess.scss";

// networking
import assignmentUserApi from '../../../../services/Networking/AssignmentUser/AssignmentUser';

// services
import convertError from '../../../../services/errors/convertError';
import userActions from '../../../../services/redux/actions/user.action';
import logOutErrors from '../../../../services/errors/logOutErrors';
import networking from '../../../../services/handleNetworking/networking';
import CustomErrorsManager from '../../../../services/errors/classes/CustomErrorsManager';

// components
import Loader from '../../../../components/Loader';

// constants
import { CONFLICT, FORBIDDEN, NOT_FOUND, SUCCESS, UNAUTHORIZED } from '../../../../services/errors/constants';

const AssignmentAccess = (props) => {

    // routing
    let history = useHistory();
    let { assignmentID, accessToken } = useParams();

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [loading, toggleLoading] = useState(user?.user ? true : false);
    const [addingUserError, updateAddingUserError] = useState(null);

    useEffect(() => {
        if (user?.user) {
            addUserToAssignment();
        } else {
            toggleLoading(false);
            updateAddingUserError(
                new CustomErrorsManager(
                    {
                        [UNAUTHORIZED]: {
                            title: "Je moet eerst inloggen"
                        }
                    }
                ).getError(UNAUTHORIZED)
            )
        }
    }, [user]);

    const addUserToAssignment = async() => {

        const returned = await networking({

            toggleLoading: toggleLoading,
        
            accessableToAllUsers: true,
            logOut: logOut,

            api: assignmentUserApi.postUserToAssignmentsWithToken,
            apiParams: {
                assignmentUuid: assignmentID,
                token: accessToken
            },
        
            updateError: updateAddingUserError,
            customErrors: (errorCode) => new CustomErrorsManager(
                {
                    [SUCCESS]: {
                        title: "Je bent toegevoegd aan de opdracht en wordt nu naar de opdrachtpagina gebracht ..."
                    },
                    [NOT_FOUND]: {
                        title: "Token niet gevonden."
                    },
                    [FORBIDDEN]: {
                        title: "Token is niet geldig."
                    },
                    [CONFLICT]: {
                        title: "Jij bent al toegevoegd aan deze opdracht. Wij brengen jouw naar de opdracht toe."
                    },
                }
            ).getError(errorCode),
        });

        if (returned && (returned.status === SUCCESS || returned.status === CONFLICT)) {
            // const assignmentId = returned?.payload?.assignmentId;
            history.replace(`/opdrachten/${assignmentID}`);
        }
    }

    // logs user out
    const logOut = () => {
        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // log user in
        dispatch(userActions.logOut());

        // display success
        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: SUCCESS,
                updateLocally: null,
                customErrors: logOutErrors
            }))
        );
    }

    return (
        <div
            className="AssignmentAccessView"
        >
            <div
                className="assignmentAccessInfoView"
            >
                <div
                    className="assignmentAccessTitle"
                >
                    Gebruiker Toevoegen Aan Opdracht
                </div>
                {
                    !user?.user || loading ? 
                    <div className="assignmentAccessTitleLoader"><Loader /></div>
                    :
                    <div className="assignmentAccessBody">{addingUserError?.title}</div>
                }
            </div>
        </div>
    )
}

export default AssignmentAccess;
