
class answerPut {

    constructor({

        title = "",
        userResponse = "",
        correct = null,
        points = null,
        userCorrect = null,
        scorePoints = null,

    } = {}) {

        this.title = title;
        this.userResponse = userResponse;
        this.correct = correct;
        this.points = points;
        this.userCorrect = userCorrect;
        this.scorePoints = scorePoints;

    }

}

export default answerPut;
