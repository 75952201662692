
class StickyButtonClass {
    
    constructor({

        title = "",
        onClickFunc = null,
        link = null,
        customComponent = null,

    }) {

        this.title = title;
        this.onClickFunc = onClickFunc;
        this.link = link;
        this.customComponent = customComponent;
    };

}

export default StickyButtonClass;
