
// styles
import "./FeedbackRestoration.scss";

// elements
import Close from "../../../../../../../../elements/Close/Close";

const FeedbackRestoration = (props) => {

    const removeBackupFeedback = () => {
        props.updateBackupUserFeedback(null);
    }

    return (
        <>{
            props.backupUserFeedback ? 
            <div
                className="feedbackRestorationView"
            >
                Je hebt één feedback veld per vraag. Als je je eerdere feedback terug wilt hebben, kies dan een van de volgende:
                <div
                    className="feedbackRestorationOptions"
                >
                    <div
                        className="feedbackRestorationOption feedbackRestorationOptionLeft"
                        onClick={() => props.restoreBackUpFeedback(true)}
                    >
                        Voeg jouw eerder gescheven feedback toe
                    </div>
                    <div
                        className="feedbackRestorationOption feedbackRestorationOptionLeft feedbackRestorationOptionRight"
                        onClick={() => props.restoreBackUpFeedback()}
                    >
                        Restoreer jouw eerder gescheven feedback & punten
                    </div>
                    <Close
                        onClick={() => removeBackupFeedback()}
                    />
                </div>
            </div> : ""
        }</>
    )
}

export default FeedbackRestoration;
