
// styles
import "./Button.scss";

const Button = (props) => {

    return (
        <div 
            className="AlertButton"
            onClick={props.data.buttonFunc}
        >{
            props.data.title
        }</div>
    )

}

export default Button;
