
// style
import "./AddAnswer.scss";

const AddAnswer = (props) => {

    const addAnswerToList = () => {

        props.questionHub.addAnswer();

        props.updateType();
        props.updateAnswers();
        props.toggleEditMCAnswers(true);
    }

    return (
        <div
            className="addAnswer"
            onClick={() => addAnswerToList()}
        >
            Antwoord toevoegen
        </div>
    )
}

export default AddAnswer;
