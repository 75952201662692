
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const topicApi = {
    createTopic,
    getTopicsByBookId,
    getTopicsReportByBookId,
    getTopicByTopicId,
    putTopicByTopicId,
    deleteTopicByTopicID
};

async function createTopic(bookId, topicObject) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/books/${bookId}/topics`, 
            topicObject, 
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;

}

async function getTopicsByBookId({bookId, type} = {}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/books/${bookId}/topics?type=${type}`, 
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
    
}

async function getTopicsReportByBookId({bookId} = {}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/books/${bookId}/topics/report`, 
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
    
}

async function getTopicByTopicId(topicId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/topics/${topicId}`, 
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function putTopicByTopicId(topicId, topicObject) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/topics/${topicId}`, 
            topicObject, 
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

async function deleteTopicByTopicID(topicId) {
    const auth = await authHeader();

    return handleResponse(
        axios.delete(
            `/topics/${topicId}`, 
            {
                headers: auth.payload
            }
        )
    );
}

export default topicApi;
