
import { useState } from "react";

// styles
import "./Grading.scss";

// components
import GradeExam from "./Views/GradeExam/GradeExam";
import Overview from "./Views/Overview/Overview";

// constants
import { OVERVIEW_EXPANDED } from "../../services/constants";

const Grading = (props) => {

    // data
    const [assignment, updateAssignment] = useState(null);

    // states
    const [overviewWidth, updateOverviewWidth] = useState(OVERVIEW_EXPANDED);
    const [selectedExamParams, updateSelectedExamParams] = useState(null);
    const [rerenderFetchedList, updatererenderFetchedList] = useState(false);

    const refetchList = () => {
        updatererenderFetchedList((value) => !value);
    }
    
    return (
        <div
            className="assignedExamView"
        >
            <GradeExam
                assignment={assignment}
                updateAssignment={updateAssignment}
                
                selectedExamParams={selectedExamParams}

                fetchExams={refetchList}

                pageInfo={{
                    title: "Student1"
                }}
            />

            <Overview
                assignment={assignment}
                updateAssignment={updateAssignment}

                rerenderFetchedList={rerenderFetchedList}

                overviewWidth={overviewWidth}
                updateOverviewWidth={updateOverviewWidth}

                selectedExamParams={selectedExamParams}
                updateSelectedExamParams={updateSelectedExamParams}
            />
        </div>
    )
}

export default Grading;
