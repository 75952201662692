
// classes
import infoRowClass from "../classes/infoRowClass";

// constants
import { NAMING_ATTEMPTS } from "./constants";

const namingTakenExams = ({
    tijdvakID=null, 
    kaID=null, 
    examID=null,
    orderId,
    takenExams,
    showAmount,
    onClick
} = {}) => {

    let takenExamsList = [];

    const amount = takenExams.length > showAmount ? showAmount : takenExams.length;

    for (let i = 0; i < amount; i++) {
        
        const element = new infoRowClass({
            id: takenExams[i].takenExamId,
            value: takenExams[i].title,
            link: true,
        })
        
        takenExamsList.push(element);
        
    }

    return takenExamsList;

}

export default namingTakenExams;
