
// standard objects
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { SUCCESS, UNAUTHORIZED } from "../../../../../../../services/errors/constants";

const addWithoutAccess = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Er is een kopie gemaakt",
                description: "De aangepaste versie is toegevoegd aan je account. Het orgineel is niet aangepast omdat deze niet door jou is gecreeerd.",
                advice: null,
                moreInfo: null,
            });

        // add incorrect password
        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: `Je hebt geen autouriteit om deze toe te voegen`,
                description: `Je hebt geen autouriteit om deze toe te voegen`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default addWithoutAccess;
