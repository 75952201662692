
// services
import validate from "./services/validate";
import cenvert from "./services/convert";
import clean from "./services/clean";

// classes
import infoObj from "../classes/infoObj";

// constants
import { SUCCESS, UNSUPPORTED_MEDIA_TYPE } from "../errors/constants";

const uploadImage = async(image) => {

    if (validate(image) != SUCCESS) {
        return new infoObj({
            status: validate(image)
        })
    }

    const base64Image = await cenvert(image);
    // const base64Cleaned = await clean(base64Image);
    // return base64Cleaned;
    return new infoObj({payload: base64Image, status: SUCCESS});

}

export default uploadImage;
