
// services
import generateOptions from "./services/generateOptions";

class filterClass {
    
    constructor({

        // title that is shown inside filter - optional
        title = null,

        // inidcates if filter is active on the full list
        active = true,
        // inidcates if user can see the filter
        visible = true,

        // used for order
        idFilter,
        // used to filter, the key is a key inside items of the full list
        filterAttributeKey,

        // user can only select one option vs select muliple
        selectOne = true, // TODO: selectOneOption
        // a select all is possible
        allOption = false, // TODO: selectAllOption
        // indicates if all are selected at the start
        allSelected = false, // TODO: allOptionsSelectedInitially

        overridOnlyShowSelected = false,

        // this renders the filters options, make sure the filter has 1 prop: data
        renderFilterItem, // TODO: renderFilter

        // a list of filterOptionClass
        optionsTitles = [], // TODO: options or filterOptions

        // indicates if a custom function should be used to filter here
        customUpdateFunc = false, // TODO: useFilterOnFetch
        
        // is added inside the filter component to trigger filtering when an option is clicked
        optionClickedUpdate,

    }) {

        this.active = active;
        this.visible = visible;

        this.renderFilterItem = renderFilterItem;

        this.idFilter = idFilter;
        this.title = title;
        this.options = generateOptions(allOption, selectOne, allSelected, optionsTitles);
        this.selectOne = selectOne;
        this.allOption = allOption;

        overridOnlyShowSelected=overridOnlyShowSelected;

        this.filterAttributeKey = filterAttributeKey;
        this.customUpdateFunc = customUpdateFunc;

        this.optionClickedUpdate = optionClickedUpdate;

        this.itemsToNotFilter = null;

    };

    getIndexesOfSelectedOptions = () => {
        let cloneOptions = this.options;
        if (this.allOption) {
            cloneOptions = cloneOptions.filter((option) => option.index !== 0);
        }

        cloneOptions = cloneOptions.filter((option) => option.selected !== false);
        const selectedKeysArray = cloneOptions.map((option) => {
            return option.keyValue;
        });

        return selectedKeysArray;
    }

    getKeyValuesOfSelectedOptions = () => {
        let cloneOptions = this.options;
        if (this.allOption) {
            cloneOptions = cloneOptions.filter((option) => option.index !== 0);
        }

        cloneOptions = cloneOptions.filter((option) => option.selected !== false);
        const selectedKeysArray = cloneOptions.map((option) => {
            return option.keyValue;
        });

        return selectedKeysArray;
    }

    applyFilterToList = (list, itemIdKey) => {
        if (this.allOption) {
            // check if first option - the select all option - is elected
            if (this.options[0].selected) {
                return list;
            }
        }

        const activeOptions = this.options.filter((option) => option.keyValue !== "All" && option.selected !== false);
        
        let newList = list.filter((item) => {
            if (this.itemsToNotFilter !== null) {
                if (this.itemsToNotFilter.has(item[itemIdKey])) {
                    return true;
                }
            }

            if (
                activeOptions.some((option) => item[this.filterAttributeKey] === option.keyValue)
            ) {
                return true;
            }

            return false;

        });

        return newList;
    }

    addItemToIgnore(itemId) {
        if (this.itemsToNotFilter === null) {
            this.itemsToNotFilter = new Set();
        };
        this.itemsToNotFilter.add(itemId);
    }

    removeItemToIgnore(itemId) {
        this.itemsToNotFilter.delete(itemId);
    }

    clearItemsToIgnore() {
        if (this.itemsToNotFilter === null) {
            return;
        };
        this.itemsToNotFilter.clear();
    }

}

export default filterClass;
