
import "./AddAssignmentItemTypeTitle.scss";

const AddAssignmentItemTypeTitle = (props) => {

    return (
        <div
            className="AddAssignmentItemTypeView"
        >{props.title}</div>
    )
}

export default AddAssignmentItemTypeTitle;
