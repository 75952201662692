
import { useState, useEffect, useRef } from "react";

// styles
import "../../../components/StickyButtons/StickyButton/ExamStickyButton.scss";

// service
import handleLocalStorage from "../../../../../services/localStorage/localStorage";

// constants
import { FETCH_LOCAL_STORAGE, REMOVE_LOCAL_STORAGE } from "../../../../../services/localStorage/constants";
import { LAST_ERROR_GENERATED_FEEDBACK } from "../../../services/constants/feedbackGeneration";

const GenerateFeedbackButton = (props) => {
    const [generateFeedbackWaitTimer, generateFeedbackWaitTimerUpdate] = useState(null);
    const intervalRef = useRef(null);

    const startCountdown = (startingTime) => {
        generateFeedbackWaitTimerUpdate(startingTime);

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

       intervalRef.current = setInterval(() => {
            generateFeedbackWaitTimerUpdate((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        if (props.exam?.sent?.agreedToGenerateFeedback === true && props.exam?.sent?.checkedByAI === false) {

            const lastError = handleLocalStorage({
                action: FETCH_LOCAL_STORAGE,
                key: LAST_ERROR_GENERATED_FEEDBACK,
            });

            if (lastError) {
                const dateError = new Date(lastError);
                const currentTime = new Date();
                const timeDifferenceInSeconds = Math.floor((currentTime - dateError) / 1000);
                const baseWaitingTime = 60 - timeDifferenceInSeconds;

                if (baseWaitingTime > 0) {
                    startCountdown(baseWaitingTime);
                    return;
                }
                handleLocalStorage({
                    action: REMOVE_LOCAL_STORAGE,
                    key: LAST_ERROR_GENERATED_FEEDBACK,
                });
            }
        }
        generateFeedbackWaitTimerUpdate(0);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [props.exam, props.showAutogradingPermissions]);

    return (
        <>{
            generateFeedbackWaitTimer === null ? null :
            props.exam?.sent?.agreedToGenerateFeedback === true && props.exam?.sent?.checkedByAI === true ? null :
            <div className="StickyButtonExam" onClick={generateFeedbackWaitTimer === 0 ? () => props.toggleShowAutogradingPermissions(true) : null} >{
                generateFeedbackWaitTimer === 0 ? props.exam?.sent?.agreedToGenerateFeedback === false ? "Toch Feedback Genereren" : "Feedback Genereren" :
                `Feedback genereren kan weer in: ${generateFeedbackWaitTimer}`
            }</div>
        }
        </>
    )
}

export default GenerateFeedbackButton;
