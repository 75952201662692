
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const feedbackApi = {
    postFeedback
};

async function postFeedback(feedbackClass) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/practiceCards/feedback`,
            feedbackClass,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

export default feedbackApi;
