
// styles
import "./ExamOverview.scss";

// components
import InfoItem from "../../../../../../../../../../../../components/InfoItem/InfoItem";

// constants
import { OVERVIEW_FOLDED } from "../../../../../../../../services/constants";
import examReviewed from "../../../../../../utils/examReviewed";

const ExamOverview = (props) => {

    const selectExam = () => {
        props.directlyToCheckingClicked(props.data?.userId, props.data?.order);
    }

    if (props.overviewWidth === OVERVIEW_FOLDED) {
        return (
            <div
                className={`
                    ExamInExamOverviewFoldedView 
                    ${props.data?.userId === props.selectedExamParams?.userId ? "ExamInExamOverviewFoldedHighlighted" : ""}
                    ${examReviewed(props.data?.validated, props.data?.checkedByTeacher) ? "ExamInExamOverviewFoldedViewValidated" : ""}
                `}
                onClick={() => selectExam()}
            >{
                props.data?.studentName
            }</div>
        )
    }

    return (
        <div
            className={`ExamInExamOverviewView ${examReviewed(props.data?.validated, props.data?.checkedByTeacher) ? "ExamInExamOverviewValidatedView" : ""}`}
        >
            <div
                className="ExamInExamOverviewSectionView"
            >
                <InfoItem
                    title="Naam"
                    value={props.data?.studentName}
                />
                <InfoItem
                    title="E-mail"
                    value={props.data?.email}
                />
                <InfoItem
                    title="Tentamen bekeken"
                    value={props.data?.validated ? "Ja" : "Nee"}
                />
                <InfoItem
                    title="Behaalde punten"
                    value={!props.data?.checkedByAI && !props.data?.checkedByTeacher && !props.data?.checkedByStudent && props.data?.scoredPoints === 0 ? "Nog niet nagekeken" : props.data?.scoredPoints}
                />
            </div>
            <div
                className="ExamInExamOverviewSectionView"
            >
                <InfoItem
                    title="Nagekeken door AI"
                    value={props.data?.checkedByAI ? "Ja" : "Nee"}
                />
                <InfoItem
                    title="Nagekeken door docent"
                    value={props.data?.checkedByTeacher ? "Ja" : "Nee"}
                />
                <InfoItem
                    title="Gevalideerd door student"
                    value={props.data?.checkedByStudent === 2 ? "Ja" : "Nee"}
                />
            </div>

            <div
                className="ExamInExamOverviewSectionView"
            >
                <div
                    className="ExamInExamOverviewStartCheckingButton"
                    onClick={() => selectExam()}
                >
                    Nakijken
                </div>
            </div>
        </div>
    )
}

export default ExamOverview;
