
// styles
import "./AssignmentLink.scss";

// components
import InfoItem from "../../../../../../../../components/InfoItem/InfoItem";

// elements
import CopyToClipboard from "../../../../../../../../elements/CopyToClipboard/CopyToClipboard";

const AssignmentLink = (props) => {
    return (
        <>
            {
                props.assignmentLink?.active === false ? null :
                <>
                    <InfoItem
                        title="Link"
                        value={props.assignmentLink?.token}
                        marginBottom={true}
                    />
                    
                    <CopyToClipboard
                        title="Kopier link"
                        value={`${window.location.origin}/opdrachten/gedeeld/${props.assignment?.id}/token/${props.assignmentLink?.token}`}
                    />
                </>
            }
        </>
    )
}

export default AssignmentLink;
