
// style
import "./ExamStickyButtons.scss";

// components local
import StickyButton from "./StickyButton";

// components
import List from "../../../../components/List";

const StickyButtons = (props) => {

    const renderStickyButton = (i, data) => {

        if (!data) {
            return null;
        }

        if (data.customComponent) {
            return data.customComponent;
        }

        return (
            <StickyButton
                key={`${i}_${data.title}`}

                title={data.title}
                onClickFunc={data.onClickFunc}
                link={data.link}
            />
        )
    }
    
    return (
        <div className="StickyButtonsExamsHolder" >
            <List
                items={props.buttons}
                renderItem={renderStickyButton}
            />
        </div>
    )
}

export default StickyButtons;
