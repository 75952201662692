
// styles
import "./assignmentSettings.scss";

// components
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import Boolean from "../../../../../../components/input/Boolean/Boolean";

const Settings = (props) => {

    return (
        <div
            className="assignmentSettingsView"
        >
            <div
                className="assignmentSettingsTitle"
            >
                Instellingen
            </div>

            {
                props.editingAssignment ? 
                <Boolean
                    title="Identificeerbaar nakijken"

                    valueKey="annonymousGrading"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Identificeerbaar nakijken"
                    value={props.assignment?.getCurrentValue("annonymousGrading") ? "Ja" : "Nee"}
                />
            }

            {/* {
                props.editingAssignment ? 
                <Boolean
                    title="Lever gestarte opdrachten automatisch in na deadline"

                    valueKey="handinActiveExamsOnDeadline"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Lever gestarte opdrachten automatisch in na deadline"
                    value={props.assignment?.getCurrentValue("handinActiveExamsOnDeadline") ? "Ja" : "Nee"}
                />
            } */}

            {
                props.editingAssignment ? 
                <Boolean
                    title="AI kan nakijken"

                    valueKey="canAIGrade"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="AI kan nakijken"
                    value={props.assignment?.getCurrentValue("canAIGrade") ? "Ja" : "Nee"}
                />
            }

            {
                props.editingAssignment ? 
                <Boolean
                    title="Studenten kunnen feedback aan zichzelf geven"

                    valueKey="canStudentGrade"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Studenten kunnen feedback aan zichzelf geven"
                    value={props.assignment?.getCurrentValue("canStudentGrade") ? "Ja" : "Nee"}
                />
            }

            {
                props.editingAssignment ? 
                <Boolean
                    title="Studenten kunnen na de deadline inleveren"

                    valueKey="canSubmittAfterDeadline"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Studenten kunnen na de deadline inleveren"
                    value={props.assignment?.getCurrentValue("canSubmittAfterDeadline") ? "Ja" : "Nee"}
                />
            }

            {/* {
                props.editingAssignment ? 
                <NumberInput
                    inlineTitle="Pogingen"

                    valueKey="retries"
                    clientData={props.assignment}

                    useBaseStyle={true}
                />
                :
                <InfoItem
                    title="Pogingen"
                    value={props.assignment?.getCurrentValue("retries")}
                />
            } */}

            {/* {
                props.editingAssignment ?
                <SelectItems
                    title="Nakijken door"

                    childListKey="graders"
                    clientData={props.assignment}

                    itemSelectedKey={"selected"}
                    itemTitleKey={"title"}
                />
                :
                <InfoItem
                    title="Nakijken door"
                    value={`
                        ${props.assignment?.childLists.graders?.map((item) => item.getCurrentValue("selected") ? `${item.getCurrentValue("title")}, ` : "").join('').slice(0, -2)}
                    `}
                />
            } */}

        </div>
    )
}

export default Settings;
