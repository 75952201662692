
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

// style
import "./checkBox.scss";

const CheckBox = (props) => {
    return (
        <>{
            props.checked ? 
            <FontAwesomeIcon 
                onClick={props.onClick}
                className="checked"
                icon={faCheckSquare}
            />
            :
            <FontAwesomeIcon 
                onClick={props.onClick}
                className="unChecked"
                icon={faSquare}
            />
        }</>
    )
}

export default CheckBox;

