
// standard objects
import errorClass from "../../../../../../../services/errors/classes/errorClass";

// constants
import { NO_CONTENT } from "../../../../../../../services/errors/constants";

const answerErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "",
                description: "Je hebt geen referentieantwoord ingevuld. Wil je dit niet nog even doen?",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default answerErrors;
