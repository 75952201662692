
// style
import "./EtAlia.scss";

// components
import Paragraph from "../components/Paragraph";

// images
import roelAndOlafImage from "./assets/RoelAndOlaf.png";
import denisa from "./assets/Denisa.png";

const EtAlia = (props) => {
    
    return (
        <div className="EtAliaView">
            <div className="EtAliaImages" >

                <img 
                    className="imgEtAlia"
                    src={roelAndOlafImage} 
                    alt="Roel en Olaf" 
                />

                <img 
                    className="imgEtAlia"
                    src={denisa} 
                    alt="Denisa" 
                />

            </div>

            <div className="EtAliaText">
                <Paragraph
                    title={<>Ons Doel</>}
                    description={
                        <>
                            Ontzettend leuk dat je je hier bevindt! Op deze website willen we door middel van digitaal lesmateriaal het leren van het vak geschiedenis makkelijker maken en meer tot leven laten komen. Op termijn willen we alle vakken van de middelbare school tackelen maar wij starten met het vak geschiedenis en focussen hierbij op HAVO/VWO scholieren in de bovenbouw.
                        </>
                    }
                />

                <Paragraph
                    title={<>De Content</>}
                    description={
                        <>
                            <p>
                            De content op deze site is gecreëerd en verzameld door een groep geschiedenisstudenten. Voor elk kenmerkend aspect kan je verschillende soorten content vinden en zijn er verschillende manier om de content te oefenen.
                            </p>

                            <p>
                            Wij hebben de syllabus gebruikt van de overheid voor het vak geschiedenis om ervoor te zorgen dat de stof goed aansluit op wat jij in de klas en voor je eindexamen moet leren (als HAVO/VWO bovenbouwleerling). <a href="https://www.etalia.nl/geschiedenis/t-13/st-49" target="_blank" rel="noopener" >Kenmerkend Aspect 47: De eenwording van Europa</a> sluit dus zo goed mogelijk aan bij het hoofdstuk over Europa in jouw schoolboek van bijvoorbeeld Noordhoff, ThiemeMeulenhoff of Malmberg.
                            </p>

                            <p>
                            Door als leerling een account aan te maken, kan je de pagina’s op het EtAlia platform aanpassen door eigen flashcards en definities toe te voegen. De flashcards en de toetsen kan je gebruiken om je kennis te testen en bij te houden. 😱 Zo weet je precies wat je nog moet leren, voor elk kenmerkend aspect, en wat je al goed kent!
                            </p>

                            <p>
                            De YouTube filmpjes die op onze site te vinden zijn, zijn van de YouTube kanalen waar ze op staan. Als je een filmpje leuk vindt dan kan je hem liken door op YouTube te klikken en wij moedigen je aan de kanalen van goede filmpjesmakers te volgen. Verder zijn de afbeeldingen met bronvermelding die op deze site te vinden zijn copyright vrij en dus ook niet in ons eigendom.
                            </p>
                        </>
                    }
                />

                <Paragraph
                    title={<>Ontwikkeling adviseur worden?</>}
                    description={
                        <>
                        Met EtAlia proberen wij de vakken van de middelbare school leuker en makkelijker te maken. Dit kunnen wij het beste doen door van jou te horen wat handig is, wat beter kan en welke functionaliteiten je nog zou willen zien. Lijkt het jou leuk om mee te denken bij de ontwikkeling van EtAlia? Dan kan je mij altijd appen (mobiel: 0031623991946) of mailen (Olaf@EtAlia.nl).
                        </>
                    }
                />
                
                <Paragraph
                    title={<>Content creëren?</>}
                    description={
                        <>
                        De content op de EtAlia site wordt continu geüpdatet. Zo worden er nieuwe links toegevoegd, nieuwe oefentoetsen bijgemaakt, tekstuitleg efficiënter geschreven en veelgestelde vragen aangepast om het vak geschiedenis steeds levendiger en makkelijker te maken. Zou jij het leuk vinden om als geschiedenisdocent, student of historicus content op de EtAlia site toe te voegen of aan te passen en zo het vak geschiedenis leuker te maken voor middelbare scholieren door het hele land, mail dan naar Olaf@EtAlia.nl of app naar 0031623991946.
                        </>
                    }
                />

                <Paragraph
                    title={<>Toekomst van EtAlia</>}
                    description={
                        <>
                            <p>
                            De afgelopen jaren zijn wij met verschillende docenten in gesprek gegaan voor advies over de ontwikkeling van EtAlia. Wij hebben begrepen dat veel docenten moeite hebben met het vinden, aanpassen en (gestructureerd) bewaren van goede digitale content voor hun lessen. Dit is precies het probleem dat wij voor docenten willen oplossen.
                            </p>

                            <p>
                            Wij hebben nu, als eerste voor het vak geschiedenis, een goede basis aan digitale content op onze website staan waarbij relevante content voor de uitleg van de verschillende onderwerpen bij elkaar is gezocht en - op basis van het verplichte curriculum van het ministerie van OCW - gestructureerd wordt gepresenteerd. Elke docent kan in de toekomst deze basis gebruiken om alle content (tekstuitleggen, oefentoetjes, flashcards etc.) aan te passen en te personaliseren voor de eigen klassen. 
                            </p>

                            <p>
                            Daarnaast zijn wij bezig met het opbouwen van een database waarmee digitale content voor ieder onderwerp makkelijk kan worden gedeeld. Hiermee kunnen docenten digitale content van collega’s binnen hun eigen school of door het hele land, verwerken in hun eigen gepersonaliseerde pagina’s. Zo kunnen docenten in de toekomst door een databank van vragen zoeken (geüpload door ons en ervaren docenten door het hele land) en daaruit eigen toetsjes samenstellen. Deze vragen kunnen dan ook individueel worden aangepast.
                            </p>
                        </>
                    }
                />

                <Paragraph
                    title={<>Scholen</>}
                    description={
                        <>
                        Voor de volgende fase van ons project zoeken wij een kleine selectie van innovatieve scholen die met ons samen willen werken om het platform uit te bouwen om op die manier het digitale leven van docenten werkelijk makkelijker te maken. Door als school met ons te partneren kunt u actief de ontwikkeling van een toekomstbestendig, makkelijk bruikbaar educatieplatform beïnvloeden om het zo optimaal bruikbaar en efficiënt mogelijk te maken voor uw eigen docenten. Mocht uw school geïnteresseerd zijn om met ons in gesprek te gaan over een ontwikkelingspartnership mail dan naar: Olaf@EtAlia.nl.
                        </>
                    }
                />


            </div>
        </div>
    )
}

export default EtAlia;
