
// react
import React from 'react';

// components
import List from "../../List";

// services
import updateSelected from "./services/updateSelected";

// style
import "./Filter.scss";

function Filter(props) {

    const updateFilter = (optionIndex) => {
        updateSelected({
            options: props.filterData.options, 
            idClickedOption: optionIndex,
            allOption: props.filterData.allOption,
            selectOne: props.filterData.selectOne
        });
    }

    const optionClicked = async(optionIndex) => {

        // update the selected options locally and inside the filter class
        updateFilter(optionIndex);
        
        // update list with updated filters class
        props.filterData.optionClickedUpdate(props.filterData.customUpdateFunc);

    }

    const renderOption = (i, Data) => {

        return (
            <div
                key={i}
                className="filterOption"
                onClick={() => optionClicked(Data.index)}
            >{
                props.filterData.renderFilterItem(i, Data)
            }</div>
        )
    }

    return (
        <>{
            <List
                items={props.filterData.options}
                renderItem={renderOption}
            />
        }</>
    )
}

export default Filter;
