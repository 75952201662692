
import React, { useState } from 'react';

// style
import "./Submit.scss";

const Submit = (props) => {

    return (
        <input
            type="submit"

            name={props.value}
            value={props.value}

            onClick={props.onClick}
        />
    )
}

export default Submit;
