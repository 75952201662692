
// constants
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../../services/constants/typeConstants";

const allAnswerFilled = (questionsHubs) => {

    let questionSkipped = false;

    questionsHubs.map((questionHub) => {

        if (questionHub.getCurrentValue("type") === ASSAY_QUESTION) {
            // test if empty string is also true!!!
            if (!questionHub.studentAnswer) {
                questionSkipped = true;
            }
        } else if (questionHub.getCurrentValue("type") === MULTIPLE_CHOICE_QUESTION) {
            if (questionHub.answerId.length < 1) {
                questionSkipped = true;
            }
        }
    });

    return questionSkipped;
    
    /*
        open question filled in if
            userResponse || newAnswer !== ""
        
        Multiple choice question filled in if
            answerId || newAnswerId !== null
    */

}

export default allAnswerFilled;
