
// redux
import { useDispatch, useSelector } from 'react-redux';

// style
import "./subTopicExamPrep.scss";

// services
import subTopicApi from "../../../../../../services/Networking/subTopic/subTopic";
import access from '../../../../../../services/access/accessCheck/access';

// components
import Delete from '../../../../../../components/Delete/Delete';

// constants
import { ADMIN } from '../../../../../../services/access/constants';

const SubTopic = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    return (
        <div 
            className={`examPrepRowItem ${props.selected ? "examPrepRowItemSelected" : ""}`} 
            onClick={() => props.updateSelected(props.id)}    
        >
            <div className="examPrepRowItemTitle" >{
                props.data.title
            }</div>

            {
                !user ? null : !user.user ? null : access({
                    accessLevel: ADMIN,
                    userRoles: user.user.roles
                }) ? 
                <Delete
                    api={subTopicApi.deleteSubTopicBySubTopicID}
                    id={props.data.subtopicId}

                    runOnSuccess={() => props.fetchData()}

                /> : null
            }
        </div>
    )

}

export default SubTopic;
