
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const questionApi = {
    putQuestionByQuestionId,
    deleteQuestionByQuestionId
}

async function putQuestionByQuestionId({questionId, questionClass}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/questions/${questionId}`,
            questionClass,
            {
                headers: auth.payload
            } 
        )
    );
    
    return responseObj;
}

async function deleteQuestionByQuestionId(questionId) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/questions/${questionId}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;

}

export default questionApi;
