
// react
import React, { useState, useEffect } from 'react';

// style
import "./EntityView.scss";

// components
import EntityInfo from "../components/EntityInfo";
import List from "../../../../components/List";

// images creators
import annabel from "./assets/images/annabel.png";
import AlexHeeringa from "./assets/images/AlexHeeringa.png";
import isa from "./assets/images/Isa.png";
import lennart from "./assets/images/Lennart.png";
import maaike from "./assets/images/Maaike.png";
import manon from "./assets/images/Manon.png";
import NinaLammerts from "./assets/images/NinaLammerts.png";

// images advisors
import erie from "./assets/images/Erie.png";

// images partners
import Kleio from "./assets/images/Kleio-logo-noBG.png";
import HumanitiesLab from "./assets/images/HumanitiesLabLogo.png";
import JongeHistorici from "./assets/images/JongeHistorici.png";

// classes
import EntityInfoClass from "../services/classes/EntityInfoClass";

const EntityView = (props) => {

    const contentCreators = [
        new EntityInfoClass({
            title: "Annabel van Amerongen",
            description: <>
            Heeft alles samengevat van tijdvak 1 tot en met tijdvak 7
            <ul>
                <li>Tijdvak 1: De tijd van jagers en boeren</li>
                <li>Tijdvak 2: De tijd van Grieken en Romeinen</li>
                <li>Tijdvak 3: De tijd van monniken en ridders</li>
                <li>Tijdvak 4: De tijd van steden en staten</li>
                <li>Tijdvak 5: De tijd van ontdekkers en hervormers</li>
                <li>Tijdvak 6: De tijd van regenten en vorsten</li>
                <li>Tijdvak 7: De tijd van pruiken en revoluties</li>
            </ul>
            </>,
            image: annabel
        }),
        new EntityInfoClass({
            title: "Isa Goosen",
            description: <>
            <ul>
                <li>KA 8: de ontwikkeling van het jodendom en het christendom als de eerste monotheïstische godsdiensten</li>
                <li>KA 11: de vrijwel volledige vervanging in West-Europa van de agrarisch-urbane cultuur door een zelfvoorzienende agrarische cultuur, georganiseerd via hofstelsel en horigheid</li>
                <li>KA 21: de protestantse reformatie die splitsing van de christelijke kerk in West-Europa tot gevolg had</li>
                <li>KA 37: de rol van moderne propaganda- en communicatiemiddelen en vormen van massaorganisatie</li>
            </ul>
            </>,
            image: isa
        }),
        new EntityInfoClass({
            title: "Lennart",
            description: <>
            <ul>
                <li>KA 39: de crisis van het wereldkapitalisme</li>
            </ul>
            </>,
            image: lennart
        }),
        new EntityInfoClass({
            title: "Maaike Jansen",
            description: <>
            <ul>
                <li>KA 29: uitbouw van de Europese overheersing, met name in de vorm van plantagekoloniën en de daarmee verbonden transatlantische slavenhandel, en de opkomst van het abolitionisme</li>
                <li>KA 32: discussies over de ‘sociale kwestie’</li>
                <li>KA 33: de moderne vorm van imperialisme die verband hield met de industrialisatie</li>
                <li>KA 34: de opkomst van emancipatiebewegingen​</li>
                <li>KA 44: vormen van verzet tegen het West-Europese imperialisme</li>
                <li>KA 46: de dekolonisatie die een eind maakte aan de westerse hegemonie in de wereld</li>
                <li>KA 48: de toenemende westerse welvaart die vanaf de jaren zestig van de twintigste eeuw aanleiding gaf tot ingrijpende sociaal-culturele veranderingsprocessen</li>
                <li>KA 49: de ontwikkeling van pluriforme en multiculturele samenlevingen​</li>
            </ul>
            </>,
            image: maaike
        }),
        new EntityInfoClass({
            title: "Manon",
            description: <>
            <ul>
                <li>KA 4: De ontwikkeling van wetenschappelijk denken en het denken over burgerschap en politiek in de Griekse stadstaat</li>
            </ul>
            </>,
            image: manon
        }),
        new EntityInfoClass({
            title: "Myrthe",
            description: <>
            <ul>
                <li>KA 41: racisme en discriminatie die leidden tot genocide, in het bijzonder op de joden</li>
            </ul>
            </>
        }),
        new EntityInfoClass({
            title: "Teun",
            description: <>
            <ul>
                <li>KA 23: het streven van vorsten naar absolute macht</li>
            </ul>
            </>
        }),
        new EntityInfoClass({
            title: "Yason",
            description: <>
            <ul>
                <li>KA 2: het ontstaan van landbouw en landbouwsamenlevingen</li>
                <li>KA 7: de confrontatie tussen de Grieks-Romeinse cultuur en de Germaanse cultuur van Noordwest Europa</li>
            </ul>
            </>
        }),
        new EntityInfoClass({
            title: "Alex Heeringa",
            description: "Hallo allemaal, mijn naam is Alex Heeringa. Ik ben 26 jaar oud en woon in de prachtige Domstad. Naast een passie voor (kunst)geschiedenis hou ik van puzzelen, jaren 80-muziek en spelshows. Daarnaast ben ik een groot liefhebber van speciaalbiertjes. Die heb ik leren drinken tijdens mijn studententijd aan de Universiteit Utrecht, waar ik binnen de BA TaaI- en Cultuurstudies een hoofdrichting Antieke CuItuur heb gevolgd. Op dit moment ben ik docent geschiedenis aan het Corderins College in Amersfoort, terwijl ik ook de opleiding Geschieden is: Educatie en Communicatie volg. Volgend jaar ga ik beginnen met de Master Cultuurgeschiedenis. Tussen dit alles door ben ik ook bezig met het createn van content voor de prachtige site waarop je je nu bevindt.",
            image: AlexHeeringa
        }),
        new EntityInfoClass({
            title: "Nina Lammerts",
            description: "Hallo! Ik ben Nina Lammerts, 24 jaar oud en woonachtig in Utrecht. Na mijn bachelor Geschiedenis aan de Universiteit van Amsterdam te hebben afgerond, ben ik nu bezig aan de master Kunstgeschiedenis. Binnen deze opleiding specialiseer ik mij in (vroeg)middeleeuwse kunst. Naast mijn studie werk ik als publieksmedewerker bij een museum, zing, dans en lees ik graag, ben ik iets te fanatiek bij het spel Triviant Pursuit en werk ik mee aan deze site. Ik hoop dat ik een steentje kan bijdragen aan het succesvol afronden van het vak geschiedenis en de stof tot leven kan wekken!",
            image: NinaLammerts
        }),
    ];

    const advisors = [
        new EntityInfoClass({
            title: "Erie De Cocq Van Delwijnen",
            description: "Erie adviseert ons door weer en wind, hoe wij het EtAlia platform het beste verder kunnen ontwikkelen.",
            image: erie
        })
    ];

    const partners = [
        new EntityInfoClass({
            title: "Kleio Amsterdam",
            description: <>
                <p>
                Kleio is dé studievereniging voor geschiedenisstudenten aan de UvA. De 8 commissies organiseren onder meer reizen, feesten en educatieve evenementen. Kleio heeft een groot bereik en is altijd op zoek naar externe partners. Onder de leden zitten genoeg enthousiaste studenten die in het onderwijs actief willen zijn.
                </p>
                <p>
                Heeft u vacatures open staan of stageplekken voor geschiedenisstudenten, mail dan naar: commissarisextern@kleio-amsterdam.nl
                </p>
                <p>
                Website: <a href="https://kleio-amsterdam.nl" target="_blank" rel="noopener" >www.kleio-amsterdam.nl</a>
                </p>
            </>,
            image: Kleio
        }),
        new EntityInfoClass({
            title: "Humanities Lab",
            description: "Het Humanities Lab is dé plek waar medewerkers, promovendi, studenten en alumni van de Faculteit der Geesteswetenschappen ideeën voor het starten van een eigen bedrijf of organisatie op kunnen doen. De core business van het Humanities Lab is het stimuleren en ondersteunen van ondernemerschap op basis van Geesteswetenschappelijke kennis en academische vaardigheden. Het Humanities Lab biedt werkplekken en begeleiding aan starters vanuit de Geesteswetenschappen, helpt bij het integreren van ondernemerschapsvaardigheden in het reguliere onderwijsaanbod, organiseert extracurriculair onderwijs en werkt mee aan het valoriseren van onderzoek. Een plek waar de begrippen inspiratie, innovatie en impact centraal staan.",
            image: HumanitiesLab
        }),
        new EntityInfoClass({
            title: "Stichting Jonge Historici",
            description: <>
                <p>
                    Stichting Jonge Historici ziet de kritische reflectie van jonge historici als essentieel voor de maatschappij. Daarom stimuleert Jonge Historici jonge historici om hun kennis en vaardigheden tentoon te spreiden.
                </p>
                <p>
                    Naast het publiceren van scripties en papers die anders ongelezen zouden blijven, creëert Jonge Historici een discussieplatform met onder meer columns en recensies door jonge historici. Ook organiseert Jonge Historici evenementen zoals de verkiezing voor Jonge Historicus van het Jaar om aanstormend historisch talent voor het voetlicht te brengen. 
                </p>
                <p>
                    Zo biedt Jonge Historici een podium voor jonge historici om zich te profileren en hun talenten verder te ontwikkelen!
                </p>
                <p>
                    Contact: info@jongehistorici.nl
                </p>
                <p>
                    Website: <a href="https://jhsg.nl" target="_blank" rel="noopener" >www.jhsg.nl </a>
                </p>
            </>,
            image: JongeHistorici
        }),
    ];

    const returnCorrectList = () => {
        if (props.type === "creators") {
            return contentCreators.sort((a,b) => a.title > b.title);
        } else if (props.type === "advisors") {
            return advisors.sort((a,b) => a.title > b.title);
        } else if (props.type === "partners") {
            return partners.sort((a,b) => a.title > b.title);
        } else {
            // no type
        }
    }

    const [listEntities, updateListEntities] = useState([]);

    useEffect(() => {

        updateListEntities(() => returnCorrectList());

    }, [props.type]);

    const renderEntityInfo = (key, data) => {
        return (
            <EntityInfo
                key={key}    
                title={data.title}
                description={data.description}

                image={data.image}
            />
        )
    }

    return (
        <div className="CreatorsView" >
            
            <List
                items={listEntities}
                renderItem={renderEntityInfo}
            />

        </div>
    )
}

export default EntityView;
