
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const LinksApi = {
    addLinkBySubTopicId,
    getLinkBySubTopicId,
    putLinkByThemeLinkAndLinkID,
    deleteLinkByThemeLinkIdAndLinkId,
    deleteLinkThemeByLinkThemeId
}

async function addLinkBySubTopicId({subTopicId, data}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subtopics/${subTopicId}/importantLinks`,
            data,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getLinkBySubTopicId(subTopicId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subtopics/${subTopicId}/importantLinks`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function putLinkByThemeLinkAndLinkID(linkThemeId, linkId, linkClass) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.put(
            `/importantLinks/${linkThemeId}/link/${linkId}`,
            linkClass,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function deleteLinkByThemeLinkIdAndLinkId(object) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/importantLinks/${object.linkThemeId}/link/${object.linkId}`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

async function deleteLinkThemeByLinkThemeId(linkThemeId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/importantLinks/${linkThemeId}`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;

}

export default LinksApi;
