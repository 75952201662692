
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

export const authApi = {
    oidcauth,
    login,
    register,
    // logOut,
    forgotPassword,
    resetPassword,
    confirmMailAdress,
    changePassword,
    updateUser,
    getUser
};

async function oidcauth(sub,scheme) {

    const responseObj = await handleResponse(
        axios.post(
            "/authentication/oidc-auth?sub="+sub+"&provider="+scheme
        )
    );

    return responseObj;

}

async function login(userData) {

    const responseObj = await handleResponse(
        axios.post(
            "/authentication/login", 
            userData
        )
    );
    return responseObj;
}

async function register(userData) {

    const responseObj = await handleResponse(
        axios.post(
            "/authentication/register", 
            userData
        )
    );

    return responseObj;

}

async function forgotPassword(email) {

    const responseObj = await handleResponse(
        axios.post(
            "/authentication/forgot-password", 
            email
        )
    );

    return responseObj;

}

async function resetPassword({userData, token}) {
    
    const responseObj = await handleResponse(
        axios.post(
            `/authentication/reset-password?token=${token}`, 
            userData
        )
    );

    return responseObj;

}

async function confirmMailAdress({token, mailadress}) {

    const responseObj = await handleResponse(
        axios.get(
            `/authentication/confirm-email?email=${mailadress}&token=${token}`
        )
    );

    return responseObj;

}
async function changePassword(userData) {
    const auth = await authHeader();
    const responseObj = await handleResponse(
        axios.put(
            `/authentication/change-password`, 
            userData,
            {
                headers: auth.payload
            } 
        )
    );

    return responseObj;
}

async function getUser(userId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
          `/user/${userId}`,
          {
              headers: auth.payload
          } 
        )
    );

    return responseObj;
}

async function updateUser(userId,userData) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
          `/user/${userId}`,userData,
          {
              headers: auth.payload
          } 
        )
    );

    return responseObj;
}
export default authApi;
