
const formatDateForUser = (date) => {
    if (!(date instanceof Date)) return '';

    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
    };

    return date.toLocaleString('en-GB', options);
}
  
export default formatDateForUser;
