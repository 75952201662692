 
// constants
import { SUCCESS, NO_CONTENT } from "../../../../../../services/errors/constants";
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../../constants/typeConstants";

const validateType = (questionType) => {

    switch (questionType) {
        case ASSAY_QUESTION:
            return SUCCESS;

        case MULTIPLE_CHOICE_QUESTION:
            return SUCCESS;

        default:
            return NO_CONTENT;
    }
}

export default validateType;
