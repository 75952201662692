
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

// constants
import { SUCCESS } from '../../errors/constants';

const booksApi = {
    postBook,
    getBooks,
};

async function postBook( subjectId, data ) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/subjects/${subjectId}/books?author=${data.author}&title=${data.title}`, 
            {
                "title": data.title,
                "author": data.author
            },
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function getBooks( subjectId ) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/subjects/${subjectId}/books`, 
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

export default booksApi;
