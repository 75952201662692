
const calculateItemOnPageIndexRange = (
    currentIndex,
    itemsPerPage,
    totalItems,
) => {
    if (currentIndex < 0 || itemsPerPage <= 0 || totalItems < 0) {
        throw new Error('Invalid parameters');
    }

    const firstIndex = currentIndex * itemsPerPage;
    let lastIndex = firstIndex + itemsPerPage - 1;

    // Ensure the last index does not exceed the total number of items
    if (lastIndex >= totalItems) {
        lastIndex = totalItems - 1;
    }

    return {
        firstIndex,
        lastIndex
    };
}

export default calculateItemOnPageIndexRange;
