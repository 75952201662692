
function breadcrumbClass({
    title = null,
    id = null,
    link = "/geschiedenis",
} = {}) {

    this.title = title;
    this.id = id;
    this.link = link;
}

export default breadcrumbClass;
