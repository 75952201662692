
import React, { useState, useEffect } from 'react';

// text
import parse from "html-react-parser";
import cleanText from "../../../../services/cleanText";

// classes
import errorClass from "../../../../services/errors/classes";

// style
import "./examTitle.scss";

// components
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/input/Text";

// constants
import { VIEW, MAKE, CHECK, ASSIGNMENT, EDIT} from "../../services/tabConstants";
import { BAD_REQUEST, INTERNAL_ERROR, NO_CONTENT, SUCCESS } from "../../../../services/errors/constants";

const Title = (props) => {

    const editTitle = () => {
        if (!props.editedExamInfo) {
            props.initEditExamInfo();
        }
    };

    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    useEffect(() => {
        if (props.validate || titleError.errorCode !== SUCCESS) {
            if (props.editedExamInfo ? !props.editedExamInfo["title"] : !props.examClass["title"]) {
                updateTitleError(new errorClass({
                    errorCode: NO_CONTENT,
                    description: "De toets heeft een title nodig."
                }));
            }
        }
    }, [props.validate]);

    return (
        <div 
            className={`examTitle ${props.justTitle ? "" : "titleClickable"}`}
            onClick={() => props.examView === EDIT ? null : props.toggleShowExamInfo((value) => !value)}
        >
            
            {
                props.examView === EDIT ?
                <div
                    className={`editExamTitle`}
                    onClick={props.clientData ? null : () => editTitle()}
                >
                    <TextInput                        
                        valueKey="title"
                        dataClass={props.editedExamInfo ? props.editedExamInfo : props.examClass}
                        clientData={props.clientData}

                        useBaseStyle={true}
                        
                        errorClass={titleError}
                        updateError={updateTitleError}
                    />
                </div>
                :
                <div className="examTitleText" >{
                    !props.title ? null :
                    parse(
                        cleanText({
                            textToClean: props.title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            bold: true,
                            list: true,
                        })
                    )
                }</div>
            }
            
            {
                props.justTitle ? null :
                <div 
                    className="examTitlePointer" 
                    onClick={() => props.examView === EDIT ? props.toggleShowExamInfo((value) => !value) : null}
                >{
                
                    props.showExamInfo ? "Info verbergen" : "Info zien"

                }</div>
            }

            {
                props.loading ? 
                <div className="takenExamLoader" >
                    <Loader /> 
                </div> : null
            }
            
        </div>
    )
}

export default Title;
