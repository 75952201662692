
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const validateYear = (year) => {

    switch (year) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;

        case "null":
            return NO_CONTENT;
    
        default:
            break;
    }

    const yearInt = parseInt(year);

    switch (true) {
        case (yearInt < 4):
            return BAD_REQUEST;

        default:
            return SUCCESS;
    }
}

export default validateYear;
