
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// api
import feedbackApi from "../../../services/Networking/feedback";

// style
import "./FeedbackField.scss";

// classes
import errorClass from "../../../services/errors/classes";
import option from "../../input/elements/Select/classes/option";

// local services
import feedbackErrors from "./services/errors/feedbackErrors";
import sentErrors from "./services/errors/sentErrors";

// services
import convertError from "../../../services/errors/convertError";

// components
import SelectInput from "../../input/Select";
import CkTextInput from "../../input/CkText";
import Loader from "../../../components/Loader";

// constants
import { SUCCESS, NO_CONTENT } from "../../../services/errors/constants";

const FeedbackField = (props) => {
    // still to implement: input e-mail optional & indicate if teacher or student

    // url
    const url = window.location.href;

    // id's
    let { tijdvakID, kaID, examID } = useParams();
    const topicId = tijdvakID ? parseInt(tijdvakID.replace("t-", "")) : null;
    const subTopicId = kaID ? parseInt(kaID.replace("st-", "")) : null;
    const examId = examID ? parseInt(examID.replace("e-", "")) : null;

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // loading
    const [loading, toggleLoading] = useState(false);

    const [typeError, updateTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [feedbackError, updateFeedbackError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [feedbackClass, updateFeedbackClass] = useState({

        type: 0,
        feedback: "",

        subject: "Hisotry",
        section: "1",
        topic: topicId,
        subTopic: subTopicId,
        exam: examId,
        url: url,

    });

    // sent feedback
    const sendFeedback = async() => {

        toggleLoading(true);

        // check if feedback filled in
        if (feedbackClass.feedback === "") {

            // thow error that something needs to be filled in!
            convertError({
                errorCode: NO_CONTENT,
                updateLocally: updateFeedbackError,
                customErrors: feedbackErrors
            });

            toggleLoading(false);
            return null;
        }

        // sendable object
        const sendable = {
            "userEmail": ``,
            "subject": "Hisotry",
            "section": `1`,
            "feedback": `URL: ${feedbackClass.url} - TopicId: ${feedbackClass.topic} - KaId: ${feedbackClass.subTopic} - ExamId: ${feedbackClass.exam}- FeedbackType: ${feedbackClass.type} - Feedback: ${feedbackClass.feedback}`
        }

        // send
        const response = await feedbackApi.postFeedback(sendable);

        // success returned, update with 200
        if (response.status === SUCCESS) {

            // display success
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: response.status,
                    updateLocally: null,
                    customErrors: sentErrors
                }))
            );

            // close
            props.toggleFolded(true);
            
        } else {
            // show auth errors publicly
            dispatch(globalErrorsActions.addError(
                    convertError({
                    errorCode: response.status,
                    updateLocally: null,
                    customErrors: null
                }))
            );
        }

        toggleLoading(false);

    }

    return (
        <div
            className="FeedbackField"
        >
            <div className="FeedbackTitle" >
                Feedback geven
            </div>

            <div
                className="feedbackSubTitle"
            >
                Feedback Type:
            </div>
            
            <SelectInput
                options={[
                    new option({
                        value: 0,
                        title: "Algemene feedback"
                    }),
                    new option({
                        value: 1,
                        title: "Advies nieuwe functionaliteiten"
                    }),
                    new option({
                        value: 2,
                        title: "Foute informatie"
                    }),
                    new option({
                        value: 3,
                        title: "Informatie toevoegen"
                    }),
                    new option({
                        value: 4,
                        title: "Iets anders"
                    }),
                ]}

                valueKey="type"
                
                // use phoney data class if custom updated
                dataClass={feedbackClass}

                // use custom update if no edited version of question
                // customUpdateFunc={updateSelected}

                useBaseStyle={true}

                errorClass={typeError}
                updateError={updateTypeError}
            />

            {/* tekst field for feedback */}
            <div
                className="feedbackSubTitle feedbackText"
            >
                Feedback:
            </div>

            <CkTextInput
                
                valueKey="feedback"
                dataClass={feedbackClass}

                // customUpdateFunc={updateTitle}

                errorClass={feedbackError}
                updateError={updateFeedbackError}
            />

            {/* button for sending */}
            { loading ? <Loader /> :
            
            <div
                className="sendFeedback"
                onClick={() => sendFeedback()}
            >

                Feedback opsturen

            </div>
            }

        </div>
    )
}

export default FeedbackField;
