
const cenvert = (image) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(image);

        fileReader.onload = () => {
            // Extract the Base64 portion of the data URL
            const base64Data = fileReader.result.split(',')[1];
            resolve(base64Data);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    })
}

export default cenvert;
