
const selectedSplit = (filters) => {
    const selectedSplit = filters.getFilter("Splits").getKeyValuesOfSelectedOptions();

    if (selectedSplit.length !== 1) {
        return null;
    }

    return selectedSplit[0];
}

export default selectedSplit;
