
const calculateNewCurrentPageIndex = (indexFirstItemCurrentPage, itemsPerPage) => {
    if (itemsPerPage <= 0) {
        throw new Error('itemsPerPage must be greater than 0');
    }

    // Calculate the page index by dividing the first item index by items per page
    const currentPageIndex = Math.floor(indexFirstItemCurrentPage / itemsPerPage);

    return currentPageIndex;
}

export default calculateNewCurrentPageIndex;
