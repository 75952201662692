
class questionPut {

    constructor({

        title = "",
        type = null,
        image = "",
        category = null,
        scoredPoints = null,
        totalPoints = null,

    } = {}) {

        this.title = title;
        this.type = type;
        this.image = image;
        this.category = category;
        this.scoredPoints = scoredPoints;
        this.totalPoints = totalPoints;

    }

}

export default questionPut;
