
// style
import "./DelQ.scss"

const DelQ = (props) => {

    return (
        <>{
            <div 
                className={`${
                    props.title === "Permanent Verwijderen" ? "DelQ" :
                        props.title === "Annuleren" ? "cancelDelQ" :
                            "DelQNormal"
                }`}
                onClick={() => props.whenClicked()}
            >

                {props.title}

            </div>
    
        }</>
    )

}

export default DelQ
