
// react
import React, { useState } from 'react';

// style
import "./ExamNav.scss";

// components
import Breadcrumb from "../../../../components/Breadcrumb";
import PageNav from "../../../../components/PageNav";

const Nav = (props) => {

    return (
        <div className="ExamNav">
            <Breadcrumb
                noTopicPage={false}
            />

            <div className="ExamViews">
                <PageNav
                    initalTab={props.initalTab}
                    
                    tabs={props.examTabs}
                    tabsAlignRight={true}

                    noLine={true}
                    customRender={true}
                    updatedCustomSelectedTab={props.updateExamView}

                    showOne={true}
                />
            </div>
        </div>
    )
}

export default Nav;
