
import parse from "html-react-parser";

// services
import cleanText from "../../../../../../../../../../services/cleanText";

// style
import "./QuestionPreview.scss";

const QuestionPreview = (props) => {
    
    return (
        <div className="sectExamQuestionPreviewView">
            <div className="sectExamQuestionNumber">
                Vraag {props.number+1}
                
                <div className="sectExamQuestionType">
                    {props.data.type === 1 ? "Open Vraag" : "Multiple Choice Vraag"}
                </div>
                
            </div>

            <div className="sectExamQuestionPreview">{
                parse(
                    cleanText({
                        textToClean: props.data.title,
                        h1: true,
                        h2: true,
                        h3: true,
                        h4: true,
                        p: false,
                        list: false,
                    })
                )
            }</div>
        </div>
    )
}

export default QuestionPreview;
