
import React, { useEffect, useRef, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";
import userActions from "../../../../services/redux/actions/user.action";

// routing
import { useHistory, useLocation } from 'react-router-dom';

// style
import "./User.scss";

// image
import vector from "../../assets/images/Vector.png";

// services
import convertError from "../../../../services/errors/convertError";
import logOutErrors from "../../../../services/errors/logOutErrors";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

const User = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    const location = useLocation();
    const history = useHistory();
    const profile = ()=>{
        history.push("/profile");
    }

    // ref
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                togglePressed(false);
            }
        }

        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [location]);

    const logOut = async() => {
        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // log user in
        dispatch(userActions.logOut());

        // display success
        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: SUCCESS,
                updateLocally: null,
                customErrors: logOutErrors
            }))
        );
    }

    const [pressed, togglePressed] = useState(false);
    const whenPressed = () => {
        if (user?.user) {
            togglePressed((value) => !value)
        }
    }

    return (
        <>{
            props.user ?
            <div ref={dropdownRef} className={`UserButton ${pressed ? "UserButtonUnfolded" : ""}`} onClick={() => whenPressed()} >
                    <img 
                        className="vectorUser"
                        src={vector} 
                        alt="Italian Trulli" 
                    />
                    
                    <div className="nameHolder" >
                        {
                            props.title
                        }
                    </div>
                {
                    pressed && (
                    <div className="UserDropdown">
                        <a className="UserDropdownItem" onClick={ ()=>{ profile();} }>ACCOUNT</a>
                        <a className="UserDropdownItem" onClick={() => logOut()} href="#">UITLOGGEN</a>
                    </div>
                )}
            </div>
            :
            <div className="UserButton" onClick={() => props.toggleShowReg((value) => !value)} >
                <img 
                    className="vectorUser"
                    src={vector} 
                    alt="Italian Trulli" 
                />
                
                <div className="nameHolder" >
                    {
                        props.title
                    }
                </div>
            </div>
        }</>
    )
}

export default User;
