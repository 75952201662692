
import { useState, useEffect } from "react";

// styles
import "./ExamFeedback.scss";

// classes
import ClientData from "../../../../services/classes/clientData/ClientData";

// services
import networking from "../../../../services/handleNetworking/networking";
import commentsApi from "../../../../services/Networking/comments/comments";

// components
import List from "../../../../components/List";
import Feedback from "./Feedback/Feedback";
import Loader from "../../../../components/Loader";

const ExamFeedback = (props) => {

    // data
    const [feedbackList, updateFeedbackList] = useState([]);

    // states
    const [loadingGet, toggleLoadingGet] = useState(true);

    // errors
    const [getExamError, updateGetExamError] = useState(null);

    const addFeedback = () => {
        updateFeedbackList((currentFeedbackList) => {
            currentFeedbackList = [
                ...currentFeedbackList,
                new ClientData({
                    post: {
                        comment: ""
                    }
                })
            ];
            return currentFeedbackList;
        });
    }

    const formatFeedback = (sentFeedback) => {
        const formattedList = sentFeedback?.map((feedback) => {
            return new ClientData({
                id: feedback?.id,
                sent: feedback
            })
        }).reverse();
        updateFeedbackList(formattedList);
    }
    const getExam = async() => {
        const returned = await networking({
            updateContent: formatFeedback,
            toggleLoading: toggleLoadingGet,
            errorOnSuccess: false,

            api: commentsApi.getComments,
            apiParams: props.exam?.sent?.uuid,

            updateError: updateGetExamError,
        });
    }
    useEffect(() => {
        getExam();
    }, []);

    const renderFeedback = (i, feedback) => {
        return (
            <Feedback
                key={feedback?.id || i}
                index={i}

                takenExamUuid={props.exam?.sent?.uuid}

                feedback={feedback}
                updateFeedbackList={updateFeedbackList}

                getExam={getExam}
            />
        )
    }

    return (
        <>{
            !props.exam?.childLists?.feedback ? null :
            <div className="examFeedbackView" >
                <div className="examFeedbackTitle">
                    Algemene commentaar
                    <div className="examFeedbackAdd" onClick={addFeedback} >
                        Toevoegen
                    </div>
                    {loadingGet ? <Loader /> : null}
                </div>
                {
                    feedbackList.length === 0 ?
                    <div>Nog geen feedback</div>
                    :
                    <List
                        items={feedbackList}
                        renderItem={renderFeedback}
                    />
                }
            </div>
        }</>
    )
}

export default ExamFeedback;
