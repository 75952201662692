
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarred } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';

// style
import "./Star.scss";

const Star = (props) => {
    return (
        <>{
            props.starred ? 
            <FontAwesomeIcon 
                onClick={props.onClick}
                className="Starred"
                icon={faStarred}
            />
            :
            <FontAwesomeIcon 
                onClick={props.onClick}
                className="Star"
                icon={faStar}
            />
        }</>
    )
}

export default Star;
