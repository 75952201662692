
const arrayToString = (urlKey, values) => {
    
    if (!Array.isArray(values)) {
        return `${urlKey}=${values}`;
    }
    
    const stringArray = values.map((value, key) => {
        
        if (key === 0) {
            return `${urlKey}=${value}`
        }

        return `&${urlKey}=${value}`
    });

    return stringArray.join('');
}

export default arrayToString;
