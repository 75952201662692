
// standard objects
import errorClass from "../../../../../../../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../../../../../../../services/errors/constants";

const submitErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: SUCCESS,
                priority: 1,
                title: `Je antwoorden zijn opgeslagen 👏`,
                description: `Je antwoorden zijn opgeslagen 👏`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default submitErrors;
