
// standard objects
import errorClass from "../../../../../services/errors/classes";

// constants
import { SUCCESS, UNAUTHORIZED } from "../../../../../services/errors/constants";

const starringErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Gelukt",
                description: "Gelukt",
                advice: null,
                moreInfo: null,
            });

        // add incorrect password
        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: `Je hebt geen autouriteit om deze te favorieten`,
                description: `Je hebt geen autouriteit om deze te favorieten`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default starringErrors;
