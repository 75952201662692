
class NewSubject {
    constructor({
        subjectName = ""
    }= {}) {
        this.subjectName = subjectName;
    }
}

export default NewSubject;
