
// react
import React from 'react';

// style
import "./ProfilePic.scss";

const ProfilePic = (props) => {
    return (
        <div className="profile-card">
            <img src={props.userImage} alt="" className="profile-image" />
            <div className="name">{props.firstName} { props.lastName}</div>
            <div className="email">{props.email}</div>
        </div>
    )
}

export default ProfilePic;
