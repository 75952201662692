
function option({
    value,
    title = ""
} = {}) {
    this.value = value;
    this.title = title;
}

export default option;
