
import React, { useState, useEffect, useRef } from 'react';

// style
import "./Time.scss";
import "../assets/commonStyles.scss";

// classes
import errorClass from "../../../../services/errors/classes";

// services
import formatTime from '../../DateTime/services/formatTime';

// constants
import { SUCCESS } from "../../../../services/errors/constants";

const Time = (props) => {
    // we're getting a warning because of this but it works
    const [typed, toggleTyped] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        toggleTyped(false);
        resetValue();
    }, [props.resetTyped]);

    const resetValue = () => {
        if (inputRef.current && !props.value) {
            inputRef.current.value = null;
        }
    }

    const onChange = (newValue) => {
        
        let newText = newValue.target.value;

        if (props.customUpdateFunc) {
            
            props.customUpdateFunc(
                props.valueKey,
                newText
            );

        } else if (props.dataObject) {

            props.dataObject[props.valueKey] = newText;

        } else if (props.clientData) {
            props.clientData.updateProperty(props.valueKey, newText);
        }

        toggleTyped(true);

        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }

        if (props.updateWarning) {
            props.updateWarning(new errorClass({
                errorCode: SUCCESS
            }));
        }
    }
    
    return (
        <input 
            type="time"
            ref={inputRef}
            className={`
                ${props.styles?.input || ""}
                ${props.showError ? props.styles?.inputError || "inputError" : props.showWarning ? props.styles?.inputWarning || "inputWarning" : null}
            `}

            name={props.valueKey}

            value={typed ? undefined : formatTime(props.value)}
            onChange={onChange}
        />
    )
}

export default Time;
