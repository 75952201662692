
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const valitateRoleInital = (role) => {

    switch (role) {
        case "Teacher":
            return BAD_REQUEST;

        default:
            return SUCCESS;
    }
}

export default valitateRoleInital;
