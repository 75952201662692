
// constants
import { PRACTICE_EXAM, FINAL_EXAM } from "./categoryConstants";

const translateExamCategory = (chategoryId) => {

    if (chategoryId === PRACTICE_EXAM) {
        return "Oefentoets";
    } else if (chategoryId === FINAL_EXAM) {
        return "Eindexamen";
    }

}

export default translateExamCategory;
