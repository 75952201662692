import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import AppRouting from './App/App';
import { createStore } from "redux";

// style
import "./newSRC/assets/constants.scss"

import track from "react-tracking";

// importing reducers
import rootReducer from './newSRC/services/redux/reducers';
import { Provider } from 'react-redux';

const store = createStore(
    rootReducer, 
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
//export default store;
function App(props) {
    return (
        <Provider store={store}>
            <AppRouting />
        </Provider>
    )
}

// functional component usage
const TrackedApp = track(
    // app-level tracking data
    { app: "EtAlia-web" },
  
    // top-level options
    {
      // custom dispatch to console.log in addition to pushing to dataLayer[]
      dispatch: data => {
        // console.log(data);
      }
    }
  )(App);

  document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#EDE8E2')

const rootElement = document.getElementById('root');
ReactDOM.render(<TrackedApp />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
