
// styles
import "./BooleanSelect.scss";

// elements
import CheckBox from "../../../AlterContent/elements/CheckBox";

const BooleanSelect = (props) => {
    return (
        <div
            className={props.styling?.view ? props.styling?.view : "basicBooleanSelectView"}
            onClick={() => props.toggleSelected((value) => !value)}
        >
            <CheckBox
                checked={props.selected}
            />
            <div>{
                props.title
            }</div>

        </div>
    )
}

export default BooleanSelect;
