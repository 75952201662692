
class infoRowClass {
    constructor({
        id = null,
        title = null,
        value,
        onClick = null,
        link=null,
        customStyling=null,
    } = {}) {
        
        this.id = id;
        this.title = title;
        this.value = value;
        this.onClick = onClick;
        this.link = link;
        this.customStyling = customStyling;
    }
}

export default infoRowClass;
