
import React, { useEffect, useState } from 'react';

// cookies
import Cookies from 'universal-cookie';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";
import userActions from "../../../../services/redux/actions/user.action";

// networking
import authApi from "../../../../services/Networking/authentication";

// classes
import loginInfo from "./services/classes";
import errorClass from "../../../../services/errors/classes";

// components
import Loader from "../../../../components/Loader";

// constants
import { SUCCESS, UNAUTHORIZED, NOT_FOUND, NO_CONTENT } from "../../../../services/errors/constants";

import { useHistory } from 'react-router-dom';
import user from '../../../../services/classes/user';
import handleLocalStorage from '../../../../services/localStorage/localStorage';

const OidcCallBack = (props) => {

    // redux
    const dispatch = useDispatch();

    const [userInfo, updateUserInfo] = useState(new loginInfo());

    let history = useHistory();
    const home = () => {
        const cookies = new Cookies();
        var callback_url=cookies.get('login_url_ck');
        if (callback_url && callback_url!==''){
            cookies.remove('login_url_ck');
            // window.location.href = callback_url;
            history.replace(callback_url);
        } else {
            history.push("/geschiedenis");
        }
    }
    
    // submit
    const [loading, toggleLoading] = useState(false);

    const hanldeLogin = async() => {
        const query = new URLSearchParams(window.location.search);
        const code = query.get('sub');
        const type = query.get('provider');
        if(code){
                // start loading
                toggleLoading(true);
                // send information to back-end
                const authResponse =await authApi.oidcauth(code,type);
                // success returned, update with 200 & update login
                if (authResponse.status === SUCCESS) 
                {
                    // add to local storage directly
                    const userClass = new user({
                        firstName: authResponse.payload.firstName,
                        email: authResponse.payload.email,
                        fullName: authResponse.payload.fullName,
                        isVerified: authResponse.payload.isVerified,
                        roles: authResponse.payload.roles,
                        token: authResponse.payload.token
                    });
                    handleLocalStorage({
                        action: "add",
                        key: "user",
                        data: userClass
                    });
                    
                    // log user in
                    dispatch(userActions.login(authResponse.payload));
                }
                // stop loading
                toggleLoading(false);
                home();
                
        }
        else{
            home();
        }
    }

    useEffect(() => {
        hanldeLogin();
    }, []);

    return (
       
       <div>Wait While we let you in....</div>
    )

}

export default OidcCallBack;
