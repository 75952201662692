
const cleanText = ({
    textToClean,
    h1 = false,
    h2 = false,
    h3 = false,
    h4 = false,
    p = false,
    pEnter = false,
    bold = false,
    list = false,
} = {}) => {
    
    let output = textToClean;

    if (Array.isArray(output)) {
        output = output.join('');
    }

    if (output) {
        
        // makes sure links always open in new tab
        output = output.replaceAll('<a', '<a target="_blank" rel="noopener"');

        // handle \n
        output = output.replaceAll('\\n', '<br>');

        if (h1) {
            output = output.replaceAll('<h1>', '').replaceAll('</h1>', '');
        }

        if (h2) {
            output = output.replaceAll('<h2>', '').replaceAll('</h2>', '');
        }

        if (h3) {
            output = output.replaceAll('<h3>', '').replaceAll('</h3>', '');
        }

        if (h4) {
            output = output.replaceAll('<h4>', '').replaceAll('</h4>', '');
        }

        if (p) {
            output = output.replaceAll('<p>', '').replaceAll('</p>', '');
        }

        if (pEnter) {
            output = output.replaceAll('<p>', '').replaceAll('</p>', '<br>');
        }

        if (bold) {
            output = output.replaceAll('<strong>', '').replaceAll('</strong>', '');
        }

        if (list) {
            output = output.replaceAll('<ol>', '').replaceAll('</ol>', '').replaceAll('<li>', '').replaceAll('</li>', '');
        }

        return output;

    }

    return output;
}

export default cleanText;
