
import { combineReducers } from 'redux';

import globalErrors from "./globalErrors.reducer";
import userReducer from "./user.reducer";

const rootReducer = combineReducers({

  globalErrors,
  userReducer,

});

export default rootReducer;
