
// service
import handleLocalStorage from "../../localStorage";

// constants
import { LOGIN, LOG_OUT} from "../constants";

// classes
import user from "../../classes/user";

let initialState = handleLocalStorage({
        action: "fetch",
        key: "user",
        data: null
    }) ? {
        user: handleLocalStorage({
            action: "fetch",
            key: "user",
            data: null
        }
    )} : {
    user: null
    };

const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN:

            const userClass = new user({
                userId: action.payload.id,
                firstName: action.payload.firstName,
                email: action.payload.email,
                fullName: action.payload.fullName,
                isVerified: action.payload.isVerified,
                roles: action.payload.roles,
                token: action.payload.token
            });

            handleLocalStorage({
                action: "add",
                key: "user",
                data: userClass
            });

            return {
                ...state,
                user: userClass
            };

        case LOG_OUT:
            
            handleLocalStorage({
                action: "remove",
                key: "user",
                data: null
            });

            return {
                ...state,
                user: null
            };

        default:
            return {
                ...state
            };
    }

}

export default userReducer;
