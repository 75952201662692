
import { SUCCESS, NO_CONTENT, BAD_REQUEST } from "../../../../../../../services/errors/constants";

const valitateLevel = (schoolLevel) => {

    switch (schoolLevel) {
        case "":
            return NO_CONTENT;
        
        case null:
            return NO_CONTENT;

        case undefined:
            return NO_CONTENT;

        case "null":
            return NO_CONTENT;

        case "VMBO":
            return BAD_REQUEST;

        case "praktijkonderwijs":
            return BAD_REQUEST;

        default:
            return SUCCESS;
    }
}

export default valitateLevel;
