
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// style
import "./TakenExams.scss";

// networking local
import fetchExamOrder from "../../services/networking/takenExams/fetchExamOrder";

// services
import convertError from "../../../../services/errors/convertError";
import access from '../../../../services/access/accessCheck/access';

// components local
import TakenExam from "./components/takenExam";

// components
import Title from "../../components/Title";
import Loader from '../../../../components/Loader';
import List from "../../../../components/List";

// constants
import { SUCCESS, NO_RESPONSE } from "../../../../services/errors/constants";
import { STUDENT } from '../../../../services/access/constants';

const TakenExams = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [loading, toggleLoading] = useState(true);

    const [takenExams, updateTakenExams] = useState([]);

    useEffect(() => {

        if (access({
            accessLevel: [STUDENT],
            user: user
        })) {
            fetchTakenExams();
        } else {
            updateTakenExams([]);
            toggleLoading(false);
        }
    }, [user, props.examClass]);
    const fetchTakenExams = async() => {

        toggleLoading(true);

        const returnedTakenExams = await fetchExamOrder({
            examId: props.examClass.uuid,
            
            handleApiReturn: handleFetchApiReturn,

            updateOrder: updateTakenExams

        });

        toggleLoading(false);

    }
    // handles fetching errors
    const handleFetchApiReturn = async(returned) => {
        
        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return false;
        }

        if (returned.status === SUCCESS) {
            
            toggleLoading(false);
            return true;

        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);
        return false;

    }

    const renderTakenExam = (i, takenExam) => {
        
        return (
            <TakenExam
                key={i}
                
                takenExam={takenExam}
                examClass={props.examClass}
            />
        )
    }

    return (
        <div className="takenExams">

            <Title
                title={"Jouw vorige poginenen"}

                justTitle={true}

                // showExamInfo={showExamInfo}
                // toggleShowExamInfo={toggleShowExamInfo}

                // loading={loading && examClass}
            />

            {
                loading ? <Loader /> :
                !user || !user.user ? 
                <div className="noTakenExams" >
                    Je moet ingelogd zijn om jouw vorige pogingen te kunnen inzien.
                </div> :
                !takenExams ? null :
                takenExams.length === 0 ? 
                <div className="noTakenExams" >

                    Deze toets heb je nog niet gemaakt. Klik op 'Toets maken' rechtsboven in om een poging te wagen. Succes!

                </div>
                :
                <List
                    items={takenExams}
                    renderItem={renderTakenExam}
                />
            }
            
        </div>
    )

}

export default TakenExams;
