
// standard objects
import errorClass from "../../../../../services/errors/classes/errorClass";

// constants
import { SUCCESS, INTERNAL_ERROR, NOT_FOUND } from "../../../../../services/errors/constants";

const sendUserData = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Wachtwoord gewijzigd",
                description: "Wachtwoord gewijzigd",
                advice: null,
                moreInfo: null,
            });

        // add incorrect password
        case INTERNAL_ERROR:

            return new errorClass({
                errorCode: INTERNAL_ERROR,
                priority: 1,
                title: "Er is iets fout gegaan. Er is mogelijk te veel tijd verstreken sinds je de 'wachtwoord wijzigen'-mail hebt ontvangen (maximaal twee uur). In dat geval kun je de mail opnieuw laten versturen.",
                description: "Er is iets fout gegaan. Er is mogelijk te veel tijd verstreken sinds je de 'wachtwoord wijzigen'-mail hebt ontvangen (maximaal twee uur). In dat geval kun je de mail opnieuw laten versturen.",
                advice: null,
                moreInfo: null
            });

        // email does not exist
        case NOT_FOUND:

            return new errorClass({
                errorCode: NOT_FOUND,
                priority: 1,
                title: "Dit mail adres bestaat niet",
                description: "Dit mail adres staat niet in ons systeem.",
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default sendUserData;
