
function regInfo({
    firstName = "",
    lastName = "",
    email = "",
    // schoolId = "",
    schoolLevel = null,
    schoolYear = null,
    password = "",
    confirmPassword = "",
    role = "Student",
    // profileImage = "",
} = {}) {

    this.firstName = firstName;
    this.lastName = lastName;
    // this.schoolId = schoolId;
    this.schoolLevel = schoolLevel;
    this.schoolYear = schoolYear;
    this.email = email;
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.role = role;
    // this.profileImage = profileImage;

}

export default regInfo;
