
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const assignmentLinkApi = {
    postAssignmentLink,
    getAssignmentLinks,
    putAssignmentLink,
    putDeactivateAssignmentLink,
}

async function postAssignmentLink({ assignmentUuid, data }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/assignments/${assignmentUuid}/links`,
            data,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function getAssignmentLinks(assignmentUuid) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/assignments/${assignmentUuid}/links`, 
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function putAssignmentLink({ assignmentUuid, token, data }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/assignments/${assignmentUuid}/links/${token}`, 
            data,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function putDeactivateAssignmentLink({ assignmentUuid, token }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/assignments/${assignmentUuid}/links/${token}/deactivate`, 
            {},
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}


export default assignmentLinkApi;
