
export const SUCCESS = 200;
export const MAIN_OPERATION_SUCCESS = 512;
export const INTERNAL_ERROR = 500;
export const NO_CONTENT = 204;
export const NO_RESPONSE = 444;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const CONFLICT = 409;
export const UNSUPPORTED_MEDIA_TYPE = 415;
