
// constants
import { ETALIA_ENV, UVA_ENV, UVA_URLS } from "./constants";

const handleEnvironment = () => {
    const hostname = window.location.hostname;

    // localhost
    // university.example.com
    if (UVA_URLS.includes(hostname)) {
        return UVA_ENV;
    }
    return ETALIA_ENV;
}

export default handleEnvironment;
