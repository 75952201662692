
import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import subTopicApi from "../../../../services/Networking/subTopic";

// style
import "./AddSubTopic.scss"

// components
import TextInput from '../../../../components/input/Text';
import Submit from "../../../../components/input/elements/Submit";
import SelectInput from "../../../../components/input/Select";
import ImageInput from "../../../../components/input/Image";
import Loader from "../../../../components/Loader";

// elements
import Title from "../../../../elements/ModalTitle";

// classes
import addSubTopicClass from "./services/classes";
import errorClass from "../../../../services/errors/classes";
import option from "../../../../components/input/elements/Select/classes/option";

// constants
import { SUCCESS, INTERNAL_ERROR } from "../../../../services/errors/constants";

// local services
import addSubTopicErrors from "./services/errors/addSubTopic";
import valitateSelect from "./services/validate/select";

// services
import convertError from "../../../../services/errors/convertError";
import validateTextInput from "../../../../services/validate/textInput";
import defaultImageErrors from "../../../../services/errors/defaultImageErrors";
import valitateImage from "../../../../services/validate/image";
import defaultTextInputErrors from "../../../../services/errors/defaultTextInputErrors";

const AddSubTopic = (props) => {

    // topic id
    let { tijdvakID } = useParams();
    const topicId = props.topicId || parseInt(tijdvakID?.replace("t-", ""));

    // redux
    const dispatch = useDispatch();

    const [subTopicInfo, updateSubTopicInfo] = useState(props.existingData ? props.existingData : new addSubTopicClass());

    // input errors
    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [subTitleError, updateSubTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [extraInfoError, updateExtraInfoError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [subTopicTypeError, updateSubTopicTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [imageError, updateImageError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const validateField = ({
        valueKey,
        validate,
        updateError,
        customErrors
    }) => {
        // validate
        const valid = validate(subTopicInfo[valueKey]);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {
        let validInput = true;

        // validate title
        if (!validateField({
            valueKey: "title",
            validate: validateTextInput,
            updateError: updateTitleError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate type
        if (!validateField({
            valueKey: "type",
            validate: valitateSelect,
            updateError: updateSubTopicTypeError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate image
        if (!validateField({
            valueKey: "backgroundImage",
            validate: valitateImage,
            updateError: updateImageError,
            customErrors: defaultImageErrors
        })) validInput = false

        return validInput;
    }

    const save = async() => {

        toggleLoading(true);

        const validInput = await validateFields();

        if (validInput) {

            // create url for now
            subTopicInfo.url = `${subTopicInfo.title}${subTopicInfo.subtitle}`;
            
            let responseSubTopic;

            if (subTopicInfo.subtopicId) {
                responseSubTopic = await subTopicApi.putSubTopicBySubTopicId(subTopicInfo.subtopicId, subTopicInfo);
            } else {
                responseSubTopic = await subTopicApi.createSubTopic(topicId, subTopicInfo);
            }
            
            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!validInput) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (responseSubTopic.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: responseSubTopic.status,
                        updateLocally: null,
                        customErrors: addSubTopicErrors
                    }))
                );
                
                if (props.triggerReRender) {
                    props.triggerReRender((value) => !value);
                }
                if (props.runOnSuccess) {
                    props.runOnSuccess();
                }
                props.toggleShow(false);

            } else {
                // also show error locally

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: responseSubTopic.status,
                        updateLocally: null,
                        customErrors: addSubTopicErrors
                    }))
                );
            }

        }

        toggleLoading(false);

    }

    return (
        <div className="AddSubTopic" >
            <Title
                title={props.existingData ? "ONDERWERP AANPASSEN" : "ONDERWERP TOEVOEGEN"}
            />

            <TextInput
                title="TITEL"
                
                valueKey="title"
                dataClass={subTopicInfo}

                errorClass={titleError}
                updateError={updateTitleError}
            />

            <TextInput
                title="SUB-TITEL"
                
                valueKey="subtitle"
                dataClass={subTopicInfo}

                errorClass={subTitleError}
                updateError={updateSubTitleError}
            />


            <TextInput
                title="EXTRA INFO"
                
                valueKey="extraInfo"
                dataClass={subTopicInfo}

                errorClass={extraInfoError}
                updateError={updateExtraInfoError}
            />

            <SelectInput
                title="ONDERWERP type"

                options={[
                    new option({
                        value: 0,
                        title: "."
                    }),
                    new option({
                        value: 1,
                        title: "Informerend"
                    }),
                    new option({
                        value: 2,
                        title: "Voorbereidend"
                    }),
                ]}

                valueKey="type"
                dataClass={subTopicInfo}
                updateDataClass={null}

                errorClass={subTopicTypeError}
                updateError={updateSubTopicTypeError}
            />

            <ImageInput
                title="AFBEELDING"

                valueKey="backgroundImage"
                dataClass={subTopicInfo}

                errorClass={imageError}
                updateError={updateImageError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

        </div>
    )
}

export default AddSubTopic;
