
// styles
import "./PageListNavigator.scss";

// services
import calculateItemOnPageIndexRange from "./services/calculateItemOnPageIndexRange";

// components
import List from "../List";

const PageListNavigator = (props) => {

    const updateIndex = (newPageIndex) => {
        if (!props.itemsLength) return;
        const {firstIndex, lastIndex} = calculateItemOnPageIndexRange(newPageIndex, props.itemsPerPage, props.itemsLength);
        props.updateIndex(newPageIndex);
        props.updateFirstItemOnPageIndex(firstIndex);
        props.updateLastItemOnPageIndex(lastIndex);
    }

    const nextClicked = () => {
        if (props.currentPageIndex + 1 === props.amountOfPages) {
            return;
        }
        const newPageIndex = props.currentPageIndex + 1;
        updateIndex(newPageIndex);
    }

    const previousClicked = () => {
        if (props.currentPageIndex === 0) {
            return;
        }
        const newPageIndex = props.currentPageIndex - 1;
        updateIndex(newPageIndex);
    }

    const renderPageNumber = (i, data) => {
        return (
            <div key={data} className="PageListNavigatorPageNumbersView PageListNavigatorPageNumberMargin" >
                <div 
                    className={`
                        PageListNavigatorPageNumberView
                        ${i === props.currentPageIndex ? "PageListNavigatorPageNumberSelectedView" : ""}
                    `}
                    onClick={() => updateIndex(i)}
                >{
                    data
                }</div>
                {i+1 === props.amountOfPages ? "" : ","}
            </div>
        )
    }
    
    return (
        <>{
            props.amountOfPages === undefined || props.amountOfPages === null ||
            props.currentPageIndex === undefined || props.currentPageIndex === null ? null :
            <div className="PageListNavigatorView" >
                <div
                    className={props.currentPageIndex === 0 ? "PageListNavigatorNotButton" : "PageListNavigatorButton"}
                    onClick={previousClicked}
                >
                    Vorige
                </div>

                <div className="PageListNavigatorPageNumbersView" >
                    <div className="PageListNavigatorPageNumberMargin">Pagina: </div>
                    <List
                        items={Array.from({ length: props.amountOfPages }, (v, i) => i + 1)}
                        renderItem={renderPageNumber}
                    />
                </div>

                <div
                    className={props.currentPageIndex + 1 === props.amountOfPages ? "PageListNavigatorNotButton" : "PageListNavigatorButton"}
                    onClick={nextClicked}
                >
                    Volgende
                </div>
            </div>
        }</>
    )
}

export default PageListNavigator;
