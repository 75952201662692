
// style
import "./PlainTextPage.scss";

const PlainTextPage = (props) => {

    return (
        <div
            className="PlainTextPageView"
        >

            <div
                className="PlainTextPageTitle"
            >{
                props.Title
            }</div>


            <div
                className="PlainTextPageTextHolders"
            >
                <div
                    className="PlainTextPageText"
                >{
                    props.Text
                }</div>
            </div>


        </div>
    )

}

export default PlainTextPage;
