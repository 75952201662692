
// styles
import "./Textarea.scss";

// elements
import Textarea from "../elements/Textarea/Textarea";

// constants
import { SUCCESS } from "../../../services/errors/constants";

const TextareaInput = (props) => {

    return (
        <>{
            !props.dataClass && !props.clientData ? null :
            <div className={props.useBaseStyle ? "textareInputBaseView" : "textareaInputView"} >
                {
                    !props.title ? null :
                    <div className="textareInputTitle" >
                        {props.title}
                    </div>
                }
                {
                    props.errorClass?.errorCode === SUCCESS ? null :
                    <div className="textareShowError" >
                        {
                            props.errorClass?.description
                        }
                    </div>
                }

                <Textarea
                    value={props.dataClass ? props.dataClass[props.valueKey] : props.clientData.getCurrentValue(props.valueKey)}
                    
                    valueKey={props.valueKey}
                    dataObject={props.dataClass}
                    clientData={props.clientData}
                    
                    showError={props.errorClass?.errorCode !== SUCCESS}
                    errorClass={props.errorClass}
                    updateError={props.updateError}

                    resizeVertical={props.resizeVertical}
                    resizeHorizontal={props.resizeHorizontal}
                />
            </div>
        }</>
    )
}

export default TextareaInput;
