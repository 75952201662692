
// constants
import { USER_CORRECT, USER_CORRECT_NO_RESPONSE, USER_HALF_CORRECT, USER_IN_CORRECT } from "./constants/userCorrectConstants";

const determineUserCorrectBasedOnSent = (questionHub) => {

    if (questionHub?.feedback?.length === 0) {
        return USER_CORRECT_NO_RESPONSE;
    }

    if (questionHub.sentQuestion?.scoredPoints === questionHub?.sentQuestion?.totalPoints) {
        return USER_CORRECT;
    }

    if (questionHub.sentQuestion?.scoredPoints === 0) {
        return USER_IN_CORRECT;
    }

    return USER_HALF_CORRECT;
}

export default determineUserCorrectBasedOnSent;
