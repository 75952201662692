
// standard objects
import errorClass from "../../../../../../../services/errors/classes";

// constants
import { NO_CONTENT } from "../../../../../../../services/errors/constants";

const startPracticingErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: NO_CONTENT,
                priority: 1,
                title: `Er zijn geen flashcards geselecteerd om te oefenenen`,
                description: `Er zijn geen flashcards geselecteerd om te oefenenen`,
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default startPracticingErrors;
