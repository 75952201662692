
// priority goes from 0 (very high priority) to lower priority

function errorClass({
    errorCode,
    priority = null,
    title = null,
    description = null,
    advice = null,
    moreInfo = null
} = {}) {

    this.errorCode = errorCode;
    this.priority = priority;
    this.title = title;
    this.description = description;
    this.advice = advice;
    this.moreInfo = moreInfo;

}

export default errorClass;
