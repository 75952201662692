
import { useState } from "react";

import parse from "html-react-parser";

// routing
import { useRouteMatch, Link } from 'react-router-dom';

// services
import cleanText from "../../../../../../services/cleanText";

// local components
import Details from "./components/Details";
import ExamPreview from "./components/ExamPreview";
import Delete from "./components/Delete/Delete";

// components
import PageNav from "../../../../../../components/PageNav";

// classes
import tab from "../../../../../../components/PageNav/services/classes";

// styles
import "./ExamInfo.scss";

// constants
import { EDITOR_ACCESS } from "../../../../../../services/access/contentAccess/contentAccessConstants";

const ExamInfo = (props) => {

    const [examState, updateExamState] = useState(props.data.publishedExam ? "publishedExam" : "draftExam");

    // routing
    let { url } = useRouteMatch();

    const onUpdateTab = (selectedTabId) => {
        if ((props.data.publishedExam || props.data.draftExam) && !(props.data.publishedExam && props.data.draftExam)) {
            // no need to update the examState if there is only one state of the exam
            return;
        }
        if (selectedTabId === 1 || selectedTabId === 2) {
            updateExamState("publishedExam");
        } else if (selectedTabId === 3 || selectedTabId === 4) {
            updateExamState("draftExam");
        }
    }

    return (
        <div className="sectExamInfoView">{
            !props.data || !props.data[examState] || !examState ? 
            <div className="sectExamInfoTitle" >
                Geen examen geselecteerd
            </div>
            :
            <>
                <div className="sectExamInfoTitle" >{
                    !props.data[examState].title ? null :
                    parse(
                        cleanText({
                            textToClean: props.data[examState].title,
                            h1: true,
                            h2: true,
                            h3: true,
                            h4: true,
                            p: true,
                            list: true,
                        })
                    )}
                    <div className="sectExamInfoTitleButtons">
                        
                        <Link 
                            className="sectStartExamInfo" 
                            to={props.userAccess === EDITOR_ACCESS ? 
                                `${url}/e-${props.data[props.data.publishedExam && props.data.draftExam ? "draftExam" : examState].uuid}/Examen-Aanpassen`
                                :
                                `${url}/e-${props.data[props.data.publishedExam && props.data.draftExam ? "draftExam" : examState].uuid}/Examen-Maken`
                            } >{
                            
                            props.userAccess === EDITOR_ACCESS ? 
                            "Aanpassen"
                            :
                            "Start"
                        }</Link>

                        {
                            props.userAccess === EDITOR_ACCESS ? 
                            <Delete
                                examId={props.data[examState].uuid}
                                onSuccess={() => props.updateDataSelected(null)}

                                triggerReRender={props.triggerReRender}
                            />
                            : null
                        }
                    </div>
                </div>

                <PageNav
                    tabs={
                        (props.data.publishedExam || props.data.draftExam) && !(props.data.publishedExam && props.data.draftExam) ?
                        [
                            new tab({
                                id: 1,
                                title: "Info",
                                component: <Details 
                                    data={props.data[examState]} 
                                    userAccess={props.userAccess}
                                />
                            }),
                            new tab({
                                id: 2,
                                title: "Vragen overzicht",
                                component: <ExamPreview data={props.data[examState]} />
                            }),
                        ]
                        :
                        [
                            new tab({
                                id: 1,
                                title: "Gepliceerde Info",
                                component: <Details 
                                    data={props.data[examState]} 
                                    userAccess={props.userAccess}
                                />
                            }),
                            new tab({
                                id: 2,
                                title: "Gepliceerde Vragen",
                                component: <ExamPreview data={props.data[examState]} />
                            }),
                            new tab({
                                id: 3,
                                title: "Draft Info",
                                component: <Details 
                                    data={props.data[examState]} 
                                    userAccess={props.userAccess}
                                />
                            }),
                            new tab({
                                id: 4,
                                title: "Draft Vragen",
                                component: <ExamPreview data={props.data[examState]} />
                            }),
                        ]
                    }
                    updatedCustomSelectedTab={onUpdateTab}
                    initalTab={(props.data.publishedExam || props.data.draftExam) && !(props.data.publishedExam && props.data.draftExam) ? null : 3}
                />
            </>
            
        }</div>
    )
}

export default ExamInfo;
