
// constants
import { VIEW, ACCESS, CHECK, ASSIGNMENT} from "../tabConstants";

// classes
import tab from "../../../../components/PageNav/services/classes";

export const VIEW_ACCESS_TABS = [
    new tab({
        id: VIEW,
        title: "Bekijken",
    }),
    new tab({
        id: CHECK,
        title: "Eerdere Pogingen",
    })
]

export const USE_ACCESS_TABS = [
    new tab({
        id: VIEW,
        title: "Bekijken",
    }),
    // new tab({
    //     id: ACCESS,
    //     title: "Delen",
    // }),
    // new tab({
    //     id: ASSIGNMENT,
    //     title: "Opgeven",
    // }),
]

export const EDITOR_ACCESS_TABS = [
    new tab({
        id: VIEW,
        title: "Bekijken",
    }),
    // new tab({
    //     id: ACCESS,
    //     title: "Delen",
    // }),
    // new tab({
    //     id: ASSIGNMENT,
    //     title: "Opgeven",
    // }),
]
