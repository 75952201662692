
// routing
import { useRouteMatch, Link } from 'react-router-dom';

// styles
import "./ExamStickyButton.scss"

const StickyButton = (props) => {

    // routing
    let { url } = useRouteMatch();

    return (
        <>{
            props.onClickFunc ? 
            <div 
                className="StickyButtonExam"
                onClick={() => props.onClickFunc()}
            >
                {props.title}
            </div>
            :
            <Link 
                className="StickyButtonExam"
                to={props.link}
            >
                {props.title}
            </Link>
        }</>
        

    )
}

export default StickyButton;
