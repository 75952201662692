
// standard objects
import errorClass from "../classes";

// constants
import { NO_CONTENT } from "../constants";

const defaultTextInputErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:
            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Dit vlak moet ingevult zijn.",
                description: "Dit vlak moet ingevult zijn.",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default defaultTextInputErrors;
