
// classes
import filterOptionClass from "../../../filterOption/filterOption";

const generateOptions = (allOption, selectOne, allSelected, optionsTitles = []) => {
    if (!optionsTitles) {
        return [];
    }

    let idTaller = 0;
    let optionsList = [];
    
    if (allOption) {
        optionsList.push(
            new filterOptionClass({
                index: idTaller,
                title: "Alles",
                selected: allSelected,

                keyValue: "All"
            })
        );

        idTaller++;
    }

    optionsTitles.map((option) => {
            optionsList.push(
            new filterOptionClass({
                index: idTaller,
                title: option.title,
                keyValue: option.keyValue,
                selected: allSelected && !selectOne ? true : option.selected
            })
        );

        idTaller++;
    });

    if (optionsList.length === 0) {
        return [];
    }

    return optionsList;

}

export default generateOptions;
