
function starring({
    subTopicId,
    cardId
} = {}) {

    this.subTopicId = subTopicId;
    this.cardId = cardId;

}

export default starring;
