
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import practiceCardApi from "../../../../services/Networking/practiceCards";

// services
import networking from '../../../../services/handleNetworking/networking/networking';

// style
import "./Definitions.scss";

// components
import Add from "../../../../elements/Add";
import List from "../../../../components/List";
import Loader from '../../../../components/Loader';
import LocalModal from '../../../../components/LocalModal';
import AddPracticeCard from "../AddPracticeCard";
import Redux from '../../../../components/Redux/Redux';

// local components
import Definition from "./Definition";

// constants
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';

const Definitions = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [fetchErrors, updateFetchErrors] = useState(null);

    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(true);

    const [definitionsList, updateDefinitionsList] = useState([]);
    const [addDefintion, toggleAddDefinition] = useState(false);

    const [filter, updateFilter] = useState(0);

    const fetchDefinitions = async() => {
        networking({
            updateContent: updateDefinitionsList,
            toggleLoading: toggleLoading,
            api: practiceCardApi.getPracticeCardsBySubTopicIDAndTypeID,
            apiParams: {
                subTopicID: subTopicId, 
                type: 1, 
                contentFilter: filter
            },
            updateError: updateFetchErrors,
            customErrors: null, // still add later?
        });
    }

    useEffect(() => {
        toggleLoading(true);
        fetchDefinitions();
    }, [rerender]);

    useEffect(() => {
        if (!fetchErrors) {
            fetchDefinitions();
        }
    }, [user]);

    const renderCard = (i, definitionData) => {
        return (
            <Definition
                key={definitionData?.practiceCardId}
                data={definitionData}

                fetchDefinitions={fetchDefinitions}
                triggerReRender={triggerReRender}
            />
        )
    }

    return (
        <div className="defintions" >
            <div className="titleTab" >
                Belangrijkste definities
                <div className="addItemInTab">
                    <Add
                        onClick={() => toggleAddDefinition(true)}
                    />
                    <LocalModal
                        show={addDefintion}
                        toggleShow={toggleAddDefinition}
                        component={
                            <AddPracticeCard
                                type={1}
                                toggleShow={toggleAddDefinition}
                        
                                triggerReRender={triggerReRender}
                            />
                        }
                    />
                </div>
            </div>

            {
                loading && definitionsList.length === 0 ? <Loader /> :
                
                !definitionsList ? null :
                definitionsList.length === 0 ?
                <div className="noDefinitionsWarning" >
                    Wij hebben nog geen definities voor dit hoofdstuk
                </div>
                :
                <List
                    items={definitionsList}
                    renderItem={renderCard}
                />
            }

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchErrors}
            />

        </div>
    )
}

export default Definitions;
