
function userResponse({
    practiceCardId,
    correct
} = {}) {

    this.practiceCardId = practiceCardId;
    this.correct = correct;

}

export default userResponse;
