
// react
import React, { useState, useEffect } from 'react';

// routing
import { useParams } from 'react-router-dom';

// local services
import emptyListText from "./services/emptyListText";

// style
import "./Overview.scss";

// components
import List from "../../../../../../components/List";
import Loader from '../../../../../../components/Loader';
import Filters from "../../../../../../components/Filters";

// elements
import CheckBox from "../../../../../../components/AlterContent/elements/CheckBox";

// local components
import PracticeCard from "../PracticeCard";

// constants
import { PRACTICING } from "../../services/constants";

const Overview = (props) => {

    const [resetItemsState, triggerredReset] = useState(true);

    const renderCard = (i, cardData) => {
        return (
            <PracticeCard
                key={i}
                data={cardData}

                color={cardData.color}

                selectAll={props.filtersClass.selectAll}
                practiceCardList={props.practiceCardList}

                // selectFlashcards={props.filtersClass.selectionActive}

                triggerReRender={props.triggerReRender} // TODO: remove

                // resetItemsState={resetItemsState}
                // triggerredReset={triggerredReset}

                filtersClass={props.filtersClass}
                updateFiltersClass={props.updateFiltersClass}

                currentView={props.currentView}
            />
        )
    }

    const startPracticing = async() => {

        const validStart = await props.validateStart();
        if (!validStart) {
            return null;
        }

        if (props.filtersClass.selectItemsPossible) {
            props.updatePracticeCardList(() => {
                const filteredlist = props.filtersClass.toggleOnlyShowSelectedItems(props.fullPracticeCardList);
                return filteredlist;
            });
        }

        // make all filters invisible during practicing
        props.updateFiltersClass((filtersClass) => {

            filtersClass.filters.map((filter) => {
                filter.visible = false;
            });

            filtersClass.selectingItemsPresentAsFilterOption = false;
            if (filtersClass.selectItemsPossible === true) {
                filtersClass.onlyShowSelectedItems = true;
            }

            return filtersClass;
        });

        props.toggleLoading(true);

        // switch to practicing
        props.updateView(PRACTICING);
    }

    return (
        <div className="PracticeCardsView" >
            
            <div className="OverViewFilters" >
                <Filters
                    filtersClass={props.filtersClass}
                    updateFiltersClass={props.updateFiltersClass}

                    fullList={props.fullPracticeCardList}
                    updateFullList={props.updateFullPracticeCardList}

                    // might not need the list, just the update?
                    list={props.practiceCardList}
                    updateFilteredList={props.updatePracticeCardList}

                    customUpdate={props.customUpdate}

                    rerender={props.rerender}

                    // TODO: add nonFilterFetchParamaters
                    nonFilterFetchParams={props.nonFilterFetchParams}

                    styles={{
                        view: "practiceCardsOverviewFilterView"
                    }}
                />

                <div
                    className="practiceButton"
                    onClick={startPracticing}
                >

                    {props.selectFlashcards ? "Oefen geselecteerde" : "Oefenen"}

                </div>
            </div>

            <div className="PracticeCardsHolder" >
                {
                    !props.practiceCardList ? null : props.practiceCardList.length === 0 ? 
                    
                        props.loading ? 
                        <div className="emptyList">
                            <Loader />
                        </div> 
                        :
                        <div className="emptyList" >{
                            emptyListText()
                        }</div>

                    :

                    <List
                        items={props.practiceCardList}
                        renderItem={renderCard}
                    />
                    
                }
            </div>
        </div>
    )
}

export default Overview;
