
function newTextClass({
    textExplanation = ""
} = {}) {

    this.textExplanation = textExplanation;

}

export default newTextClass;
