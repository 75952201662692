
// classes
import ClientData from "../../../../services/classes/clientData/ClientData";

// classes local
import questionHubClass from "../classes/questionHubClass";
import answerHubClass from "../classes/answerHubClass";

// services
import createEmptyFeedback from "../createEmptyFeedback/createEmptyFeedback";
import sortFeedbackList from "../../components/Question/components/Feedback/services/sortFeedbackList";

// constants
import { MULTIPLE_CHOICE_QUESTION } from "../constants/typeConstants";

const initQuestionHubs = ({
    sentQuestions
} = {}) => {

    if (!sentQuestions) {
        return [];
    }
    
    let questionHubs = [];

    sentQuestions.map((question, i) => {

        let answerHubs = [];
        let numberOfCorrectAnswers = 0;
        let userResponse;
        let selectedAnswerIds = [];

        // add the answers!
        question.answers.map((answer, i) => {

            const newAnswerHub = new answerHubClass({
                questionId: question.questionId,
                
                answerId: answer.answerId,
                number: i,
                sentAnswer: {
                    title: answer.title,
                    correct: answer.correct,
                    points: answer.points,
                    state: answer.state,
                    userChoice: answer.userChoice,
                    userCorrect: answer.userCorrect,
                    scorePoints: answer.scorePoints,
                    userResponse: answer.userResponse
                }
            });

            if (answer.correct) {
                numberOfCorrectAnswers += 1;
            }
            
            // add existing selected answer if mc
            if (question.type === MULTIPLE_CHOICE_QUESTION && answer.selected) {
                selectedAnswerIds.push(answer.answerId);
            }
            
            // add existing answer
            if (answer.userResponse) {
                userResponse = answer.userResponse;
            }

            answerHubs = [...answerHubs, newAnswerHub];
        });

        // add oneCorrect
        question["oneCorrect"] = numberOfCorrectAnswers <= 1 ? true : false;
        // // add feedback manually for now
        // question["feedback"] = [
        //     {
        //         "id": 1,
        //         "model": "GPT-4.0", // "Student"
        //         "feedback": "<p>Heel goed</p>",
        //         "points": 1
        //     },
        //     {
        //         "id": 2,
        //         "model": "Student", // "Student"
        //         "feedback": "Specaculair goed",
        //         "points": 3
        //     }
        // ];
        const formattedFeedback = question["evaluations"]?.map((feedback) => {
            const data = new ClientData({
                id: feedback.id,
                sent: feedback,

                createEmptyInstance: createEmptyFeedback,
            });
            return data;
        });

        // const orderedFormattedFeedback = sortFeedbackList(formattedFeedback);
        const orderedFormattedFeedback = formattedFeedback;

        const newQuestionHub = new questionHubClass({
            // add sent question info to hub
            sentQuestion: question,
            questionId: question.questionId,
            number: i,

            feedback: orderedFormattedFeedback
        });

        newQuestionHub.answers = answerHubs;

        if (userResponse) {
            newQuestionHub["studentAnswer"] = userResponse;
        }
        if (selectedAnswerIds.length > 0) {
            newQuestionHub["answerId"] = [...selectedAnswerIds];
        }

        questionHubs = [...questionHubs, newQuestionHub];

    });

    return questionHubs;
}

export default initQuestionHubs;
