
class ItemSelection {
    constructor() {
        this.selectedItems = new Set();
    }

    selectItem(itemId) {
        this.selectedItems.add(itemId);
    }

    deselectItem(itemId) {
        this.selectedItems.delete(itemId);
    }

    toggleSelection(itemId) {
        if (this.selectedItems.has(itemId)) {
            this.deselectItem(itemId);
        } else {
            this.selectItem(itemId);
        }
    }

    clearSelection() {
        this.selectedItems.clear();
    }

    getSelection() {
        return Array.from(this.selectedItems);
    }

    isItemSelected(itemId) {
        return this.selectedItems.has(itemId);
    }

    isEmpty() {
        return this.selectedItems.size === 0;
    }
}

export default ItemSelection;
