
// classes
import errorClass from "../../../../errors/classes/errorClass";

// constants
import { UNAUTHORIZED } from "../../../../errors/constants";

const sessionEndedErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case UNAUTHORIZED:

            return new errorClass({
                errorCode: errorCode,
                title: "Sessie verlopen, je moet opnieuw inloggen.",
                description: "Je sessie is verlopen, je moet opnieuw inloggen.",
                moreInfo: null,
            });

        default:
            return null;
    }

}

export default sessionEndedErrors;
