
import { useState, useEffect } from "react";
import { BarChart, Label, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// styles
import "./ExamPointsGraph.scss";

// services
import networking from "../../../../services/handleNetworking/networking";
import reportApi from "../../../../services/Networking/report/report";

// component
import Loader from "../../../../components/Loader";

const ExamPointsGraph = (props) => {

    // states
    const [loading, toggleLoading] = useState(true);
    const [folded, toggleFolded] = useState(false);

    // data
    const [report, updateReport] = useState(null);

    const transformData = (data) => {
        const transformedData = data.map((item, index) => ({
            questionNumber: `Vraag ${index+1}`,
            ...item,
            remainingPoints: item.maxPoints - item.obtainedPoints,
        }));

        updateReport(transformedData);
    }
    const fetchReport = async() => {
        await networking({
            updateContent: transformData,
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: reportApi.getSubmittedExamReports,
            apiParams: props.takenExamUuId // "be1801c2-7a2f-4b6c-b0e5-a9ac70db2b66", // "9ce33727-882b-4ce1-9924-fffd36618962"

            // updateError: updateGetAssignmentError,
        });
    }
    useEffect(() => {
        if (props.takenExamUuId) {
            fetchReport();
        } else {
            toggleLoading(false);
        }
    }, [props.exam, props.takenExamUuId]);

    return (
        <div className="ExamPointsGraph" >
            <div className="ExamPointsGraphTitle">
                Behaalde punten overzicht
                {
                    !loading ? 
                    <div 
                        className="ExamPointsGraphFoldButton"
                        onClick={() => toggleFolded((value) => !value)}
                    >{
                        folded ? "Uitvouwen" : "Invouwen"
                    }</div>
                    :
                    <div className="ExamPointsGraphFoldButton">
                        <Loader />
                    </div>
                }
            </div>
            
            <>{
                folded || loading ? null :
                !report ? "Data not available" :
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={report}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="questionNumber" />
                        <YAxis>
                            <Label value="Punten" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                        </YAxis>
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="obtainedPoints" stackId="a" fill="#008000" name="Behaalde Punten" />
                        <Bar dataKey="remainingPoints" stackId="a" fill="#f86161" name="Gemiste Punten" />
                    </BarChart>
                </ResponsiveContainer>
            }</>
        </div>
    )
}

export default ExamPointsGraph;
