
// classes local
import answerPut from "../../../components/Question/services/classes/answerPut";

class answerHubClass {

    constructor({
        questionId = null,
        
        answerId = null,
        number = null,
        
        sentAnswer = null,
        answerPut = null,
        answerPost = null,

        answerDelete = null

    } = {}) {

        this.questionId = questionId;

        this.answerId = answerId;
        this.number = number;

        // should just be sentAnswer right?!
        this.sentAnswer = sentAnswer;
        this.answerPut = answerPut;
        this.answerPost = sentAnswer ? answerPost : {
            title: "",
            correct: number === 0 ? true : false,
            points: number === 0 ? 1 : 0
        };

        this.answerDelete = answerDelete;

    }

    /*
        Get values on answer
    */
    // getValue
    getCurrentValue = (key) => {

        if (this.sentAnswer) {
            if (this.answerPut) {
                return this.answerPut[key];
            }
            return this.sentAnswer[key];
        } else if (this.answerPost) {
            return this.answerPost[key];
        }
        return null;
    }

    // gets current answer version, also works if answer is deleted
    getAnswer = () => {
        if (!this.sentAnswer) {
            return this.answerPost;
        }

        if (this.answerPut) {
            return this.answerPut;
        }

        return this.sentAnswer;
    }

    // returns true if answer correct <- Can't I use getCurrentValue & delete this?
    answerCorrect = () => {
        
        if (this.getAnswer()) {

            // get relavant version and return if it is correct or not
            return this.getAnswer().correct;
        }

        // getAnswer return not valid
        return null;
        
    }

    // Indicates if answer is deleted
    answerDeleted = () => {

        if (this.answerDelete === null) {
            return false;
        }

        return true;

    }

    /*
        Update values on answer
    */
    // update property
    updateProperty = (key, newValue) => {        
        if (!key || newValue === null || newValue === undefined) {
            return null;
        }

        // handle post
        if (this.answerPost) {
            this.answerPost[key] = newValue;
            return newValue;
        }

        // handle put
        const valueToCompair = this.answerPut ? this.answerPut[key] : this.sentAnswer[key]

        if (!this.answerPut && valueToCompair !== newValue) {
            this.initAnswerPut();
        }

        if (valueToCompair !== newValue) {
            this.answerPut[key] = newValue;
            return newValue;
        }

        return null;

    }

    initAnswerPut = () => {
        if (!this.answerPut) {
            this.answerPut = new answerPut({
                title: this.sentAnswer.title,
                userResponse: this.sentAnswer.userResponse,
                correct: this.sentAnswer.correct,
                points: this.sentAnswer.points,
                userCorrect: this.sentAnswer.userCorrect,
                scorePoints: this.sentAnswer.scorePoints,
            });
        }
    }

    deleteAnswer = () => {

        // answer is already removed
        if (this.answerDelete !== null) {
            return null;
        }
        
        // if there is no sent ther must be a put
        if (!this.sentAnswer) {
            
            // give answerDelete the value of post
            this.answerDelete = this.answerPost;

            return true;
        }

        if (this.answerPut) {
            
            // give answerDelete the value of put
            this.answerDelete = this.answerPut;

            return true;
        }

        if (this.sentAnswer) {

            // give answerDelete the value of put
            this.answerDelete = this.sentAnswer;

            return true;
        }

    }

}

export default answerHubClass;
