
import { SUCCESS, INTERNAL_ERROR } from "../../../errors/constants";

// classes
import infoObj from "../../../classes/infoObj";

const clean = (durtyImage) => {

    if (durtyImage.includes('data:image/jpeg;base64,') || durtyImage.includes('data:image/png;base64,') || durtyImage.includes('data:image/jpg;base64,') || durtyImage.includes('data:image/webp;base64,')) {
        const cleanImage = durtyImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '').replace('data:image/jpg;base64,', '').replace('data:image/webp;base64,', '');
        
        return new infoObj({
            payload: cleanImage,
            status: SUCCESS
        });
    }
    return new infoObj({
        status: INTERNAL_ERROR
    });

}

export default clean;
