
// standard objects
import CustomErrorsManager from "../../../../../../../services/errors/classes/CustomErrorsManager";
import errorClass from "../../../../../../../services/errors/classes/errorClass";

// constants
import { INTERNAL_ERROR, NO_CONTENT } from "../../../../../../../services/errors/constants";

const validationWarnings = (errorCode) => {

    if (!errorCode) {
        return null;
    }

    switch (errorCode) {

        case NO_CONTENT:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "",
                description: "Je hebt één of meerdere vragen niet volledig ingevuld.",
                advice: null,
                moreInfo: null,
            });

        default:
            return null;
    }

}

export const validationWarningsClass = new CustomErrorsManager({
    [NO_CONTENT]: {
        title: "Je hebt één of meerdere vragen niet volledig ingevuld."
    }
});

export default validationWarnings;
