
// networking
import examsApi from "../../../../../../services/Networking/exams";

// services local
import initQuestionHubs from "../../../../services/initQuestionHubs";

const fetchExams = async({
        examId,
        state,

        handleApiReturn,
        updateExamClass,
        updateExamChat,
        updateQuestionHubs
    } = {}) => {
        if (!examId || !handleApiReturn || !updateExamClass || !updateQuestionHubs) {
            return null;
        }

        const returned = await examsApi.getExamByExamId({examId, state});

        const fetchSuccessfull = await handleApiReturn(returned);

        if (fetchSuccessfull) {
            const tempExamClass = returned.payload;

            if (updateExamChat) {
                updateExamChat(tempExamClass.category)
            }
            
            updateExamClass(tempExamClass);

            updateQuestionHubs(() => {

                return initQuestionHubs({sentQuestions: tempExamClass.questions});

            });

            return returned.payload;
        }

        return false;
}

export default fetchExams;
