
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// style
import "./Topic.scss";

// networking
import subTopicApi from '../../../../services/Networking/subTopic/subTopic';
import examsApi from '../../../../services/Networking/exams/exams';

// services
import access from '../../../../services/access/accessCheck/access';
import networking from '../../../../services/handleNetworking/networking/networking';
import topicApi from '../../../../services/Networking/topic/topic';

// componenets
import Redux from '../../../../components/Redux/Redux';
import List from '../../../../components/List/List';
import Loader from '../../../../components/Loader/Loader';
import LocalModal from '../../../../components/LocalModal';
import AddExam from '../../../../components/AddExam/AddExam';
import Delete from '../../../../components/Delete/Delete';
import AddSubTopic from '../../../Topic/components/AddSubTopic/AddSubTopic';

// components local
import SubTopic from "./components/subTopic";
import Exam from './components/Exam/ExamPrepExam';

// elements
import Pointer from "../../../../elements/Pointer/Pointer";
import Add from '../../../../elements/Add';

// constants
import { RIGHT, DOWN } from "../../../../elements/Pointer/services/constants";
import { NO_CONTENT, NO_RESPONSE, SUCCESS } from '../../../../services/errors/constants';
import { PREPARATORY } from '../../../../services/constants/topicTypeConstants';
import { FORMER_EXAMS, USER_RESULTS } from '../../services/constants';
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';
import { ADMIN, EDITOR } from '../../../../services/access/constants';
import { ALL, PUBLISHED } from '../../../../services/constants/stateConstants';

const Topic = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // states
    const [folded, toggleFolded] = useState(true);
    const [selectedSubTopic, updateSelectedSubTopic] = useState(null);
    const [addExam, toggleAddExam] = useState(false);
    const [rerender, triggerReRender] = useState(true);

    // loading
    const [loadingSubTopics, toggleLoadingSubTopics] = useState(props.isReport ? false : true);
    const [loadingExams, toggleLoadingExams] = useState(props.isReport ? false : true);

    // data
    const [subTopics, updateSubTopics] = useState(null);
    const [exams, updateExams] = useState([]);

    // adding content
    const [showAddSubTopic, toggleShowAddSubTopic] = useState(false);

    // errors
    const [fetchSubTopicError, updateFetchSubTopicError] = useState(null);
    const [fetchExamsError, updateFetchExamsError] = useState(null);

    const fetchSubTopics = async() => {
        
        const subTopicsTemp = await networking({
            updateContent: null,

            toggleLoading: toggleLoadingSubTopics,
                    
            api: subTopicApi.getSubTopicByTopicID,
            apiParams: {
                topicID: props.data.topicId,
                type: PREPARATORY
            },
        
            updateError: updateFetchSubTopicError,
        
        });

        if (subTopicsTemp.status == SUCCESS) {
            if (subTopicsTemp.payload.length > 0 && !selectedSubTopic) {
                // select first subtopic if no subtopic is already selected
                updateSelectedSubTopic(subTopicsTemp.payload[0].subtopicId);
            }

            updateSubTopics(subTopicsTemp.payload);
        }

    }

    // fetch subTopic exams
    const fetchExams = async() => {

        networking({
            updateContent: updateExams,

            toggleLoading: toggleLoadingExams,
            
            api: examsApi.getExamsBySubTopicId,
            apiParams: {
                subTopicId: selectedSubTopic,
                state: !user ? PUBLISHED : !user.user ? PUBLISHED : access({
                    accessLevel: EDITOR,
                    userRoles: user.user.roles
                }) ? ALL : PUBLISHED 
            },
        
            updateError: updateFetchExamsError,
        
        });
    }

    // fetch subtopics
    useEffect(() => {

        if (!props.isReport) {
            
            // report already contains subtopic info
            fetchSubTopics();

        } else {

            // select first in report if no subtopic id selected
            if (!selectedSubTopic) {

                if (props.data.subtopicReport) {
                    if (props.data.subtopicReport.length > 0) {
                        // select first subtopic if no subtopic is already selected
                        updateSelectedSubTopic(props.data.subtopicReport[0].id);
                    }
                }
            }

        }

    }, [rerender]);

    useEffect(() => {

        if (selectedSubTopic && !props.isReport) {
            // report already contains subtopic info and only fetch if there is a subTopic to fetch exams for
            fetchExams();
        } else {
            toggleLoadingExams(false);
        }
        
    }, [selectedSubTopic]);

    const renderSubTopic = (i, data) => {

        if (data) {

            const id = props.isReport ? data.id : data.subtopicId

            return (
                <SubTopic
                    key={i}
                    data={data}

                    id={id}
                    selected={id === selectedSubTopic}
                    
                    updateSelected={updateSelectedSubTopic}

                    fetchData={props.fetchData}

                />
            )
        }

    }

    const renderExam = (i, data) => {

        if (data) {

            const topicId = props.isReport ? props.data.id : props.data.topicId;
            const examId = props.isReport ? data.id : data.uuid;
            
            return (
                <Exam
                    key={i}
                    data={data}

                    user={user}

                    topicId={topicId}
                    subtopicId={selectedSubTopic}
                    examId={examId}
                />
            )
        }

    }

    const getExamReports = () => {

        const selectedSubTopicReport = props.data.subtopicReport.filter(subtopicReport => subtopicReport.id === selectedSubTopic);

        if (selectedSubTopicReport.length === 1) {
            if (selectedSubTopicReport[0]) {
                return selectedSubTopicReport[0].examReport;
            }
        }

    }

    return (
        
        <div
            className="holderExamPrep"
        >
            <div
                className={`topicExamPrep ${props.index == 0 ? "extraMarginTopExamPrep" : ""}`}
                onClick={() => toggleFolded(value => !value)}
            >
                <div className="topicTitleExamPrep">{
                    props.data.title
                }</div>

                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: ADMIN,
                        userRoles: user.user.roles
                    }) ? 
                    <Delete
                        api={topicApi.deleteTopicByTopicID}
                        id={props.data.topicId}

                        runOnSuccess={() => props.fetchData()}

                    /> : null
                }
                <div className="pointerHolderExamPrep" >
                    <Pointer 
                        direction={folded ? RIGHT : DOWN}
                    />
                </div>
                
            </div>

            {
                folded ? null :
                <div className="topicChildExamPrep" >
                    <div className="topicChildChildExamPrep">{
                        loadingSubTopics ? props.isReport ? false : 
                        <div className="examPrepLoader"><Loader /></div> :
                        
                        // props.isReport ? props.data.subtopicReport.length === 0 ? true : false : subTopics.length === 0 ? true : false ?
                        //     "No content"
                        //     :
                            <>
                            <List
                                items={props.isReport ? props.data.subtopicReport : subTopics}
                                renderItem={renderSubTopic}
                            />

                            {
                                !user ? null : !user.user ? null : access({
                                    accessLevel: ADMIN,
                                    userRoles: user.user.roles
                                }) ? 
                                <div
                                    className="examPrepPageAddSubTopic"
                                    onClick={() => toggleShowAddSubTopic(true)}
                                >
                                    Hoofdstuk toevoegen
                                </div> : null
                            }
                            </>
                            
                    }</div>
                    <div className="lineExamPrep" />
                    <div className="topicChildChildExamPrep">
                        {
                            loadingExams ?
                            <div className="examPrepLoader"><Loader /></div> :

                            !exams ? null : exams.length === 0 ? 
                            <div
                                className="ExamPrepNoExamsWarning"
                            >
                                Wij hebben nog geen examens voor dit jaar beschikbaar.
                            </div>
                            :
                            <List
                                items={props.isReport ? getExamReports() : exams}
                                renderItem={renderExam}
                            />
                        }

                        <>{
                            props.isReport ? 
                            null : 
                            <>
                                {
                                    !user ? null : !user.user ? null : access({
                                        accessLevel: EDITOR,
                                        userRoles: user.user.roles
                                    }) ?
                                    <div className="addItemInTab">
                                        <Add
                                            onClick={() => toggleAddExam(true)}
                                        />
                                    </div> : null
                                }
                                <LocalModal
                                    show={addExam}
                                    toggleShow={toggleAddExam}
                                    component={
                                        <AddExam
                                            type={1}
                                            toggleShow={toggleAddExam}

                                            subTopicType={PREPARATORY}
                                            topicId={props.data?.topicId}
                                            subTopicId={selectedSubTopic}
                                    
                                            triggerReRender={triggerReRender}
                                        />
                                    }
                                />
                            </>
                        }</>
                    </div>
                </div>
            }

            <LocalModal
                show={showAddSubTopic}
                toggleShow={toggleShowAddSubTopic}
                component={
                    <AddSubTopic
                        toggleShow={toggleShowAddSubTopic}

                        topicId={props.data?.topicId}

                        runOnSuccess={() => props.fetchData()}
                    />
                }
            />

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchSubTopicError}
            />

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchExamsError}
            />

        </div>
    )

}

export default Topic
