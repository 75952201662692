
import React, { useState, useEffect } from 'react';

// style
import "./delOption.scss"

// constants
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../../../../../../../services/constants/typeConstants";

const DelOption = (props) => {

    const [deleteActive, toggleDeleteActive] = useState(false);
    const toggleDeleteActiveFunc = (e) => {
        // prevent parent clickable
        e.stopPropagation();

        toggleDeleteActive(value => !value);

    }

    const deleteAnswer = (e) => {
        // prevent parent clickable
        e.stopPropagation();

        const answers = props.questionHub.getNotDelAnswers();
        if (!answers) {
            return null;
        }

        // check if only two answers
        if (answers.length === 2) {
            // add the switch to assay question
            props.questionHub.updateProperty("type", ASSAY_QUESTION);
            props.updateType();
        }

        // delete answer
        props.answerHub.deleteAnswer();

        // update shown points
        props.updatePoints();

        // toggle delete
        props.toggleAnsewerDeleted(true);

    }

    return (
        <div>{
            
            deleteActive == false ?
            
                <div
                    className="DelOptionNormal"

                    onClick={(e) => toggleDeleteActiveFunc(e)}
                >

                    Verwijderen
            
                </div>

                :
            
                <>
                
                    <div
                        className="DelAnswer"

                        onClick={(e) => deleteAnswer(e)}
                    >

                        Verwijderen
                    
                    </div>

                    <div
                        className="cancelDelAnswer"

                        onClick={(e) => toggleDeleteActiveFunc(e)}
                    >

                        Annuleren
                    
                    </div>


                </>

        }</div>
    )

}

export default DelOption
