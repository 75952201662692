
import React, { useState, useEffect } from 'react';

// style
import "./AssayAnswerInput.scss";

// components
import CkTextInput from "../../../../../../../../components/input/CkText";

// classes
import errorClass from "../../../../../../../../services/errors/classes";

// constants
import { SUCCESS, NO_CONTENT, UNAUTHORIZED } from "../../../../../../../../services/errors/constants";

const AnswerInput = (props) => {

    useEffect(() => {

        const userAnswer = props.questionHub.studentAnswer;
        if (!userAnswer) {
            return null;
        }

        // TEST THIS!
        // make sure its not changed if there is already a question error
        if (props.questionError.errorCode === SUCCESS && userAnswer.newAnswer === "") {
            props.updateQuestionError(new errorClass({
                errorCode: NO_CONTENT,
                description: "Niet Ingevuld!"
            }))
        }

    }, [props.handedIn]);

    return (
        <div className="assayAnswerInput" >
            <CkTextInput
                // config={new CkEditorConfig({
                //     toolbar: ['italic', 'link'],
                // })}
                
                // title="GEBEURTENIS TITLE"
                
                valueKey="studentAnswer"
                dataClass={props.questionHub}

                errorClass={props.questionError}
                updateError={props.updateQuestionError}
            />
        </div>
    )
}

export default AnswerInput;
