
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const commentsApi = {
    postComment,
    putComment,
    deleteComment,
    getComments,
}

async function postComment({takenExamUuid, comment}) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.post(
            `/takenExams/${takenExamUuid}/comments`,
            comment,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function putComment({commentId, comment}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.put(
            `/takenExams/comments/${commentId}`,
            comment,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function deleteComment(commentId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.delete(
            `/takenExams/comments/${commentId}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

async function getComments(takenExamUuid) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/takenExams/${takenExamUuid}/comments`,
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

export default commentsApi;
