
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const assignmentUserApi = {
    postUserToAssignmentsWithToken,
    postAssignedExamByUser,
    getLatestSubmission,
};

async function postUserToAssignmentsWithToken({ assignmentUuid, token }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/assignments/${assignmentUuid}/assign/${token}`,
            {},
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function postAssignedExamByUser({ assignmentUuid, itemUuid, state, userAnswers }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/assignments/${assignmentUuid}/items/${itemUuid}/exam?state=${state}`,
            userAnswers,
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function getLatestSubmission({ assignmentUuid, itemUuid, }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/assignments/${assignmentUuid}/items/${itemUuid}/submitted`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}


export default assignmentUserApi;
