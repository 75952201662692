
// delete
const createEditedExamInfo = (examClass) => {

    const editedExam = {
        title: examClass ? examClass.title : "",
        category: examClass ? examClass.category : null,
        state: examClass ? examClass.state : null,
    };

    return editedExam;

}

export default createEditedExamInfo;
