
import React, { useState, useEffect } from 'react';

// style
import "./QuestionImage.scss";

// services
import findEditedQuestion from "../../services/findEditedQuestion";

// classes
import errorClass from "../../../../../../services/errors/classes";

// compontents
import LocalModal from "../../../../../../components/LocalModal";
import ImageInput from "../../../../../../components/input/Image";

// constants
import { SUCCESS, NO_CONTENT } from "../../../../../../services/errors/constants";
import { EDIT } from '../../../../services/tabConstants';

const Image = (props) => {

    const [imageState, updateImageState] = useState(props.questionHub.getCurrentValue("image"));

    const [fullscreen, toggleFullScreen] = useState(false);
    const [imageError, updateImageError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const renderImage = () => {
        return (
            <img 
                src={imageState}
                className="QuestionImageFullScreen"
                onClick={() => toggleFullScreen((value) => !value)}
            />
        )
    }

    const updateImage = (valueKey, newText) => {
        // update property
        props.questionHub.updateProperty(valueKey, newText);

        // update state
        updateImageState(newText);
    }

    return (
        <div className="QuestionImageHolder" >
            {
                props.examView && imageState ?
                <>
                    <img 
                        src={imageState}
                        className="QuestionImageStyle"
                        onClick={() => toggleFullScreen((value) => !value)}
                    />

                    <LocalModal
                        show={fullscreen}
                        toggleShow={toggleFullScreen}
                        component={renderImage()}
                    />
                </>
                :
                null
            }
            
            {
                props.examView === EDIT ?
                    <>
                    <div
                        onClick={() => props.questionHub.initQuestionPut()}
                    >{
                        <ImageInput
                            // title="Nieuwe AFBEELDING"

                            valueKey="image"
                            dataClass={props.questionHub.getQuestion()}

                            customUpdateFunc={updateImage}

                            errorClass={imageError}
                            updateError={updateImageError}
                        />
                    }</div>

                    {
                    imageState ?
                    <div 
                        className="ReplaceImage"
                        onClick={() => updateImage("image", "")}
                    >
                        Afbeelding verwijderen
                    </div> : null
                    }
                    </>

                : null
            }
            

        </div>
    )

}

export default Image;
